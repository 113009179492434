/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #b2b2b2 #f1f1f1; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 15px; }

*::-webkit-scrollbar-track {
  background: #f1f1f1; }

*::-webkit-scrollbar-thumb {
  background-color: #b2b2b2; }

.app-wrapper .rbc-calendar .rbc-event {
  border: 1px solid #eeeeee; }

.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px; }

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px; }

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%; }

/* The screen (or content) of the device */
.smartphone .content {
  width: 100%;
  height: 100%;
  background: #e8eaf6; }

.clickable {
  cursor: pointer; }

.link {
  color: #673ab7; }
  .link:hover {
    cursor: pointer;
    color: #5c34a4;
    transition: transform .2s;
    transform: scale(1.01);
    text-decoration: underline; }

.tag {
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* TODO: use $color declaration */
.light-tag {
  color: #8492A6;
  background-color: transparent;
  border: 1px solid #8492A6; }

.success-tag {
  color: #4CAF50;
  background-color: transparent;
  border: 1px solid #4CAF50; }

.danger-tag {
  color: #F55859;
  background-color: transparent;
  border: 1px solid #F55859; }

.blink {
  -webkit-animation: 1.5s linear infinite condemned_blink_effect;
  animation: 1.5s linear infinite condemned_blink_effect; }

.rdw-editor-main {
  min-height: 300px;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  padding: 0 1rem; }

.toolbar-icon {
  width: 15px;
  height: 15px; }

.MuiDropzoneArea-root {
  min-height: 150px !important; }
