@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #b2b2b2 #f1f1f1; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 15px; }

*::-webkit-scrollbar-track {
  background: #f1f1f1; }

*::-webkit-scrollbar-thumb {
  background-color: #b2b2b2; }

.app-wrapper .rbc-calendar .rbc-event {
  border: 1px solid #eeeeee; }

.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px; }

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px; }

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%; }

/* The screen (or content) of the device */
.smartphone .content {
  width: 100%;
  height: 100%;
  background: #e8eaf6; }

.clickable {
  cursor: pointer; }

.link {
  color: #673ab7; }
  .link:hover {
    cursor: pointer;
    color: #5c34a4;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    text-decoration: underline; }

.tag {
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* TODO: use $color declaration */
.light-tag {
  color: #8492A6;
  background-color: transparent;
  border: 1px solid #8492A6; }

.success-tag {
  color: #4CAF50;
  background-color: transparent;
  border: 1px solid #4CAF50; }

.danger-tag {
  color: #F55859;
  background-color: transparent;
  border: 1px solid #F55859; }

.blink {
  -webkit-animation: 1.5s linear infinite condemned_blink_effect;
  animation: 1.5s linear infinite condemned_blink_effect; }

.rdw-editor-main {
  min-height: 300px;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  padding: 0 1rem; }

.toolbar-icon {
  width: 15px;
  height: 15px; }

.MuiDropzoneArea-root {
  min-height: 150px !important; }

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #673ab7;
  --secondary: #E91E63;
  --success: #4caf50;
  --info: #00bcd4;
  --warning: #ff9800;
  --danger: #f44336;
  --light: #e8eaf6;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f8f9fa; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 12px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #673ab7;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #512e90;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 12px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 24px; }

h2, .h2 {
  font-size: 20px; }

h3, .h3 {
  font-size: 17px; }

h4, .h4 {
  font-size: 15px; }

h5, .h5 {
  font-size: 13px; }

h6, .h6 {
  font-size: 12px; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 2px;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #f8f9fa; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d4c8eb; }

.table-hover .table-primary:hover {
  background-color: #c5b5e4; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c5b5e4; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f9c0d3; }

.table-hover .table-secondary:hover {
  background-color: #f7a9c3; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f7a9c3; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde9ce; }

.table-hover .table-success:hover {
  background-color: #bbe1bd; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bbe1bd; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8ecf3; }

.table-hover .table-info:hover {
  background-color: #a2e6ef; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a2e6ef; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe2b8; }

.table-hover .table-warning:hover {
  background-color: #ffd89f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffd89f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7; }

.table-hover .table-danger:hover {
  background-color: #fbb3af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbb3af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9f9fc; }

.table-hover .table-light:hover {
  background-color: #e8e8f4; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e8e8f4; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 18px;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 14px;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #673ab7;
  border-color: #673ab7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background-color: #57319a;
    border-color: #512e90; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(103, 58, 183, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #512e90;
    border-color: #4c2b87; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(103, 58, 183, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #673ab7;
  background-color: transparent;
  background-image: none;
  border-color: #673ab7; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #673ab7;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(103, 58, 183, 0.5); }

.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #673ab7;
  background-color: transparent; }
  .btn-link:hover {
    color: #512e90;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 14px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 14px;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
            user-select: none;
    background-color: #dee2e6;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 24px; }

.card-title {
  margin-bottom: 20px; }

.card-subtitle {
  margin-top: -10px;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 24px; }

.card-header {
  padding: 20px 24px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 20px 24px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -12px;
  margin-bottom: -20px;
  margin-left: -12px;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -12px;
  margin-left: -12px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 2px; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 18px;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #673ab7; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #512e90; }

.badge-secondary {
  color: #fff;
  background-color: #E91E63; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #c1134e; }

.badge-success {
  color: #fff;
  background-color: #4caf50; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #3d8b40; }

.badge-info {
  color: #fff;
  background-color: #00bcd4; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #008fa1; }

.badge-warning {
  color: #212529;
  background-color: #ff9800; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #cc7a00; }

.badge-danger {
  color: #fff;
  background-color: #f44336; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ea1c0d; }

.badge-light {
  color: #212529;
  background-color: #e8eaf6; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #c3c9e8; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 6px; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #361e5f;
  background-color: #e1d8f1;
  border-color: #d4c8eb; }
  .alert-primary hr {
    border-top-color: #c5b5e4; }
  .alert-primary .alert-link {
    color: #201238; }

.alert-secondary {
  color: #791033;
  background-color: #fbd2e0;
  border-color: #f9c0d3; }
  .alert-secondary hr {
    border-top-color: #f7a9c3; }
  .alert-secondary .alert-link {
    color: #4c0a20; }

.alert-success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce; }
  .alert-success hr {
    border-top-color: #bbe1bd; }
  .alert-success .alert-link {
    color: #18381a; }

.alert-info {
  color: #00626e;
  background-color: #ccf2f6;
  border-color: #b8ecf3; }
  .alert-info hr {
    border-top-color: #a2e6ef; }
  .alert-info .alert-link {
    color: #00353b; }

.alert-warning {
  color: #854f00;
  background-color: #ffeacc;
  border-color: #ffe2b8; }
  .alert-warning hr {
    border-top-color: #ffd89f; }
  .alert-warning .alert-link {
    color: #523100; }

.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7; }
  .alert-danger hr {
    border-top-color: #fbb3af; }
  .alert-danger .alert-link {
    color: #551713; }

.alert-light {
  color: #797a80;
  background-color: #fafbfd;
  border-color: #f9f9fc; }
  .alert-light hr {
    border-top-color: #e8e8f4; }
  .alert-light .alert-link {
    color: #606166; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #361e5f;
  background-color: #d4c8eb; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #361e5f;
    background-color: #c5b5e4; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #361e5f;
    border-color: #361e5f; }

.list-group-item-secondary {
  color: #791033;
  background-color: #f9c0d3; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #791033;
    background-color: #f7a9c3; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #791033;
    border-color: #791033; }

.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #285b2a;
    background-color: #bbe1bd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #285b2a;
    border-color: #285b2a; }

.list-group-item-info {
  color: #00626e;
  background-color: #b8ecf3; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #00626e;
    background-color: #a2e6ef; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00626e;
    border-color: #00626e; }

.list-group-item-warning {
  color: #854f00;
  background-color: #ffe2b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #854f00;
    background-color: #ffd89f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #854f00;
    border-color: #854f00; }

.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f231c;
    background-color: #fbb3af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f231c;
    border-color: #7f231c; }

.list-group-item-light {
  color: #797a80;
  background-color: #f9f9fc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #797a80;
    background-color: #e8e8f4; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #797a80;
    border-color: #797a80; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 6px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 6px 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 6px 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 14px;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(6px - 1px);
  border-top-right-radius: calc(6px - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #673ab7 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #512e90 !important; }

.bg-secondary {
  background-color: #E91E63 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #c1134e !important; }

.bg-success {
  background-color: #4caf50 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important; }

.bg-info {
  background-color: #00bcd4 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #008fa1 !important; }

.bg-warning {
  background-color: #ff9800 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc7a00 !important; }

.bg-danger {
  background-color: #f44336 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important; }

.bg-light {
  background-color: #e8eaf6 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c3c9e8 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #673ab7 !important; }

.border-secondary {
  border-color: #E91E63 !important; }

.border-success {
  border-color: #4caf50 !important; }

.border-info {
  border-color: #00bcd4 !important; }

.border-warning {
  border-color: #ff9800 !important; }

.border-danger {
  border-color: #f44336 !important; }

.border-light {
  border-color: #e8eaf6 !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 2px !important; }

.rounded-top {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important; }

.rounded-right {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important; }

.rounded-bottom {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-left {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
          clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-dark {
  color: #333 !important; }

.text-primary {
  color: #673ab7 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #512e90 !important; }

.text-secondary {
  color: #E91E63 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #c1134e !important; }

.text-success {
  color: #4caf50 !important; }

a.text-success:hover, a.text-success:focus {
  color: #3d8b40 !important; }

.text-info {
  color: #00bcd4 !important; }

a.text-info:hover, a.text-info:focus {
  color: #008fa1 !important; }

.text-warning {
  color: #ff9800 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #cc7a00 !important; }

.text-danger {
  color: #f44336 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ea1c0d !important; }

.text-light {
  color: #e8eaf6 !important; }

a.text-light:hover, a.text-light:focus {
  color: #c3c9e8 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/*
Author         : G-axon
Template Name  : Jumbo React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
    B. Base
        01. Base Styles
        02. Typography Styles
    C. Layouts
        01. App Layout Styles
        02. Sidebar Styles
        03. Header Styles
        04. Main Content Styles
        05. Footer Styles
        06. Right Sidebar Styles
    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. Badges Styles
        04. Blockquotes Styles
        05. Bottom Navigation Styles
        06. Breadcrumb Styles
        07. Button Styles
        08. Button Group Styles
        09. Callout Styles
        10. Cards Styles
        11. Charts Styles
        12. Chips Styles
        13. Carousel Styles
        14. Color Picker Styles
        15. Drawer Styles
        16. E-commerce Styles
        17. Grids List Styles
        18. Icons Styles
        19. Label Styles
        20. List Styles
        21. Nav Style
        22. Pricing Styles
        23. Progressbar Styles
        24. Popover Styles
        25. React Joyride Styles
        26. Tables Styles
        27. Tabs Styles
        28. Testimonial Styles
        29. Time Lines Style
        30. Joyride Tour Styles
        31. Form Styles
    E. Pages
        01. Login Styles
        02. Error Styles
        03. Editor Styles
        04. Contact Page Styles
        05. Portfolio Styles
        06. Faqs Styles
        07. Dashboard Styles
    F. Slider
    G. Modules
        01. App Module Style
        02. Chat Module Styles
        03. Mails Module Styles
        04. Contact Module Styles
        05. Calendar Module Style
    H. Color Theme
        01. Theme Indigo Styles
        02. Theme Cyan Styles
        03. Theme Amber Styles
        04. Theme Deep Orange Styles
        05. Theme Pink Styles
        06. Theme Blue Styles
        07. Theme Deep Purple Styles
        08. Theme Green Styles
        09. Theme Dark Indigo Styles
        10. Theme Dark Cyan Styles
        11. Theme Dark Amber Styles
        12. Theme Dark Deep Orange Styles
        13. Theme Dark Pink Styles
        14. Theme Dark Blue Styles
        15. Theme Dark Deep Purple Styles
        16. Theme Dark Green Styles
  =============================================================*/
/* Globals
   ========================================================================== */
.jr-menu-icon .menu-icon {
  position: relative;
  width: 70%;
  height: 1px;
  min-height: 1px;
  color: inherit;
  background-color: #fff;
  display: block;
  transition: all 0.4s ease; }
  .jr-menu-icon .menu-icon:after, .jr-menu-icon .menu-icon:before {
    position: absolute;
    display: block;
    color: inherit;
    background-color: inherit;
    left: 0;
    height: 1px;
    min-height: 1px;
    content: "";
    transition: all 0.4s ease; }
  .jr-menu-icon .menu-icon:after {
    width: 120%;
    bottom: -6px; }
  .jr-menu-icon .menu-icon:before {
    width: 130%;
    top: -6px; }

.jr-menu-icon:hover .menu-icon {
  width: 100%; }
  .jr-menu-icon:hover .menu-icon:after, .jr-menu-icon:hover .menu-icon:before {
    width: 100%; }

.size-50, .user-avatar, .jr-fab-btn {
  height: 50px !important;
  width: 50px !important;
  line-height: 50px; }

.size-60, .jr-btn-fab-lg {
  height: 60px !important;
  width: 60px !important;
  line-height: 60px; }

.size-80 {
  height: 80px !important;
  width: 80px !important;
  line-height: 80px; }

.size-100, .user-list .user-avatar {
  height: 100px !important;
  width: 100px !important;
  line-height: 100px; }

.size-30, .jr-menu-icon, .jr-btn-fab-xs, .icon {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px; }

.size-40, .jr-btn-fab-sm {
  height: 40px !important;
  width: 40px !important;
  line-height: 40px; }

.size-120, .profile-intro img.avatar-circle {
  height: 120px !important;
  width: 120px !important;
  line-height: 120px; }

.icon-addon {
  display: inline-block;
  text-align: center;
  background-color: inherit; }

.page-heading, .jr-card {
  padding: 18px 24px;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 2px;
  position: relative; }

/* Ripple magic */
.ripple-effect {
  position: relative;
  overflow: hidden; }
  .ripple-effect:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    -webkit-transform: scale(1, 1) translate(-50%);
            transform: scale(1, 1) translate(-50%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  .ripple-effect:hover:after {
    -webkit-animation: ripple 1s ease-out;
            animation: ripple 1s ease-out; }

@keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0.5; }
  20% {
    -webkit-transform: scale(60, 60);
            transform: scale(60, 60);
    opacity: 0.3; }
  100% {
    opacity: 0;
    -webkit-transform: scale(100, 100);
            transform: scale(100, 100); } }

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0.5; }
  20% {
    -webkit-transform: scale(60, 60);
            transform: scale(60, 60);
    opacity: 0.3; }
  100% {
    opacity: 0;
    -webkit-transform: scale(100, 100);
            transform: scale(100, 100); } }

.quick-menu .icon-alert:after {
  display: block;
  -o-box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0); } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(244, 67, 54, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0); } }

.chat-avatar-mode .chat-mode.online, .chat-avatar-mode .chat-mode.offline {
  display: block;
  -o-box-shadow: 0 0 0 rgba(40, 167, 69, 0.4);
  box-shadow: 0 0 0 rgba(40, 167, 69, 0.4);
  -webkit-animation: online 2s infinite;
          animation: online 2s infinite; }

@-webkit-keyframes online {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0); } }

@keyframes online {
  0% {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(40, 167, 69, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0); } }

.chat-avatar-mode .chat-mode.away {
  display: block;
  -o-box-shadow: 0 0 0 rgba(255, 193, 7, 0.4);
  box-shadow: 0 0 0 rgba(255, 193, 7, 0.4);
  -webkit-animation: away 2s infinite;
          animation: away 2s infinite; }

@-webkit-keyframes away {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 193, 7, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0); } }

@keyframes away {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 193, 7, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0); } }

/*Different size images set in Equal block height and width*/
.grid-thumb-equal, .brand-logo {
  position: relative;
  padding-bottom: 68%;
  height: 0;
  width: 100%;
  overflow: hidden; }

.grid-thumb-cover, .brand-logo .brand-logo-inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%; }

.grid-thumb-cover img {
  height: auto;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 100%; }

a.btn-info, a.btn-danger, a.btn-warning, a.btn-success, a.btn-dark, a.badge-success, a.badge-danger, a.badge-warning, a.badge-info, a.badge-dark {
  color: #fff !important; }
  a.btn-info:hover, a.btn-danger:hover, a.btn-warning:hover, a.btn-success:hover, a.btn-dark:hover, a.badge-success:hover, a.badge-danger:hover, a.badge-warning:hover, a.badge-info:hover, a.badge-dark:hover, a.btn-info:focus, a.btn-danger:focus, a.btn-warning:focus, a.btn-success:focus, a.btn-dark:focus, a.badge-success:focus, a.badge-danger:focus, a.badge-warning:focus, a.badge-info:focus, a.badge-dark:focus {
    color: #fff !important; }

.carousel-control-prev, .carousel-control-next {
  cursor: pointer; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-25 {
  max-width: 25% !important; }

.mw-50 {
  max-width: 50% !important; }

.mw-75 {
  max-width: 75% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-25 {
  max-height: 25% !important; }

.mh-50 {
  max-height: 50% !important; }

.mh-75 {
  max-height: 75% !important; }

.mh-100 {
  max-height: 100% !important; }

.bg-primary {
  background-color: #3f51b5 !important; }

.text-primary {
  color: #3f51b5 !important; }
  .text-primary.icon-outline {
    border-color: #3f51b5 !important; }

.bg-primary.lighten-5 {
  background-color: #e8eaf6 !important; }

.text-primary.text-lighten-5 {
  color: #e8eaf6 !important; }
  .text-primary.text-lighten-5.icon-outline {
    border-color: #e8eaf6 !important; }

.bg-primary.lighten-4 {
  background-color: #c5cae9 !important; }

.text-primary.text-lighten-4 {
  color: #c5cae9 !important; }
  .text-primary.text-lighten-4.icon-outline {
    border-color: #c5cae9 !important; }

.bg-primary.lighten-3 {
  background-color: #9fa8da !important; }

.text-primary.text-lighten-3 {
  color: #9fa8da !important; }
  .text-primary.text-lighten-3.icon-outline {
    border-color: #9fa8da !important; }

.bg-primary.lighten-2 {
  background-color: #7986cb !important; }

.text-primary.text-lighten-2 {
  color: #7986cb !important; }
  .text-primary.text-lighten-2.icon-outline {
    border-color: #7986cb !important; }

.bg-primary.lighten-1 {
  background-color: #5c6bc0 !important; }

.text-primary.text-lighten-1 {
  color: #5c6bc0 !important; }
  .text-primary.text-lighten-1.icon-outline {
    border-color: #5c6bc0 !important; }

.bg-primary.darken-1 {
  background-color: #3949ab !important; }

.text-primary.text-darken-1 {
  color: #3949ab !important; }
  .text-primary.text-darken-1.icon-outline {
    border-color: #3949ab !important; }

.bg-primary.darken-2 {
  background-color: #303f9f !important; }

.text-primary.text-darken-2 {
  color: #303f9f !important; }
  .text-primary.text-darken-2.icon-outline {
    border-color: #303f9f !important; }

.bg-primary.darken-3 {
  background-color: #283593 !important; }

.text-primary.text-darken-3 {
  color: #283593 !important; }
  .text-primary.text-darken-3.icon-outline {
    border-color: #283593 !important; }

.bg-primary.darken-4 {
  background-color: #1a237e !important; }

.text-primary.text-darken-4 {
  color: #1a237e !important; }
  .text-primary.text-darken-4.icon-outline {
    border-color: #1a237e !important; }

.bg-primary.accent-1 {
  background-color: #8c9eff !important; }

.text-primary.text-accent-1 {
  color: #8c9eff !important; }
  .text-primary.text-accent-1.icon-outline {
    border-color: #8c9eff !important; }

.bg-primary.accent-2 {
  background-color: #536dfe !important; }

.text-primary.text-accent-2 {
  color: #536dfe !important; }
  .text-primary.text-accent-2.icon-outline {
    border-color: #536dfe !important; }

.bg-primary.accent-3 {
  background-color: #3d5afe !important; }

.text-primary.text-accent-3 {
  color: #3d5afe !important; }
  .text-primary.text-accent-3.icon-outline {
    border-color: #3d5afe !important; }

.bg-primary.accent-4 {
  background-color: #304ffe !important; }

.text-primary.text-accent-4 {
  color: #304ffe !important; }
  .text-primary.text-accent-4.icon-outline {
    border-color: #304ffe !important; }

.bg-red {
  background-color: #F44336 !important; }

.text-red {
  color: #F44336 !important; }
  .text-red.icon-outline {
    border-color: #F44336 !important; }

.bg-red.lighten-5 {
  background-color: #FFEBEE !important; }

.text-red.text-lighten-5 {
  color: #FFEBEE !important; }
  .text-red.text-lighten-5.icon-outline {
    border-color: #FFEBEE !important; }

.bg-red.lighten-4 {
  background-color: #FFCDD2 !important; }

.text-red.text-lighten-4 {
  color: #FFCDD2 !important; }
  .text-red.text-lighten-4.icon-outline {
    border-color: #FFCDD2 !important; }

.bg-red.lighten-3 {
  background-color: #EF9A9A !important; }

.text-red.text-lighten-3 {
  color: #EF9A9A !important; }
  .text-red.text-lighten-3.icon-outline {
    border-color: #EF9A9A !important; }

.bg-red.lighten-2 {
  background-color: #E57373 !important; }

.text-red.text-lighten-2 {
  color: #E57373 !important; }
  .text-red.text-lighten-2.icon-outline {
    border-color: #E57373 !important; }

.bg-red.lighten-1 {
  background-color: #EF5350 !important; }

.text-red.text-lighten-1 {
  color: #EF5350 !important; }
  .text-red.text-lighten-1.icon-outline {
    border-color: #EF5350 !important; }

.bg-red.darken-1 {
  background-color: #E53935 !important; }

.text-red.text-darken-1 {
  color: #E53935 !important; }
  .text-red.text-darken-1.icon-outline {
    border-color: #E53935 !important; }

.bg-red.darken-2 {
  background-color: #D32F2F !important; }

.text-red.text-darken-2 {
  color: #D32F2F !important; }
  .text-red.text-darken-2.icon-outline {
    border-color: #D32F2F !important; }

.bg-red.darken-3 {
  background-color: #C62828 !important; }

.text-red.text-darken-3 {
  color: #C62828 !important; }
  .text-red.text-darken-3.icon-outline {
    border-color: #C62828 !important; }

.bg-red.darken-4 {
  background-color: #B71C1C !important; }

.text-red.text-darken-4 {
  color: #B71C1C !important; }
  .text-red.text-darken-4.icon-outline {
    border-color: #B71C1C !important; }

.bg-red.accent-1 {
  background-color: #FF8A80 !important; }

.text-red.text-accent-1 {
  color: #FF8A80 !important; }
  .text-red.text-accent-1.icon-outline {
    border-color: #FF8A80 !important; }

.bg-red.accent-2 {
  background-color: #FF5252 !important; }

.text-red.text-accent-2 {
  color: #FF5252 !important; }
  .text-red.text-accent-2.icon-outline {
    border-color: #FF5252 !important; }

.bg-red.accent-3 {
  background-color: #FF1744 !important; }

.text-red.text-accent-3 {
  color: #FF1744 !important; }
  .text-red.text-accent-3.icon-outline {
    border-color: #FF1744 !important; }

.bg-red.accent-4 {
  background-color: #D50000 !important; }

.text-red.text-accent-4 {
  color: #D50000 !important; }
  .text-red.text-accent-4.icon-outline {
    border-color: #D50000 !important; }

.bg-pink {
  background-color: #e91e63 !important; }

.text-pink {
  color: #e91e63 !important; }
  .text-pink.icon-outline {
    border-color: #e91e63 !important; }

.bg-pink.lighten-5 {
  background-color: #fce4ec !important; }

.text-pink.text-lighten-5 {
  color: #fce4ec !important; }
  .text-pink.text-lighten-5.icon-outline {
    border-color: #fce4ec !important; }

.bg-pink.lighten-4 {
  background-color: #f8bbd0 !important; }

.text-pink.text-lighten-4 {
  color: #f8bbd0 !important; }
  .text-pink.text-lighten-4.icon-outline {
    border-color: #f8bbd0 !important; }

.bg-pink.lighten-3 {
  background-color: #f48fb1 !important; }

.text-pink.text-lighten-3 {
  color: #f48fb1 !important; }
  .text-pink.text-lighten-3.icon-outline {
    border-color: #f48fb1 !important; }

.bg-pink.lighten-2 {
  background-color: #f06292 !important; }

.text-pink.text-lighten-2 {
  color: #f06292 !important; }
  .text-pink.text-lighten-2.icon-outline {
    border-color: #f06292 !important; }

.bg-pink.lighten-1 {
  background-color: #ec407a !important; }

.text-pink.text-lighten-1 {
  color: #ec407a !important; }
  .text-pink.text-lighten-1.icon-outline {
    border-color: #ec407a !important; }

.bg-pink.darken-1 {
  background-color: #d81b60 !important; }

.text-pink.text-darken-1 {
  color: #d81b60 !important; }
  .text-pink.text-darken-1.icon-outline {
    border-color: #d81b60 !important; }

.bg-pink.darken-2 {
  background-color: #c2185b !important; }

.text-pink.text-darken-2 {
  color: #c2185b !important; }
  .text-pink.text-darken-2.icon-outline {
    border-color: #c2185b !important; }

.bg-pink.darken-3 {
  background-color: #ad1457 !important; }

.text-pink.text-darken-3 {
  color: #ad1457 !important; }
  .text-pink.text-darken-3.icon-outline {
    border-color: #ad1457 !important; }

.bg-pink.darken-4 {
  background-color: #880e4f !important; }

.text-pink.text-darken-4 {
  color: #880e4f !important; }
  .text-pink.text-darken-4.icon-outline {
    border-color: #880e4f !important; }

.bg-pink.accent-1 {
  background-color: #ff80ab !important; }

.text-pink.text-accent-1 {
  color: #ff80ab !important; }
  .text-pink.text-accent-1.icon-outline {
    border-color: #ff80ab !important; }

.bg-pink.accent-2 {
  background-color: #ff4081 !important; }

.text-pink.text-accent-2 {
  color: #ff4081 !important; }
  .text-pink.text-accent-2.icon-outline {
    border-color: #ff4081 !important; }

.bg-pink.accent-3 {
  background-color: #f50057 !important; }

.text-pink.text-accent-3 {
  color: #f50057 !important; }
  .text-pink.text-accent-3.icon-outline {
    border-color: #f50057 !important; }

.bg-pink.accent-4 {
  background-color: #c51162 !important; }

.text-pink.text-accent-4 {
  color: #c51162 !important; }
  .text-pink.text-accent-4.icon-outline {
    border-color: #c51162 !important; }

.bg-purple {
  background-color: #9C27B0 !important; }

.text-purple {
  color: #9C27B0 !important; }
  .text-purple.icon-outline {
    border-color: #9C27B0 !important; }

.bg-purple.lighten-5 {
  background-color: #f3e5f5 !important; }

.text-purple.text-lighten-5 {
  color: #f3e5f5 !important; }
  .text-purple.text-lighten-5.icon-outline {
    border-color: #f3e5f5 !important; }

.bg-purple.lighten-4 {
  background-color: #e1bee7 !important; }

.text-purple.text-lighten-4 {
  color: #e1bee7 !important; }
  .text-purple.text-lighten-4.icon-outline {
    border-color: #e1bee7 !important; }

.bg-purple.lighten-3 {
  background-color: #ce93d8 !important; }

.text-purple.text-lighten-3 {
  color: #ce93d8 !important; }
  .text-purple.text-lighten-3.icon-outline {
    border-color: #ce93d8 !important; }

.bg-purple.lighten-2 {
  background-color: #ba68c8 !important; }

.text-purple.text-lighten-2 {
  color: #ba68c8 !important; }
  .text-purple.text-lighten-2.icon-outline {
    border-color: #ba68c8 !important; }

.bg-purple.lighten-1 {
  background-color: #ab47bc !important; }

.text-purple.text-lighten-1 {
  color: #ab47bc !important; }
  .text-purple.text-lighten-1.icon-outline {
    border-color: #ab47bc !important; }

.bg-purple.darken-1 {
  background-color: #8e24aa !important; }

.text-purple.text-darken-1 {
  color: #8e24aa !important; }
  .text-purple.text-darken-1.icon-outline {
    border-color: #8e24aa !important; }

.bg-purple.darken-2 {
  background-color: #7b1fa2 !important; }

.text-purple.text-darken-2 {
  color: #7b1fa2 !important; }
  .text-purple.text-darken-2.icon-outline {
    border-color: #7b1fa2 !important; }

.bg-purple.darken-3 {
  background-color: #6a1b9a !important; }

.text-purple.text-darken-3 {
  color: #6a1b9a !important; }
  .text-purple.text-darken-3.icon-outline {
    border-color: #6a1b9a !important; }

.bg-purple.darken-4 {
  background-color: #4a148c !important; }

.text-purple.text-darken-4 {
  color: #4a148c !important; }
  .text-purple.text-darken-4.icon-outline {
    border-color: #4a148c !important; }

.bg-purple.accent-1 {
  background-color: #ea80fc !important; }

.text-purple.text-accent-1 {
  color: #ea80fc !important; }
  .text-purple.text-accent-1.icon-outline {
    border-color: #ea80fc !important; }

.bg-purple.accent-2 {
  background-color: #e040fb !important; }

.text-purple.text-accent-2 {
  color: #e040fb !important; }
  .text-purple.text-accent-2.icon-outline {
    border-color: #e040fb !important; }

.bg-purple.accent-3 {
  background-color: #d500f9 !important; }

.text-purple.text-accent-3 {
  color: #d500f9 !important; }
  .text-purple.text-accent-3.icon-outline {
    border-color: #d500f9 !important; }

.bg-purple.accent-4 {
  background-color: #aa00ff !important; }

.text-purple.text-accent-4 {
  color: #aa00ff !important; }
  .text-purple.text-accent-4.icon-outline {
    border-color: #aa00ff !important; }

.bg-deep-purple {
  background-color: #673ab7 !important; }

.text-deep-purple {
  color: #673ab7 !important; }
  .text-deep-purple.icon-outline {
    border-color: #673ab7 !important; }

.bg-deep-purple.lighten-5 {
  background-color: #ede7f6 !important; }

.text-deep-purple.text-lighten-5 {
  color: #ede7f6 !important; }
  .text-deep-purple.text-lighten-5.icon-outline {
    border-color: #ede7f6 !important; }

.bg-deep-purple.lighten-4 {
  background-color: #d1c4e9 !important; }

.text-deep-purple.text-lighten-4 {
  color: #d1c4e9 !important; }
  .text-deep-purple.text-lighten-4.icon-outline {
    border-color: #d1c4e9 !important; }

.bg-deep-purple.lighten-3 {
  background-color: #b39ddb !important; }

.text-deep-purple.text-lighten-3 {
  color: #b39ddb !important; }
  .text-deep-purple.text-lighten-3.icon-outline {
    border-color: #b39ddb !important; }

.bg-deep-purple.lighten-2 {
  background-color: #9575cd !important; }

.text-deep-purple.text-lighten-2 {
  color: #9575cd !important; }
  .text-deep-purple.text-lighten-2.icon-outline {
    border-color: #9575cd !important; }

.bg-deep-purple.lighten-1 {
  background-color: #7e57c2 !important; }

.text-deep-purple.text-lighten-1 {
  color: #7e57c2 !important; }
  .text-deep-purple.text-lighten-1.icon-outline {
    border-color: #7e57c2 !important; }

.bg-deep-purple.darken-1 {
  background-color: #5e35b1 !important; }

.text-deep-purple.text-darken-1 {
  color: #5e35b1 !important; }
  .text-deep-purple.text-darken-1.icon-outline {
    border-color: #5e35b1 !important; }

.bg-deep-purple.darken-2 {
  background-color: #512da8 !important; }

.text-deep-purple.text-darken-2 {
  color: #512da8 !important; }
  .text-deep-purple.text-darken-2.icon-outline {
    border-color: #512da8 !important; }

.bg-deep-purple.darken-3 {
  background-color: #4527a0 !important; }

.text-deep-purple.text-darken-3 {
  color: #4527a0 !important; }
  .text-deep-purple.text-darken-3.icon-outline {
    border-color: #4527a0 !important; }

.bg-deep-purple.darken-4 {
  background-color: #311b92 !important; }

.text-deep-purple.text-darken-4 {
  color: #311b92 !important; }
  .text-deep-purple.text-darken-4.icon-outline {
    border-color: #311b92 !important; }

.bg-deep-purple.accent-1 {
  background-color: #b388ff !important; }

.text-deep-purple.text-accent-1 {
  color: #b388ff !important; }
  .text-deep-purple.text-accent-1.icon-outline {
    border-color: #b388ff !important; }

.bg-deep-purple.accent-2 {
  background-color: #7c4dff !important; }

.text-deep-purple.text-accent-2 {
  color: #7c4dff !important; }
  .text-deep-purple.text-accent-2.icon-outline {
    border-color: #7c4dff !important; }

.bg-deep-purple.accent-3 {
  background-color: #651fff !important; }

.text-deep-purple.text-accent-3 {
  color: #651fff !important; }
  .text-deep-purple.text-accent-3.icon-outline {
    border-color: #651fff !important; }

.bg-deep-purple.accent-4 {
  background-color: #6200ea !important; }

.text-deep-purple.text-accent-4 {
  color: #6200ea !important; }
  .text-deep-purple.text-accent-4.icon-outline {
    border-color: #6200ea !important; }

.bg-indigo {
  background-color: #3f51b5 !important; }

.text-indigo {
  color: #3f51b5 !important; }
  .text-indigo.icon-outline {
    border-color: #3f51b5 !important; }

.bg-indigo.lighten-5 {
  background-color: #e8eaf6 !important; }

.text-indigo.text-lighten-5 {
  color: #e8eaf6 !important; }
  .text-indigo.text-lighten-5.icon-outline {
    border-color: #e8eaf6 !important; }

.bg-indigo.lighten-4 {
  background-color: #c5cae9 !important; }

.text-indigo.text-lighten-4 {
  color: #c5cae9 !important; }
  .text-indigo.text-lighten-4.icon-outline {
    border-color: #c5cae9 !important; }

.bg-indigo.lighten-3 {
  background-color: #9fa8da !important; }

.text-indigo.text-lighten-3 {
  color: #9fa8da !important; }
  .text-indigo.text-lighten-3.icon-outline {
    border-color: #9fa8da !important; }

.bg-indigo.lighten-2 {
  background-color: #7986cb !important; }

.text-indigo.text-lighten-2 {
  color: #7986cb !important; }
  .text-indigo.text-lighten-2.icon-outline {
    border-color: #7986cb !important; }

.bg-indigo.lighten-1 {
  background-color: #5c6bc0 !important; }

.text-indigo.text-lighten-1 {
  color: #5c6bc0 !important; }
  .text-indigo.text-lighten-1.icon-outline {
    border-color: #5c6bc0 !important; }

.bg-indigo.darken-1 {
  background-color: #3949ab !important; }

.text-indigo.text-darken-1 {
  color: #3949ab !important; }
  .text-indigo.text-darken-1.icon-outline {
    border-color: #3949ab !important; }

.bg-indigo.darken-2 {
  background-color: #303f9f !important; }

.text-indigo.text-darken-2 {
  color: #303f9f !important; }
  .text-indigo.text-darken-2.icon-outline {
    border-color: #303f9f !important; }

.bg-indigo.darken-3 {
  background-color: #283593 !important; }

.text-indigo.text-darken-3 {
  color: #283593 !important; }
  .text-indigo.text-darken-3.icon-outline {
    border-color: #283593 !important; }

.bg-indigo.darken-4 {
  background-color: #1a237e !important; }

.text-indigo.text-darken-4 {
  color: #1a237e !important; }
  .text-indigo.text-darken-4.icon-outline {
    border-color: #1a237e !important; }

.bg-indigo.accent-1 {
  background-color: #8c9eff !important; }

.text-indigo.text-accent-1 {
  color: #8c9eff !important; }
  .text-indigo.text-accent-1.icon-outline {
    border-color: #8c9eff !important; }

.bg-indigo.accent-2 {
  background-color: #536dfe !important; }

.text-indigo.text-accent-2 {
  color: #536dfe !important; }
  .text-indigo.text-accent-2.icon-outline {
    border-color: #536dfe !important; }

.bg-indigo.accent-3 {
  background-color: #3d5afe !important; }

.text-indigo.text-accent-3 {
  color: #3d5afe !important; }
  .text-indigo.text-accent-3.icon-outline {
    border-color: #3d5afe !important; }

.bg-indigo.accent-4 {
  background-color: #304ffe !important; }

.text-indigo.text-accent-4 {
  color: #304ffe !important; }
  .text-indigo.text-accent-4.icon-outline {
    border-color: #304ffe !important; }

.bg-blue {
  background-color: #2196F3 !important; }

.text-blue {
  color: #2196F3 !important; }
  .text-blue.icon-outline {
    border-color: #2196F3 !important; }

.bg-blue.lighten-5 {
  background-color: #E3F2FD !important; }

.text-blue.text-lighten-5 {
  color: #E3F2FD !important; }
  .text-blue.text-lighten-5.icon-outline {
    border-color: #E3F2FD !important; }

.bg-blue.lighten-4 {
  background-color: #BBDEFB !important; }

.text-blue.text-lighten-4 {
  color: #BBDEFB !important; }
  .text-blue.text-lighten-4.icon-outline {
    border-color: #BBDEFB !important; }

.bg-blue.lighten-3 {
  background-color: #90CAF9 !important; }

.text-blue.text-lighten-3 {
  color: #90CAF9 !important; }
  .text-blue.text-lighten-3.icon-outline {
    border-color: #90CAF9 !important; }

.bg-blue.lighten-2 {
  background-color: #64B5F6 !important; }

.text-blue.text-lighten-2 {
  color: #64B5F6 !important; }
  .text-blue.text-lighten-2.icon-outline {
    border-color: #64B5F6 !important; }

.bg-blue.lighten-1 {
  background-color: #42A5F5 !important; }

.text-blue.text-lighten-1 {
  color: #42A5F5 !important; }
  .text-blue.text-lighten-1.icon-outline {
    border-color: #42A5F5 !important; }

.bg-blue.darken-1 {
  background-color: #1E88E5 !important; }

.text-blue.text-darken-1 {
  color: #1E88E5 !important; }
  .text-blue.text-darken-1.icon-outline {
    border-color: #1E88E5 !important; }

.bg-blue.darken-2 {
  background-color: #1976D2 !important; }

.text-blue.text-darken-2 {
  color: #1976D2 !important; }
  .text-blue.text-darken-2.icon-outline {
    border-color: #1976D2 !important; }

.bg-blue.darken-3 {
  background-color: #1565C0 !important; }

.text-blue.text-darken-3 {
  color: #1565C0 !important; }
  .text-blue.text-darken-3.icon-outline {
    border-color: #1565C0 !important; }

.bg-blue.darken-4 {
  background-color: #0D47A1 !important; }

.text-blue.text-darken-4 {
  color: #0D47A1 !important; }
  .text-blue.text-darken-4.icon-outline {
    border-color: #0D47A1 !important; }

.bg-blue.accent-1 {
  background-color: #82B1FF !important; }

.text-blue.text-accent-1 {
  color: #82B1FF !important; }
  .text-blue.text-accent-1.icon-outline {
    border-color: #82B1FF !important; }

.bg-blue.accent-2 {
  background-color: #448AFF !important; }

.text-blue.text-accent-2 {
  color: #448AFF !important; }
  .text-blue.text-accent-2.icon-outline {
    border-color: #448AFF !important; }

.bg-blue.accent-3 {
  background-color: #2979FF !important; }

.text-blue.text-accent-3 {
  color: #2979FF !important; }
  .text-blue.text-accent-3.icon-outline {
    border-color: #2979FF !important; }

.bg-blue.accent-4 {
  background-color: #2962FF !important; }

.text-blue.text-accent-4 {
  color: #2962FF !important; }
  .text-blue.text-accent-4.icon-outline {
    border-color: #2962FF !important; }

.bg-light-blue {
  background-color: #03a9f4 !important; }

.text-light-blue {
  color: #03a9f4 !important; }
  .text-light-blue.icon-outline {
    border-color: #03a9f4 !important; }

.bg-light-blue.lighten-5 {
  background-color: #e1f5fe !important; }

.text-light-blue.text-lighten-5 {
  color: #e1f5fe !important; }
  .text-light-blue.text-lighten-5.icon-outline {
    border-color: #e1f5fe !important; }

.bg-light-blue.lighten-4 {
  background-color: #b3e5fc !important; }

.text-light-blue.text-lighten-4 {
  color: #b3e5fc !important; }
  .text-light-blue.text-lighten-4.icon-outline {
    border-color: #b3e5fc !important; }

.bg-light-blue.lighten-3 {
  background-color: #81d4fa !important; }

.text-light-blue.text-lighten-3 {
  color: #81d4fa !important; }
  .text-light-blue.text-lighten-3.icon-outline {
    border-color: #81d4fa !important; }

.bg-light-blue.lighten-2 {
  background-color: #4fc3f7 !important; }

.text-light-blue.text-lighten-2 {
  color: #4fc3f7 !important; }
  .text-light-blue.text-lighten-2.icon-outline {
    border-color: #4fc3f7 !important; }

.bg-light-blue.lighten-1 {
  background-color: #29b6f6 !important; }

.text-light-blue.text-lighten-1 {
  color: #29b6f6 !important; }
  .text-light-blue.text-lighten-1.icon-outline {
    border-color: #29b6f6 !important; }

.bg-light-blue.darken-1 {
  background-color: #039be5 !important; }

.text-light-blue.text-darken-1 {
  color: #039be5 !important; }
  .text-light-blue.text-darken-1.icon-outline {
    border-color: #039be5 !important; }

.bg-light-blue.darken-2 {
  background-color: #0288d1 !important; }

.text-light-blue.text-darken-2 {
  color: #0288d1 !important; }
  .text-light-blue.text-darken-2.icon-outline {
    border-color: #0288d1 !important; }

.bg-light-blue.darken-3 {
  background-color: #0277bd !important; }

.text-light-blue.text-darken-3 {
  color: #0277bd !important; }
  .text-light-blue.text-darken-3.icon-outline {
    border-color: #0277bd !important; }

.bg-light-blue.darken-4 {
  background-color: #01579b !important; }

.text-light-blue.text-darken-4 {
  color: #01579b !important; }
  .text-light-blue.text-darken-4.icon-outline {
    border-color: #01579b !important; }

.bg-light-blue.accent-1 {
  background-color: #80d8ff !important; }

.text-light-blue.text-accent-1 {
  color: #80d8ff !important; }
  .text-light-blue.text-accent-1.icon-outline {
    border-color: #80d8ff !important; }

.bg-light-blue.accent-2 {
  background-color: #40c4ff !important; }

.text-light-blue.text-accent-2 {
  color: #40c4ff !important; }
  .text-light-blue.text-accent-2.icon-outline {
    border-color: #40c4ff !important; }

.bg-light-blue.accent-3 {
  background-color: #00b0ff !important; }

.text-light-blue.text-accent-3 {
  color: #00b0ff !important; }
  .text-light-blue.text-accent-3.icon-outline {
    border-color: #00b0ff !important; }

.bg-light-blue.accent-4 {
  background-color: #0091ea !important; }

.text-light-blue.text-accent-4 {
  color: #0091ea !important; }
  .text-light-blue.text-accent-4.icon-outline {
    border-color: #0091ea !important; }

.bg-cyan {
  background-color: #00bcd4 !important; }

.text-cyan {
  color: #00bcd4 !important; }
  .text-cyan.icon-outline {
    border-color: #00bcd4 !important; }

.bg-cyan.lighten-5 {
  background-color: #e0f7fa !important; }

.text-cyan.text-lighten-5 {
  color: #e0f7fa !important; }
  .text-cyan.text-lighten-5.icon-outline {
    border-color: #e0f7fa !important; }

.bg-cyan.lighten-4 {
  background-color: #b2ebf2 !important; }

.text-cyan.text-lighten-4 {
  color: #b2ebf2 !important; }
  .text-cyan.text-lighten-4.icon-outline {
    border-color: #b2ebf2 !important; }

.bg-cyan.lighten-3 {
  background-color: #80deea !important; }

.text-cyan.text-lighten-3 {
  color: #80deea !important; }
  .text-cyan.text-lighten-3.icon-outline {
    border-color: #80deea !important; }

.bg-cyan.lighten-2 {
  background-color: #4dd0e1 !important; }

.text-cyan.text-lighten-2 {
  color: #4dd0e1 !important; }
  .text-cyan.text-lighten-2.icon-outline {
    border-color: #4dd0e1 !important; }

.bg-cyan.lighten-1 {
  background-color: #26c6da !important; }

.text-cyan.text-lighten-1 {
  color: #26c6da !important; }
  .text-cyan.text-lighten-1.icon-outline {
    border-color: #26c6da !important; }

.bg-cyan.darken-1 {
  background-color: #00acc1 !important; }

.text-cyan.text-darken-1 {
  color: #00acc1 !important; }
  .text-cyan.text-darken-1.icon-outline {
    border-color: #00acc1 !important; }

.bg-cyan.darken-2 {
  background-color: #0097a7 !important; }

.text-cyan.text-darken-2 {
  color: #0097a7 !important; }
  .text-cyan.text-darken-2.icon-outline {
    border-color: #0097a7 !important; }

.bg-cyan.darken-3 {
  background-color: #00838f !important; }

.text-cyan.text-darken-3 {
  color: #00838f !important; }
  .text-cyan.text-darken-3.icon-outline {
    border-color: #00838f !important; }

.bg-cyan.darken-4 {
  background-color: #006064 !important; }

.text-cyan.text-darken-4 {
  color: #006064 !important; }
  .text-cyan.text-darken-4.icon-outline {
    border-color: #006064 !important; }

.bg-cyan.accent-1 {
  background-color: #84ffff !important; }

.text-cyan.text-accent-1 {
  color: #84ffff !important; }
  .text-cyan.text-accent-1.icon-outline {
    border-color: #84ffff !important; }

.bg-cyan.accent-2 {
  background-color: #18ffff !important; }

.text-cyan.text-accent-2 {
  color: #18ffff !important; }
  .text-cyan.text-accent-2.icon-outline {
    border-color: #18ffff !important; }

.bg-cyan.accent-3 {
  background-color: #00e5ff !important; }

.text-cyan.text-accent-3 {
  color: #00e5ff !important; }
  .text-cyan.text-accent-3.icon-outline {
    border-color: #00e5ff !important; }

.bg-cyan.accent-4 {
  background-color: #00b8d4 !important; }

.text-cyan.text-accent-4 {
  color: #00b8d4 !important; }
  .text-cyan.text-accent-4.icon-outline {
    border-color: #00b8d4 !important; }

.bg-teal {
  background-color: #009688 !important; }

.text-teal {
  color: #009688 !important; }
  .text-teal.icon-outline {
    border-color: #009688 !important; }

.bg-teal.lighten-5 {
  background-color: #e0f2f1 !important; }

.text-teal.text-lighten-5 {
  color: #e0f2f1 !important; }
  .text-teal.text-lighten-5.icon-outline {
    border-color: #e0f2f1 !important; }

.bg-teal.lighten-4 {
  background-color: #b2dfdb !important; }

.text-teal.text-lighten-4 {
  color: #b2dfdb !important; }
  .text-teal.text-lighten-4.icon-outline {
    border-color: #b2dfdb !important; }

.bg-teal.lighten-3 {
  background-color: #80cbc4 !important; }

.text-teal.text-lighten-3 {
  color: #80cbc4 !important; }
  .text-teal.text-lighten-3.icon-outline {
    border-color: #80cbc4 !important; }

.bg-teal.lighten-2 {
  background-color: #4db6ac !important; }

.text-teal.text-lighten-2 {
  color: #4db6ac !important; }
  .text-teal.text-lighten-2.icon-outline {
    border-color: #4db6ac !important; }

.bg-teal.lighten-1 {
  background-color: #26a69a !important; }

.text-teal.text-lighten-1 {
  color: #26a69a !important; }
  .text-teal.text-lighten-1.icon-outline {
    border-color: #26a69a !important; }

.bg-teal.darken-1 {
  background-color: #00897b !important; }

.text-teal.text-darken-1 {
  color: #00897b !important; }
  .text-teal.text-darken-1.icon-outline {
    border-color: #00897b !important; }

.bg-teal.darken-2 {
  background-color: #00796b !important; }

.text-teal.text-darken-2 {
  color: #00796b !important; }
  .text-teal.text-darken-2.icon-outline {
    border-color: #00796b !important; }

.bg-teal.darken-3 {
  background-color: #00695c !important; }

.text-teal.text-darken-3 {
  color: #00695c !important; }
  .text-teal.text-darken-3.icon-outline {
    border-color: #00695c !important; }

.bg-teal.darken-4 {
  background-color: #004d40 !important; }

.text-teal.text-darken-4 {
  color: #004d40 !important; }
  .text-teal.text-darken-4.icon-outline {
    border-color: #004d40 !important; }

.bg-teal.accent-1 {
  background-color: #a7ffeb !important; }

.text-teal.text-accent-1 {
  color: #a7ffeb !important; }
  .text-teal.text-accent-1.icon-outline {
    border-color: #a7ffeb !important; }

.bg-teal.accent-2 {
  background-color: #64ffda !important; }

.text-teal.text-accent-2 {
  color: #64ffda !important; }
  .text-teal.text-accent-2.icon-outline {
    border-color: #64ffda !important; }

.bg-teal.accent-3 {
  background-color: #1de9b6 !important; }

.text-teal.text-accent-3 {
  color: #1de9b6 !important; }
  .text-teal.text-accent-3.icon-outline {
    border-color: #1de9b6 !important; }

.bg-teal.accent-4 {
  background-color: #00bfa5 !important; }

.text-teal.text-accent-4 {
  color: #00bfa5 !important; }
  .text-teal.text-accent-4.icon-outline {
    border-color: #00bfa5 !important; }

.bg-green {
  background-color: #4CAF50 !important; }

.text-green {
  color: #4CAF50 !important; }
  .text-green.icon-outline {
    border-color: #4CAF50 !important; }

.bg-green.lighten-5 {
  background-color: #E8F5E9 !important; }

.text-green.text-lighten-5 {
  color: #E8F5E9 !important; }
  .text-green.text-lighten-5.icon-outline {
    border-color: #E8F5E9 !important; }

.bg-green.lighten-4 {
  background-color: #C8E6C9 !important; }

.text-green.text-lighten-4 {
  color: #C8E6C9 !important; }
  .text-green.text-lighten-4.icon-outline {
    border-color: #C8E6C9 !important; }

.bg-green.lighten-3 {
  background-color: #A5D6A7 !important; }

.text-green.text-lighten-3 {
  color: #A5D6A7 !important; }
  .text-green.text-lighten-3.icon-outline {
    border-color: #A5D6A7 !important; }

.bg-green.lighten-2 {
  background-color: #81C784 !important; }

.text-green.text-lighten-2 {
  color: #81C784 !important; }
  .text-green.text-lighten-2.icon-outline {
    border-color: #81C784 !important; }

.bg-green.lighten-1 {
  background-color: #66BB6A !important; }

.text-green.text-lighten-1 {
  color: #66BB6A !important; }
  .text-green.text-lighten-1.icon-outline {
    border-color: #66BB6A !important; }

.bg-green.darken-1 {
  background-color: #43A047 !important; }

.text-green.text-darken-1 {
  color: #43A047 !important; }
  .text-green.text-darken-1.icon-outline {
    border-color: #43A047 !important; }

.bg-green.darken-2 {
  background-color: #388E3C !important; }

.text-green.text-darken-2 {
  color: #388E3C !important; }
  .text-green.text-darken-2.icon-outline {
    border-color: #388E3C !important; }

.bg-green.darken-3 {
  background-color: #2E7D32 !important; }

.text-green.text-darken-3 {
  color: #2E7D32 !important; }
  .text-green.text-darken-3.icon-outline {
    border-color: #2E7D32 !important; }

.bg-green.darken-4 {
  background-color: #1B5E20 !important; }

.text-green.text-darken-4 {
  color: #1B5E20 !important; }
  .text-green.text-darken-4.icon-outline {
    border-color: #1B5E20 !important; }

.bg-green.accent-1 {
  background-color: #B9F6CA !important; }

.text-green.text-accent-1 {
  color: #B9F6CA !important; }
  .text-green.text-accent-1.icon-outline {
    border-color: #B9F6CA !important; }

.bg-green.accent-2 {
  background-color: #69F0AE !important; }

.text-green.text-accent-2 {
  color: #69F0AE !important; }
  .text-green.text-accent-2.icon-outline {
    border-color: #69F0AE !important; }

.bg-green.accent-3 {
  background-color: #00E676 !important; }

.text-green.text-accent-3 {
  color: #00E676 !important; }
  .text-green.text-accent-3.icon-outline {
    border-color: #00E676 !important; }

.bg-green.accent-4 {
  background-color: #00C853 !important; }

.text-green.text-accent-4 {
  color: #00C853 !important; }
  .text-green.text-accent-4.icon-outline {
    border-color: #00C853 !important; }

.bg-light-green {
  background-color: #8bc34a !important; }

.text-light-green {
  color: #8bc34a !important; }
  .text-light-green.icon-outline {
    border-color: #8bc34a !important; }

.bg-light-green.lighten-5 {
  background-color: #f1f8e9 !important; }

.text-light-green.text-lighten-5 {
  color: #f1f8e9 !important; }
  .text-light-green.text-lighten-5.icon-outline {
    border-color: #f1f8e9 !important; }

.bg-light-green.lighten-4 {
  background-color: #dcedc8 !important; }

.text-light-green.text-lighten-4 {
  color: #dcedc8 !important; }
  .text-light-green.text-lighten-4.icon-outline {
    border-color: #dcedc8 !important; }

.bg-light-green.lighten-3 {
  background-color: #c5e1a5 !important; }

.text-light-green.text-lighten-3 {
  color: #c5e1a5 !important; }
  .text-light-green.text-lighten-3.icon-outline {
    border-color: #c5e1a5 !important; }

.bg-light-green.lighten-2 {
  background-color: #aed581 !important; }

.text-light-green.text-lighten-2 {
  color: #aed581 !important; }
  .text-light-green.text-lighten-2.icon-outline {
    border-color: #aed581 !important; }

.bg-light-green.lighten-1 {
  background-color: #9ccc65 !important; }

.text-light-green.text-lighten-1 {
  color: #9ccc65 !important; }
  .text-light-green.text-lighten-1.icon-outline {
    border-color: #9ccc65 !important; }

.bg-light-green.darken-1 {
  background-color: #7cb342 !important; }

.text-light-green.text-darken-1 {
  color: #7cb342 !important; }
  .text-light-green.text-darken-1.icon-outline {
    border-color: #7cb342 !important; }

.bg-light-green.darken-2 {
  background-color: #689f38 !important; }

.text-light-green.text-darken-2 {
  color: #689f38 !important; }
  .text-light-green.text-darken-2.icon-outline {
    border-color: #689f38 !important; }

.bg-light-green.darken-3 {
  background-color: #558b2f !important; }

.text-light-green.text-darken-3 {
  color: #558b2f !important; }
  .text-light-green.text-darken-3.icon-outline {
    border-color: #558b2f !important; }

.bg-light-green.darken-4 {
  background-color: #33691e !important; }

.text-light-green.text-darken-4 {
  color: #33691e !important; }
  .text-light-green.text-darken-4.icon-outline {
    border-color: #33691e !important; }

.bg-light-green.accent-1 {
  background-color: #ccff90 !important; }

.text-light-green.text-accent-1 {
  color: #ccff90 !important; }
  .text-light-green.text-accent-1.icon-outline {
    border-color: #ccff90 !important; }

.bg-light-green.accent-2 {
  background-color: #b2ff59 !important; }

.text-light-green.text-accent-2 {
  color: #b2ff59 !important; }
  .text-light-green.text-accent-2.icon-outline {
    border-color: #b2ff59 !important; }

.bg-light-green.accent-3 {
  background-color: #76ff03 !important; }

.text-light-green.text-accent-3 {
  color: #76ff03 !important; }
  .text-light-green.text-accent-3.icon-outline {
    border-color: #76ff03 !important; }

.bg-light-green.accent-4 {
  background-color: #64dd17 !important; }

.text-light-green.text-accent-4 {
  color: #64dd17 !important; }
  .text-light-green.text-accent-4.icon-outline {
    border-color: #64dd17 !important; }

.bg-lime {
  background-color: #cddc39 !important; }

.text-lime {
  color: #cddc39 !important; }
  .text-lime.icon-outline {
    border-color: #cddc39 !important; }

.bg-lime.lighten-5 {
  background-color: #f9fbe7 !important; }

.text-lime.text-lighten-5 {
  color: #f9fbe7 !important; }
  .text-lime.text-lighten-5.icon-outline {
    border-color: #f9fbe7 !important; }

.bg-lime.lighten-4 {
  background-color: #f0f4c3 !important; }

.text-lime.text-lighten-4 {
  color: #f0f4c3 !important; }
  .text-lime.text-lighten-4.icon-outline {
    border-color: #f0f4c3 !important; }

.bg-lime.lighten-3 {
  background-color: #e6ee9c !important; }

.text-lime.text-lighten-3 {
  color: #e6ee9c !important; }
  .text-lime.text-lighten-3.icon-outline {
    border-color: #e6ee9c !important; }

.bg-lime.lighten-2 {
  background-color: #dce775 !important; }

.text-lime.text-lighten-2 {
  color: #dce775 !important; }
  .text-lime.text-lighten-2.icon-outline {
    border-color: #dce775 !important; }

.bg-lime.lighten-1 {
  background-color: #d4e157 !important; }

.text-lime.text-lighten-1 {
  color: #d4e157 !important; }
  .text-lime.text-lighten-1.icon-outline {
    border-color: #d4e157 !important; }

.bg-lime.darken-1 {
  background-color: #c0ca33 !important; }

.text-lime.text-darken-1 {
  color: #c0ca33 !important; }
  .text-lime.text-darken-1.icon-outline {
    border-color: #c0ca33 !important; }

.bg-lime.darken-2 {
  background-color: #afb42b !important; }

.text-lime.text-darken-2 {
  color: #afb42b !important; }
  .text-lime.text-darken-2.icon-outline {
    border-color: #afb42b !important; }

.bg-lime.darken-3 {
  background-color: #9e9d24 !important; }

.text-lime.text-darken-3 {
  color: #9e9d24 !important; }
  .text-lime.text-darken-3.icon-outline {
    border-color: #9e9d24 !important; }

.bg-lime.darken-4 {
  background-color: #827717 !important; }

.text-lime.text-darken-4 {
  color: #827717 !important; }
  .text-lime.text-darken-4.icon-outline {
    border-color: #827717 !important; }

.bg-lime.accent-1 {
  background-color: #f4ff81 !important; }

.text-lime.text-accent-1 {
  color: #f4ff81 !important; }
  .text-lime.text-accent-1.icon-outline {
    border-color: #f4ff81 !important; }

.bg-lime.accent-2 {
  background-color: #eeff41 !important; }

.text-lime.text-accent-2 {
  color: #eeff41 !important; }
  .text-lime.text-accent-2.icon-outline {
    border-color: #eeff41 !important; }

.bg-lime.accent-3 {
  background-color: #c6ff00 !important; }

.text-lime.text-accent-3 {
  color: #c6ff00 !important; }
  .text-lime.text-accent-3.icon-outline {
    border-color: #c6ff00 !important; }

.bg-lime.accent-4 {
  background-color: #aeea00 !important; }

.text-lime.text-accent-4 {
  color: #aeea00 !important; }
  .text-lime.text-accent-4.icon-outline {
    border-color: #aeea00 !important; }

.bg-yellow {
  background-color: #ffeb3b !important; }

.text-yellow {
  color: #ffeb3b !important; }
  .text-yellow.icon-outline {
    border-color: #ffeb3b !important; }

.bg-yellow.lighten-5 {
  background-color: #fffde7 !important; }

.text-yellow.text-lighten-5 {
  color: #fffde7 !important; }
  .text-yellow.text-lighten-5.icon-outline {
    border-color: #fffde7 !important; }

.bg-yellow.lighten-4 {
  background-color: #fff9c4 !important; }

.text-yellow.text-lighten-4 {
  color: #fff9c4 !important; }
  .text-yellow.text-lighten-4.icon-outline {
    border-color: #fff9c4 !important; }

.bg-yellow.lighten-3 {
  background-color: #fff59d !important; }

.text-yellow.text-lighten-3 {
  color: #fff59d !important; }
  .text-yellow.text-lighten-3.icon-outline {
    border-color: #fff59d !important; }

.bg-yellow.lighten-2 {
  background-color: #fff176 !important; }

.text-yellow.text-lighten-2 {
  color: #fff176 !important; }
  .text-yellow.text-lighten-2.icon-outline {
    border-color: #fff176 !important; }

.bg-yellow.lighten-1 {
  background-color: #ffee58 !important; }

.text-yellow.text-lighten-1 {
  color: #ffee58 !important; }
  .text-yellow.text-lighten-1.icon-outline {
    border-color: #ffee58 !important; }

.bg-yellow.darken-1 {
  background-color: #fdd835 !important; }

.text-yellow.text-darken-1 {
  color: #fdd835 !important; }
  .text-yellow.text-darken-1.icon-outline {
    border-color: #fdd835 !important; }

.bg-yellow.darken-2 {
  background-color: #fbc02d !important; }

.text-yellow.text-darken-2 {
  color: #fbc02d !important; }
  .text-yellow.text-darken-2.icon-outline {
    border-color: #fbc02d !important; }

.bg-yellow.darken-3 {
  background-color: #f9a825 !important; }

.text-yellow.text-darken-3 {
  color: #f9a825 !important; }
  .text-yellow.text-darken-3.icon-outline {
    border-color: #f9a825 !important; }

.bg-yellow.darken-4 {
  background-color: #f57f17 !important; }

.text-yellow.text-darken-4 {
  color: #f57f17 !important; }
  .text-yellow.text-darken-4.icon-outline {
    border-color: #f57f17 !important; }

.bg-yellow.accent-1 {
  background-color: #ffff8d !important; }

.text-yellow.text-accent-1 {
  color: #ffff8d !important; }
  .text-yellow.text-accent-1.icon-outline {
    border-color: #ffff8d !important; }

.bg-yellow.accent-2 {
  background-color: #ffff00 !important; }

.text-yellow.text-accent-2 {
  color: #ffff00 !important; }
  .text-yellow.text-accent-2.icon-outline {
    border-color: #ffff00 !important; }

.bg-yellow.accent-3 {
  background-color: #ffea00 !important; }

.text-yellow.text-accent-3 {
  color: #ffea00 !important; }
  .text-yellow.text-accent-3.icon-outline {
    border-color: #ffea00 !important; }

.bg-yellow.accent-4 {
  background-color: #ffd600 !important; }

.text-yellow.text-accent-4 {
  color: #ffd600 !important; }
  .text-yellow.text-accent-4.icon-outline {
    border-color: #ffd600 !important; }

.bg-amber {
  background-color: #ffc107 !important; }

.text-amber {
  color: #ffc107 !important; }
  .text-amber.icon-outline {
    border-color: #ffc107 !important; }

.bg-amber.lighten-5 {
  background-color: #fff8e1 !important; }

.text-amber.text-lighten-5 {
  color: #fff8e1 !important; }
  .text-amber.text-lighten-5.icon-outline {
    border-color: #fff8e1 !important; }

.bg-amber.lighten-4 {
  background-color: #ffecb3 !important; }

.text-amber.text-lighten-4 {
  color: #ffecb3 !important; }
  .text-amber.text-lighten-4.icon-outline {
    border-color: #ffecb3 !important; }

.bg-amber.lighten-3 {
  background-color: #ffe082 !important; }

.text-amber.text-lighten-3 {
  color: #ffe082 !important; }
  .text-amber.text-lighten-3.icon-outline {
    border-color: #ffe082 !important; }

.bg-amber.lighten-2 {
  background-color: #ffd54f !important; }

.text-amber.text-lighten-2 {
  color: #ffd54f !important; }
  .text-amber.text-lighten-2.icon-outline {
    border-color: #ffd54f !important; }

.bg-amber.lighten-1 {
  background-color: #ffca28 !important; }

.text-amber.text-lighten-1 {
  color: #ffca28 !important; }
  .text-amber.text-lighten-1.icon-outline {
    border-color: #ffca28 !important; }

.bg-amber.darken-1 {
  background-color: #ffb300 !important; }

.text-amber.text-darken-1 {
  color: #ffb300 !important; }
  .text-amber.text-darken-1.icon-outline {
    border-color: #ffb300 !important; }

.bg-amber.darken-2 {
  background-color: #ffa000 !important; }

.text-amber.text-darken-2 {
  color: #ffa000 !important; }
  .text-amber.text-darken-2.icon-outline {
    border-color: #ffa000 !important; }

.bg-amber.darken-3 {
  background-color: #ff8f00 !important; }

.text-amber.text-darken-3 {
  color: #ff8f00 !important; }
  .text-amber.text-darken-3.icon-outline {
    border-color: #ff8f00 !important; }

.bg-amber.darken-4 {
  background-color: #ff6f00 !important; }

.text-amber.text-darken-4 {
  color: #ff6f00 !important; }
  .text-amber.text-darken-4.icon-outline {
    border-color: #ff6f00 !important; }

.bg-amber.accent-1 {
  background-color: #ffe57f !important; }

.text-amber.text-accent-1 {
  color: #ffe57f !important; }
  .text-amber.text-accent-1.icon-outline {
    border-color: #ffe57f !important; }

.bg-amber.accent-2 {
  background-color: #ffd740 !important; }

.text-amber.text-accent-2 {
  color: #ffd740 !important; }
  .text-amber.text-accent-2.icon-outline {
    border-color: #ffd740 !important; }

.bg-amber.accent-3 {
  background-color: #ffc400 !important; }

.text-amber.text-accent-3 {
  color: #ffc400 !important; }
  .text-amber.text-accent-3.icon-outline {
    border-color: #ffc400 !important; }

.bg-amber.accent-4 {
  background-color: #ffab00 !important; }

.text-amber.text-accent-4 {
  color: #ffab00 !important; }
  .text-amber.text-accent-4.icon-outline {
    border-color: #ffab00 !important; }

.bg-orange {
  background-color: #ff9800 !important; }

.text-orange {
  color: #ff9800 !important; }
  .text-orange.icon-outline {
    border-color: #ff9800 !important; }

.bg-orange.lighten-5 {
  background-color: #fff3e0 !important; }

.text-orange.text-lighten-5 {
  color: #fff3e0 !important; }
  .text-orange.text-lighten-5.icon-outline {
    border-color: #fff3e0 !important; }

.bg-orange.lighten-4 {
  background-color: #ffe0b2 !important; }

.text-orange.text-lighten-4 {
  color: #ffe0b2 !important; }
  .text-orange.text-lighten-4.icon-outline {
    border-color: #ffe0b2 !important; }

.bg-orange.lighten-3 {
  background-color: #ffcc80 !important; }

.text-orange.text-lighten-3 {
  color: #ffcc80 !important; }
  .text-orange.text-lighten-3.icon-outline {
    border-color: #ffcc80 !important; }

.bg-orange.lighten-2 {
  background-color: #ffb74d !important; }

.text-orange.text-lighten-2 {
  color: #ffb74d !important; }
  .text-orange.text-lighten-2.icon-outline {
    border-color: #ffb74d !important; }

.bg-orange.lighten-1 {
  background-color: #ffa726 !important; }

.text-orange.text-lighten-1 {
  color: #ffa726 !important; }
  .text-orange.text-lighten-1.icon-outline {
    border-color: #ffa726 !important; }

.bg-orange.darken-1 {
  background-color: #fb8c00 !important; }

.text-orange.text-darken-1 {
  color: #fb8c00 !important; }
  .text-orange.text-darken-1.icon-outline {
    border-color: #fb8c00 !important; }

.bg-orange.darken-2 {
  background-color: #f57c00 !important; }

.text-orange.text-darken-2 {
  color: #f57c00 !important; }
  .text-orange.text-darken-2.icon-outline {
    border-color: #f57c00 !important; }

.bg-orange.darken-3 {
  background-color: #ef6c00 !important; }

.text-orange.text-darken-3 {
  color: #ef6c00 !important; }
  .text-orange.text-darken-3.icon-outline {
    border-color: #ef6c00 !important; }

.bg-orange.darken-4 {
  background-color: #e65100 !important; }

.text-orange.text-darken-4 {
  color: #e65100 !important; }
  .text-orange.text-darken-4.icon-outline {
    border-color: #e65100 !important; }

.bg-orange.accent-1 {
  background-color: #ffd180 !important; }

.text-orange.text-accent-1 {
  color: #ffd180 !important; }
  .text-orange.text-accent-1.icon-outline {
    border-color: #ffd180 !important; }

.bg-orange.accent-2 {
  background-color: #ffab40 !important; }

.text-orange.text-accent-2 {
  color: #ffab40 !important; }
  .text-orange.text-accent-2.icon-outline {
    border-color: #ffab40 !important; }

.bg-orange.accent-3 {
  background-color: #ff9100 !important; }

.text-orange.text-accent-3 {
  color: #ff9100 !important; }
  .text-orange.text-accent-3.icon-outline {
    border-color: #ff9100 !important; }

.bg-orange.accent-4 {
  background-color: #ff6d00 !important; }

.text-orange.text-accent-4 {
  color: #ff6d00 !important; }
  .text-orange.text-accent-4.icon-outline {
    border-color: #ff6d00 !important; }

.bg-deep-orange {
  background-color: #ff5722 !important; }

.text-deep-orange {
  color: #ff5722 !important; }
  .text-deep-orange.icon-outline {
    border-color: #ff5722 !important; }

.bg-deep-orange.lighten-5 {
  background-color: #fbe9e7 !important; }

.text-deep-orange.text-lighten-5 {
  color: #fbe9e7 !important; }
  .text-deep-orange.text-lighten-5.icon-outline {
    border-color: #fbe9e7 !important; }

.bg-deep-orange.lighten-4 {
  background-color: #ffccbc !important; }

.text-deep-orange.text-lighten-4 {
  color: #ffccbc !important; }
  .text-deep-orange.text-lighten-4.icon-outline {
    border-color: #ffccbc !important; }

.bg-deep-orange.lighten-3 {
  background-color: #ffab91 !important; }

.text-deep-orange.text-lighten-3 {
  color: #ffab91 !important; }
  .text-deep-orange.text-lighten-3.icon-outline {
    border-color: #ffab91 !important; }

.bg-deep-orange.lighten-2 {
  background-color: #ff8a65 !important; }

.text-deep-orange.text-lighten-2 {
  color: #ff8a65 !important; }
  .text-deep-orange.text-lighten-2.icon-outline {
    border-color: #ff8a65 !important; }

.bg-deep-orange.lighten-1 {
  background-color: #ff7043 !important; }

.text-deep-orange.text-lighten-1 {
  color: #ff7043 !important; }
  .text-deep-orange.text-lighten-1.icon-outline {
    border-color: #ff7043 !important; }

.bg-deep-orange.darken-1 {
  background-color: #f4511e !important; }

.text-deep-orange.text-darken-1 {
  color: #f4511e !important; }
  .text-deep-orange.text-darken-1.icon-outline {
    border-color: #f4511e !important; }

.bg-deep-orange.darken-2 {
  background-color: #e64a19 !important; }

.text-deep-orange.text-darken-2 {
  color: #e64a19 !important; }
  .text-deep-orange.text-darken-2.icon-outline {
    border-color: #e64a19 !important; }

.bg-deep-orange.darken-3 {
  background-color: #d84315 !important; }

.text-deep-orange.text-darken-3 {
  color: #d84315 !important; }
  .text-deep-orange.text-darken-3.icon-outline {
    border-color: #d84315 !important; }

.bg-deep-orange.darken-4 {
  background-color: #bf360c !important; }

.text-deep-orange.text-darken-4 {
  color: #bf360c !important; }
  .text-deep-orange.text-darken-4.icon-outline {
    border-color: #bf360c !important; }

.bg-deep-orange.accent-1 {
  background-color: #ff9e80 !important; }

.text-deep-orange.text-accent-1 {
  color: #ff9e80 !important; }
  .text-deep-orange.text-accent-1.icon-outline {
    border-color: #ff9e80 !important; }

.bg-deep-orange.accent-2 {
  background-color: #ff6e40 !important; }

.text-deep-orange.text-accent-2 {
  color: #ff6e40 !important; }
  .text-deep-orange.text-accent-2.icon-outline {
    border-color: #ff6e40 !important; }

.bg-deep-orange.accent-3 {
  background-color: #ff3d00 !important; }

.text-deep-orange.text-accent-3 {
  color: #ff3d00 !important; }
  .text-deep-orange.text-accent-3.icon-outline {
    border-color: #ff3d00 !important; }

.bg-deep-orange.accent-4 {
  background-color: #dd2c00 !important; }

.text-deep-orange.text-accent-4 {
  color: #dd2c00 !important; }
  .text-deep-orange.text-accent-4.icon-outline {
    border-color: #dd2c00 !important; }

.bg-brown {
  background-color: #795548 !important; }

.text-brown {
  color: #795548 !important; }
  .text-brown.icon-outline {
    border-color: #795548 !important; }

.bg-brown.lighten-5 {
  background-color: #efebe9 !important; }

.text-brown.text-lighten-5 {
  color: #efebe9 !important; }
  .text-brown.text-lighten-5.icon-outline {
    border-color: #efebe9 !important; }

.bg-brown.lighten-4 {
  background-color: #d7ccc8 !important; }

.text-brown.text-lighten-4 {
  color: #d7ccc8 !important; }
  .text-brown.text-lighten-4.icon-outline {
    border-color: #d7ccc8 !important; }

.bg-brown.lighten-3 {
  background-color: #bcaaa4 !important; }

.text-brown.text-lighten-3 {
  color: #bcaaa4 !important; }
  .text-brown.text-lighten-3.icon-outline {
    border-color: #bcaaa4 !important; }

.bg-brown.lighten-2 {
  background-color: #a1887f !important; }

.text-brown.text-lighten-2 {
  color: #a1887f !important; }
  .text-brown.text-lighten-2.icon-outline {
    border-color: #a1887f !important; }

.bg-brown.lighten-1 {
  background-color: #8d6e63 !important; }

.text-brown.text-lighten-1 {
  color: #8d6e63 !important; }
  .text-brown.text-lighten-1.icon-outline {
    border-color: #8d6e63 !important; }

.bg-brown.darken-1 {
  background-color: #6d4c41 !important; }

.text-brown.text-darken-1 {
  color: #6d4c41 !important; }
  .text-brown.text-darken-1.icon-outline {
    border-color: #6d4c41 !important; }

.bg-brown.darken-2 {
  background-color: #5d4037 !important; }

.text-brown.text-darken-2 {
  color: #5d4037 !important; }
  .text-brown.text-darken-2.icon-outline {
    border-color: #5d4037 !important; }

.bg-brown.darken-3 {
  background-color: #4e342e !important; }

.text-brown.text-darken-3 {
  color: #4e342e !important; }
  .text-brown.text-darken-3.icon-outline {
    border-color: #4e342e !important; }

.bg-brown.darken-4 {
  background-color: #3e2723 !important; }

.text-brown.text-darken-4 {
  color: #3e2723 !important; }
  .text-brown.text-darken-4.icon-outline {
    border-color: #3e2723 !important; }

.bg-blue-grey {
  background-color: #607d8b !important; }

.text-blue-grey {
  color: #607d8b !important; }
  .text-blue-grey.icon-outline {
    border-color: #607d8b !important; }

.bg-blue-grey.lighten-5 {
  background-color: #eceff1 !important; }

.text-blue-grey.text-lighten-5 {
  color: #eceff1 !important; }
  .text-blue-grey.text-lighten-5.icon-outline {
    border-color: #eceff1 !important; }

.bg-blue-grey.lighten-4 {
  background-color: #cfd8dc !important; }

.text-blue-grey.text-lighten-4 {
  color: #cfd8dc !important; }
  .text-blue-grey.text-lighten-4.icon-outline {
    border-color: #cfd8dc !important; }

.bg-blue-grey.lighten-3 {
  background-color: #b0bec5 !important; }

.text-blue-grey.text-lighten-3 {
  color: #b0bec5 !important; }
  .text-blue-grey.text-lighten-3.icon-outline {
    border-color: #b0bec5 !important; }

.bg-blue-grey.lighten-2 {
  background-color: #90a4ae !important; }

.text-blue-grey.text-lighten-2 {
  color: #90a4ae !important; }
  .text-blue-grey.text-lighten-2.icon-outline {
    border-color: #90a4ae !important; }

.bg-blue-grey.lighten-1 {
  background-color: #78909c !important; }

.text-blue-grey.text-lighten-1 {
  color: #78909c !important; }
  .text-blue-grey.text-lighten-1.icon-outline {
    border-color: #78909c !important; }

.bg-blue-grey.darken-1 {
  background-color: #546e7a !important; }

.text-blue-grey.text-darken-1 {
  color: #546e7a !important; }
  .text-blue-grey.text-darken-1.icon-outline {
    border-color: #546e7a !important; }

.bg-blue-grey.darken-2 {
  background-color: #455a64 !important; }

.text-blue-grey.text-darken-2 {
  color: #455a64 !important; }
  .text-blue-grey.text-darken-2.icon-outline {
    border-color: #455a64 !important; }

.bg-blue-grey.darken-3 {
  background-color: #37474f !important; }

.text-blue-grey.text-darken-3 {
  color: #37474f !important; }
  .text-blue-grey.text-darken-3.icon-outline {
    border-color: #37474f !important; }

.bg-blue-grey.darken-4 {
  background-color: #263238 !important; }

.text-blue-grey.text-darken-4 {
  color: #263238 !important; }
  .text-blue-grey.text-darken-4.icon-outline {
    border-color: #263238 !important; }

.bg-grey {
  background-color: #9e9e9e !important; }

.text-grey {
  color: #9e9e9e !important; }
  .text-grey.icon-outline {
    border-color: #9e9e9e !important; }

.bg-grey.lighten-5 {
  background-color: #fafafa !important; }

.text-grey.text-lighten-5 {
  color: #fafafa !important; }
  .text-grey.text-lighten-5.icon-outline {
    border-color: #fafafa !important; }

.bg-grey.lighten-4 {
  background-color: #f5f5f5 !important; }

.text-grey.text-lighten-4 {
  color: #f5f5f5 !important; }
  .text-grey.text-lighten-4.icon-outline {
    border-color: #f5f5f5 !important; }

.bg-grey.lighten-3 {
  background-color: #eeeeee !important; }

.text-grey.text-lighten-3 {
  color: #eeeeee !important; }
  .text-grey.text-lighten-3.icon-outline {
    border-color: #eeeeee !important; }

.bg-grey.lighten-2 {
  background-color: #e0e0e0 !important; }

.text-grey.text-lighten-2 {
  color: #e0e0e0 !important; }
  .text-grey.text-lighten-2.icon-outline {
    border-color: #e0e0e0 !important; }

.bg-grey.lighten-1 {
  background-color: #bdbdbd !important; }

.text-grey.text-lighten-1 {
  color: #bdbdbd !important; }
  .text-grey.text-lighten-1.icon-outline {
    border-color: #bdbdbd !important; }

.bg-grey.darken-1 {
  background-color: #757575 !important; }

.text-grey.text-darken-1 {
  color: #757575 !important; }
  .text-grey.text-darken-1.icon-outline {
    border-color: #757575 !important; }

.bg-grey.darken-2 {
  background-color: #616161 !important; }

.text-grey.text-darken-2 {
  color: #616161 !important; }
  .text-grey.text-darken-2.icon-outline {
    border-color: #616161 !important; }

.bg-grey.darken-3 {
  background-color: #424242 !important; }

.text-grey.text-darken-3 {
  color: #424242 !important; }
  .text-grey.text-darken-3.icon-outline {
    border-color: #424242 !important; }

.bg-grey.darken-4 {
  background-color: #212121 !important; }

.text-grey.text-darken-4 {
  color: #212121 !important; }
  .text-grey.text-darken-4.icon-outline {
    border-color: #212121 !important; }

.bg-black {
  background-color: #000000 !important; }

.text-black {
  color: #000000 !important; }
  .text-black.icon-outline {
    border-color: #000000 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.text-white {
  color: #FFFFFF !important; }
  .text-white.icon-outline {
    border-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.text-transparent {
  color: transparent !important; }
  .text-transparent.icon-outline {
    border-color: transparent !important; }

/*Custom Animation*/
@keyframes flipCenter {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 90deg); }
  40% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  80% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  100% {
    -webkit-transform: perspective(100000px);
            transform: perspective(100000px); } }

@-webkit-keyframes flipCenter {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 90deg); }
  40% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  80% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  100% {
    -webkit-transform: perspective(100000px);
            transform: perspective(100000px); } }

.flipCenter {
  -webkit-animation-name: flipCenter;
          animation-name: flipCenter;
  -webkit-backface-visibility: visible;
          backface-visibility: visible; }

@keyframes desployDown {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: center top 0;
            transform-origin: center top 0; }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: center top 0;
            transform-origin: center top 0; } }

@-webkit-keyframes desployDown {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: center top 0;
            transform-origin: center top 0; }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: center top 0;
            transform-origin: center top 0; } }

.desployDown {
  -webkit-animation-name: desployDown;
          animation-name: desployDown;
  -webkit-backface-visibility: visible;
          backface-visibility: visible; }

@keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0px, 0px);
            transform: translate3d(30%, 0px, 0px); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0px, 0px);
            transform: translate3d(30%, 0px, 0px); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fadeInRightTiny {
  -webkit-animation-name: fadeInRightTiny;
          animation-name: fadeInRightTiny; }

@keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0px, 0px);
            transform: translate3d(-30%, 0px, 0px); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0px, 0px);
            transform: translate3d(-30%, 0px, 0px); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fadeInLeftTiny {
  -webkit-animation-name: fadeInLeftTiny;
          animation-name: fadeInLeftTiny; }

@keyframes slideInRightTiny {
  0% {
    -webkit-transform: translate3d(30%, 0px, 0px);
            transform: translate3d(30%, 0px, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes slideInRightTiny {
  0% {
    -webkit-transform: translate3d(30%, 0px, 0px);
            transform: translate3d(30%, 0px, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

.slideInRightTiny {
  -webkit-animation-name: slideInRightTiny;
          animation-name: slideInRightTiny; }

@keyframes slideInLeftTiny {
  0% {
    -webkit-transform: translate3d(-30%, 0px, 0px);
            transform: translate3d(-30%, 0px, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes slideInLeftTiny {
  0% {
    -webkit-transform: translate3d(-30%, 0px, 0px);
            transform: translate3d(-30%, 0px, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

.slideInLeftTiny {
  -webkit-animation-name: slideInLeftTiny;
          animation-name: slideInLeftTiny; }

@keyframes slideInUpTiny {
  0% {
    -webkit-transform: translate3d(0px, 5%, 0px);
            transform: translate3d(0px, 5%, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes slideInUpTiny {
  0% {
    -webkit-transform: translate3d(0px, 5%, 0px);
            transform: translate3d(0px, 5%, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

.slideInUpTiny {
  -webkit-animation-name: slideInUpTiny;
          animation-name: slideInUpTiny; }

/*Animation Durations*/
.animation-duration-1 {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s; }

.animation-duration-2 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s; }

.animation-duration-3 {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s; }

.animation-duration-4 {
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s; }

.animation-duration-5 {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.animation-duration-6 {
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s; }

.animation-duration-7 {
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s; }

.animation-duration-8 {
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s; }

.animation-duration-9 {
  -webkit-animation-duration: 0.9s;
          animation-duration: 0.9s; }

.animation-duration-10 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.animation-duration-11 {
  -webkit-animation-duration: 1.1s;
          animation-duration: 1.1s; }

.animation-duration-12 {
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s; }

.animation-duration-13 {
  -webkit-animation-duration: 1.3s;
          animation-duration: 1.3s; }

.animation-duration-14 {
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s; }

.animation-duration-15 {
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s; }

.animation-duration-16 {
  -webkit-animation-duration: 1.6s;
          animation-duration: 1.6s; }

.animation-duration-17 {
  -webkit-animation-duration: 1.7s;
          animation-duration: 1.7s; }

.animation-duration-18 {
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s; }

.animation-duration-19 {
  -webkit-animation-duration: 1.9s;
          animation-duration: 1.9s; }

.animation-duration-20 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s; }

.animation-duration-21 {
  -webkit-animation-duration: 2.1s;
          animation-duration: 2.1s; }

.animation-duration-22 {
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s; }

.animation-duration-23 {
  -webkit-animation-duration: 2.3s;
          animation-duration: 2.3s; }

.animation-duration-24 {
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s; }

.animation-duration-25 {
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s; }

.animation-duration-26 {
  -webkit-animation-duration: 2.6s;
          animation-duration: 2.6s; }

.animation-duration-27 {
  -webkit-animation-duration: 2.7s;
          animation-duration: 2.7s; }

.animation-duration-28 {
  -webkit-animation-duration: 2.8s;
          animation-duration: 2.8s; }

.animation-duration-29 {
  -webkit-animation-duration: 2.9s;
          animation-duration: 2.9s; }

.animation-duration-30 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s; }

.animation-duration-31 {
  -webkit-animation-duration: 3.1s;
          animation-duration: 3.1s; }

.animation-duration-32 {
  -webkit-animation-duration: 3.2s;
          animation-duration: 3.2s; }

.animation-duration-33 {
  -webkit-animation-duration: 3.3s;
          animation-duration: 3.3s; }

.animation-duration-34 {
  -webkit-animation-duration: 3.4s;
          animation-duration: 3.4s; }

.animation-duration-35 {
  -webkit-animation-duration: 3.5s;
          animation-duration: 3.5s; }

.animation-duration-36 {
  -webkit-animation-duration: 3.6s;
          animation-duration: 3.6s; }

.animation-duration-37 {
  -webkit-animation-duration: 3.7s;
          animation-duration: 3.7s; }

.animation-duration-38 {
  -webkit-animation-duration: 3.8s;
          animation-duration: 3.8s; }

.animation-duration-39 {
  -webkit-animation-duration: 3.9s;
          animation-duration: 3.9s; }

.animation-duration-40 {
  -webkit-animation-duration: 4s;
          animation-duration: 4s; }

.animation-delay-1 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }

.animation-delay-2 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

.animation-delay-3 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }

.animation-delay-4 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }

.animation-delay-5 {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.animation-delay-6 {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; }

.animation-delay-7 {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s; }

.animation-delay-8 {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s; }

.animation-delay-9 {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s; }

.animation-delay-10 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.animation-delay-11 {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s; }

.animation-delay-12 {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s; }

.animation-delay-13 {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s; }

.animation-delay-14 {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s; }

.animation-delay-15 {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s; }

.animation-delay-16 {
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s; }

.animation-delay-17 {
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s; }

.animation-delay-18 {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s; }

.animation-delay-19 {
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s; }

.animation-delay-20 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s; }

.animation-delay-21 {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s; }

.animation-delay-22 {
  -webkit-animation-delay: 2.2s;
          animation-delay: 2.2s; }

.animation-delay-23 {
  -webkit-animation-delay: 2.3s;
          animation-delay: 2.3s; }

.animation-delay-24 {
  -webkit-animation-delay: 2.4s;
          animation-delay: 2.4s; }

.animation-delay-25 {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s; }

.animation-delay-26 {
  -webkit-animation-delay: 2.6s;
          animation-delay: 2.6s; }

.animation-delay-27 {
  -webkit-animation-delay: 2.6s;
          animation-delay: 2.6s; }

.animation-delay-28 {
  -webkit-animation-delay: 2.8s;
          animation-delay: 2.8s; }

.animation-delay-29 {
  -webkit-animation-delay: 2.9s;
          animation-delay: 2.9s; }

.animation-delay-30 {
  -webkit-animation-delay: 3s;
          animation-delay: 3s; }

.animation-delay-31 {
  -webkit-animation-delay: 3.1s;
          animation-delay: 3.1s; }

.animation-delay-32 {
  -webkit-animation-delay: 3.2s;
          animation-delay: 3.2s; }

.animation-delay-33 {
  -webkit-animation-delay: 3.3s;
          animation-delay: 3.3s; }

.animation-delay-34 {
  -webkit-animation-delay: 3.4s;
          animation-delay: 3.4s; }

.animation-delay-35 {
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s; }

.animation-delay-36 {
  -webkit-animation-delay: 3.6s;
          animation-delay: 3.6s; }

.animation-delay-37 {
  -webkit-animation-delay: 3.7s;
          animation-delay: 3.7s; }

.animation-delay-38 {
  -webkit-animation-delay: 3.8s;
          animation-delay: 3.8s; }

.animation-delay-39 {
  -webkit-animation-delay: 3.9s;
          animation-delay: 3.9s; }

.animation-delay-40 {
  -webkit-animation-delay: 4s;
          animation-delay: 4s; }

.animation-delay-41 {
  -webkit-animation-delay: 4.1s;
          animation-delay: 4.1s; }

.animation-delay-42 {
  -webkit-animation-delay: 4.2s;
          animation-delay: 4.2s; }

.animation-delay-43 {
  -webkit-animation-delay: 4.3s;
          animation-delay: 4.3s; }

.animation-delay-44 {
  -webkit-animation-delay: 4.4s;
          animation-delay: 4.4s; }

.animation-delay-45 {
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s; }

.animation-delay-46 {
  -webkit-animation-delay: 4.6s;
          animation-delay: 4.6s; }

.animation-delay-47 {
  -webkit-animation-delay: 4.7s;
          animation-delay: 4.7s; }

.animation-delay-48 {
  -webkit-animation-delay: 4.8s;
          animation-delay: 4.8s; }

.animation-delay-49 {
  -webkit-animation-delay: 4.9s;
          animation-delay: 4.9s; }

.animation-delay-50 {
  -webkit-animation-delay: 5s;
          animation-delay: 5s; }

/* Base
   ========================================================================== */
/*Base Styles*/
html {
  height: 100%; }

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #212529;
  background-color: #f8f9fa;
  height: 100%;
  overflow: hidden; }

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important; }

#nprogress .bar {
  z-index: 5000 !important;
  background: #E91E63 !important; }

#nprogress .peg {
  -o-box-shadow: 0 0 10px #E91E63, 0 0 5px #E91E63 !important;
  box-shadow: 0 0 10px #E91E63, 0 0 5px #E91E63 !important; }

#nprogress .spinner {
  z-index: 5000 !important; }

#nprogress .spinner-icon {
  border-top-color: #E91E63 !important;
  border-left-color: #E91E63 !important; }

.text-transform-none {
  text-transform: none; }

.br-break {
  word-break: break-all !important; }

.font-weight-semibold {
  font-weight: 500 !important; }

.z-index-20 {
  z-index: 20; }

.border-bottom {
  border-bottom: solid 1px #dee2e6; }

/*div[tabindex="-1"] {
  z-index: 1500 !important;
}*/
.col-with-divider div[class*="col-"] {
  border-left: solid 1px #dee2e6; }
  .col-with-divider div[class*="col-"]:first-child {
    border: 0 none; }

.height-100 {
  height: 100px !important; }

.height-150 {
  height: 150px !important; }

.height-200 {
  height: 200px !important; }

.max-width-100 {
  max-width: 100px !important; }

.max-height-120 {
  max-height: 120px !important; }

.right-arrow {
  display: inline-block;
  color: #673ab7;
  text-transform: capitalize; }
  .right-arrow:after {
    content: "\f30f";
    font-family: "Material-Design-Iconic-Font";
    color: #673ab7;
    margin-left: 8px;
    font-size: 20px;
    vertical-align: middle; }
  .right-arrow:hover, .right-arrow:focus {
    text-decoration: none; }

.letter-spacing-base {
  letter-spacing: 3px; }

.letter-spacing-lg {
  letter-spacing: 6px; }

.letter-spacing-xl {
  letter-spacing: 8px; }

.page-heading {
  margin-bottom: 36px;
  padding: 18px 24px; }
  .page-heading .title {
    text-transform: capitalize; }
  .page-heading .breadcrumb {
    padding: 0 !important;
    background-color: transparent !important;
    margin-bottom: 0; }
  @media screen and (max-width: 575px) {
    .page-heading {
      margin-bottom: 18px; } }

.sub-heading {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 0; }
  .sub-heading:not(:last-child) {
    margin-bottom: 16px !important; }

.manage-margin {
  margin-bottom: 0; }
  .manage-margin > * {
    margin-bottom: 6px; }
    .manage-margin > *:not(:last-child) {
      margin-right: 16px; }

.no-shadow {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.shadow-sm {
  -o-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15); }

.shadow {
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }

.shadow-lg {
  -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2); }

.shadow-xl {
  -o-box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.2); }

.avatar-shadow {
  -o-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5); }

.remove-table-border td, .remove-table-border th {
  border: 0 none; }

.media-list {
  margin-bottom: 10px; }
  .media-list:not(:last-child) {
    border-bottom: solid 1px #e9ecef;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative; }

.border-2 {
  border: 2px solid #ced4da !important; }

.border-w2 {
  border-width: 2px; }

.border-primary {
  border-color: #3f51b5 !important; }

.border-red {
  border-color: #F44336 !important; }

.border-pink {
  border-color: #e91e63 !important; }

.border-purple {
  border-color: #9C27B0 !important; }

.border-deep-purple {
  border-color: #673ab7 !important; }

.border-indigo {
  border-color: #3f51b5 !important; }

.border-blue {
  border-color: #2196F3 !important; }

.border-light-blue {
  border-color: #03a9f4 !important; }

.border-cyan {
  border-color: #00bcd4 !important; }

.border-teal {
  border-color: #009688 !important; }

.border-green {
  border-color: #4CAF50 !important; }

.border-light-green {
  border-color: #8bc34a !important; }

.border-lime {
  border-color: #cddc39 !important; }

.border-yellow {
  border-color: #ffeb3b !important; }

.border-amber {
  border-color: #ffc107 !important; }

.border-orange {
  border-color: #ff9800 !important; }

.border-deep-orange {
  border-color: #ff5722 !important; }

.border-brown {
  border-color: #795548 !important; }

.border-blue-grey {
  border-color: #607d8b !important; }

.border-grey {
  border-color: #9e9e9e !important; }

.center-crop-img {
  position: relative;
  width: 100%;
  min-height: 120px;
  overflow: hidden; }
  .center-crop-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .center-crop-img img.portrait {
    width: 100%;
    height: auto; }

.pointer {
  cursor: pointer; }

/*Typography Styles*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  margin: 0 0 12px;
  color: inherit; }

h1, .h1 {
  font-size: 24px; }

h2, .h2 {
  font-size: 20px; }

h3, .h3 {
  font-size: 17px; }

h4, .h4 {
  font-size: 15px; }

h5, .h5 {
  font-size: 13px; }

h6, .h6 {
  font-size: 12px; }

.text-small {
  font-size: 14px; }

.text-large {
  font-size: 18px; }

.text-strikethrough {
  text-decoration: line-through !important; }

@media screen and (max-width: 991px) {
  h1, .h1 {
    font-size: 20px; }
  h2, .h2 {
    font-size: 17px; }
  h3, .h3 {
    font-size: 15px; } }

.desc-list {
  margin-bottom: 0; }
  .desc-list li {
    padding: 18px 24px;
    border-bottom: 1px solid #adb5bd;
    margin-left: -24px;
    margin-right: -24px; }
    .desc-list li:last-child {
      border-bottom: 0 none; }
    .desc-list li .card-title {
      margin-bottom: 6px; }
  .desc-list.even-odd li {
    border-bottom: 0 none; }
    .desc-list.even-odd li:nth-child(2n) {
      background-color: #dee2e6; }

/* Layouts
   ========================================================================== */
/*App Layout Styles*/
#app-site,
.app-main {
  display: flex;
  width: 100%;
  height: 100%; }

.app-container {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%; }

.app-sidebar {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 280px;
  min-width: 280px;
  max-width: 280px; }

.app-main-container {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden; }

.app-header {
  min-height: 70px; }

.app-main-content-wrapper {
  flex: 1 1;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto; }

.app-container.fixed-drawer .app-main-header {
  width: auto !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 280px;
  right: 0; }
  @media screen and (max-width: 1199px) {
    .app-container.fixed-drawer .app-main-header {
      left: 0;
      right: 0; } }

.app-container.collapsible-drawer .app-main-header {
  width: auto !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 0;
  right: 0; }

.app-container.mini-drawer .app-main-header {
  width: auto !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 80px;
  right: 0; }

.app-container.mini-drawer .app-sidebar {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

/*Sidebar Styles*/
.side-nav {
  width: 280px;
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important;
  z-index: 1250 !important; }
  .side-nav .user-profile {
    background-color: #1d1d1d;
    padding: 13px 20px !important; }
  .side-nav .user-avatar {
    margin: 0 15px 0 0 !important;
    height: 44px !important;
    width: 44px !important;
    line-height: 44px; }
  .side-nav .user-detail .user-name {
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap; }

.user-detail .user-name {
  cursor: pointer; }

ul.nav-menu {
  position: relative;
  font-size: 14px;
  list-style: outside none none;
  margin: 0;
  padding: 0; }

ul.nav-menu li {
  position: relative;
  margin-bottom: 1px; }

ul.nav-menu li.menu + .nav-header {
  margin-top: 20px; }

ul.nav-menu li.menu .sub-menu {
  display: none; }

ul.nav-menu li.menu.open > .sub-menu {
  display: block; }

ul.nav-menu li.menu.open .menu.active > .sub-menu {
  display: block; }

ul.nav-menu li a {
  color: #a1a1a1;
  display: block;
  font-size: 14px;
  min-width: inherit !important;
  padding: 16px 20px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 0; }

ul.nav-menu .nav-header {
  color: #a1a1a1;
  padding: 10px 20px;
  font-size: 11px;
  text-transform: uppercase; }
  ul.nav-menu .nav-header:first-child {
    margin-top: 10px; }

ul.nav-menu > li.open > a,
ul.nav-menu > li > a:hover,
ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

ul.nav-menu li.menu > a {
  border-left: 2px solid transparent; }

ul.nav-menu li.menu.open > a {
  border-color: #ff9800; }

ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

ul.nav-menu li i {
  margin-right: 16px;
  font-size: 15px; }

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  background-color: #1d1d1d;
  overflow: hidden; }

ul.nav-menu li ul > li > a {
  padding-left: 56px; }

ul.nav-menu li ul > li > ul > li > a {
  padding-left: 76px; }

ul.nav-menu li ul > li > ul > li > ul > li > a {
  padding-left: 96px; }

ul.nav-menu li ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 116px; }

ul.nav-menu li.menu > a:before {
  color: #a1a1a1;
  content: "\f2fb";
  display: inline-block;
  float: right;
  font-family: 'Material-Design-Iconic-Font';
  font-size: 15px;
  margin-left: 10px;
  position: relative;
  transition: all 300ms ease-in-out;
  font-weight: normal; }

ul.nav-menu li.menu.no-arrow > a:before {
  display: none; }

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before {
  background-color: transparent;
  color: #fff; }

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu.active > a:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff;
  border-left: 2px solid #ff9800; }

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li.active > a:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.app-container.mini-drawer .side-nav {
  transition: all 0.2s ease-in-out;
  width: 80px; }
  .app-container.mini-drawer .side-nav .nav-menu li span.nav-text,
  .app-container.mini-drawer .side-nav .user-profile .user-detail,
  .app-container.mini-drawer .side-nav .customizer {
    display: none; }
  .app-container.mini-drawer .side-nav .nav-menu .menu.open > .sub-menu {
    display: none !important; }
  .app-container.mini-drawer .side-nav .nav-header {
    padding: 10px; }
  .app-container.mini-drawer .side-nav .nav-menu li {
    text-align: center; }
    .app-container.mini-drawer .side-nav .nav-menu li i {
      margin-right: 0; }
  .app-container.mini-drawer .side-nav ul.nav-menu li a {
    padding: 16px 20px; }
  .app-container.mini-drawer .side-nav ul.nav-menu ul.sub-menu li a {
    padding-left: 30px; }
  .app-container.mini-drawer .side-nav ul.nav-menu > li > a:before {
    display: none; }

.app-container.mini-drawer .side-nav:hover {
  width: 280px; }
  .app-container.mini-drawer .side-nav:hover .nav-menu li span.nav-text,
  .app-container.mini-drawer .side-nav:hover .user-profile .user-detail {
    display: inline-block !important; }
  .app-container.mini-drawer .side-nav:hover .nav-menu .menu.open > .sub-menu {
    display: block !important; }
  .app-container.mini-drawer .side-nav:hover .nav-header {
    padding: 10px 20px; }
  .app-container.mini-drawer .side-nav:hover .customizer {
    display: flex !important; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu li {
    text-align: left; }
    .app-container.mini-drawer .side-nav:hover ul.nav-menu li i {
      margin-right: 16px; }
    .app-container.mini-drawer .side-nav:hover ul.nav-menu li a {
      padding: 16px 20px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu li a {
    padding-left: 56px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > a {
    padding-left: 76px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > a {
    padding-left: 96px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > ul > li > a {
    padding-left: 116px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu > li > a:before {
    display: block; }

.customizer {
  padding: 20px 35px; }
  .customizer img {
    padding: 5px;
    cursor: pointer; }

.user-info ul li {
  font-size: 15px; }

/*Header Styles*/
.jr-menu-icon {
  outline: none !important;
  justify-content: flex-start !important;
  padding: 0 5px !important;
  margin-right: 15px; }

.app-main-header {
  background-color: #673ab7 !important;
  color: #fff !important; }
  .app-main-header .search-bar input {
    transition: all 0.2s ease-in-out;
    background-color: #7446c4;
    color: #fff; }
    .app-main-header .search-bar input + .search-icon {
      top: 0; }
      .app-main-header .search-bar input + .search-icon i {
        color: rgba(255, 255, 255, 0.8); }
    .app-main-header .search-bar input:focus {
      background-color: #fff;
      color: #212529; }
      .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(33, 37, 41, 0.8); }

.app-logo img {
  height: 35px; }
  @media screen and (max-width: 575px) {
    .app-logo img {
      height: 28px; } }

.app-toolbar {
  min-height: 70px;
  height: 70px;
  max-height: 70px; }
  .app-toolbar .search-bar {
    margin-left: 100px;
    margin-right: auto;
    max-width: 400px;
    width: 380px; }
    @media screen and (max-width: 991px) {
      .app-toolbar .search-bar {
        width: 280px; } }
    @media screen and (max-width: 767px) {
      .app-toolbar .search-bar {
        margin-left: 40px;
        width: 200px; } }

.app-toolbar .nav-searchbox .search-bar {
  margin-left: 0;
  width: 100%; }

.app-toolbar .nav-searchbox .search-bar input {
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  color: #212529; }
  .app-toolbar .nav-searchbox .search-bar input + .search-icon i {
    color: rgba(33, 37, 41, 0.8); }
  .app-toolbar .nav-searchbox .search-bar input:focus {
    background-color: transparent;
    color: #212529; }
    .app-toolbar .nav-searchbox .search-bar input:focus + .search-icon i {
      color: rgba(33, 37, 41, 0.8); }

.header-notifications {
  margin-bottom: 0; }
  .header-notifications li {
    vertical-align: middle; }

.quick-menu .dropdown-menu {
  padding: 5px 10px;
  width: 350px; }
  .quick-menu .dropdown-menu .jr-card-header {
    margin: -5px -10px 5px;
    padding: 10px 10px 10px 20px;
    -o-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    z-index: 10; }
  .quick-menu .dropdown-menu:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    top: -9px;
    content: "";
    height: 9px;
    position: absolute;
    right: 8px;
    width: 10px; }
  .quick-menu .dropdown-menu:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
    top: -10px;
    content: "";
    height: 10px;
    position: absolute;
    right: 8px;
    width: 10px; }
  @media screen and (max-width: 449px) {
    .quick-menu .dropdown-menu {
      width: 280px; } }
  @media screen and (max-width: 359px) {
    .quick-menu .dropdown-menu {
      width: 240px; } }

.quick-menu .messages-list .user-avatar {
  position: relative; }

.quick-menu .messages-list .badge {
  position: absolute;
  left: -4px;
  top: -4px;
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  padding: 0; }

@media screen and (max-width: 575px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 200px; } }

@media screen and (max-width: 399px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 100px; } }

.messages-list.language-list ul li {
  padding: 7px 6px 6px; }

.messages-list ul li {
  position: relative;
  padding: 20px 10px 14px;
  border-bottom: 1px solid #dee2e6; }
  .messages-list ul li .user-name a {
    color: #673ab7; }
  .messages-list ul li .sub-heading {
    font-size: 11px;
    margin-bottom: 6px !important; }
    .messages-list ul li .sub-heading.mb-0 {
      margin-bottom: 0 !important; }
  .messages-list ul li:last-child {
    border-bottom: 0 none; }
  .messages-list ul li .jr-btn {
    margin-right: 0 !important; }
  .messages-list ul li .jr-btn-xs {
    font-size: 11px !important;
    padding: 5px !important; }

.quick-menu .icon-btn {
  color: #fff; }

.quick-menu .icon-alert {
  position: relative; }
  .quick-menu .icon-alert:after {
    display: block;
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    z-index: 10;
    width: 8px;
    height: 8px;
    background-color: #f44336;
    border-radius: 50%; }

.quick-menu i.animated.infinite {
  -webkit-animation-iteration-count: 25;
  animation-iteration-count: 25; }

.quick-menu .dropdown-menu {
  display: block;
  visibility: hidden;
  left: auto !important;
  right: 0 !important;
  opacity: 0;
  -webkit-transform: translate3d(0px, 100px, 0px) !important;
          transform: translate3d(0px, 100px, 0px) !important;
  transition: all 200ms linear 0ms; }

.quick-menu.show .dropdown-menu {
  opacity: 1;
  -webkit-transform: translate3d(0px, 30px, 0px) !important;
          transform: translate3d(0px, 30px, 0px) !important;
  visibility: visible; }

/*Main Content Styles*/
.app-main-content {
  flex: 1 1;
  width: 100%; }

.app-wrapper {
  padding: 24px;
  width: 100%; }
  @media screen and (max-width: 575px) {
    .app-wrapper {
      padding: 20px; } }

/*Footer Styles*/
.app-footer {
  padding: 0 24px;
  -o-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #f8f9fa;
  color: #a1a1a1;
  line-height: 50px;
  min-height: 50px;
  height: 50px;
  max-height: 50px; }
  .app-footer a {
    color: #a1a1a1; }
    .app-footer a:hover {
      color: #fff; }
  @media screen and (max-width: 400px) {
    .app-footer {
      font-size: 12px;
      padding: 0 20px; } }

/*Right Sidebar Styles*/
.app-sidebar-content.right-sidebar {
  z-index: 1501 !important; }

.color-theme {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 300px;
  height: 100%; }
  @media screen and (max-width: 499px) {
    .color-theme {
      max-width: 250px; } }

.color-theme-header {
  background-color: #673ab7;
  color: #fff;
  padding: 16px 25px;
  text-transform: uppercase;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .color-theme-header .color-theme-title {
    margin-bottom: 0; }
  @media screen and (max-width: 499px) {
    .color-theme-header {
      padding: 16px 15px; } }

.color-theme-body {
  padding: 28px 25px; }
  .color-theme-body h3 {
    margin-bottom: 20px; }
  @media screen and (max-width: 499px) {
    .color-theme-body {
      padding: 25px 15px; } }

.color-option {
  list-style: none;
  padding-left: 0;
  margin: 0 -7px 10px; }
  .color-option li {
    position: relative;
    display: inline-block;
    padding: 0 7px;
    margin-bottom: 10px; }
  .color-option li a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    display: inline-block;
    color: transparent !important;
    position: relative; }
    .color-option li a:before {
      content: "\f26b";
      font-family: "Material-Design-Iconic-Font";
      font-size: 20px;
      height: 45px;
      width: 45px;
      line-height: 45px;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      text-align: center;
      display: none;
      z-index: 2; }
  .color-option li a.active:before {
    display: block; }

.cr-op-dark-sidebar li a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #212529;
  width: 8px; }

.theme-option {
  position: fixed;
  top: 30%;
  right: 0;
  z-index: 1500;
  background-color: #9C27B0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -o-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  color: #fff; }

@media screen and (max-width: 499px) {
  .color-theme .jr-btn {
    padding: 10px 15px !important; } }

/* UI Components
   ========================================================================== */
/*Alert Styles*/
.alert {
  border: 0 none; }

.alert {
  padding: 20px 24px;
  border-radius: 2px;
  position: relative; }
  .alert .close {
    line-height: inherit;
    font-weight: 300; }
  .alert .alert-addon {
    width: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1; }

.alert-dismissible {
  padding-right: 60px; }

.alert-addon-card {
  padding-left: 80px; }

/*Avatar Styles*/
.user-avatar {
  margin-right: 8px; }
  .user-avatar img {
    max-width: 100%;
    height: auto; }

.user-detail .user-name {
  margin-bottom: 2px;
  font-weight: 400;
  text-transform: capitalize; }

.user-detail .user-description {
  font-size: 13px;
  margin-bottom: 0;
  color: #6c757d; }

/*Badges Styles*/
.badge {
  font-weight: 400;
  padding: 3px 8px;
  line-height: inherit;
  margin-bottom: 6px;
  border-radius: 2px; }
  .badge:not(:last-child) {
    margin-right: 15px; }
  table .badge {
    margin-bottom: 0; }

.badge-pill {
  border-radius: 20px; }

.badge-border {
  border: 2px solid !important; }

/*Blockquotes Styles*/
blockquote {
  padding: 10px 0 10px 20px;
  font-size: 14px !important; }
  blockquote .blockquote-footer {
    font-size: 14px !important; }

.blockquote {
  border-left: 5px solid #e9ecef; }

.blockquote-reverse {
  border-left: 0 none;
  border-right: 5px solid #e9ecef;
  text-align: right;
  padding-left: 0;
  padding-right: 20px; }

/*Bottom Navigation Styles*/
.bottom-navigation button {
  min-width: 10px !important; }

/*Breadcrumb Styles*/
.breadcrumb {
  border-radius: 2px; }

.breadcrumb-item + .breadcrumb-item::before {
  content: '\F2FB';
  font-family: 'Material-Design-Iconic-Font';
  font-size: 16px;
  line-height: 1;
  vertical-align: middle; }

/*Button Styles*/
button {
  outline: 0 none !important;
  cursor: pointer; }

.btn, .jr-btn {
  position: relative;
  min-width: 10px !important;
  min-height: 10px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  line-height: 1.2 !important;
  border-radius: 2px !important;
  cursor: pointer; }

.btn:focus, .btn:active {
  outline: 0 none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.jr-btn,
.jr-fab-btn,
.btn {
  margin-bottom: 6px; }
  .jr-btn:not(:last-child),
  .jr-fab-btn:not(:last-child),
  .btn:not(:last-child) {
    margin-right: 15px; }

.jr-fab-btn {
  min-height: 10px !important; }

.jr-btn {
  padding: 12px 20px !important; }
  .jr-btn i + span,
  .jr-btn span + i {
    margin-left: 6px; }

.jr-btn-group {
  margin: 0 -10px; }
  .jr-btn-group:not(:last-child) {
    margin-bottom: 16px; }
  .jr-btn-group .jr-btn,
  .jr-btn-group .jr-fab-btn {
    margin: 0 10px 18px; }

.jr-btn-rounded {
  border-radius: 50px !important; }

.jr-btn-lg, .btn-group-lg > .jr-btn {
  padding: 16px 24px !important; }

.jr-btn-sm, .btn-group-sm > .jr-btn {
  padding: 8px 15px !important; }

.jr-btn-xs {
  padding: 6px 10px !important; }

.jr-btn-fab-lg, .jr-btn-lg, .btn-group-lg > .jr-btn {
  font-size: 18px !important; }
  .jr-btn-fab-lg i,
  .jr-btn-lg i,
  .btn-group-lg > .jr-btn i {
    font-size: 20px !important; }

.jr-btn-fab-sm, .jr-btn-sm, .btn-group-sm > .jr-btn {
  font-size: 14px !important; }
  .jr-btn-fab-sm i,
  .jr-btn-sm i,
  .btn-group-sm > .jr-btn i {
    font-size: 14px !important; }

.jr-btn-fab-xs,
.jr-btn-xs {
  font-size: 10px !important; }
  .jr-btn-fab-xs i,
  .jr-btn-xs i {
    font-size: 10px !important; }

.complex-btn-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 200px;
  width: 100%; }

.complex-btn {
  position: relative;
  height: 200px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .complex-btn {
      width: 100% !important;
      height: 100px !important; } }
  .complex-btn:hover {
    z-index: 1; }
    .complex-btn:hover .img-btn-overlay {
      opacity: 0.15; }
    .complex-btn:hover .img-marked {
      opacity: 0; }
    .complex-btn:hover .img-title {
      border: 4px solid currentColor; }
  .complex-btn .img-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; }
  .complex-btn .img-src {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center 40%; }
  .complex-btn .img-btn-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.4;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1); }
  .complex-btn .img-title {
    position: relative;
    padding: 16px 32px 14px; }
  .complex-btn .img-marked {
    height: 3px;
    width: 18px;
    background: #fff;
    position: absolute;
    bottom: -2px;
    left: calc(50% - 9px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1); }

.btn-group-mins {
  margin: 0 -5px; }
  .btn-group-mins .btn,
  .btn-group-mins .jr-btn {
    margin: 0 5px 5px; }

.jr-btn.jr-btn-label i {
  background-color: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 100%;
  line-height: 42px; }

.jr-btn.jr-btn-label.left i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1; }

.jr-btn.jr-btn-label.right i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }

.jr-btn.jr-btn-label.left i + span {
  margin-left: 30px; }

.jr-btn.jr-btn-label.right i + span {
  margin-right: 30px;
  margin-left: 0; }

.jr-btn.jr-btn-label.jr-btn-lg i, .btn-group-lg > .jr-btn.jr-btn-label i {
  line-height: 54px; }

.jr-btn.jr-btn-label.jr-btn-sm i, .btn-group-sm > .jr-btn.jr-btn-label i {
  line-height: 32px; }

.jr-btn.jr-btn-label.jr-btn-xs i {
  line-height: 24px;
  width: 30px; }

/*Button Group Styles*/
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .jr-btn,
  .btn-group-vertical > .jr-btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .jr-btn:hover,
    .btn-group-vertical > .jr-btn:hover {
      z-index: 2; }
    .btn-group > .jr-btn:focus, .btn-group > .jr-btn:active, .btn-group > .jr-btn.active,
    .btn-group-vertical > .jr-btn:focus,
    .btn-group-vertical > .jr-btn:active,
    .btn-group-vertical > .jr-btn.active {
      z-index: 2; }
  .btn-group .jr-btn + .jr-btn,
  .btn-group .jr-btn + .jr-btn-group,
  .btn-group .btn-group + .jr-btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .jr-btn + .jr-btn,
  .btn-group-vertical .jr-btn + .jr-btn-group,
  .btn-group-vertical .btn-group + .jr-btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .jr-btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 !important; }

.btn-group > .jr-btn:first-child {
  margin-left: 0; }
  .btn-group > .jr-btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }

.btn-group > .jr-btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
  border-radius: 0 !important; }

.btn-group > .btn-group:first-child:not(:last-child) > .jr-btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.btn-group > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.jr-btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .jr-btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.jr-btn-sm + .dropdown-toggle-split, .btn-group-sm > .jr-btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.jr-btn-lg + .dropdown-toggle-split, .btn-group-lg > .jr-btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    -o-box-shadow: none;
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .jr-btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .jr-btn + .jr-btn,
  .btn-group-vertical > .jr-btn + .btn-group,
  .btn-group-vertical > .btn-group + .jr-btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .jr-btn:not(:first-child):not(:last-child) {
    border-radius: 0 !important; }
  .btn-group-vertical > .jr-btn:first-child:not(:last-child) {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .btn-group-vertical > .jr-btn:last-child:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
    border-radius: 0 !important; }
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .jr-btn:last-child,
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .btn-group-vertical > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }

[data-toggle="buttons"] > .jr-btn input[type="radio"],
[data-toggle="buttons"] > .jr-btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .jr-btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .jr-btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0); }

.btn-group > .jr-btn,
.btn-group > .btn,
.btn-group-vertical > .jr-btn,
.btn-group-vertical > .btn {
  margin: 0 !important; }

/*Callout Styles*/
.card-image .grid-thumb-equal {
  padding-bottom: 82%; }

/*Product listing page style*/
.product-row {
  position: relative;
  overflow: hidden;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: right; }
  .product-row.even {
    text-align: left; }

.product-col {
  order: 1;
  width: 50%; }

.odd .product-thumb {
  order: 2; }

.product-content {
  padding: 20px 55px; }

.product-row .grid-thumb-equal {
  padding-bottom: 69%; }

.product-thumb img {
  width: 100%; }

.product-content h4 {
  margin-bottom: 25px; }

@media screen and (max-width: 1230px) {
  .product-row .grid-thumb-equal {
    padding-bottom: 71%; } }

@media screen and (max-width: 1199px) {
  .product-content h4 {
    margin-bottom: 10px; }
  .product-content {
    padding: 20px 35px; }
  .product-row .grid-thumb-equal {
    padding-bottom: 85%; } }

@media screen and (max-width: 991px) {
  .card-image .grid-thumb-equal {
    padding-bottom: 120%; } }

@media screen and (max-width: 767px) {
  .product-row {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center; }
    .product-row.even {
      text-align: center; }
  .product-content {
    padding: 20px 0; }
  .product-col,
  .odd .product-thumb {
    order: 1;
    width: 100%; }
  .product-row .grid-thumb-equal {
    padding-bottom: 44%; }
  .card-image .grid-thumb-equal {
    padding-bottom: 44%; } }

/*Cards Styles*/
.profile-intro {
  background-color: #fff;
  border-radius: 2px !important;
  overflow: hidden; }
  .profile-intro:hover {
    -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2); }
    .profile-intro:hover .avatar-circle {
      transition: border-radius 0.4s ease 0.1s;
      border-radius: 25% !important; }
  .profile-intro img.avatar-circle {
    display: inline-block;
    border-radius: 50% !important;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    margin: 0 auto;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    transition: all 0.4s ease-in-out;
    z-index: 1; }

.profile-intro .card-image {
  position: relative;
  width: 100%;
  min-height: 100%;
  background-image: url("http://via.placeholder.com/1920x1000");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.profile-intro .pi-content {
  padding: 70px 40px 24px !important; }

.profile-intro .pi-footer {
  padding: 0 40px 24px !important; }

.profile-intro .icon {
  display: inline-block;
  transition: all 0.2s ease 0s;
  color: #673ab7; }
  .profile-intro .icon:hover, .profile-intro .icon:focus, .profile-intro .icon:active {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }

.profile-intro .icon:not(:last-child) {
  margin-right: 10px; }

.layer:before {
  display: block;
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0; }

.jr-card > .jr-card-body:first-child {
  margin-top: 6px; }

.jr-card.p-0 > .jr-card-body:first-child {
  margin-top: 0; }

.jr-card .jr-card-thumb {
  margin: -18px -24px 0; }
  .jr-card .jr-card-thumb .card-img {
    border-radius: 2px 2px 0 0; }

@media screen and (max-width: 575px) {
  .jr-card {
    margin-bottom: 18px; } }

.jr-card-header {
  margin-bottom: 24px; }
  .jr-card-header .card-heading {
    margin-bottom: 4px; }
  .jr-card-header .badge {
    padding: 2px 5px; }
    .jr-card-header .badge:last-child {
      margin-left: 10px; }
  @media screen and (max-width: 575px) {
    .jr-card-header {
      margin-bottom: 18px; } }

.jr-entry-header {
  margin-bottom: 24px; }
  .jr-entry-header .entry-heading {
    margin-bottom: 4px; }
  .jr-entry-header .entry-description {
    margin-bottom: 0;
    font-size: 13px;
    color: #6c757d; }
  @media screen and (max-width: 575px) {
    .jr-entry-header {
      margin-bottom: 18px; } }

.card {
  margin-bottom: 24px;
  border-radius: 2px; }
  @media screen and (max-width: 575px) {
    .card {
      margin-bottom: 18px; } }

.card-header {
  font-size: 16px; }

.card-title {
  font-size: 17px;
  text-transform: capitalize; }
  @media screen and (max-width: 991px) {
    .card-title {
      margin-bottom: 10px; } }

.product-item-vertical .card-footer {
  padding-right: 0;
  padding-left: 0; }

.card-subtitle {
  font-size: 14px;
  color: #495057; }
  .card-subtitle:not(:last-child) {
    margin-bottom: 17px; }

.card-mt-footer {
  padding: 0 10px 14px;
  margin-top: -10px; }

.jr-card-social {
  font-size: 22px;
  padding-top: 20px; }

.social-link {
  list-style: none;
  padding-left: 0;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -5px 10px; }
  .social-link li {
    padding: 0 5px;
    margin-bottom: 5px; }
  .social-link li a {
    color: #212529; }
  .social-link li.active a,
  .social-link li a:hover,
  .social-link li a:focus {
    color: #E91E63; }
  .jr-card-social .social-link {
    margin-bottom: 0;
    justify-content: space-around; }

.jr-cart-ab {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  color: #fff;
  font-size: 17px; }
  .jr-cart-ab i {
    vertical-align: middle;
    font-size: 25px; }

.img-overlay-card {
  border-radius: 2px;
  margin-bottom: 24px; }
  @media screen and (max-width: 1199px) {
    .img-overlay-card .center-crop-img {
      min-height: 135px; } }
  @media screen and (max-width: 575px) {
    .img-overlay-card {
      margin-bottom: 18px; } }

/*Charts Styles*/
.chart-header {
  padding: 15px 18px; }
  .chart-header .card-title h4 {
    color: #fff;
    margin-bottom: 4px; }
  .chart-header .title h4 {
    margin-bottom: 4px; }
  .chart-header p {
    margin-bottom: 4px; }

/*Chips Styles*/
.chip-outline {
  background-color: transparent !important;
  border: 1px solid #adb5bd !important; }

/*Carousel Styles*/
.carousel-caption h3 {
  font-size: 20px; }

/*Color Picker Styles*/
.cp-color {
  width: 36px;
  height: 14px;
  border-radius: 2px; }

.cp-swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  -o-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer; }

.cp-popover {
  position: absolute;
  z-index: 2; }

.cp-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.photoshop-picker,
.swatches-picker,
.alpha-picker,
.hue-picker {
  width: 100% !important; }

.material-picker {
  width: 100% !important;
  height: 100% !important; }

/*Drawer Styles*/
.drawer {
  width: 250px; }

.full-drawer {
  width: 100%; }

/*E-commerce Styles*/
.product-item, .product-item-vertical {
  border: 0 none;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.product-item-vertical .card-image .grid-thumb-equal {
  padding-bottom: 110%; }
  @media screen and (max-width: 1199px) {
    .product-item-vertical .card-image .grid-thumb-equal {
      padding-bottom: 74%; } }
  @media screen and (max-width: 991px) {
    .product-item-vertical .card-image .grid-thumb-equal {
      padding-bottom: 110%; } }
  @media screen and (max-width: 829px) {
    .product-item-vertical .card-image .grid-thumb-equal {
      padding-bottom: 120%; } }
  @media screen and (max-width: 767px) {
    .product-item-vertical .card-image .grid-thumb-equal {
      padding-bottom: 44%; } }

/*Grids List Styles*/
.gl-image,
.gl-list,
.gl-advanced,
.gl-single-line {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden; }

.gl {
  max-width: 100%;
  max-height: 360px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }

.gl-single-line .slg {
  flex-wrap: nowrap;
  max-width: 100%; }

.title-gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important; }

.title-gradient-bottom {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important; }

/*Icons Styles*/
.icon {
  display: inline-block;
  text-align: center; }

.icons-wrapper {
  display: inline-block; }
  .icons-wrapper + .icons-wrapper {
    margin-left: 25px; }

/*Label Styles*/
label {
  margin-bottom: 0; }

/*List Styles*/
.pinned-list {
  position: relative;
  height: 370px;
  overflow: auto;
  width: auto; }

.user-list {
  width: 100%;
  padding: 20px 15px 10px; }
  .user-list .user-avatar {
    margin-right: 20px; }
    @media screen and (max-width: 575px) {
      .user-list .user-avatar {
        height: 80px !important;
        width: 80px !important;
        line-height: 80px; } }
  .user-list h6 {
    color: #7e8b98; }

.list-inline {
  margin-left: -5px;
  margin-right: -5px; }
  .list-inline li {
    padding: 0 5px; }

.card-strip {
  border-bottom: solid 1px #dee2e6;
  margin-bottom: 10px; }

.jr-mbtn-list {
  margin-left: -20px; }

.nav-link {
  cursor: pointer; }

.card-header.bg-primary .nav-link {
  color: #fff; }
  .card-header.bg-primary .nav-link.active, .card-header.bg-primary .nav-link:hover, .card-header.bg-primary .nav-link:focus {
    color: #000;
    background-color: #fff; }
  .card-header.bg-primary .nav-link.disabled {
    color: #f8f9fa; }
  .card-header.bg-primary .nav-link.disabled:hover {
    color: #212529; }

/*Pricing Styles*/
.pt-classic .package,
.pt-default .package,
.pt-dark .package,
.pt-circle .package {
  position: relative;
  padding: 0;
  overflow: hidden;
  border: 0 none;
  border-radius: 10px;
  transition: all 0.5s ease-in-out; }
  .pt-classic .package:hover, .pt-classic .package:focus, .pt-classic .package:active,
  .pt-default .package:hover,
  .pt-default .package:focus,
  .pt-default .package:active,
  .pt-dark .package:hover,
  .pt-dark .package:focus,
  .pt-dark .package:active,
  .pt-circle .package:hover,
  .pt-circle .package:focus,
  .pt-circle .package:active {
    -o-box-shadow: 0 0 16px rgba(102, 102, 102, 0.5);
    box-shadow: 0 0 16px rgba(102, 102, 102, 0.5);
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  .pt-classic .package.highlight,
  .pt-default .package.highlight,
  .pt-dark .package.highlight,
  .pt-circle .package.highlight {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    z-index: 2; }

.pt-classic .package-header,
.pt-default .package-header,
.pt-dark .package-header,
.pt-circle .package-header {
  padding: 35px;
  text-align: center; }
  .pt-classic .package-header > .price,
  .pt-default .package-header > .price,
  .pt-dark .package-header > .price,
  .pt-circle .package-header > .price {
    display: inline-block;
    font-size: 36px;
    font-weight: 700;
    margin-left: 0;
    margin-bottom: 12px; }
    .pt-classic .package-header > .price > i,
    .pt-default .package-header > .price > i,
    .pt-dark .package-header > .price > i,
    .pt-circle .package-header > .price > i {
      display: inline-block;
      margin-right: 6px;
      font-weight: bold; }
  .pt-classic .package-header h5,
  .pt-default .package-header h5,
  .pt-dark .package-header h5,
  .pt-circle .package-header h5 {
    color: inherit; }

.pt-classic .package-items,
.pt-default .package-items,
.pt-dark .package-items,
.pt-circle .package-items {
  list-style: none;
  font-size: 14px;
  margin: 0;
  padding: 50px 50px 120px; }
  .pt-classic .package-items li,
  .pt-default .package-items li,
  .pt-dark .package-items li,
  .pt-circle .package-items li {
    margin-bottom: 16px;
    line-height: inherit; }
    .pt-classic .package-items li:before, .pt-classic .package-items li:after,
    .pt-default .package-items li:before,
    .pt-default .package-items li:after,
    .pt-dark .package-items li:before,
    .pt-dark .package-items li:after,
    .pt-circle .package-items li:before,
    .pt-circle .package-items li:after {
      content: " ";
      display: table; }
    .pt-classic .package-items li:after,
    .pt-default .package-items li:after,
    .pt-dark .package-items li:after,
    .pt-circle .package-items li:after {
      clear: both; }
    .pt-classic .package-items li i,
    .pt-default .package-items li i,
    .pt-dark .package-items li i,
    .pt-circle .package-items li i {
      float: left;
      min-width: 20px;
      margin-right: 16px;
      line-height: inherit; }
    .pt-classic .package-items li span,
    .pt-default .package-items li span,
    .pt-dark .package-items li span,
    .pt-circle .package-items li span {
      display: block;
      overflow: hidden; }

.pt-classic .package-footer,
.pt-default .package-footer,
.pt-dark .package-footer,
.pt-circle .package-footer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  padding: 40px 50px;
  text-align: center; }
  .pt-classic .package-footer .btn,
  .pt-default .package-footer .btn,
  .pt-dark .package-footer .btn,
  .pt-circle .package-footer .btn {
    color: inherit;
    padding: 10px 30px;
    border: 0 none; }

.pt-classic .package {
  border: 0 none;
  border-radius: 0; }

.pt-dark .package:hover {
  border-radius: 20px; }

.pt-circle .package-header {
  border-radius: 50%;
  height: 224px;
  width: 224px;
  margin: 40px auto 0;
  align-items: center; }
  .pt-circle .package-header .price {
    margin-top: 30px; }

@media only screen and (max-width: 991px) {
  .pt-classic .package-header,
  .pt-default .package-header,
  .pt-dark .package-header,
  .pt-circle .package-header {
    padding: 30px 20px; }
  .pt-classic .package-items,
  .pt-default .package-items,
  .pt-dark .package-items,
  .pt-circle .package-items {
    padding: 30px 20px 120px; }
  .pt-circle .package-header {
    height: 204px;
    width: 204px; } }

@media only screen and (max-width: 767px) {
  .pt-classic .package:hover, .pt-classic .package:focus, .pt-classic .package:active, .pt-classic .package.highlight,
  .pt-default .package:hover,
  .pt-default .package:focus,
  .pt-default .package:active,
  .pt-default .package.highlight,
  .pt-dark .package:hover,
  .pt-dark .package:focus,
  .pt-dark .package:active,
  .pt-dark .package.highlight,
  .pt-circle .package:hover,
  .pt-circle .package:focus,
  .pt-circle .package:active,
  .pt-circle .package.highlight {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/*Progressbar Styles*/
.pb-wrapper {
  display: flex;
  align-items: center; }

.pb-btn-wrapper {
  margin: 8px;
  position: relative; }
  .pb-btn-wrapper .btn-success {
    background-color: #4CAF50; }
    .pb-btn-wrapper .btn-success:hover {
      background-color: #388E3C; }

.fab-progress {
  color: #4CAF50;
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1; }

.btn-progress {
  color: #4CAF50;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px; }

/*Popover Styles*/
.popover {
  z-index: 1510; }

.popover-header {
  background-color: transparent;
  border-bottom: 0 none;
  color: #000;
  font-size: 20px;
  padding: 15px 20px 0; }

.popover-body {
  padding: 15px 20px; }

/*React Joyride Styles*/
.joyride-beacon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: 1500; }
  .joyride-beacon:active, .joyride-beacon:focus, .joyride-beacon:hover {
    outline: none; }
  .joyride-beacon__inner {
    -webkit-animation: joyride-beacon-inner 1.2s infinite ease-in-out;
            animation: joyride-beacon-inner 1.2s infinite ease-in-out;
    background-color: #f04;
    border-radius: 50%;
    display: block;
    height: 50%;
    left: 50%;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50%; }
  .joyride-beacon__outer {
    -webkit-animation: joyride-beacon-outer 1.2s infinite ease-in-out;
            animation: joyride-beacon-outer 1.2s infinite ease-in-out;
    background-color: rgba(255, 0, 68, 0.2);
    border: 2px solid #f04;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transform-origin: center;
            transform-origin: center;
    width: 100%; }

.joyride-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1500; }

.joyride-hole {
  border-radius: 4px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
  position: absolute; }
  .joyride-hole.safari {
    box-shadow: 0 0 999px 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5); }

.joyride-tooltip {
  background-color: #fff;
  border-radius: 4px;
  color: #555;
  cursor: default;
  -webkit-filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
  opacity: 0;
  padding: 20px;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  width: 290px;
  z-index: 1510; }
  .joyride-tooltip--animate {
    -webkit-animation: joyride-tooltip 0.4s forwards;
            animation: joyride-tooltip 0.4s forwards;
    -webkit-animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18);
            animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18); }
  .joyride-tooltip__triangle {
    background-repeat: no-repeat;
    position: absolute; }
  .joyride-tooltip.bottom, .joyride-tooltip.bottom-left, .joyride-tooltip.bottom-right {
    margin-top: 18px; }
    .joyride-tooltip.bottom .joyride-tooltip__triangle, .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.bottom-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28180 8 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 18px;
      left: 50%;
      top: -16px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.top, .joyride-tooltip.top-left, .joyride-tooltip.top-right {
    margin-bottom: 18px; }
    .joyride-tooltip.top .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%280%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      bottom: -16px;
      height: 18px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
    left: 3%;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
        left: 2%; } }
  .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
    left: auto;
    right: 3%;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
        right: 2%; } }
  .joyride-tooltip.left {
    margin-right: 18px; }
    .joyride-tooltip.left .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28270 8 8%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      right: -16px;
      width: 18px; }
  .joyride-tooltip.right {
    margin-left: 18px; }
    .joyride-tooltip.right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%2890 4 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      left: -16px;
      width: 18px; }
  .joyride-tooltip__close {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.117.323l-6.073%206.075-5.449-6.075c-.431-.431-1.131-.431-1.562%200-.431.431-.431%201.131%200%201.562l5.449%206.075-6.159%206.159c-.431.431-.431%201.134%200%201.562.431.431%201.131.431%201.562%200l6.075-6.073%205.449%206.073c.429.431%201.131.431%201.56%200%20.431-.429.431-1.131%200-1.562l-5.452-6.073%206.159-6.161c.431-.431.431-1.131%200-1.562-.431-.431-1.129-.431-1.56%200z%22%20fill%3D%22rgba(85, 85, 85, 0.5)%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    height: 12px;
    position: absolute;
    right: 10px;
    text-decoration: none;
    top: 10px;
    width: 12px;
    z-index: 10;
    display: block; }
    .joyride-tooltip__close:active, .joyride-tooltip__close:focus, .joyride-tooltip__close:hover {
      outline: none; }
    .joyride-tooltip__close:hover, .joyride-tooltip__close:focus {
      color: rgba(60, 60, 60, 0.5);
      outline: none; }
    .joyride-tooltip__close--header {
      right: 20px;
      top: 20px; }
  .joyride-tooltip__header {
    border-bottom: 1px solid #f04;
    color: #555;
    font-size: 16px;
    padding-bottom: 6px;
    padding-right: 18px;
    position: relative; }
    .joyride-tooltip__header ~ .joyride-tooltip__main {
      padding: 12px 0 18px; }
  .joyride-tooltip__main {
    font-size: 14px;
    padding-bottom: 18px;
    padding-right: 18px; }
  .joyride-tooltip__footer {
    text-align: right; }
  .joyride-tooltip__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0; }
    .joyride-tooltip__button:active, .joyride-tooltip__button:focus, .joyride-tooltip__button:hover {
      outline: none; }
    .joyride-tooltip__button--primary {
      background-color: #f04;
      border-radius: 4px;
      color: #fff;
      padding: 6px 12px;
      transition: background-color 0.2s ease-in-out; }
      .joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
        background-color: #ff1f5a;
        color: #fff; }
    .joyride-tooltip__button--secondary {
      color: #f04;
      margin-right: 10px; }
    .joyride-tooltip__button--skip {
      color: #ccc;
      float: left;
      margin-right: 10px; }
  .joyride-tooltip--standalone .joyride-tooltip__main {
    padding-bottom: 0; }
  .joyride-tooltip--standalone .joyride-tooltip__footer {
    display: none; }
  @media screen and (min-width: 480px) {
    .joyride-tooltip {
      width: 360px; } }
  @media screen and (min-width: 960px) {
    .joyride-tooltip {
      width: 450px; } }

@-webkit-keyframes joyride-tooltip {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale(1);
            transform: perspective(1px) scale(1); } }

@keyframes joyride-tooltip {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale(1);
            transform: perspective(1px) scale(1); } }

@-webkit-keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@-webkit-keyframes joyride-beacon-outer {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    opacity: 0.7;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }
  100% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes joyride-beacon-outer {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    opacity: 0.7;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }
  100% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); } }

/*Tables Styles*/
.table-header {
  padding-right: 2px !important; }
  .table-header.highlight-light {
    color: #616161;
    background-color: #e0e0e0; }
  .table-header.highlight-dark {
    color: #fff;
    background-color: #616161; }

.spacer {
  flex: 1 1 100%; }

.actions {
  color: #F44336; }

.title {
  flex: 0 0 auto; }

.table-hover tbody tr:hover {
  background-color: rgba(103, 58, 183, 0.075); }

.table-material {
  width: 100% !important;
  max-width: 100% !important;
  background-color: transparent !important; }
  .table-material th,
  .table-material td {
    padding: 0.75rem !important;
    vertical-align: top !important;
    border-top: 1px solid #dee2e6 !important; }
  .table-material thead th {
    vertical-align: bottom !important;
    border-bottom: 2px solid #dee2e6 !important; }
  .table-material tbody + tbody {
    border-top: 2px solid #dee2e6 !important; }
  .table-material .table-material {
    background-color: #f8f9fa !important; }

.d-flex-stretch {
  align-items: stretch;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap; }

.default-table {
  margin-bottom: 0; }
  .default-table thead th {
    font-weight: 400;
    font-size: 16px; }
  .default-table td {
    vertical-align: middle; }

.table-unbordered th,
.table-unbordered td {
  border: 0 none !important; }

.table-unbordered .th-border-b th {
  border-bottom: 2px solid #ced4da !important; }

.table-responsive-material {
  min-height: .01%;
  overflow-x: auto; }

@media screen and (max-width: 767px) {
  .table-responsive-material {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd; }
  .table-responsive-material > .table {
    margin-bottom: 0; }
  .table-responsive-material > .table > thead > tr > th,
  .table-responsive-material > .table > tbody > tr > th,
  .table-responsive-material > .table > tfoot > tr > th,
  .table-responsive-material > .table > thead > tr > td,
  .table-responsive-material > .table > tbody > tr > td,
  .table-responsive-material > .table > tfoot > tr > td {
    white-space: nowrap; }
  .table-responsive-material > .table-bordered {
    border: 0; }
  .table-responsive-material > .table-bordered > thead > tr > th:first-child,
  .table-responsive-material > .table-bordered > tbody > tr > th:first-child,
  .table-responsive-material > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive-material > .table-bordered > thead > tr > td:first-child,
  .table-responsive-material > .table-bordered > tbody > tr > td:first-child,
  .table-responsive-material > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .table-responsive-material > .table-bordered > thead > tr > th:last-child,
  .table-responsive-material > .table-bordered > tbody > tr > th:last-child,
  .table-responsive-material > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive-material > .table-bordered > thead > tr > td:last-child,
  .table-responsive-material > .table-bordered > tbody > tr > td:last-child,
  .table-responsive-material > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .table-responsive-material > .table-bordered > tbody > tr:last-child > th,
  .table-responsive-material > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive-material > .table-bordered > tbody > tr:last-child > td,
  .table-responsive-material > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0; } }

/*Tabs Styles*/
.tab {
  min-width: 10px !important; }

.tab-icon > span {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

/*Testimonial Styles*/
.testimonial-in-bg {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.06);
  padding: 30px 30px 30px 150px;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 450px) {
    .testimonial-in-bg {
      padding-left: 100px; } }
  .testimonial-in-bg .pic {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 30px;
    left: 20px;
    border: 4px solid #ffa000; }
    @media screen and (max-width: 450px) {
      .testimonial-in-bg .pic {
        width: 50px;
        height: 50px; } }
  .testimonial-in-bg .pic img {
    width: 100%;
    height: auto; }
  .testimonial-in-bg .description {
    color: #bdbdbd;
    font-style: italic;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 15px;
    position: relative; }
    .testimonial-in-bg .description:before {
      content: "\f1b2";
      font-family: 'Material-Design-Iconic-Font';
      font-size: 24px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      position: absolute;
      left: -24px; }
  .testimonial-in-bg .title {
    color: #ffa000;
    margin-bottom: 2px; }

.classic-testimonial {
  text-align: center; }
  .classic-testimonial .pic {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 120px;
    height: 120px; }
    @media screen and (max-width: 500px) {
      .classic-testimonial .pic {
        width: 80px;
        height: 80px; } }
  .classic-testimonial .pic img {
    width: 100%;
    height: 100%; }

/*Time Lines Style*/
.timeline-left {
  float: left;
  margin-right: 20px; }

.timeline-right {
  float: right;
  margin-left: 20px; }

.timeline-circle {
  border-radius: 50%; }

.timeline-section {
  margin-bottom: 25px; }

.timeline-item {
  padding-bottom: 25px;
  padding-left: 80px;
  position: relative; }
  .timeline-item:before {
    border-left: solid 2px #dee2e6;
    content: "";
    left: 25px;
    position: absolute;
    top: 25px;
    bottom: -20px;
    width: 2px;
    z-index: 1; }
  .timeline-item:first-child:before {
    border-left-style: dashed; }
  .timeline-item:last-child:before {
    border-left-style: dashed;
    bottom: 25px; }

.timeline-badge {
  background-color: #dee2e6;
  height: 50px;
  width: 50px;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 2;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-radius: 50%; }
  .timeline-badge.timeline-img {
    background-color: transparent; }
  .timeline-badge svg {
    vertical-align: calc(100% - 56px); }

.timeline-img > img,
.timeline-header-img > img {
  display: inline-block;
  max-width: 100%;
  height: auto; }

.timeline-panel {
  padding: 20px 30px;
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2); }
  .timeline-panel:before, .timeline-panel:after {
    content: "";
    position: absolute;
    border-style: solid;
    display: inline-block; }
  .timeline-panel:before {
    border-color: transparent #dee2e6;
    border-width: 15px 15px 15px 0;
    left: -15px;
    top: 27px; }
  .timeline-panel:after {
    border-color: transparent #fff;
    border-width: 14px 14px 14px 0;
    left: -14px;
    top: 27px; }

.timeline-no-padding {
  padding: 0; }

.timeline-no-shadow {
  -o-box-shadow: none;
  box-shadow: none; }
  .timeline-no-shadow:before, .timeline-no-shadow:after {
    display: none; }

.timeline-panel-header, .timeline-header-img {
  margin-bottom: 10px; }

.timeline-inverted .timeline-left {
  float: right;
  margin-right: 0;
  margin-left: 20px; }

.timeline-heading {
  overflow: hidden;
  margin-bottom: 10px; }

.timeline-body {
  clear: both; }

/*Gxtl Center Style*/
.timeline-center .timeline-item {
  clear: both;
  float: right;
  width: 50%;
  padding-left: 55px; }
  .timeline-center .timeline-item:before {
    left: 0; }

.timeline-center .timeline-badge {
  left: -25px;
  right: auto; }

.timeline-center .timeline-time {
  position: absolute;
  top: 32px;
  left: -150px;
  right: auto;
  z-index: 2; }

.timeline-center .timeline-inverted {
  float: left;
  text-align: right;
  padding-left: 0;
  padding-right: 55px; }
  .timeline-center .timeline-inverted:before {
    border-left: 0 none;
    border-right: solid 2px #dee2e6;
    left: auto;
    right: -2px; }
  .timeline-center .timeline-inverted .timeline-badge {
    left: auto;
    right: -25px; }
  .timeline-center .timeline-inverted .timeline-panel:before {
    border-width: 15px 0 15px 15px;
    left: auto;
    right: -15px; }
  .timeline-center .timeline-inverted .timeline-panel:after {
    border-width: 14px 0 14px 14px;
    left: auto;
    right: -14px; }
  .timeline-center .timeline-inverted .timeline-time {
    position: absolute;
    top: 32px;
    right: -150px;
    left: auto;
    z-index: 2; }

/*Gxtl Zigzag Style*/
.timeline-zigzag .timeline-item {
  padding-left: 150px; }
  .timeline-zigzag .timeline-item + .timeline-item {
    margin-top: -80px; }

.timeline-zigzag .timeline-img {
  width: 90px;
  height: 90px;
  left: 5px; }
  .timeline-zigzag .timeline-img > img {
    width: 100%; }

.timeline-zigzag .timeline-inverted .timeline-img {
  right: 5px !important; }

.timeline-zigzag .timeline-item:before {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  top: 20px;
  bottom: 20px; }

.timeline-zigzag .timeline-inverted {
  padding-left: 0;
  padding-right: 150px; }
  .timeline-zigzag .timeline-inverted:before {
    -webkit-transform: rotate(-35deg);
    transform: rotate(-35deg); }

.timeline-zigzag .timeline-item:first-child:before {
  border-left-style: solid; }

.timeline-zigzag .timeline-item:last-child:before {
  display: none; }

.timeline-item:last-child:before {
  bottom: 25px; }

@media screen and (max-width: 991px) {
  .timeline-zigzag .timeline-item + .timeline-item {
    margin-top: -60px; }
  .timeline-zigzag .timeline-item:before {
    bottom: 0; }
  .timeline-zigzag .timeline-item {
    padding-left: 120px; }
  .timeline-zigzag .timeline-inverted {
    padding-right: 120px;
    padding-left: 0; } }

@media screen and (max-width: 767px) {
  .timeline-center .timeline-item {
    float: none;
    padding-left: 80px;
    width: auto; }
    .timeline-center .timeline-item:before {
      left: 25px; }
  .timeline-center .timeline-badge {
    left: 0; }
  .timeline-center .timeline-inverted {
    float: none;
    text-align: left;
    padding-right: 0; }
    .timeline-center .timeline-inverted:before {
      border-left: solid 2px #dee2e6;
      border-right: 0 none;
      left: 24px;
      right: auto; }
    .timeline-center .timeline-inverted .timeline-badge {
      right: auto;
      left: 0; }
    .timeline-center .timeline-inverted .timeline-panel:before {
      border-width: 15px 15px 15px 0;
      left: -15px;
      right: auto; }
    .timeline-center .timeline-inverted .timeline-panel:after {
      border-width: 14px 14px 14px 0;
      left: -14px;
      right: auto; }
  .timeline-inverted .timeline-panel-header {
    float: none; }
  .timeline-inverted .timeline-left {
    float: left;
    margin-left: 0;
    margin-right: 20px; }
  .timeline-zigzag .timeline-panel {
    max-height: none;
    min-height: 10px;
    overflow-y: visible; }
  .timeline-zigzag .timeline-item {
    padding-left: 100px; }
    .timeline-zigzag .timeline-item + .timeline-item {
      margin-top: 0; }
    .timeline-zigzag .timeline-item:before {
      -webkit-transform: none;
              transform: none;
      top: 25px;
      bottom: -25px;
      left: 45px; }
    .timeline-zigzag .timeline-item:last-child:before {
      bottom: 0; }
  .timeline-zigzag .timeline-inverted:before {
    -webkit-transform: none;
            transform: none; }
  .timeline-center .timeline-time,
  .timeline-center .timeline-inverted .timeline-time {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-bottom: 6px; }
  .timeline-center .timeline-time-item .timeline-panel::before {
    top: 10px; }
  .timeline-center .timeline-time-item .timeline-panel::after {
    top: 11px; } }

@media screen and (max-width: 399px) {
  .timeline-left {
    float: none;
    margin-right: 0; }
  .timeline-right {
    float: none;
    margin-left: 0; }
  .timeline-inverted .timeline-left {
    float: none;
    margin-right: 0; } }

/*React Joyride Styles*/
.joyride-beacon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: 1500; }
  .joyride-beacon:active, .joyride-beacon:focus, .joyride-beacon:hover {
    outline: none; }
  .joyride-beacon__inner {
    -webkit-animation: joyride-beacon-inner 1.2s infinite ease-in-out;
            animation: joyride-beacon-inner 1.2s infinite ease-in-out;
    background-color: #f04;
    border-radius: 50%;
    display: block;
    height: 50%;
    left: 50%;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50%; }
  .joyride-beacon__outer {
    -webkit-animation: joyride-beacon-outer 1.2s infinite ease-in-out;
            animation: joyride-beacon-outer 1.2s infinite ease-in-out;
    background-color: rgba(255, 0, 68, 0.2);
    border: 2px solid #f04;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transform-origin: center;
            transform-origin: center;
    width: 100%; }

.joyride-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1500; }

.joyride-hole {
  border-radius: 4px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
  position: absolute; }
  .joyride-hole.safari {
    box-shadow: 0 0 999px 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5); }

.joyride-tooltip {
  background-color: #fff;
  border-radius: 4px;
  color: #555;
  cursor: default;
  -webkit-filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
  opacity: 0;
  padding: 20px;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  width: 290px;
  z-index: 1510; }
  .joyride-tooltip--animate {
    -webkit-animation: joyride-tooltip 0.4s forwards;
            animation: joyride-tooltip 0.4s forwards;
    -webkit-animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18);
            animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18); }
  .joyride-tooltip__triangle {
    background-repeat: no-repeat;
    position: absolute; }
  .joyride-tooltip.bottom, .joyride-tooltip.bottom-left, .joyride-tooltip.bottom-right {
    margin-top: 18px; }
    .joyride-tooltip.bottom .joyride-tooltip__triangle, .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.bottom-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28180 8 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 18px;
      left: 50%;
      top: -16px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.top, .joyride-tooltip.top-left, .joyride-tooltip.top-right {
    margin-bottom: 18px; }
    .joyride-tooltip.top .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%280%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      bottom: -16px;
      height: 18px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
    left: 3%;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
        left: 2%; } }
  .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
    left: auto;
    right: 3%;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
        right: 2%; } }
  .joyride-tooltip.left {
    margin-right: 18px; }
    .joyride-tooltip.left .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28270 8 8%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      right: -16px;
      width: 18px; }
  .joyride-tooltip.right {
    margin-left: 18px; }
    .joyride-tooltip.right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%2890 4 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      left: -16px;
      width: 18px; }
  .joyride-tooltip__close {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.117.323l-6.073%206.075-5.449-6.075c-.431-.431-1.131-.431-1.562%200-.431.431-.431%201.131%200%201.562l5.449%206.075-6.159%206.159c-.431.431-.431%201.134%200%201.562.431.431%201.131.431%201.562%200l6.075-6.073%205.449%206.073c.429.431%201.131.431%201.56%200%20.431-.429.431-1.131%200-1.562l-5.452-6.073%206.159-6.161c.431-.431.431-1.131%200-1.562-.431-.431-1.129-.431-1.56%200z%22%20fill%3D%22rgba(85, 85, 85, 0.5)%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    height: 12px;
    position: absolute;
    right: 10px;
    text-decoration: none;
    top: 10px;
    width: 12px;
    z-index: 10;
    display: block; }
    .joyride-tooltip__close:active, .joyride-tooltip__close:focus, .joyride-tooltip__close:hover {
      outline: none; }
    .joyride-tooltip__close:hover, .joyride-tooltip__close:focus {
      color: rgba(60, 60, 60, 0.5);
      outline: none; }
    .joyride-tooltip__close--header {
      right: 20px;
      top: 20px; }
  .joyride-tooltip__header {
    border-bottom: 1px solid #f04;
    color: #555;
    font-size: 16px;
    padding-bottom: 6px;
    padding-right: 18px;
    position: relative; }
    .joyride-tooltip__header ~ .joyride-tooltip__main {
      padding: 12px 0 18px; }
  .joyride-tooltip__main {
    font-size: 14px;
    padding-bottom: 18px;
    padding-right: 18px; }
  .joyride-tooltip__footer {
    text-align: right; }
  .joyride-tooltip__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0; }
    .joyride-tooltip__button:active, .joyride-tooltip__button:focus, .joyride-tooltip__button:hover {
      outline: none; }
    .joyride-tooltip__button--primary {
      background-color: #f04;
      border-radius: 4px;
      color: #fff;
      padding: 6px 12px;
      transition: background-color 0.2s ease-in-out; }
      .joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
        background-color: #ff1f5a;
        color: #fff; }
    .joyride-tooltip__button--secondary {
      color: #f04;
      margin-right: 10px; }
    .joyride-tooltip__button--skip {
      color: #ccc;
      float: left;
      margin-right: 10px; }
  .joyride-tooltip--standalone .joyride-tooltip__main {
    padding-bottom: 0; }
  .joyride-tooltip--standalone .joyride-tooltip__footer {
    display: none; }
  @media screen and (min-width: 480px) {
    .joyride-tooltip {
      width: 360px; } }
  @media screen and (min-width: 960px) {
    .joyride-tooltip {
      width: 450px; } }

@keyframes joyride-tooltip {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale(1);
            transform: perspective(1px) scale(1); } }

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@keyframes joyride-beacon-outer {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    opacity: 0.7;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }
  100% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); } }

/*Joyride Tour Styles*/
/*Form Styles*/
@media screen and (max-width: 575px) {
  .horizontal-stepper p,
  .horizontal-stepper span {
    opacity: 0;
    visibility: hidden;
    font-size: 12px; }
  .horizontal-stepper.active p,
  .horizontal-stepper.active span {
    opacity: 1;
    visibility: visible; }
  .horizontal-stepper > *,
  .horizontal-stepper > button > * {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; }
  .horizontal-stepper .stepperlabel,
  .horizontal-stepper .stepperbutton,
  .horizontal-stepper .stepperlabel span:first-child,
  .horizontal-stepper .stepperbutton span:first-child {
    opacity: 1;
    visibility: visible; }
  .horizontal-stepper-linear {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

/*Input Styles*/
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px; }

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px; }

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px; }

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px; }

.module-box-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px; }

.module-box-header ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px; }

.module-box-header :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px; }

.module-box-header :-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px; }

.search-bar {
  background-color: #fff;
  position: relative; }
  .search-bar .form-group {
    width: 100%;
    height: 100%;
    margin-bottom: 0; }
  .search-bar input[type="search"] {
    padding-right: 50px; }
  .search-bar .search-icon {
    background-color: transparent;
    border: 0 none;
    color: #868e96;
    height: 36px;
    width: 30px; }
  .search-bar .form-control {
    box-shadow: none; }
    .search-bar .form-control.form-control-lg + .search-icon {
      position: absolute;
      right: 14px;
      top: 6px; }
  .search-bar .search-icon {
    position: absolute;
    right: 14px;
    top: 3px; }
  .search-bar.right-side-icon input[type="search"] {
    padding-right: 16px;
    padding-left: 50px; }
  .search-bar.right-side-icon .search-icon {
    position: absolute;
    left: 14px;
    top: 2px; }
  .search-bar.right-side-icon .form-control {
    box-shadow: none; }
    .search-bar.right-side-icon .form-control.form-control-lg + .search-icon {
      position: absolute;
      left: 14px;
      top: 6px; }

.nav-searchbox .search-bar input + .search-icon i {
  color: rgba(33, 37, 41, 0.8); }

@media screen and (max-width: 767px) {
  .jr-card .app-main-header .search-bar {
    width: 150px; } }

/* Pages
   ========================================================================== */
/*Login Styles*/
/*Login Styles*/
.login-container {
  position: relative;
  height: 100%; }

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: #fff;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 14px;
  max-width: 94%; }

.login-header {
  margin-bottom: 30px; }

.login-content .form-control {
  background-color: #f5f5f5; }
  .login-content .form-control:focus {
    box-shadow: none;
    border-color: #673ab7; }

.login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important; }

.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto; }
  .app-login-container .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2; }

.app-login-main-content {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 14px;
  overflow: hidden; }

.app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
  order: 1; }
  @media screen and (max-width: 575px) {
    .app-login-content {
      width: 100%;
      order: 2; } }

.app-login-header {
  margin-bottom: 30px; }

.app-login-content .form-control {
  background-color: #f5f5f5; }
  .app-login-content .form-control:focus {
    box-shadow: none;
    border-color: #673ab7; }

.app-login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important; }

.app-logo-content {
  background-color: #673ab7;
  padding: 35px 35px 20px;
  width: 40%;
  order: 2; }
  @media screen and (max-width: 575px) {
    .app-logo-content {
      width: 100%;
      order: 1; } }

.app-social-block {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .app-social-block .social-link,
  .app-social-block .social-link li {
    margin: 0; }
  .app-social-block .social-link button {
    border: solid 1px #673ab7;
    border-radius: 50%;
    color: #673ab7; }
    .app-social-block .social-link button:hover, .app-social-block .social-link button:focus {
      color: #fff;
      background-color: #673ab7; }
  .app-social-block p {
    margin-bottom: 0; }

/*Error Styles*/
.page-error-container {
  padding: 30px 0;
  position: relative; }
  @media screen and (max-width: 575px) {
    .page-error-container {
      padding: 15px 0; } }

.page-error-content {
  margin: 0 auto;
  padding: 40px 0;
  width: 380px;
  max-width: 94%; }
  @media screen and (max-width: 767px) {
    .page-error-content {
      padding: 15px 0; } }
  @media screen and (max-width: 575px) {
    .page-error-content {
      padding: 0; } }

.error-code {
  color: #212529;
  font-size: 160px;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  text-shadow: 10px 6px 8px rgba(117, 117, 117, 0.8); }
  @media screen and (max-width: 991px) {
    .error-code {
      font-size: 130px; } }
  @media screen and (max-width: 767px) {
    .error-code {
      font-size: 100px; } }
  @media screen and (max-width: 575px) {
    .error-code {
      font-size: 80px; } }

.page-error-content h2 {
  margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    .page-error-content h2 {
      margin-bottom: 20px; } }

/*Editor Styles*/
.rdw-inline-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.rdw-option-wrapper {
  margin-bottom: 6px; }

/*Contact Page Styles*/
.contact-form {
  padding: 60px; }

.contact-block .contact-info {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  -webkit-align-content: stretch;
  -ms-flex-align: stretch;
  align-content: stretch; }
  .contact-block .contact-info li {
    flex: 1 1;
    overflow: hidden;
    padding: 10px 40px;
    border-right: 2px solid #212529;
    word-wrap: break-word; }
    .contact-block .contact-info li:last-child {
      border: 0 none; }
    .contact-block .contact-info li > i {
      float: left;
      font-size: 16px;
      margin-right: 5px; }
    .contact-block .contact-info li .contact-body {
      overflow: hidden; }
      .contact-block .contact-info li .contact-body .icons-wrapper {
        margin-top: 15px;
        margin-left: 0; }
      .contact-block .contact-info li .contact-body .icon {
        background-color: #5c6bc0;
        color: #fff;
        border-radius: 50%;
        margin-right: 6px;
        margin-bottom: 6px; }
        .contact-block .contact-info li .contact-body .icon:last-child {
          margin-right: 0; }
      .contact-block .contact-info li .contact-body .twitter-icon {
        background-color: #40c4ff; }
      .contact-block .contact-info li .contact-body .google-icon {
        background-color: #EF5350; }
      .contact-block .contact-info li .contact-body .linkedin-icon {
        background-color: #0277bd; }
  .contact-block .contact-info.vertical {
    display: block;
    margin-bottom: 0; }
    .contact-block .contact-info.vertical li {
      padding: 0 0 24px;
      border-right: 0 none;
      border-bottom: 2px solid #212529;
      margin-bottom: 24px; }
      .contact-block .contact-info.vertical li:last-child {
        border: 0 none;
        margin-bottom: 0;
        padding-bottom: 0; }

@media screen and (max-width: 767px) {
  .contact-form {
    padding: 30px; }
  .contact-block .contact-info {
    display: block;
    margin-bottom: 0; }
    .contact-block .contact-info li {
      padding: 0 0 24px;
      border-right: 0 none;
      border-bottom: 2px solid #212529;
      margin-bottom: 24px; }
      .contact-block .contact-info li:last-child {
        border: 0 none;
        margin-bottom: 0;
        padding-bottom: 0; }
  .content-section {
    padding: 20px 10px 0;
    text-align: center; }
    .content-section h2 {
      font-size: 28px; }
    .content-section p {
      font-size: 18px; } }

/*Portfolio Styles*/
.portfolio-section .card-image {
  margin-bottom: 20px; }
  .portfolio-section .card-image img {
    width: 100%;
    transition: all 1200ms ease; }
  .portfolio-section .card-image:hover img {
    -o-box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.6);
    -webkit-transform: scale(1.08);
    transform: scale(1.08); }

.filter-with-bg-color ul {
  padding-left: 0;
  margin: 0 -10px 45px; }
  .filter-with-bg-color ul li {
    display: inline-block;
    margin-bottom: 10px;
    padding: 0 10px; }
  .filter-with-bg-color ul li a {
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 24px;
    border: 1px solid #673ab7;
    display: block;
    text-decoration: none; }
  .filter-with-bg-color ul li a:hover,
  .filter-with-bg-color ul li a:focus,
  .filter-with-bg-color ul li a.active {
    cursor: pointer;
    background-color: #673ab7;
    border-color: #673ab7;
    color: #fff; }
  @media screen and (max-width: 575px) {
    .filter-with-bg-color ul {
      margin-bottom: 15px; } }

/*Faqs Styles*/
.Collapsible {
  background-color: #fff;
  margin-bottom: 15px; }

.Collapsible__contentInner {
  padding: 20px;
  border: 1px solid #fff;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding: 18px 20px;
  border-radius: 6px;
  background: #673ab7;
  color: #fff; }
  .Collapsible__trigger:after {
    content: '\f2f9';
    font-family: 'Material-Design-Iconic-Font';
    font-size: 20px;
    position: absolute;
    right: 24px;
    top: 16px;
    display: block;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }
  .Collapsible__trigger.is-open {
    border-radius: 6px 6px 0 0; }
    .Collapsible__trigger.is-open:after {
      -webkit-transform: rotateZ(180deg);
              transform: rotateZ(180deg); }

.categories-list {
  margin-bottom: 0; }
  .categories-list li {
    margin-bottom: 10px; }
    .categories-list li:last-child {
      margin-bottom: 0; }
  .categories-list li.active a {
    color: #673ab7; }
  .categories-list .badge {
    font-size: 9px; }

.articles-section .articles {
  margin-bottom: 26px; }
  .articles-section .articles:last-child {
    margin-bottom: 0; }

.article-image {
  display: block;
  margin-bottom: 12px; }
  .article-image > img {
    border-radius: 6px;
    width: 100%; }

.article-description p {
  font-weight: 500;
  margin-bottom: 2px; }

.meta-wrapper {
  margin-bottom: 10px; }

.meta-date,
.meta-comment {
  font-size: 12px;
  color: #6c757d;
  display: inline-block; }
  .meta-date i,
  .meta-comment i {
    display: inline-block;
    margin-right: 6px; }

.meta-date + .meta-comment {
  margin-left: 10px; }

/*Dashboard Styles*/
.box {
  position: relative; }
  .box .box-top,
  .box .box-bottom {
    height: 100px;
    padding: 32px 15px;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 300; }
    .box .box-top .size-h5,
    .box .box-bottom .size-h5 {
      font-size: 24px;
      margin-left: 2px; }
    .box .box-top .material-icons,
    .box .box-bottom .material-icons {
      font-size: 40px;
      line-height: 40px; }
  .box .box-bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .box .box-info {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -12px;
    text-align: center; }
    .box .box-info span {
      height: 24px;
      display: inline-block;
      padding: 4px 10px;
      text-transform: uppercase;
      line-height: 14px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 12px;
      color: #6c757d;
      border-radius: 1em; }
      .box .box-info span .material-icons {
        line-height: 14px;
        font-size: 11px; }

.contact-list {
  margin-bottom: 14px; }
  .contact-list i {
    margin-right: 14px;
    color: #673ab7; }
  .contact-list .media {
    margin-bottom: 10px; }

.temp-section .temp-point,
.temp-section .detail-icon {
  font-size: 54px; }

.assignment-table tbody tr:nth-child(odd) {
  background-color: #dee2e6 !important; }

.assignment-table td {
  padding-top: 10px;
  padding-bottom: 10px; }

.status-cell {
  min-width: 100px;
  max-width: 120px;
  padding-right: 24px !important; }
  .status-cell .badge {
    min-width: 70px; }

.thumbnail-list {
  margin-bottom: 0; }
  .thumbnail-list .thumbnail-item {
    padding: 0 2.5px;
    display: inline-block;
    width: 33.333%;
    margin-bottom: 5px; }
    @media screen and (max-width: 767px) {
      .thumbnail-list .thumbnail-item {
        width: 50%; } }
    @media screen and (max-width: 575px) {
      .thumbnail-list .thumbnail-item {
        width: 33.333%; } }

.post-list .post-image .grid-thumb-equal {
  padding-bottom: 100%; }

/*Ecommerce Dashboard Styles*/
.chart-f30 {
  font-size: 30px; }
  @media screen and (max-width: 1099px) {
    .chart-f30 {
      font-size: 20px; } }

.chart-f20 {
  font-size: 20px; }
  @media screen and (max-width: 1099px) {
    .chart-f20 {
      font-size: 16px; } }

.chart-order .col-5 {
  order: 2;
  text-align: center; }
  @media screen and (max-width: 575px) {
    .chart-order .col-5 {
      order: 1; } }

.chart-order .col-7 {
  order: 1; }
  @media screen and (max-width: 575px) {
    .chart-order .col-7 {
      order: 2; } }

.tab-notifications {
  margin-left: -24px;
  margin-right: -24px;
  border-bottom: 1px solid #adb5bd; }
  @media screen and (max-width: 567px) {
    .tab-notifications button,
    .tab-notifications button * {
      font-size: 12px !important; } }

.chart-title h2 {
  font-size: 22px;
  margin-bottom: 5px; }

.img-fluid {
  width: 100%; }

.comment-footer button {
  margin-bottom: 6px; }

/*Intranet Dashboard Styles*/
.net-chart {
  padding-left: 0;
  padding-right: 8px;
  min-height: 100px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }
  .net-chart .jr-card-thumb {
    margin: 0 20px 0 0;
    width: 65px;
    transition: all 0.2s ease 0.1s; }
  .net-chart:hover .jr-card-thumb {
    margin-right: 5px;
    width: 80px; }
  .net-chart .jr-card-thumb img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    transition: all 0.2s ease 0.1s;
    overflow: hidden;
    margin-left: 15px; }
  .net-chart:hover .jr-card-thumb img {
    width: 80px;
    height: 100px;
    border-radius: 0;
    margin: -18px 15px -18px 0; }
  .net-chart .jr-card-body {
    width: calc(100% - 95px); }
  .net-chart:hover .jr-card-body {
    width: calc(100% - 95px); }

.jr-chart-or .chart-header {
  padding-bottom: 0; }

.net-chart-hr {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-height: 120px;
  padding-right: 8px;
  overflow: hidden; }
  .net-chart-hr .jr-card-thumb {
    margin: -30px 12px -30px -24px;
    min-width: 100px; }
  .net-chart-hr .grid-thumb-equal {
    padding-bottom: 130%; }
  .net-chart-hr .jr-card-body {
    width: calc(100% - 100px); }

.user-profile {
  padding: 10px 0; }

.mh-120 {
  min-height: 120px; }

.jr-card-header-top {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -16px; }

.jr-card-header-color {
  padding: 10px 20px 20px;
  margin: -18px -24px 25px; }

.todo-cell-group .todo-cell:not(:last-child) {
  margin-bottom: 8px; }

.social-list-line {
  position: relative; }
  .social-list-line:not(:last-child):before {
    border-left: solid 1px #adb5bd;
    content: "";
    left: 20px;
    position: absolute;
    top: 5px;
    bottom: 0;
    width: 1px;
    z-index: 1; }

.list-line-item {
  position: relative;
  padding-left: 40px; }
  .list-line-item:not(:last-child):before {
    border-left: solid 1px #adb5bd;
    content: "";
    left: 20px;
    position: absolute;
    top: -25px;
    bottom: -25px;
    width: 1px;
    z-index: 1; }

.list-line-badge {
  position: absolute;
  left: 16px;
  top: 15px;
  z-index: 2;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #adb5bd; }

/* Slider
   ========================================================================== */
.brand-logo {
  border: 1px solid #dee2e6;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 20px;
  padding-bottom: 75%; }
  .brand-logo .brand-logo-inner {
    text-align: center; }
    .brand-logo .brand-logo-inner:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle; }
    .brand-logo .brand-logo-inner img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      display: inline-block;
      vertical-align: middle;
      width: auto !important; }
  .brand-logo:hover {
    -o-box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease-in-out 0.1s;
    background-color: #f2f2f2; }
  @media screen and (max-width: 575px) {
    .brand-logo {
      margin-bottom: 15px; } }

.owl-app-frame.owl-carousel .owl-item img {
  display: inline-block;
  width: auto; }

.carousel-item img {
  max-width: 100%; }

/* Modules
   ========================================================================== */
/* App Module Style */
.app-wrapper-module {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100%; }

.app-module {
  position: relative;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: -24px; }

.module-side {
  position: relative;
  z-index: 2;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 0 1 230px;
  max-width: 230px;
  min-width: 230px; }

.module-side-header {
  border-bottom: solid 1px #e9ecef;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }
  .module-side-header .user-detail {
    padding: 15px 10px;
    text-align: center; }

.module-logo {
  padding: 10px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 78px;
  font-size: 20px;
  background-color: #f9fafb;
  color: #2f353a; }
  .module-logo .zmdi {
    font-size: 28px; }

.module-user-info, .module-side-content {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }

.module-user-detail {
  font-size: 12px; }

.module-add-task {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px; }

.module-side-scroll {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 950px; }

.module-nav {
  list-style: none;
  padding-left: 0;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center; }
  .module-nav li {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; }
  .module-nav li a {
    padding: 12px 29px;
    color: #9ba5ae;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none; }
    .module-nav li a:hover, .module-nav li a:focus {
      color: #212529; }
    .module-nav li a.active {
      color: #673ab7; }
  .module-nav li i {
    margin-right: 16px;
    font-size: 16px; }
  .module-nav li span {
    display: inline-block;
    vertical-align: middle; }
  .module-nav li.module-nav-label {
    padding: 12px 29px; }

.module-box {
  position: relative;
  z-index: 2;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  max-width: calc(100% - 231px); }

.module-box-header {
  padding: 10px 26px;
  min-height: 79px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: #fff;
  border-bottom: solid 1px #e9ecef; }
  .module-box-header .search-bar.right-side-icon {
    min-width: 350px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      border-radius: 0;
      height: 46px;
      padding: 10px 18px 10px 40px; }
      .module-box-header .search-bar.right-side-icon .form-control:focus {
        background-color: #f8f9fa; }
    .module-box-header .search-bar.right-side-icon .search-icon {
      width: 25px;
      height: 46px;
      font-size: 16px;
      left: 10px;
      top: 0;
      padding: 0; }

.module-box-header-inner {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.module-box-content {
  background-color: #fff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }

.module-box-topbar {
  padding: 8px 12px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: solid 1px #e9ecef; }
  .module-box-topbar button + button {
    border-radius: 0; }

.module-box-topbar-todo {
  padding-left: 64px; }
  @media screen and (max-width: 575px) {
    .module-box-topbar-todo {
      padding-left: 27px; } }

.module-list-icon {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 10px; }

.bar-icon {
  margin-right: 15px; }

.toolbar-separator {
  border-left: solid 1px #ced4da;
  height: 100%;
  width: 1px;
  margin: 0 12px; }

.toolbar-left {
  margin-left: auto; }

.module-list {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center; }

.module-list-item {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 28px;
  border-bottom: solid 1px #e9ecef;
  z-index: 5;
  position: relative;
  cursor: pointer; }
  .module-list-item > .check-label {
    margin-left: 0;
    margin-bottom: 0; }
  .module-list-item.mail-cell {
    padding-left: 12px;
    align-items: start; }

.module-detail-header {
  margin-bottom: 10px; }
  .module-detail-header > .check-label {
    margin-right: auto; }

.module-list-info {
  flex: 1 1;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: calc(100% - 169px); }

.module-todo-content {
  position: relative; }
  .module-todo-content .subject {
    margin-bottom: 5px; }

.module-list-actions {
  text-align: right; }

.app-module-sidenav {
  background-color: #fff;
  border-right: solid 1px #e9ecef;
  position: relative;
  z-index: 9; }
  .app-module-sidenav .chat-sidenav-main {
    max-width: 300px;
    min-width: 300px;
    width: 90%; }

.module-date {
  max-width: 160px; }
  .module-date > div:before {
    display: none; }

.module-detail-item {
  padding: 10px 28px; }

.task-title {
  font-size: 18px; }

.chat-todo-avatar {
  margin-right: 15px; }

.modal-title {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 18px; }

.loader-view-block, .loader-view {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center; }
  .loader-view-block .slimScrollBar, .loader-view .slimScrollBar {
    display: none !important; }

.module-date > div {
  align-items: center; }

@media screen and (max-width: 1199px) {
  .module-side {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-block;
    display: -o-block;
    display: block;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 1 auto;
    max-width: 280px;
    min-width: 280px; }
  .module-box-header {
    position: relative;
    min-height: 58px;
    padding: 5px 26px 5px 75px; }
    .module-box-header .drawer-btn {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      border-right: solid 1px #e9ecef;
      height: 58px;
      width: 56px;
      border-radius: 0;
      font-size: 22px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      height: 38px;
      padding: 5px 18px 5px 40px; }
    .module-box-header .search-bar.right-side-icon .search-icon {
      height: 38px; }
  .chat-loader-view {
    height: calc(100vh - 120px); }
  .module-box {
    max-width: 100%; } }

@media screen and (min-width: 991px) {
  .modal-dialog {
    max-width: 750px; } }

@media screen and (max-width: 991px) {
  .app-module {
    padding-top: 0; } }

@media screen and (max-width: 599px) {
  .module-box-header .search-bar {
    margin-left: 0;
    max-width: none; }
    .module-box-header .search-bar.right-side-icon {
      min-width: 250px; } }

@media screen and (max-width: 575px) {
  .module-list-item {
    padding: 10px 12px; }
  .module-list-info {
    max-width: 100%; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu {
    right: auto !important;
    left: 0 !important; }
  .module-box-header .search-bar.right-side-icon {
    min-width: 200px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      border-radius: 0.25rem; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu:after {
    right: auto;
    left: 9px; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu:before {
    right: auto;
    left: 9px; }
  .module-box-header .search-bar {
    padding-right: 2px; } }

@media screen and (max-width: 399px) {
  .module-box-header .search-bar.right-side-icon {
    min-width: 100px; } }

@media screen and (min-width: 399px) {
  .d-xs-flex {
    display: flex !important; } }

/*Chat Module Styles*/
.chat-module {
  flex: 1 1 auto; }
  .chat-module:before {
    left: -29px; }

.chat-module-box {
  -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  background-color: #f8f9fa;
  position: relative;
  z-index: 2;
  width: 100%; }

.chat-sidenav {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 0 1 315px;
  min-width: 315px;
  border-right: solid 1px #e9ecef; }

.chat-box {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  max-width: 100%; }

.chat-sidenav-header {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 25px 20px 12px;
  background-color: #f9fafb; }

.chat-user-hd {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 25px; }

.chat-sidenav-header .search-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 0; }
  .chat-sidenav-header .search-wrapper .search-bar.right-side-icon {
    flex: 1 1 auto; }
    .chat-sidenav-header .search-wrapper .search-bar.right-side-icon .form-control {
      -o-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 0;
      padding-left: 42px;
      height: 42px; }
    .chat-sidenav-header .search-wrapper .search-bar.right-side-icon .search-icon {
      left: 10px;
      top: 0;
      height: 42px;
      padding: 0; }

.chat-avatar {
  min-width: 50px; }
  .chat-user-item .chat-avatar {
    padding: 0 5px;
    min-width: 10px; }
  .chat-user-hd .chat-avatar {
    cursor: pointer; }

.chat-avatar-mode {
  position: relative;
  max-width: 50px; }
  .chat-avatar-mode .chat-mode {
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    z-index: 1;
    width: 12px;
    height: 12px;
    border-radius: 50%; }
    .chat-avatar-mode .chat-mode.small {
      width: 8px;
      height: 8px; }
    .chat-avatar-mode .chat-mode.online {
      background-color: #4CAF50; }
    .chat-avatar-mode .chat-mode.offline {
      background-color: #fff;
      border: solid 1px #4CAF50; }
    .chat-avatar-mode .chat-mode.away {
      background-color: #fdd835; }
  .chat-main-header-info .chat-avatar-mode {
    max-width: 60px; }

.chat-sidenav-content {
  background-color: #fff; }

.chat-sidenav-title {
  padding: 10px 16px;
  font-size: 14px;
  color: #00bcd4;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center; }

.chat-tabs-header {
  background-color: #f9fafb !important; }

.chat-user {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }
  .chat-user.chat-user-center {
    justify-content: center;
    align-items: center; }
    .chat-user.chat-user-center .chat-avatar {
      margin-left: auto; }

.chat-user-item {
  border-bottom: solid 1px #e9ecef;
  padding: 16px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  cursor: pointer;
  max-height: 96px; }
  .chat-user-item.active, .chat-user-item:hover {
    background-color: #e8e1f5; }

.chat-user-row {
  margin: 0 -5px;
  align-items: center; }

.chat-info, .chat-contact-col {
  padding: 0 5px; }
  .chat-info p, .chat-contact-col p {
    margin-bottom: 0; }
  .chat-info .h4, .chat-contact-col .h4 {
    display: block;
    margin-bottom: 3px; }

.chat-info-des {
  color: #6c757d;
  font-size: 13px; }

.chat-date {
  padding: 0 5px;
  text-align: right; }

.chat-box-main {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%; }

.module-default {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 15px; }

.s-128 {
  font-size: 128px !important;
  width: 128px !important;
  height: 128px !important;
  line-height: 128px !important;
  margin-bottom: 12px; }

.chat-main {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }

.chat-main-header {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: solid 1px #e9ecef;
  padding: 16px;
  background-color: #fff;
  width: 100%; }

.chat-main-header-info {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: auto; }

.chat-contact-name {
  font-size: 20px;
  font-weight: 500; }

.chat-main-footer {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-top: solid 1px #e9ecef;
  padding: 16px;
  background-color: #fff;
  width: 100%; }
  .chat-main-footer .form-group {
    margin-bottom: 0; }

.todo-main-footer {
  padding: 6px 16px; }

.chat-item {
  padding: 16px; }
  .chat-item .bubble {
    padding: 8px 12px;
    background-color: #fff;
    -o-box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    border-radius: 20px 20px 20px 0px;
    position: relative;
    margin-left: 16px;
    max-width: 600px;
    /* &:before,
     &:after {
       content: "";
       position: absolute;
       z-index: 1;
       width: 0;
       height: 0;
     }

     &:before {
       border-top: 9px solid transparent;
       border-bottom: 9px solid transparent;

       border-right: 9px solid $gray-300;
       left: -9px;
       top: 5px;
     }

     &:after {
       border-top: 8px solid transparent;
       border-bottom: 8px solid transparent;

       border-right: 8px solid $white;
       left: -8px;
       top: 6px;
     }*/ }
  .chat-item.flex-row-reverse .bubble {
    margin-right: 16px;
    margin-left: 0;
    background-color: #e8e1f5;
    border-radius: 20px 20px 0 20px;
    /*&:before {
        left: auto;
        right: -9px;
        border-left: 9px solid $gray-300;
        border-right: 0 none;
      }

      &:after {
        left: auto;
        right: -8px;
        border-left: 8px solid lighten($app-primary, 45%);
        border-right: 0 none;
      }*/ }

.chat-btn {
  font-size: 38px !important;
  margin: 0 10px 0 -8px; }

.chat-textarea {
  height: 40px;
  -o-box-shadow: none;
  box-shadow: none;
  border-radius: 0; }

.last-message-time {
  font-size: 11px;
  color: #adb5bd; }

@media screen and (max-width: 1399px) {
  .s-128 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important; } }

@media screen and (max-width: 1199px) {
  .chat-module {
    margin: -24px -29px -23px;
    padding-top: 0; }
  .chat-main-header {
    padding: 8px 24px; }
    .chat-main-header .size-60 {
      height: 50px !important;
      width: 50px !important;
      line-height: 50px; }
    .chat-main-header button {
      margin-bottom: 0; }
  .chat-main-header-info .chat-avatar-mode {
    max-width: 50px; }
  .chat-contact-name,
  .chat-sidenav-title {
    font-size: 16px; }
  .chat-main-footer {
    padding: 8px 16px; }
  .s-128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    line-height: 128px !important; } }

@media screen and (max-width: 991px) {
  .s-128 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
    margin-bottom: 10px; } }

@media screen and (max-width: 767px) {
  .chat-sidenav-title,
  .chat-contact-name {
    font-size: 15px; }
  .s-128 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    line-height: 80px !important; } }

@media screen and (max-width: 575px) {
  .module-default h1 {
    font-size: 17px; }
  .s-128 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important; } }

/*Mails Module Styles*/
.module-box-row {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.module-box-column {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%; }

.mail-user-info {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }
  .mail-user-info .avatar {
    margin-right: 10px; }
  .mail-user-info .time,
  .mail-user-info button {
    margin-left: auto; }
  .mail-user-info .badge {
    margin-bottom: 0; }

.module-list-item.mail-cell {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.mail-detail .module-list-scroll {
  padding: 24px; }

.mail-header {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }

.mail-header-content {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }
  .mail-header-content .subject {
    margin-bottom: 5px; }

.show-detail {
  cursor: pointer; }

.text-truncate, .module-list-content .subject, .module-list-content .message p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.module-list-content .message {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: #919ca6;
  font-size: 13px; }
  .module-list-content .message p {
    margin-bottom: 8px; }

.size {
  margin: 5px 0; }

.sender-name {
  margin-right: 0;
  font-weight: 500;
  font-size: 15px; }

.mail-header-actions {
  text-align: right;
  min-width: 100px; }
  .mail-header-actions .btn:not(:last-child) {
    margin-right: 3px; }

@media screen and (max-width: 991px) {
  .module-list-item.mail-cell {
    padding-right: 20px; } }

@media screen and (max-width: 576px) {
  .mail-list {
    flex: 1 1 auto;
    max-width: 100%;
    border-right: 0 none; }
  .mail-detail {
    flex: 1 1 auto;
    max-width: 100%; }
    .mail-detail .module-list-scroll {
      padding: 24px; } }

@media screen and (max-width: 499px) {
  .mail-header {
    display: block;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap; }
  .mail-header-actions {
    text-align: left;
    min-width: 10px; }
  .MuiDialog-paperWidthSm-99 {
    max-width: 250px !important; } }

/*Contact Module Styles*/
.contact-item {
  align-items: center;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: solid 1px #e9ecef;
  padding: 8px 12px; }

.contact-name {
  font-size: 15px; }

.modal-box {
  position: relative; }

.modal-w {
  max-width: 400px;
  min-width: 350px; }
  .modal-box-mail .modal-w {
    min-width: 600px; }

.modal-box-header {
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.modal-box-header h2 {
  color: #fff;
  position: relative; }
  .modal-box-header h2 button {
    position: absolute;
    right: 0;
    top: -10px;
    z-index: 1;
    color: #fff; }

.modal-box-content {
  padding: 24px; }
  .modal-box-content .avatar {
    margin: 0 auto; }

.modal-box-footer {
  padding: 0 24px 24px; }
  .modal-box-footer .attach-file {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px; }

.btn-fixed {
  position: fixed !important;
  right: 40px;
  bottom: 60px;
  z-index: 100; }

.draggable-icon {
  cursor: all-scroll;
  color: #6c757d; }
  .draggable-icon:hover, .draggable-icon:focus, .draggable-icon:active {
    cursor: all-scroll;
    color: #212529; }

.module-side-nav {
  padding: 20px 0; }

@media screen and (max-width: 767px) {
  .modal-box-mail .modal-w {
    min-width: 500px; }
  .modal-box-header h2 {
    font-size: 18px; } }

@media screen and (max-width: 575px) {
  .modal-w {
    min-width: 300px; }
    .modal-box-mail .modal-w {
      min-width: 400px; }
  .modal-box-header h2 {
    font-size: 16px; } }

@media screen and (max-width: 419px) {
  .modal-w {
    min-width: 250px; }
    .modal-box-mail .modal-w {
      min-width: 250px; } }

@media screen and (max-width: 399px) {
  .con-inf-mw-100 {
    min-width: 100% !important; } }

/* Calendar Module Style */
.app-calendar .rbc-calendar {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  min-height: calc(100vh - 160px); }

.app-cul-calendar .rbc-calendar {
  min-height: calc(100vh - 200px); }

.rbc-event {
  background-color: #673ab7; }

.rbc-event.rbc-selected {
  background-color: #512e90; }

.rbc-slot-selection {
  background-color: rgba(103, 58, 183, 0.7); }

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: #fff;
  background-image: none;
  -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #fff;
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.rbc-toolbar button:focus {
  color: #fff;
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.rbc-toolbar button:hover {
  color: #fff;
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.rbc-month-row {
  min-height: 100px; }

.rbc-event {
  min-height: 50px; }

@media screen and (max-width: 867px) {
  .rbc-toolbar {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start; }
  .rbc-toolbar .rbc-toolbar-label {
    margin: 8px 0; } }

@media screen and (max-height: 600px) {
  .app-calendar .rbc-calendar {
    min-height: 400px; }
  .app-cul-calendar .rbc-calendar {
    min-height: 400px; } }

@media screen and (max-width: 467px) {
  .rbc-toolbar {
    font-size: 12px; }
  .rbc-toolbar button {
    padding: 5px 10px; } }

/* Color Theme
   ========================================================================== */
/*Theme Indigo Styles*/
.indigo .right-arrow {
  color: #3f51b5; }
  .indigo .right-arrow:after {
    color: #3f51b5; }

.indigo a,
.indigo a.text-primary {
  color: #3f51b5; }
  .indigo a:focus, .indigo a:hover,
  .indigo a.text-primary:focus,
  .indigo a.text-primary:hover {
    color: #32408f; }

.indigo .text-primary {
  color: #3f51b5 !important; }

.indigo .page-link {
  color: #3f51b5; }
  .indigo .page-link:focus, .indigo .page-link:hover {
    color: #3f51b5; }

.indigo .bg-primary,
.indigo .badge-primary {
  background-color: #3f51b5 !important;
  color: #fff !important; }

.indigo .btn-primary {
  background-color: #3f51b5;
  border-color: #3f51b5;
  color: #fff; }
  .indigo .btn-primary:hover, .indigo .btn-primary:focus, .indigo .btn-primary:active {
    background-color: #3849a2 !important;
    border-color: #3849a2 !important;
    color: #fff !important; }

.indigo .bg-primary.lighten-4 {
  background-color: #c5cae9 !important; }

.indigo .bg-primary.lighten-3 {
  background-color: #9fa8da !important; }

.indigo .bg-primary.lighten-2 {
  background-color: #7986cb !important; }

.indigo .bg-primary.lighten-1 {
  background-color: #5c6bc0 !important; }

.indigo .bg-primary.darken-1 {
  background-color: #3949ab !important; }

.indigo .bg-primary.darken-2 {
  background-color: #303f9f !important; }

.indigo .bg-primary.darken-3 {
  background-color: #283593 !important; }

.indigo .bg-primary.darken-4 {
  background-color: #1a237e !important; }

.indigo .bg-primary.accent-1 {
  background-color: #8c9eff !important; }

.indigo .bg-primary.accent-2 {
  background-color: #536dfe !important; }

.indigo .bg-primary.accent-3 {
  background-color: #3d5afe !important; }

.indigo .bg-primary.accent-4 {
  background-color: #304ffe !important; }

.indigo a.text-secondary {
  color: #FF4081; }
  .indigo a.text-secondary:focus, .indigo a.text-secondary:hover {
    color: #ff0d5f !important; }

.indigo .text-secondary {
  color: #FF4081 !important; }

.indigo .bg-secondary,
.indigo .badge-secondary {
  background-color: #FF4081 !important;
  color: #fff !important; }

.indigo .btn-secondary {
  background-color: #FF4081;
  border-color: #FF4081;
  color: #fff; }
  .indigo .btn-secondary:hover, .indigo .btn-secondary:focus, .indigo .btn-secondary:active {
    background-color: #ff2770 !important;
    border-color: #ff2770 !important;
    color: #fff !important; }

.indigo .app-main-header {
  background-color: #3f51b5 !important; }
  .indigo .app-main-header .search-bar input {
    background-color: #4d5ec1; }
    .indigo .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .indigo .app-main-header .search-dropdown input {
      background-color: #fff; } }

.indigo .color-theme-header {
  background-color: #3f51b5; }

.indigo .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .indigo .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .indigo .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .indigo .side-nav .user-detail .user-name {
    color: #3f51b5; }

.indigo ul.nav-menu li a {
  color: #868e96; }

.indigo ul.nav-menu .nav-header {
  color: #868e96; }

.indigo ul.nav-menu > li.open > a,
.indigo ul.nav-menu > li > a:hover,
.indigo ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #3f51b5; }

.indigo ul.nav-menu li.menu.open > a {
  border-color: #3f51b5; }

.indigo ul.nav-menu li.menu > a:focus:before,
.indigo ul.nav-menu li.menu > a:hover:before {
  color: #3f51b5; }

.indigo ul.nav-menu li ul {
  background-color: #fff; }

.indigo ul.nav-menu li.menu > a:before {
  color: #868e96; }

.indigo ul.nav-menu li.menu.open > a:before,
.indigo ul.nav-menu li.menu > a:focus:before,
.indigo ul.nav-menu li.menu li > a:hover:before {
  color: #3f51b5; }

.indigo ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.indigo ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #fff;
  color: #3f51b5; }

.indigo ul.nav-menu li.menu .sub-menu li > a:hover,
.indigo ul.nav-menu li.menu .sub-menu li > a:focus,
.indigo ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.indigo ul.nav-menu li.menu .sub-menu li > a:focus:before,
.indigo ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #3f51b5; }

.indigo .module-nav li a.active {
  border-color: #3f51b5; }

.indigo .rbc-event {
  background-color: #3f51b5; }

.indigo .rbc-event.rbc-selected {
  background-color: #32408f; }

.indigo .rbc-slot-selection {
  background-color: rgba(63, 81, 181, 0.7); }

.indigo .rbc-toolbar button:active,
.indigo .rbc-toolbar button.rbc-active {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-toolbar button:active:hover,
.indigo .rbc-toolbar button.rbc-active:hover,
.indigo .rbc-toolbar button:active:focus,
.indigo .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-toolbar button:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-toolbar button:hover {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .chat-sidenav-title {
  color: #3f51b5; }

.indigo .chat-user-item.active, .indigo .chat-user-item:hover {
  background-color: #e4e7f6; }

.indigo .contact-list i {
  color: #3f51b5; }

.indigo .Collapsible__trigger {
  background: #3f51b5; }

.indigo .categories-list li a:focus,
.indigo .categories-list li a:hover,
.indigo .categories-list li.active a {
  color: #3f51b5; }

.indigo .login-content .form-control:focus {
  border-color: #3f51b5; }

.indigo .filter-with-bg-color ul li a {
  border-color: #3f51b5; }
  .indigo .filter-with-bg-color ul li a:hover, .indigo .filter-with-bg-color ul li a:focus, .indigo .filter-with-bg-color ul li a.active {
    background-color: #3f51b5; }

.indigo .profile-intro .icon {
  color: #3f51b5; }

.indigo .social-link li.active a,
.indigo .social-link li a:hover,
.indigo .social-link li a:focus {
  color: #FF4081; }

.indigo .actions {
  color: #FF4081; }

.indigo .table-hover tbody tr:hover {
  background-color: rgba(63, 81, 181, 0.075); }

.indigo .border-primary {
  border-color: #3f51b5 !important; }

.indigo .app-logo-content {
  background-color: #3f51b5; }

.indigo .app-social-block .social-link button {
  border: solid 1px #3f51b5;
  color: #3f51b5; }
  .indigo .app-social-block .social-link button:hover, .indigo .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #3f51b5; }

/*Theme Cyan Styles*/
.cyan .right-arrow {
  color: #00bcd4; }
  .cyan .right-arrow:after {
    color: #00bcd4; }

.cyan a,
.cyan a.text-primary {
  color: #00bcd4; }
  .cyan a:focus, .cyan a:hover,
  .cyan a.text-primary:focus,
  .cyan a.text-primary:hover {
    color: #008fa1; }

.cyan .text-primary {
  color: #00bcd4 !important; }

.cyan .page-link {
  color: #00bcd4; }
  .cyan .page-link:focus, .cyan .page-link:hover {
    color: #00bcd4; }

.cyan .bg-primary,
.cyan .badge-primary {
  background-color: #00bcd4 !important;
  color: #fff !important; }

.cyan .btn-primary {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff !important; }
  .cyan .btn-primary:hover, .cyan .btn-primary:focus, .cyan .btn-primary:active {
    background-color: #00a5bb !important;
    border-color: #00a5bb !important;
    color: #fff !important; }

.cyan .bg-primary.lighten-4 {
  background-color: #b2ebf2 !important; }

.cyan .bg-primary.lighten-3 {
  background-color: #80deea !important; }

.cyan .bg-primary.lighten-2 {
  background-color: #4dd0e1 !important; }

.cyan .bg-primary.lighten-1 {
  background-color: #26c6da !important; }

.cyan .bg-primary.darken-1 {
  background-color: #00acc1 !important; }

.cyan .bg-primary.darken-2 {
  background-color: #0097a7 !important; }

.cyan .bg-primary.darken-3 {
  background-color: #00838f !important; }

.cyan .bg-primary.darken-4 {
  background-color: #006064 !important; }

.cyan .bg-primary.accent-1 {
  background-color: #84ffff !important; }

.cyan .bg-primary.accent-2 {
  background-color: #18ffff !important; }

.cyan .bg-primary.accent-3 {
  background-color: #00e5ff !important; }

.cyan .bg-primary.accent-4 {
  background-color: #00b8d4 !important; }

.cyan a.text-secondary {
  color: #69F0AE; }
  .cyan a.text-secondary:focus, .cyan a.text-secondary:hover {
    color: #3beb95 !important; }

.cyan .text-secondary {
  color: #69F0AE !important; }

.cyan .bg-secondary,
.cyan .badge-secondary {
  background-color: #69F0AE !important;
  color: #fff !important; }

.cyan .btn-secondary {
  background-color: #69F0AE;
  border-color: #69F0AE;
  color: #fff; }
  .cyan .btn-secondary:hover, .cyan .btn-secondary:focus, .cyan .btn-secondary:active {
    background-color: #52eea1 !important;
    border-color: #52eea1 !important;
    color: #fff !important; }

.cyan .app-main-header {
  background-color: #00bcd4 !important; }
  .cyan .app-main-header .search-bar input {
    background-color: #00d3ee; }
    .cyan .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .cyan .app-main-header .search-dropdown input {
      background-color: #fff; } }

.cyan .color-theme-header {
  background-color: #00bcd4; }

.cyan .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .cyan .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .cyan .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .cyan .side-nav .user-detail .user-name {
    color: #00bcd4; }

.cyan ul.nav-menu li a {
  color: #868e96; }

.cyan ul.nav-menu .nav-header {
  color: #868e96; }

.cyan ul.nav-menu > li.open > a,
.cyan ul.nav-menu > li > a:hover,
.cyan ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #00bcd4; }

.cyan ul.nav-menu li.menu.open > a {
  border-color: #00bcd4; }

.cyan ul.nav-menu li.menu > a:focus:before,
.cyan ul.nav-menu li.menu > a:hover:before {
  color: #00bcd4; }

.cyan ul.nav-menu li ul {
  background-color: #fff; }

.cyan ul.nav-menu li.menu > a:before {
  color: #868e96; }

.cyan ul.nav-menu li.menu.open > a:before,
.cyan ul.nav-menu li.menu > a:focus:before,
.cyan ul.nav-menu li.menu li > a:hover:before {
  color: #00bcd4; }

.cyan ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.cyan ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #fff;
  color: #00bcd4; }

.cyan ul.nav-menu li.menu .sub-menu li > a:hover,
.cyan ul.nav-menu li.menu .sub-menu li > a:focus,
.cyan ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.cyan ul.nav-menu li.menu .sub-menu li > a:focus:before,
.cyan ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #00bcd4; }

.cyan .module-nav li a.active {
  border-color: #00bcd4; }

.cyan .rbc-event {
  background-color: #00bcd4; }

.cyan .rbc-event.rbc-selected {
  background-color: #008fa1; }

.cyan .rbc-slot-selection {
  background-color: rgba(0, 188, 212, 0.7); }

.cyan .rbc-toolbar button:active,
.cyan .rbc-toolbar button.rbc-active {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-toolbar button:active:hover,
.cyan .rbc-toolbar button.rbc-active:hover,
.cyan .rbc-toolbar button:active:focus,
.cyan .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-toolbar button:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-toolbar button:hover {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .chat-sidenav-title {
  color: #00bcd4; }

.cyan .chat-user-item.active, .cyan .chat-user-item:hover {
  background-color: #bbf7ff; }

.cyan .contact-list i {
  color: #00bcd4; }

.cyan .Collapsible__trigger {
  background: #00bcd4; }

.cyan .categories-list li a:focus,
.cyan .categories-list li a:hover,
.cyan .categories-list li.active a {
  color: #00bcd4; }

.cyan .login-content .form-control:focus {
  border-color: #00bcd4; }

.cyan .filter-with-bg-color ul li a {
  border-color: #00bcd4; }
  .cyan .filter-with-bg-color ul li a:hover, .cyan .filter-with-bg-color ul li a:focus, .cyan .filter-with-bg-color ul li a.active {
    background-color: #00bcd4; }

.cyan .profile-intro .icon {
  color: #00bcd4; }

.cyan .social-link li.active a,
.cyan .social-link li a:hover,
.cyan .social-link li a:focus {
  color: #69F0AE; }

.cyan .actions {
  color: #69F0AE; }

.cyan .table-hover tbody tr:hover {
  background-color: rgba(0, 188, 212, 0.075); }

.cyan .border-primary {
  border-color: #00bcd4 !important; }

.cyan .app-logo-content {
  background-color: #00bcd4; }

.cyan .app-social-block .social-link button {
  border: solid 1px #00bcd4;
  color: #00bcd4; }
  .cyan .app-social-block .social-link button:hover, .cyan .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #00bcd4; }

/*Theme Amber Styles*/
.amber .side-nav {
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .amber .side-nav .user-profile {
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .amber .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }

.amber ul.nav-menu li.open > a {
  border-color: #FFC107; }

.amber .right-arrow {
  color: #FFC107; }
  .amber .right-arrow:after {
    color: #FFC107; }

.amber a,
.amber a.text-primary {
  color: #FFC107; }
  .amber a:focus, .amber a:hover,
  .amber a.text-primary:focus,
  .amber a.text-primary:hover {
    color: #d39e00; }

.amber .text-primary {
  color: #FFC107 !important; }

.amber .page-link {
  color: #FFC107; }
  .amber .page-link:focus, .amber .page-link:hover {
    color: #FFC107; }

.amber .bg-primary,
.amber .badge-primary {
  background-color: #FFC107 !important;
  color: #fff !important; }

.amber .btn-primary {
  background-color: #FFC107;
  border-color: #FFC107;
  color: #fff; }
  .amber .btn-primary:hover, .amber .btn-primary:focus, .amber .btn-primary:active {
    background-color: #edb100 !important;
    border-color: #edb100 !important;
    color: #fff !important; }

.amber .bg-primary.lighten-4 {
  background-color: #ffecb3 !important; }

.amber .bg-primary.lighten-3 {
  background-color: #ffe082 !important; }

.amber .bg-primary.lighten-2 {
  background-color: #ffd54f !important; }

.amber .bg-primary.lighten-1 {
  background-color: #ffca28 !important; }

.amber .bg-primary.darken-1 {
  background-color: #ffb300 !important; }

.amber .bg-primary.darken-2 {
  background-color: #ffa000 !important; }

.amber .bg-primary.darken-3 {
  background-color: #ff8f00 !important; }

.amber .bg-primary.darken-4 {
  background-color: #ff6f00 !important; }

.amber .bg-primary.accent-1 {
  background-color: #ffe57f !important; }

.amber .bg-primary.accent-2 {
  background-color: #ffd740 !important; }

.amber .bg-primary.accent-3 {
  background-color: #ffc400 !important; }

.amber .bg-primary.accent-4 {
  background-color: #ffab00 !important; }

.amber a.text-secondary {
  color: #FF3D00; }
  .amber a.text-secondary:focus, .amber a.text-secondary:hover {
    color: #cc3100 !important; }

.amber .text-secondary {
  color: #FF3D00 !important; }

.amber .bg-secondary,
.amber .badge-secondary {
  background-color: #FF3D00 !important;
  color: #fff !important; }

.amber .btn-secondary {
  background-color: #FF3D00;
  border-color: #FF3D00;
  color: #fff; }
  .amber .btn-secondary:hover, .amber .btn-secondary:focus, .amber .btn-secondary:active {
    background-color: #e63700;
    border-color: #e63700;
    color: #fff; }

.amber .app-main-header {
  background-color: #FFC107 !important; }
  .amber .app-main-header .search-bar input {
    background-color: #ffc721; }
    .amber .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .amber .app-main-header .search-dropdown input {
      background-color: #fff; } }

.amber .color-theme-header {
  background-color: #FFC107; }

.amber .side-nav {
  background-color: #fff !important;
  color: #868e96 !important; }
  .amber .side-nav .user-profile {
    background-color: #fff; }
  .amber .side-nav .user-detail .user-name {
    color: #FFC107; }

.amber ul.nav-menu li a {
  color: #868e96; }

.amber ul.nav-menu .nav-header {
  color: #868e96; }

.amber ul.nav-menu > li.open > a,
.amber ul.nav-menu > li > a:hover,
.amber ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #FFC107; }

.amber ul.nav-menu li.open > a {
  border-color: #FFC107; }

.amber ul.nav-menu li.menu > a:focus:before,
.amber ul.nav-menu li.menu > a:hover:before {
  color: #FFC107; }

.amber ul.nav-menu li ul {
  background-color: #fff; }

.amber ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.amber ul.nav-menu li.menu.open > a:before,
.amber ul.nav-menu li.menu > a:focus:before,
.amber ul.nav-menu li.menu li > a:hover:before {
  color: #FFC107; }

.amber ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.amber ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #fff;
  color: #FFC107; }

.amber ul.nav-menu li.menu .sub-menu li > a:hover,
.amber ul.nav-menu li.menu .sub-menu li > a:focus,
.amber ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.amber ul.nav-menu li.menu .sub-menu li > a:focus:before,
.amber ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #FFC107; }

.amber .module-nav li a.active {
  border-color: #FFC107; }

.amber .rbc-event {
  background-color: #FFC107; }

.amber .rbc-event.rbc-selected {
  background-color: #d39e00; }

.amber .rbc-slot-selection {
  background-color: rgba(255, 193, 7, 0.7); }

.amber .rbc-toolbar button:active,
.amber .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-toolbar button:active:hover,
.amber .rbc-toolbar button.rbc-active:hover,
.amber .rbc-toolbar button:active:focus,
.amber .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-toolbar button:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-toolbar button:hover {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .chat-sidenav-title {
  color: #FFC107; }

.amber .chat-user-item.active, .amber .chat-user-item:hover {
  background-color: #fffaed; }

.amber .contact-list i {
  color: #FFC107; }

.amber .Collapsible__trigger {
  background: #FFC107; }

.amber .categories-list li a:focus,
.amber .categories-list li a:hover,
.amber .categories-list li.active a {
  color: #FFC107; }

.amber .login-content .form-control:focus {
  border-color: #FFC107; }

.amber .filter-with-bg-color ul li a {
  border-color: #FFC107; }
  .amber .filter-with-bg-color ul li a:hover, .amber .filter-with-bg-color ul li a:focus, .amber .filter-with-bg-color ul li a.active {
    background-color: #FFC107; }

.amber .profile-intro .icon {
  color: #FFC107; }

.amber .social-link li.active a,
.amber .social-link li a:hover,
.amber .social-link li a:focus {
  color: #FF3D00; }

.amber .actions {
  color: #FF3D00; }

.amber .table-hover tbody tr:hover {
  background-color: rgba(255, 193, 7, 0.075); }

.amber .border-primary {
  border-color: #FFC107 !important; }

.amber .app-logo-content {
  background-color: #FFC107; }

.amber .app-social-block .social-link button {
  border: solid 1px #FFC107;
  color: #FFC107; }
  .amber .app-social-block .social-link button:hover, .amber .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #FFC107; }

/*Theme Deep Orange Styles*/
.deep-orange .right-arrow {
  color: #ff5722; }
  .deep-orange .right-arrow:after {
    color: #ff5722; }

.deep-orange a,
.deep-orange a.text-primary {
  color: #ff5722; }
  .deep-orange a:focus, .deep-orange a:hover,
  .deep-orange a.text-primary:focus,
  .deep-orange a.text-primary:hover {
    color: #ee3900; }

.deep-orange .text-primary {
  color: #ff5722 !important; }

.deep-orange .page-link {
  color: #ff5722; }
  .deep-orange .page-link:focus, .deep-orange .page-link:hover {
    color: #ff5722; }

.deep-orange .bg-primary,
.deep-orange .badge-primary {
  background-color: #ff5722 !important;
  color: #fff !important; }

.deep-orange .btn-primary {
  background-color: #ff5722;
  border-color: #ff5722;
  color: #fff; }
  .deep-orange .btn-primary:hover, .deep-orange .btn-primary:focus, .deep-orange .btn-primary:active {
    background-color: #ff4409 !important;
    border-color: #ff4409 !important;
    color: #fff !important; }

.deep-orange .bg-primary.lighten-4 {
  background-color: #ffccbc !important; }

.deep-orange .bg-primary.lighten-3 {
  background-color: #ffab91 !important; }

.deep-orange .bg-primary.lighten-2 {
  background-color: #ff8a65 !important; }

.deep-orange .bg-primary.lighten-1 {
  background-color: #ff7043 !important; }

.deep-orange .bg-primary.darken-1 {
  background-color: #f4511e !important; }

.deep-orange .bg-primary.darken-2 {
  background-color: #e64a19 !important; }

.deep-orange .bg-primary.darken-3 {
  background-color: #d84315 !important; }

.deep-orange .bg-primary.darken-4 {
  background-color: #bf360c !important; }

.deep-orange .bg-primary.accent-1 {
  background-color: #ff9e80 !important; }

.deep-orange .bg-primary.accent-2 {
  background-color: #ff6e40 !important; }

.deep-orange .bg-primary.accent-3 {
  background-color: #ff3d00 !important; }

.deep-orange .bg-primary.accent-4 {
  background-color: #dd2c00 !important; }

.deep-orange a.text-secondary {
  color: #40C4FF; }
  .deep-orange a.text-secondary:focus, .deep-orange a.text-secondary:hover {
    color: #0db4ff !important; }

.deep-orange .text-secondary {
  color: #40C4FF !important; }

.deep-orange .bg-secondary,
.deep-orange .badge-secondary {
  background-color: #40C4FF !important;
  color: #fff !important; }

.deep-orange .btn-secondary {
  background-color: #40C4FF;
  border-color: #40C4FF;
  color: #fff; }
  .deep-orange .btn-secondary:hover, .deep-orange .btn-secondary:focus, .deep-orange .btn-secondary:active {
    background-color: #27bcff !important;
    border-color: #27bcff !important;
    color: #fff !important; }

.deep-orange .app-main-header {
  background-color: #ff5722 !important; }
  .deep-orange .app-main-header .search-bar input {
    background-color: #ff6a3c; }
    .deep-orange .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .deep-orange .app-main-header .search-dropdown input {
      background-color: #fff; } }

.deep-orange .color-theme-header {
  background-color: #ff5722; }

.deep-orange .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .deep-orange .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .deep-orange .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .deep-orange .side-nav .user-detail .user-name {
    color: #ff5722; }

.deep-orange ul.nav-menu li a {
  color: #868e96; }

.deep-orange ul.nav-menu .nav-header {
  color: #868e96; }

.deep-orange ul.nav-menu > li.open > a,
.deep-orange ul.nav-menu > li > a:hover,
.deep-orange ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #ff5722; }

.deep-orange ul.nav-menu li.menu.open > a {
  border-color: #ff5722; }

.deep-orange ul.nav-menu li.menu > a:focus:before,
.deep-orange ul.nav-menu li.menu > a:hover:before {
  color: #ff5722; }

.deep-orange ul.nav-menu li ul {
  background-color: #fff; }

.deep-orange ul.nav-menu li.menu > a:before {
  color: #868e96; }

.deep-orange ul.nav-menu li.menu.open > a:before,
.deep-orange ul.nav-menu li.menu > a:focus:before,
.deep-orange ul.nav-menu li.menu li > a:hover:before {
  color: #ff5722; }

.deep-orange ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.deep-orange ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #fff;
  color: #ff5722; }

.deep-orange ul.nav-menu li.menu .sub-menu li > a:hover,
.deep-orange ul.nav-menu li.menu .sub-menu li > a:focus,
.deep-orange ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.deep-orange ul.nav-menu li.menu .sub-menu li > a:focus:before,
.deep-orange ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #ff5722; }

.deep-orange .module-nav li a.active {
  border-color: #ff5722; }

.deep-orange .rbc-event {
  background-color: #ff5722; }

.deep-orange .rbc-event.rbc-selected {
  background-color: #ee3900; }

.deep-orange .rbc-slot-selection {
  background-color: rgba(255, 87, 34, 0.7); }

.deep-orange .rbc-toolbar button:active,
.deep-orange .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-toolbar button:active:hover,
.deep-orange .rbc-toolbar button.rbc-active:hover,
.deep-orange .rbc-toolbar button:active:focus,
.deep-orange .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-toolbar button:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-toolbar button:hover {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .chat-sidenav-title {
  color: #ff5722; }

.deep-orange .chat-user-item.active, .deep-orange .chat-user-item:hover {
  background-color: white; }

.deep-orange .contact-list i {
  color: #ff5722; }

.deep-orange .Collapsible__trigger {
  background: #ff5722; }

.deep-orange .categories-list li a:focus,
.deep-orange .categories-list li a:hover,
.deep-orange .categories-list li.active a {
  color: #ff5722; }

.deep-orange .login-content .form-control:focus {
  border-color: #ff5722; }

.deep-orange .filter-with-bg-color ul li a {
  border-color: #ff5722; }
  .deep-orange .filter-with-bg-color ul li a:hover, .deep-orange .filter-with-bg-color ul li a:focus, .deep-orange .filter-with-bg-color ul li a.active {
    background-color: #ff5722; }

.deep-orange .profile-intro .icon {
  color: #ff5722; }

.deep-orange .social-link li.active a,
.deep-orange .social-link li a:hover,
.deep-orange .social-link li a:focus {
  color: #40C4FF; }

.deep-orange .actions {
  color: #40C4FF; }

.deep-orange .table-hover tbody tr:hover {
  background-color: rgba(255, 87, 34, 0.075); }

.deep-orange .border-primary {
  border-color: #ff5722 !important; }

.deep-orange .app-logo-content {
  background-color: #ff5722; }

.deep-orange .app-social-block .social-link button {
  border: solid 1px #ff5722;
  color: #ff5722; }
  .deep-orange .app-social-block .social-link button:hover, .deep-orange .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #ff5722; }

/*Theme Pink Styles*/
.pink .right-arrow {
  color: #e91e63; }
  .pink .right-arrow:after {
    color: #e91e63; }

.pink a,
.pink a.text-primary {
  color: #e91e63; }
  .pink a:focus, .pink a:hover,
  .pink a.text-primary:focus,
  .pink a.text-primary:hover {
    color: #c1134e; }

.pink .text-primary {
  color: #e91e63 !important; }

.pink .page-link {
  color: #e91e63; }
  .pink .page-link:focus, .pink .page-link:hover {
    color: #e91e63; }

.pink .bg-primary,
.pink .badge-primary {
  background-color: #e91e63 !important;
  color: #fff !important; }

.pink .btn-primary {
  background-color: #e91e63;
  border-color: #e91e63;
  color: #fff; }
  .pink .btn-primary:hover, .pink .btn-primary:focus, .pink .btn-primary:active {
    background-color: #d81558 !important;
    border-color: #d81558 !important;
    color: #fff !important; }

.pink .bg-primary.lighten-4 {
  background-color: #f8bbd0 !important; }

.pink .bg-primary.lighten-3 {
  background-color: #f48fb1 !important; }

.pink .bg-primary.lighten-2 {
  background-color: #f06292 !important; }

.pink .bg-primary.lighten-1 {
  background-color: #ec407a !important; }

.pink .bg-primary.darken-1 {
  background-color: #d81b60 !important; }

.pink .bg-primary.darken-2 {
  background-color: #c2185b !important; }

.pink .bg-primary.darken-3 {
  background-color: #ad1457 !important; }

.pink .bg-primary.darken-4 {
  background-color: #880e4f !important; }

.pink .bg-primary.accent-1 {
  background-color: #ff80ab !important; }

.pink .bg-primary.accent-2 {
  background-color: #ff4081 !important; }

.pink .bg-primary.accent-3 {
  background-color: #f50057 !important; }

.pink .bg-primary.accent-4 {
  background-color: #c51162 !important; }

.deep-orange a.text-secondary {
  color: #00BFA5; }
  .deep-orange a.text-secondary:focus, .deep-orange a.text-secondary:hover {
    color: #008c79 !important; }

.deep-orange .text-secondary {
  color: #00BFA5 !important; }

.deep-orange .bg-secondary,
.deep-orange .badge-secondary {
  background-color: #00BFA5 !important;
  color: #fff !important; }

.deep-orange .btn-secondary {
  background-color: #00BFA5;
  border-color: #00BFA5;
  color: #fff; }
  .deep-orange .btn-secondary:hover, .deep-orange .btn-secondary:focus, .deep-orange .btn-secondary:active {
    background-color: #00a68f !important;
    border-color: #00a68f !important;
    color: #fff !important; }

.pink .app-main-header {
  background-color: #e91e63 !important; }
  .pink .app-main-header .search-bar input {
    background-color: #eb3573; }
    .pink .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .pink .app-main-header .search-dropdown input {
      background-color: #fff; } }

.pink .color-theme-header {
  background-color: #e91e63; }

.pink .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .pink .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .pink .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .pink .side-nav .user-detail .user-name {
    color: #e91e63; }

.pink ul.nav-menu li a {
  color: #868e96; }

.pink ul.nav-menu .nav-header {
  color: #868e96; }

.pink ul.nav-menu > li.open > a,
.pink ul.nav-menu > li > a:hover,
.pink ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #e91e63; }

.pink ul.nav-menu li.menu.open > a {
  border-color: #e91e63; }

.pink ul.nav-menu li.menu > a:focus:before,
.pink ul.nav-menu li.menu > a:hover:before {
  color: #e91e63; }

.pink ul.nav-menu li ul {
  background-color: #fff; }

.pink ul.nav-menu li.menu > a:before {
  color: #868e96; }

.pink ul.nav-menu li.menu.open > a:before,
.pink ul.nav-menu li.menu > a:focus:before,
.pink ul.nav-menu li.menu li > a:hover:before {
  color: #e91e63; }

.pink ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.pink ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #fff;
  color: #e91e63; }

.pink ul.nav-menu li.menu .sub-menu li > a:hover,
.pink ul.nav-menu li.menu .sub-menu li > a:focus,
.pink ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.pink ul.nav-menu li.menu .sub-menu li > a:focus:before,
.pink ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #e91e63; }

.pink .module-nav li a.active {
  border-color: #e91e63; }

.pink .rbc-event {
  background-color: #e91e63; }

.pink .rbc-event.rbc-selected {
  background-color: #c1134e; }

.pink .rbc-slot-selection {
  background-color: rgba(233, 30, 99, 0.7); }

.pink .rbc-toolbar button:active,
.pink .rbc-toolbar button.rbc-active {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.pink .rbc-toolbar button:active:hover,
.pink .rbc-toolbar button.rbc-active:hover,
.pink .rbc-toolbar button:active:focus,
.pink .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.pink .rbc-toolbar button:focus {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.pink .rbc-toolbar button:hover {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.pink .chat-sidenav-title {
  color: #e91e63; }

.pink .chat-user-item.active, .pink .chat-user-item:hover {
  background-color: #fdeff4; }

.pink .contact-list i {
  color: #e91e63; }

.pink .Collapsible__trigger {
  background: #e91e63; }

.pink .categories-list li a:focus,
.pink .categories-list li a:hover,
.pink .categories-list li.active a {
  color: #e91e63; }

.pink .login-content .form-control:focus {
  border-color: #e91e63; }

.pink .filter-with-bg-color ul li a {
  border-color: #e91e63; }
  .pink .filter-with-bg-color ul li a:hover, .pink .filter-with-bg-color ul li a:focus, .pink .filter-with-bg-color ul li a.active {
    background-color: #e91e63; }

.pink .profile-intro .icon {
  color: #e91e63; }

.pink .social-link li.active a,
.pink .social-link li a:hover,
.pink .social-link li a:focus {
  color: #00BFA5; }

.pink .actions {
  color: #00BFA5; }

.pink .table-hover tbody tr:hover {
  background-color: rgba(233, 30, 99, 0.075); }

.pink .border-primary {
  border-color: #e91e63 !important; }

.pink .app-logo-content {
  background-color: #e91e63; }

.pink .app-social-block .social-link button {
  border: solid 1px #e91e63;
  color: #e91e63; }
  .pink .app-social-block .social-link button:hover, .pink .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #e91e63; }

/*Theme Blue Styles*/
.blue .right-arrow {
  color: #2196F3; }
  .blue .right-arrow:after {
    color: #2196F3; }

.blue a,
.blue a.text-primary {
  color: #2196F3; }
  .blue a:focus, .blue a:hover,
  .blue a.text-primary:focus,
  .blue a.text-primary:hover {
    color: #0c7cd5; }

.blue .text-primary {
  color: #2196F3 !important; }

.blue .page-link {
  color: #2196F3; }
  .blue .page-link:focus, .blue .page-link:hover {
    color: #2196F3; }

.blue .bg-primary,
.blue .badge-primary {
  background-color: #2196F3 !important;
  color: #fff !important; }

.blue .btn-primary {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #fff; }
  .blue .btn-primary:hover, .blue .btn-primary:focus, .blue .btn-primary:active {
    background-color: #0d8aee !important;
    border-color: #0d8aee !important;
    color: #fff !important; }

.blue .bg-primary.lighten-4 {
  background-color: #BBDEFB !important; }

.blue .bg-primary.lighten-3 {
  background-color: #90CAF9 !important; }

.blue .bg-primary.lighten-2 {
  background-color: #64B5F6 !important; }

.blue .bg-primary.lighten-1 {
  background-color: #42A5F5 !important; }

.blue .bg-primary.darken-1 {
  background-color: #1E88E5 !important; }

.blue .bg-primary.darken-2 {
  background-color: #1976D2 !important; }

.blue .bg-primary.darken-3 {
  background-color: #1565C0 !important; }

.blue .bg-primary.darken-4 {
  background-color: #0D47A1 !important; }

.blue .bg-primary.accent-1 {
  background-color: #82B1FF !important; }

.blue .bg-primary.accent-2 {
  background-color: #448AFF !important; }

.blue .bg-primary.accent-3 {
  background-color: #2979FF !important; }

.blue .bg-primary.accent-4 {
  background-color: #2962FF !important; }

.blue a.text-secondary {
  color: #FF6E40; }
  .blue a.text-secondary:focus, .blue a.text-secondary:hover {
    color: #ff470d !important; }

.blue .text-secondary {
  color: #FF6E40 !important; }

.blue .bg-secondary,
.blue .badge-secondary {
  background-color: #FF6E40 !important;
  color: #fff !important; }

.blue .btn-secondary {
  background-color: #FF6E40;
  border-color: #FF6E40;
  color: #fff; }
  .blue .btn-secondary:hover, .blue .btn-secondary:focus, .blue .btn-secondary:active {
    background-color: #ff5b27 !important;
    border-color: #ff5b27 !important;
    color: #fff !important; }

.blue .app-main-header {
  background-color: #2196F3 !important; }
  .blue .app-main-header .search-bar input {
    background-color: #39a1f4; }
    .blue .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .blue .app-main-header .search-dropdown input {
      background-color: #fff; } }

.blue .color-theme-header {
  background-color: #2196F3; }

.blue .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .blue .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .blue .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .blue .side-nav .user-detail .user-name {
    color: #2196F3; }

.blue ul.nav-menu li a {
  color: #868e96; }

.blue ul.nav-menu .nav-header {
  color: #868e96; }

.blue ul.nav-menu > li.open > a,
.blue ul.nav-menu > li > a:hover,
.blue ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #2196F3; }

.blue ul.nav-menu li.menu.open > a {
  border-color: #2196F3; }

.blue ul.nav-menu li.menu > a:focus:before,
.blue ul.nav-menu li.menu > a:hover:before {
  color: #2196F3; }

.blue ul.nav-menu li ul {
  background-color: #fff; }

.blue ul.nav-menu li.menu > a:before {
  color: #868e96; }

.blue ul.nav-menu li.menu.open > a:before,
.blue ul.nav-menu li.menu > a:focus:before,
.blue ul.nav-menu li.menu li > a:hover:before {
  color: #2196F3; }

.blue ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.blue ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #fff;
  color: #2196F3; }

.blue ul.nav-menu li.menu .sub-menu li > a:hover,
.blue ul.nav-menu li.menu .sub-menu li > a:focus,
.blue ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.blue ul.nav-menu li.menu .sub-menu li > a:focus:before,
.blue ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #2196F3; }

.blue .module-nav li a.active {
  border-color: #2196F3; }

.blue .rbc-event {
  background-color: #2196F3; }

.blue .rbc-event.rbc-selected {
  background-color: #0c7cd5; }

.blue .rbc-slot-selection {
  background-color: rgba(33, 150, 243, 0.7); }

.blue .rbc-toolbar button:active,
.blue .rbc-toolbar button.rbc-active {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-toolbar button:active:hover,
.blue .rbc-toolbar button.rbc-active:hover,
.blue .rbc-toolbar button:active:focus,
.blue .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-toolbar button:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-toolbar button:hover {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .chat-sidenav-title {
  color: #2196F3; }

.blue .chat-user-item.active, .blue .chat-user-item:hover {
  background-color: #fbfdff; }

.blue .contact-list i {
  color: #2196F3; }

.blue .Collapsible__trigger {
  background: #2196F3; }

.blue .categories-list li a:focus,
.blue .categories-list li a:hover,
.blue .categories-list li.active a {
  color: #2196F3; }

.blue .login-content .form-control:focus {
  border-color: #2196F3; }

.blue .filter-with-bg-color ul li a {
  border-color: #2196F3; }
  .blue .filter-with-bg-color ul li a:hover, .blue .filter-with-bg-color ul li a:focus, .blue .filter-with-bg-color ul li a.active {
    background-color: #2196F3; }

.blue .profile-intro .icon {
  color: #2196F3; }

.blue .social-link li.active a,
.blue .social-link li a:hover,
.blue .social-link li a:focus {
  color: #FF6E40; }

.blue .actions {
  color: #FF6E40; }

.blue .table-hover tbody tr:hover {
  background-color: rgba(33, 150, 243, 0.075); }

.blue .border-primary {
  border-color: #2196F3 !important; }

.blue .app-logo-content {
  background-color: #2196F3; }

.blue .app-social-block .social-link button {
  border: solid 1px #2196F3;
  color: #2196F3; }
  .blue .app-social-block .social-link button:hover, .blue .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #2196F3; }

/*Theme Deep Purple Styles*/
.deep-purple .right-arrow {
  color: #673ab7; }
  .deep-purple .right-arrow:after {
    color: #673ab7; }

.deep-purple a,
.deep-purple a.text-primary {
  color: #673ab7; }
  .deep-purple a:focus, .deep-purple a:hover,
  .deep-purple a.text-primary:focus,
  .deep-purple a.text-primary:hover {
    color: #512e90; }

.deep-purple .text-primary {
  color: #673ab7 !important; }

.deep-purple .page-link {
  color: #673ab7; }
  .deep-purple .page-link:focus, .deep-purple .page-link:hover {
    color: #673ab7; }

.deep-purple .bg-primary,
.deep-purple .badge-primary {
  background-color: #673ab7 !important;
  color: #fff !important; }

.deep-purple .btn-primary {
  background-color: #673ab7;
  border-color: #673ab7;
  color: #fff; }
  .deep-purple .btn-primary:hover, .deep-purple .btn-primary:focus, .deep-purple .btn-primary:active {
    background-color: #5c34a4 !important;
    border-color: #5c34a4 !important;
    color: #fff !important; }

.deep-purple .bg-primary.lighten-4 {
  background-color: #d1c4e9 !important; }

.deep-purple .bg-primary.lighten-3 {
  background-color: #b39ddb !important; }

.deep-purple .bg-primary.lighten-2 {
  background-color: #9575cd !important; }

.deep-purple .bg-primary.lighten-1 {
  background-color: #7e57c2 !important; }

.deep-purple .bg-primary.darken-1 {
  background-color: #5e35b1 !important; }

.deep-purple .bg-primary.darken-2 {
  background-color: #512da8 !important; }

.deep-purple .bg-primary.darken-3 {
  background-color: #4527a0 !important; }

.deep-purple .bg-primary.darken-4 {
  background-color: #311b92 !important; }

.deep-purple .bg-primary.accent-1 {
  background-color: #b388ff !important; }

.deep-purple .bg-primary.accent-2 {
  background-color: #7c4dff !important; }

.deep-purple .bg-primary.accent-3 {
  background-color: #651fff !important; }

.deep-purple .bg-primary.accent-4 {
  background-color: #6200ea !important; }

.deep-purple a.text-secondary {
  color: #FFAB00; }
  .deep-purple a.text-secondary:focus, .deep-purple a.text-secondary:hover {
    color: #cc8900 !important; }

.deep-purple .text-secondary {
  color: #FFAB00 !important; }

.deep-purple .bg-secondary,
.deep-purple .badge-secondary {
  background-color: #FFAB00 !important;
  color: #fff !important; }

.deep-purple .btn-secondary {
  background-color: #FFAB00;
  border-color: #FFAB00;
  color: #fff; }
  .deep-purple .btn-secondary:hover, .deep-purple .btn-secondary:focus, .deep-purple .btn-secondary:active {
    background-color: #e69a00 !important;
    border-color: #e69a00 !important;
    color: #fff !important; }

.deep-purple .app-main-header {
  background-color: #673ab7 !important; }
  .deep-purple .app-main-header .search-bar input {
    background-color: #7446c4; }
    .deep-purple .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .deep-purple .app-main-header .search-dropdown input {
      background-color: #fff; } }

.deep-purple .color-theme-header {
  background-color: #673ab7; }

.deep-purple .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .deep-purple .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .deep-purple .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .deep-purple .side-nav .user-detail .user-name {
    color: #673ab7; }

.deep-purple ul.nav-menu li a {
  color: #868e96; }

.deep-purple ul.nav-menu .nav-header {
  color: #868e96; }

.deep-purple ul.nav-menu > li.open > a,
.deep-purple ul.nav-menu > li > a:hover,
.deep-purple ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #673ab7; }

.deep-purple ul.nav-menu li.menu.open > a {
  border-color: #673ab7; }

.deep-purple ul.nav-menu li.menu > a:focus:before,
.deep-purple ul.nav-menu li.menu > a:hover:before {
  color: #673ab7; }

.deep-purple ul.nav-menu li ul {
  background-color: #fff; }

.deep-purple ul.nav-menu li.menu > a:before {
  color: #868e96; }

.deep-purple ul.nav-menu li.menu.open > a:before,
.deep-purple ul.nav-menu li.menu > a:focus:before,
.deep-purple ul.nav-menu li.menu li > a:hover:before {
  color: #673ab7; }

.deep-purple ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.deep-purple ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #fff;
  color: #673ab7; }

.deep-purple ul.nav-menu li.menu .sub-menu li > a:hover,
.deep-purple ul.nav-menu li.menu .sub-menu li > a:focus,
.deep-purple ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.deep-purple ul.nav-menu li.menu .sub-menu li > a:focus:before,
.deep-purple ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #673ab7; }

.deep-purple .module-nav li a.active {
  border-color: #673ab7; }

.deep-purple .rbc-event {
  background-color: #673ab7; }

.deep-purple .rbc-event.rbc-selected {
  background-color: #512e90; }

.deep-purple .rbc-slot-selection {
  background-color: rgba(103, 58, 183, 0.7); }

.deep-purple .rbc-toolbar button:active,
.deep-purple .rbc-toolbar button.rbc-active {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-toolbar button:active:hover,
.deep-purple .rbc-toolbar button.rbc-active:hover,
.deep-purple .rbc-toolbar button:active:focus,
.deep-purple .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-toolbar button:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-toolbar button:hover {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .chat-sidenav-title {
  color: #673ab7; }

.deep-purple .chat-user-item.active, .deep-purple .chat-user-item:hover {
  background-color: #e8e1f5; }

.deep-purple .contact-list i {
  color: #673ab7; }

.deep-purple .Collapsible__trigger {
  background: #673ab7; }

.deep-purple .categories-list li a:focus,
.deep-purple .categories-list li a:hover,
.deep-purple .categories-list li.active a {
  color: #673ab7; }

.deep-purple .login-content .form-control:focus {
  border-color: #673ab7; }

.deep-purple .filter-with-bg-color ul li a {
  border-color: #673ab7; }
  .deep-purple .filter-with-bg-color ul li a:hover, .deep-purple .filter-with-bg-color ul li a:focus, .deep-purple .filter-with-bg-color ul li a.active {
    background-color: #673ab7; }

.deep-purple .profile-intro .icon {
  color: #673ab7; }

.deep-purple .social-link li.active a,
.deep-purple .social-link li a:hover,
.deep-purple .social-link li a:focus {
  color: #FFAB00; }

.deep-purple .actions {
  color: #FFAB00; }

.deep-purple .table-hover tbody tr:hover {
  background-color: rgba(103, 58, 183, 0.075); }

.deep-purple .border-primary {
  border-color: #673ab7 !important; }

.deep-purple .app-logo-content {
  background-color: #673ab7; }

.deep-purple .app-social-block .social-link button {
  border: solid 1px #673ab7;
  color: #673ab7; }
  .deep-purple .app-social-block .social-link button:hover, .deep-purple .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #673ab7; }

/*Theme Green Styles*/
.green .right-arrow {
  color: #4CAF50; }
  .green .right-arrow:after {
    color: #4CAF50; }

.green a,
.green a.text-primary {
  color: #4CAF50; }
  .green a:focus, .green a:hover,
  .green a.text-primary:focus,
  .green a.text-primary:hover {
    color: #3d8b40; }

.green .text-primary {
  color: #4CAF50 !important; }

.green .page-link {
  color: #4CAF50; }
  .green .page-link:focus, .green .page-link:hover {
    color: #4CAF50; }

.green .bg-primary,
.green .badge-primary {
  background-color: #4CAF50 !important;
  color: #fff !important; }

.green .btn-primary {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }
  .green .btn-primary:hover, .green .btn-primary:focus, .green .btn-primary:active {
    background-color: #449d48 !important;
    border-color: #449d48 !important;
    color: #fff !important; }

.green .bg-primary.lighten-4 {
  background-color: #C8E6C9 !important; }

.green .bg-primary.lighten-3 {
  background-color: #A5D6A7 !important; }

.green .bg-primary.lighten-2 {
  background-color: #81C784 !important; }

.green .bg-primary.lighten-1 {
  background-color: #66BB6A !important; }

.green .bg-primary.darken-1 {
  background-color: #43A047 !important; }

.green .bg-primary.darken-2 {
  background-color: #388E3C !important; }

.green .bg-primary.darken-3 {
  background-color: #2E7D32 !important; }

.green .bg-primary.darken-4 {
  background-color: #1B5E20 !important; }

.green .bg-primary.accent-1 {
  background-color: #B9F6CA !important; }

.green .bg-primary.accent-2 {
  background-color: #69F0AE !important; }

.green .bg-primary.accent-3 {
  background-color: #00E676 !important; }

.green .bg-primary.accent-4 {
  background-color: #00C853 !important; }

.green a.text-secondary {
  color: #FF6D00; }
  .green a.text-secondary:focus, .green a.text-secondary:hover {
    color: #cc5700 !important; }

.green .text-secondary {
  color: #FF6D00 !important; }

.green .bg-secondary,
.green .badge-secondary {
  background-color: #FF6D00 !important;
  color: #fff !important; }

.green .btn-secondary {
  background-color: #FF6D00;
  border-color: #FF6D00;
  color: #fff; }
  .green .btn-secondary:hover, .green .btn-secondary:focus, .green .btn-secondary:active {
    background-color: #e66200 !important;
    border-color: #e66200 !important;
    color: #fff !important; }

.green .app-main-header {
  background-color: #4CAF50 !important; }
  .green .app-main-header .search-bar input {
    background-color: #5cb860; }
    .green .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .green .app-main-header .search-dropdown input {
      background-color: #fff; } }

.green .color-theme-header {
  background-color: #4CAF50; }

.green .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .green .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .green .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .green .side-nav .user-detail .user-name {
    color: #4CAF50; }

.green ul.nav-menu li a {
  color: #868e96; }

.green ul.nav-menu .nav-header {
  color: #868e96; }

.green ul.nav-menu > li.open > a,
.green ul.nav-menu > li > a:hover,
.green ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #4CAF50; }

.green ul.nav-menu li.menu.open > a {
  border-color: #4CAF50; }

.green ul.nav-menu li.menu > a:focus:before,
.green ul.nav-menu li.menu > a:hover:before {
  color: #4CAF50; }

.green ul.nav-menu li ul {
  background-color: #fff; }

.green ul.nav-menu li.menu > a:before {
  color: #868e96; }

.green ul.nav-menu li.menu.open > a:before,
.green ul.nav-menu li.menu > a:focus:before,
.green ul.nav-menu li.menu li > a:hover:before {
  color: #4CAF50; }

.green ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.green ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #fff;
  color: #4CAF50; }

.green ul.nav-menu li.menu .sub-menu li > a:hover,
.green ul.nav-menu li.menu .sub-menu li > a:focus,
.green ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.green ul.nav-menu li.menu .sub-menu li > a:focus:before,
.green ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #4CAF50; }

.green .module-nav li a.active {
  border-color: #4CAF50; }

.green .rbc-event {
  background-color: #4CAF50; }

.green .rbc-event.rbc-selected {
  background-color: #3d8b40; }

.green .rbc-slot-selection {
  background-color: rgba(76, 175, 80, 0.7); }

.green .rbc-toolbar button:active,
.green .rbc-toolbar button.rbc-active {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-toolbar button:active:hover,
.green .rbc-toolbar button.rbc-active:hover,
.green .rbc-toolbar button:active:focus,
.green .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-toolbar button:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-toolbar button:hover {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .chat-sidenav-title {
  color: #4CAF50; }

.green .chat-user-item.active, .green .chat-user-item:hover {
  background-color: #eaf6eb; }

.green .contact-list i {
  color: #4CAF50; }

.green .Collapsible__trigger {
  background: #4CAF50; }

.green .categories-list li a:focus,
.green .categories-list li a:hover,
.green .categories-list li.active a {
  color: #4CAF50; }

.green .login-content .form-control:focus {
  border-color: #4CAF50; }

.green .filter-with-bg-color ul li a {
  border-color: #4CAF50; }
  .green .filter-with-bg-color ul li a:hover, .green .filter-with-bg-color ul li a:focus, .green .filter-with-bg-color ul li a.active {
    background-color: #4CAF50; }

.green .profile-intro .icon {
  color: #4CAF50; }

.green .social-link li.active a,
.green .social-link li a:hover,
.green .social-link li a:focus {
  color: #FF6D00; }

.green .actions {
  color: #FF6D00; }

.green .table-hover tbody tr:hover {
  background-color: rgba(76, 175, 80, 0.075); }

.green .border-primary {
  border-color: #4CAF50 !important; }

.green .app-logo-content {
  background-color: #4CAF50; }

.green .app-social-block .social-link button {
  border: solid 1px #4CAF50;
  color: #4CAF50; }
  .green .app-social-block .social-link button:hover, .green .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #4CAF50; }

/*Theme Dark Indigo Styles*/
.dark-indigo .right-arrow {
  color: #3f51b5; }
  .dark-indigo .right-arrow:after {
    color: #3f51b5; }

.dark-indigo a,
.dark-indigo a.text-primary {
  color: #3f51b5; }
  .dark-indigo a:focus, .dark-indigo a:hover,
  .dark-indigo a.text-primary:focus,
  .dark-indigo a.text-primary:hover {
    color: #32408f; }

.dark-indigo .text-primary {
  color: #3f51b5 !important; }

.dark-indigo .page-link {
  color: #3f51b5; }
  .dark-indigo .page-link:focus, .dark-indigo .page-link:hover {
    color: #3f51b5; }

.dark-indigo .bg-primary,
.dark-indigo .badge-primary {
  background-color: #3f51b5 !important;
  color: #fff !important; }

.dark-indigo .btn-primary {
  background-color: #3f51b5;
  border-color: #3f51b5;
  color: #fff; }
  .dark-indigo .btn-primary:hover, .dark-indigo .btn-primary:focus, .dark-indigo .btn-primary:active {
    background-color: #3849a2 !important;
    border-color: #3849a2 !important;
    color: #fff !important; }

.dark-indigo .bg-primary.lighten-4 {
  background-color: #c5cae9 !important; }

.dark-indigo .bg-primary.lighten-3 {
  background-color: #9fa8da !important; }

.dark-indigo .bg-primary.lighten-2 {
  background-color: #7986cb !important; }

.dark-indigo .bg-primary.lighten-1 {
  background-color: #5c6bc0 !important; }

.dark-indigo .bg-primary.darken-1 {
  background-color: #3949ab !important; }

.dark-indigo .bg-primary.darken-2 {
  background-color: #303f9f !important; }

.dark-indigo .bg-primary.darken-3 {
  background-color: #283593 !important; }

.dark-indigo .bg-primary.darken-4 {
  background-color: #1a237e !important; }

.dark-indigo .bg-primary.accent-1 {
  background-color: #8c9eff !important; }

.dark-indigo .bg-primary.accent-2 {
  background-color: #536dfe !important; }

.dark-indigo .bg-primary.accent-3 {
  background-color: #3d5afe !important; }

.dark-indigo .bg-primary.accent-4 {
  background-color: #304ffe !important; }

.dark-indigo a.text-secondary {
  color: #FF4081; }
  .dark-indigo a.text-secondary:focus, .dark-indigo a.text-secondary:hover {
    color: #ff0d5f !important; }

.dark-indigo .text-secondary {
  color: #FF4081 !important; }

.dark-indigo .bg-secondary,
.dark-indigo .badge-secondary {
  background-color: #FF4081 !important;
  color: #fff !important; }

.dark-indigo .btn-secondary {
  background-color: #FF4081;
  border-color: #FF4081;
  color: #fff; }
  .dark-indigo .btn-secondary:hover, .dark-indigo .btn-secondary:focus, .dark-indigo .btn-secondary:active {
    background-color: #ff2770 !important;
    border-color: #ff2770 !important;
    color: #fff !important; }

.dark-indigo .app-main-header {
  background-color: #3f51b5 !important; }
  .dark-indigo .app-main-header .search-bar input {
    background-color: #4d5ec1; }
    .dark-indigo .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-indigo .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-indigo .color-theme-header {
  background-color: #3f51b5; }

.dark-indigo .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-indigo .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-indigo .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-indigo .side-nav .user-detail .user-name {
    color: #fff; }

.dark-indigo ul.nav-menu li a {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu > li.open > a,
.dark-indigo ul.nav-menu > li > a:hover,
.dark-indigo ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-indigo ul.nav-menu li.menu.open > a {
  border-color: #ff9800; }

.dark-indigo ul.nav-menu li.menu > a:focus:before,
.dark-indigo ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-indigo ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-indigo ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu li.menu.open > a:before,
.dark-indigo ul.nav-menu li.menu > a:focus:before,
.dark-indigo ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-indigo ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff; }

.dark-indigo ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-indigo ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-indigo ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.dark-indigo ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-indigo ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.dark-indigo .module-nav li a.active {
  color: #3f51b5; }

.dark-indigo .rbc-event {
  background-color: #3f51b5; }

.dark-indigo .rbc-event.rbc-selected {
  background-color: #32408f; }

.dark-indigo .rbc-slot-selection {
  background-color: rgba(63, 81, 181, 0.7); }

.dark-indigo .rbc-toolbar button:active,
.dark-indigo .rbc-toolbar button.rbc-active {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-toolbar button:active:hover,
.dark-indigo .rbc-toolbar button.rbc-active:hover,
.dark-indigo .rbc-toolbar button:active:focus,
.dark-indigo .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-toolbar button:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-toolbar button:hover {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .chat-sidenav-title {
  color: #3f51b5; }

.dark-indigo .chat-user-item.active, .dark-indigo .chat-user-item:hover {
  background-color: #e4e7f6; }

.dark-indigo .contact-list i {
  color: #3f51b5; }

.dark-indigo .Collapsible__trigger {
  background: #3f51b5; }

.dark-indigo .categories-list li a:focus,
.dark-indigo .categories-list li a:hover,
.dark-indigo .categories-list li.active a {
  color: #3f51b5; }

.dark-indigo .login-content .form-control:focus {
  border-color: #3f51b5; }

.dark-indigo .filter-with-bg-color ul li a {
  border-color: #3f51b5; }
  .dark-indigo .filter-with-bg-color ul li a:hover, .dark-indigo .filter-with-bg-color ul li a:focus, .dark-indigo .filter-with-bg-color ul li a.active {
    background-color: #3f51b5; }

.dark-indigo .profile-intro .icon {
  color: #3f51b5; }

.dark-indigo .social-link li.active a,
.dark-indigo .social-link li a:hover,
.dark-indigo .social-link li a:focus {
  color: #FF4081; }

.dark-indigo .actions {
  color: #FF4081; }

.dark-indigo .table-hover tbody tr:hover {
  background-color: rgba(63, 81, 181, 0.075); }

.dark-indigo .border-primary {
  border-color: #3f51b5 !important; }

.dark-indigo .app-logo-content {
  background-color: #3f51b5; }

.dark-indigo .app-social-block .social-link button {
  border: solid 1px #3f51b5;
  color: #3f51b5; }
  .dark-indigo .app-social-block .social-link button:hover, .dark-indigo .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #3f51b5; }

/*Theme Dark Cyan Styles*/
.dark-cyan .right-arrow {
  color: #00bcd4; }
  .dark-cyan .right-arrow:after {
    color: #00bcd4; }

.dark-cyan a,
.dark-cyan a.text-primary {
  color: #00bcd4; }
  .dark-cyan a:focus, .dark-cyan a:hover,
  .dark-cyan a.text-primary:focus,
  .dark-cyan a.text-primary:hover {
    color: #008fa1; }

.dark-cyan .text-primary {
  color: #00bcd4 !important; }

.dark-cyan .page-link {
  color: #00bcd4; }
  .dark-cyan .page-link:focus, .dark-cyan .page-link:hover {
    color: #00bcd4; }

.dark-cyan .bg-primary,
.dark-cyan .badge-primary {
  background-color: #00bcd4 !important;
  color: #fff !important; }

.dark-cyan .btn-primary {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff; }
  .dark-cyan .btn-primary:hover, .dark-cyan .btn-primary:focus, .dark-cyan .btn-primary:active {
    background-color: #00a5bb !important;
    border-color: #00a5bb !important;
    color: #fff !important; }

.dark-cyan .bg-primary.lighten-4 {
  background-color: #b2ebf2 !important; }

.dark-cyan .bg-primary.lighten-3 {
  background-color: #80deea !important; }

.dark-cyan .bg-primary.lighten-2 {
  background-color: #4dd0e1 !important; }

.dark-cyan .bg-primary.lighten-1 {
  background-color: #26c6da !important; }

.dark-cyan .bg-primary.darken-1 {
  background-color: #00acc1 !important; }

.dark-cyan .bg-primary.darken-2 {
  background-color: #0097a7 !important; }

.dark-cyan .bg-primary.darken-3 {
  background-color: #00838f !important; }

.dark-cyan .bg-primary.darken-4 {
  background-color: #006064 !important; }

.dark-cyan .bg-primary.accent-1 {
  background-color: #84ffff !important; }

.dark-cyan .bg-primary.accent-2 {
  background-color: #18ffff !important; }

.dark-cyan .bg-primary.accent-3 {
  background-color: #00e5ff !important; }

.dark-cyan .bg-primary.accent-4 {
  background-color: #00b8d4 !important; }

.dark-cyan a.text-secondary {
  color: #69F0AE; }
  .dark-cyan a.text-secondary:focus, .dark-cyan a.text-secondary:hover {
    color: #3beb95 !important; }

.dark-cyan .text-secondary {
  color: #69F0AE !important; }

.dark-cyan .bg-secondary,
.dark-cyan .badge-secondary {
  background-color: #69F0AE !important;
  color: #fff !important; }

.dark-cyan .btn-secondary {
  background-color: #69F0AE;
  border-color: #69F0AE;
  color: #fff; }
  .dark-cyan .btn-secondary:hover, .dark-cyan .btn-secondary:focus, .dark-cyan .btn-secondary:active {
    background-color: #52eea1 !important;
    border-color: #52eea1 !important;
    color: #fff !important; }

.dark-cyan .app-main-header {
  background-color: #00bcd4 !important; }
  .dark-cyan .app-main-header .search-bar input {
    background-color: #00d3ee; }
    .dark-cyan .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-cyan .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-cyan .color-theme-header {
  background-color: #00bcd4; }

.dark-cyan .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-cyan .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-cyan .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-cyan .side-nav .user-detail .user-name {
    color: #fff; }

.dark-cyan ul.nav-menu li a {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu > li.open > a,
.dark-cyan ul.nav-menu > li > a:hover,
.dark-cyan ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-cyan ul.nav-menu li.menu.open > a {
  border-color: #00bcd4; }

.dark-cyan ul.nav-menu li.menu > a:focus:before,
.dark-cyan ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-cyan ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-cyan ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu li.menu.open > a:before,
.dark-cyan ul.nav-menu li.menu > a:focus:before,
.dark-cyan ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-cyan ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff; }

.dark-cyan ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-cyan ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-cyan ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.dark-cyan ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-cyan ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.dark-cyan .module-nav li a.active {
  border-color: #00bcd4; }

.dark-cyan .rbc-event {
  background-color: #00bcd4; }

.dark-cyan .rbc-event.rbc-selected {
  background-color: #008fa1; }

.dark-cyan .rbc-slot-selection {
  background-color: rgba(0, 188, 212, 0.7); }

.dark-cyan .rbc-toolbar button:active,
.dark-cyan .rbc-toolbar button.rbc-active {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-toolbar button:active:hover,
.dark-cyan .rbc-toolbar button.rbc-active:hover,
.dark-cyan .rbc-toolbar button:active:focus,
.dark-cyan .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-toolbar button:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-toolbar button:hover {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .chat-sidenav-title {
  color: #00bcd4; }

.dark-cyan .chat-user-item.active, .dark-cyan .chat-user-item:hover {
  background-color: #bbf7ff; }

.dark-cyan .contact-list i {
  color: #00bcd4; }

.dark-cyan .Collapsible__trigger {
  background: #00bcd4; }

.dark-cyan .categories-list li a:focus,
.dark-cyan .categories-list li a:hover,
.dark-cyan .categories-list li.active a {
  color: #00bcd4; }

.dark-cyan .login-content .form-control:focus {
  border-color: #00bcd4; }

.dark-cyan .filter-with-bg-color ul li a {
  border-color: #00bcd4; }
  .dark-cyan .filter-with-bg-color ul li a:hover, .dark-cyan .filter-with-bg-color ul li a:focus, .dark-cyan .filter-with-bg-color ul li a.active {
    background-color: #00bcd4; }

.dark-cyan .profile-intro .icon {
  color: #00bcd4; }

.dark-cyan .social-link li.active a,
.dark-cyan .social-link li a:hover,
.dark-cyan .social-link li a:focus {
  color: #69F0AE; }

.dark-cyan .actions {
  color: #69F0AE; }

.dark-cyan .table-hover tbody tr:hover {
  background-color: rgba(0, 188, 212, 0.075); }

.dark-cyan .border-primary {
  border-color: #00bcd4 !important; }

.dark-cyan .app-logo-content {
  background-color: #00bcd4; }

.dark-cyan .app-social-block .social-link button {
  border: solid 1px #00bcd4;
  color: #00bcd4; }
  .dark-cyan .app-social-block .social-link button:hover, .dark-cyan .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #00bcd4; }

/*Theme Dark Amber Styles*/
.dark-amber .right-arrow {
  color: #ffc107; }
  .dark-amber .right-arrow:after {
    color: #ffc107; }

.dark-amber a,
.dark-amber a.text-primary {
  color: #ffc107; }
  .dark-amber a:focus, .dark-amber a:hover,
  .dark-amber a.text-primary:focus,
  .dark-amber a.text-primary:hover {
    color: #d39e00; }

.dark-amber .text-primary {
  color: #ffc107 !important; }

.dark-amber .page-link {
  color: #ffc107; }
  .dark-amber .page-link:focus, .dark-amber .page-link:hover {
    color: #ffc107; }

.dark-amber .bg-primary,
.dark-amber .badge-primary {
  background-color: #ffc107 !important;
  color: #fff !important; }

.dark-amber .btn-primary {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #fff; }
  .dark-amber .btn-primary:hover, .dark-amber .btn-primary:focus, .dark-amber .btn-primary:active {
    background-color: #edb100 !important;
    border-color: #edb100 !important;
    color: #fff !important; }

.dark-amber .bg-primary.lighten-4 {
  background-color: #ffecb3 !important; }

.dark-amber .bg-primary.lighten-3 {
  background-color: #ffe082 !important; }

.dark-amber .bg-primary.lighten-2 {
  background-color: #ffd54f !important; }

.dark-amber .bg-primary.lighten-1 {
  background-color: #ffca28 !important; }

.dark-amber .bg-primary.darken-1 {
  background-color: #ffb300 !important; }

.dark-amber .bg-primary.darken-2 {
  background-color: #ffa000 !important; }

.dark-amber .bg-primary.darken-3 {
  background-color: #ff8f00 !important; }

.dark-amber .bg-primary.darken-4 {
  background-color: #ff6f00 !important; }

.dark-amber .bg-primary.accent-1 {
  background-color: #ffe57f !important; }

.dark-amber .bg-primary.accent-2 {
  background-color: #ffd740 !important; }

.dark-amber .bg-primary.accent-3 {
  background-color: #ffc400 !important; }

.dark-amber .bg-primary.accent-4 {
  background-color: #ffab00 !important; }

.dark-amber a.text-secondary {
  color: #FF3D00; }
  .dark-amber a.text-secondary:focus, .dark-amber a.text-secondary:hover {
    color: #cc3100 !important; }

.dark-amber .text-secondary {
  color: #FF3D00 !important; }

.dark-amber .bg-secondary,
.dark-amber .badge-secondary {
  background-color: #FF3D00 !important;
  color: #fff !important; }

.dark-amber .btn-secondary {
  background-color: #FF3D00;
  border-color: #FF3D00;
  color: #fff; }
  .dark-amber .btn-secondary:hover, .dark-amber .btn-secondary:focus, .dark-amber .btn-secondary:active {
    background-color: #e63700 !important;
    border-color: #e63700 !important;
    color: #fff !important; }

.dark-amber .app-main-header {
  background-color: #ffc107 !important; }
  .dark-amber .app-main-header .search-bar input {
    background-color: #ffc721; }
    .dark-amber .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-amber .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-amber .color-theme-header {
  background-color: #ffc107; }

.dark-amber .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-amber .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-amber .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-amber .side-nav .user-detail .user-name {
    color: #fff; }

.dark-amber ul.nav-menu li a {
  color: #a1a1a1; }

.dark-amber ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-amber ul.nav-menu > li.open > a,
.dark-amber ul.nav-menu > li > a:hover,
.dark-amber ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-amber ul.nav-menu li.menu.open > a {
  border-color: #ffc107; }

.dark-amber ul.nav-menu li.menu > a:focus:before,
.dark-amber ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-amber ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-amber ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-amber ul.nav-menu li.menu.open > a:before,
.dark-amber ul.nav-menu li.menu > a:focus:before,
.dark-amber ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-amber ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-amber ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff; }

.dark-amber ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-amber ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-amber ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.dark-amber ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-amber ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.dark-amber .module-nav li a.active {
  border-color: #ffc107; }

.dark-amber .rbc-event {
  background-color: #ffc107; }

.dark-amber .rbc-event.rbc-selected {
  background-color: #d39e00; }

.dark-amber .rbc-slot-selection {
  background-color: rgba(255, 193, 7, 0.7); }

.dark-amber .rbc-toolbar button:active,
.dark-amber .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-toolbar button:active:hover,
.dark-amber .rbc-toolbar button.rbc-active:hover,
.dark-amber .rbc-toolbar button:active:focus,
.dark-amber .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-toolbar button:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-toolbar button:hover {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .chat-sidenav-title {
  color: #ffc107; }

.dark-amber .chat-user-item.active, .dark-amber .chat-user-item:hover {
  background-color: #fffaed; }

.dark-amber .contact-list i {
  color: #ffc107; }

.dark-amber .Collapsible__trigger {
  background: #ffc107; }

.dark-amber .categories-list li a:focus,
.dark-amber .categories-list li a:hover,
.dark-amber .categories-list li.active a {
  color: #ffc107; }

.dark-amber .login-content .form-control:focus {
  border-color: #ffc107; }

.dark-amber .filter-with-bg-color ul li a {
  border-color: #ffc107; }
  .dark-amber .filter-with-bg-color ul li a:hover, .dark-amber .filter-with-bg-color ul li a:focus, .dark-amber .filter-with-bg-color ul li a.active {
    background-color: #ffc107; }

.dark-amber .profile-intro .icon {
  color: #ffc107; }

.dark-amber .social-link li.active a,
.dark-amber .social-link li a:hover,
.dark-amber .social-link li a:focus {
  color: #FF3D00; }

.dark-amber .actions {
  color: #FF3D00; }

.dark-amber .table-hover tbody tr:hover {
  background-color: rgba(255, 193, 7, 0.075); }

.dark-amber .border-primary {
  border-color: #ffc107 !important; }

.dark-amber .app-logo-content {
  background-color: #ffc107; }

.dark-amber .app-social-block .social-link button {
  border: solid 1px #ffc107;
  color: #ffc107; }
  .dark-amber .app-social-block .social-link button:hover, .dark-amber .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #ffc107; }

/*Theme Dark Deep Orange Styles*/
.dark-deep-orange .right-arrow {
  color: #ff5722; }
  .dark-deep-orange .right-arrow:after {
    color: #ff5722; }

.dark-deep-orange a,
.dark-deep-orange a.text-primary {
  color: #ff5722; }
  .dark-deep-orange a:focus, .dark-deep-orange a:hover,
  .dark-deep-orange a.text-primary:focus,
  .dark-deep-orange a.text-primary:hover {
    color: #ee3900; }

.dark-deep-orange .text-primary {
  color: #ff5722 !important; }

.dark-deep-orange .page-link {
  color: #ff5722; }
  .dark-deep-orange .page-link:focus, .dark-deep-orange .page-link:hover {
    color: #ff5722; }

.dark-deep-orange .bg-primary,
.dark-deep-orange .badge-primary {
  background-color: #ff5722 !important;
  color: #fff !important; }

.dark-deep-orange .btn-primary {
  background-color: #ff5722;
  border-color: #ff5722;
  color: #fff; }
  .dark-deep-orange .btn-primary:hover, .dark-deep-orange .btn-primary:focus, .dark-deep-orange .btn-primary:active {
    background-color: #ff4409 !important;
    border-color: #ff4409 !important;
    color: #fff !important; }

.dark-deep-orange .bg-primary.lighten-4 {
  background-color: #ffccbc !important; }

.dark-deep-orange .bg-primary.lighten-3 {
  background-color: #ffab91 !important; }

.dark-deep-orange .bg-primary.lighten-2 {
  background-color: #ff8a65 !important; }

.dark-deep-orange .bg-primary.lighten-1 {
  background-color: #ff7043 !important; }

.dark-deep-orange .bg-primary.darken-1 {
  background-color: #f4511e !important; }

.dark-deep-orange .bg-primary.darken-2 {
  background-color: #e64a19 !important; }

.dark-deep-orange .bg-primary.darken-3 {
  background-color: #d84315 !important; }

.dark-deep-orange .bg-primary.darken-4 {
  background-color: #bf360c !important; }

.dark-deep-orange .bg-primary.accent-1 {
  background-color: #ff9e80 !important; }

.dark-deep-orange .bg-primary.accent-2 {
  background-color: #ff6e40 !important; }

.dark-deep-orange .bg-primary.accent-3 {
  background-color: #ff3d00 !important; }

.dark-deep-orange .bg-primary.accent-4 {
  background-color: #dd2c00 !important; }

.dark-deep-orange a.text-secondary {
  color: #40C4FF; }
  .dark-deep-orange a.text-secondary:focus, .dark-deep-orange a.text-secondary:hover {
    color: #0db4ff !important; }

.dark-deep-orange .text-secondary {
  color: #40C4FF !important; }

.dark-deep-orange .bg-secondary,
.dark-deep-orange .badge-secondary {
  background-color: #40C4FF !important;
  color: #fff !important; }

.dark-deep-orange .btn-secondary {
  background-color: #40C4FF;
  border-color: #40C4FF;
  color: #fff; }
  .dark-deep-orange .btn-secondary:hover, .dark-deep-orange .btn-secondary:focus, .dark-deep-orange .btn-secondary:active {
    background-color: #27bcff !important;
    border-color: #27bcff !important;
    color: #fff !important; }

.dark-deep-orange .app-main-header {
  background-color: #ff5722 !important; }
  .dark-deep-orange .app-main-header .search-bar input {
    background-color: #ff6a3c; }
    .dark-deep-orange .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-deep-orange .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-deep-orange .color-theme-header {
  background-color: #ff5722; }

.dark-deep-orange .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-deep-orange .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-deep-orange .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-deep-orange .side-nav .user-detail .user-name {
    color: #fff; }

.dark-deep-orange ul.nav-menu li a {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu > li.open > a,
.dark-deep-orange ul.nav-menu > li > a:hover,
.dark-deep-orange ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-orange ul.nav-menu li.menu.open > a {
  border-color: #ff5722; }

.dark-deep-orange ul.nav-menu li.menu > a:focus:before,
.dark-deep-orange ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-deep-orange ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-deep-orange ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu li.menu.open > a:before,
.dark-deep-orange ul.nav-menu li.menu > a:focus:before,
.dark-deep-orange ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-deep-orange ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-orange .module-nav li a.active {
  border-color: #ff5722; }

.dark-deep-orange .rbc-event {
  background-color: #ff5722; }

.dark-deep-orange .rbc-event.rbc-selected {
  background-color: #ee3900; }

.dark-deep-orange .rbc-slot-selection {
  background-color: rgba(255, 87, 34, 0.7); }

.dark-deep-orange .rbc-toolbar button:active,
.dark-deep-orange .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-toolbar button:active:hover,
.dark-deep-orange .rbc-toolbar button.rbc-active:hover,
.dark-deep-orange .rbc-toolbar button:active:focus,
.dark-deep-orange .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-toolbar button:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-toolbar button:hover {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .chat-sidenav-title {
  color: #ff5722; }

.dark-deep-orange .chat-user-item.active, .dark-deep-orange .chat-user-item:hover {
  background-color: white; }

.dark-deep-orange .contact-list i {
  color: #ff5722; }

.dark-deep-orange .Collapsible__trigger {
  background: #ff5722; }

.dark-deep-orange .categories-list li a:focus,
.dark-deep-orange .categories-list li a:hover,
.dark-deep-orange .categories-list li.active a {
  color: #ff5722; }

.dark-deep-orange .login-content .form-control:focus {
  border-color: #ff5722; }

.dark-deep-orange .filter-with-bg-color ul li a {
  border-color: #ff5722; }
  .dark-deep-orange .filter-with-bg-color ul li a:hover, .dark-deep-orange .filter-with-bg-color ul li a:focus, .dark-deep-orange .filter-with-bg-color ul li a.active {
    background-color: #ff5722; }

.dark-deep-orange .profile-intro .icon {
  color: #ff5722; }

.dark-deep-orange .social-link li.active a,
.dark-deep-orange .social-link li a:hover,
.dark-deep-orange .social-link li a:focus {
  color: #40C4FF; }

.dark-deep-orange .actions {
  color: #40C4FF; }

.dark-deep-orange .table-hover tbody tr:hover {
  background-color: rgba(255, 87, 34, 0.075); }

.dark-deep-orange .border-primary {
  border-color: #ff5722 !important; }

.dark-deep-orange .app-logo-content {
  background-color: #ff5722; }

.dark-deep-orange .app-social-block .social-link button {
  border: solid 1px #ff5722;
  color: #ff5722; }
  .dark-deep-orange .app-social-block .social-link button:hover, .dark-deep-orange .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #ff5722; }

/*Theme Dark Pink Styles*/
.dark-pink .right-arrow {
  color: #e91e63; }
  .dark-pink .right-arrow:after {
    color: #e91e63; }

.dark-pink a,
.dark-pink a.text-primary {
  color: #e91e63; }
  .dark-pink a:focus, .dark-pink a:hover,
  .dark-pink a.text-primary:focus,
  .dark-pink a.text-primary:hover {
    color: #c1134e; }

.dark-pink .text-primary {
  color: #e91e63 !important; }

.dark-pink .page-link {
  color: #e91e63; }
  .dark-pink .page-link:focus, .dark-pink .page-link:hover {
    color: #e91e63; }

.dark-pink .bg-primary,
.dark-pink .badge-primary {
  background-color: #e91e63 !important;
  color: #fff !important; }

.dark-pink .btn-primary {
  background-color: #e91e63;
  border-color: #e91e63;
  color: #fff; }
  .dark-pink .btn-primary:hover, .dark-pink .btn-primary:focus, .dark-pink .btn-primary:active {
    background-color: #d81558 !important;
    border-color: #d81558 !important;
    color: #fff !important; }

.dark-pink .bg-primary.lighten-4 {
  background-color: #f8bbd0 !important; }

.dark-pink .bg-primary.lighten-3 {
  background-color: #f48fb1 !important; }

.dark-pink .bg-primary.lighten-2 {
  background-color: #f06292 !important; }

.dark-pink .bg-primary.lighten-1 {
  background-color: #ec407a !important; }

.dark-pink .bg-primary.darken-1 {
  background-color: #d81b60 !important; }

.dark-pink .bg-primary.darken-2 {
  background-color: #c2185b !important; }

.dark-pink .bg-primary.darken-3 {
  background-color: #ad1457 !important; }

.dark-pink .bg-primary.darken-4 {
  background-color: #880e4f !important; }

.dark-pink .bg-primary.accent-1 {
  background-color: #ff80ab !important; }

.dark-pink .bg-primary.accent-2 {
  background-color: #ff4081 !important; }

.dark-pink .bg-primary.accent-3 {
  background-color: #f50057 !important; }

.dark-pink .bg-primary.accent-4 {
  background-color: #c51162 !important; }

.dark-pink a.text-secondary {
  color: #00BFA5; }
  .dark-pink a.text-secondary:focus, .dark-pink a.text-secondary:hover {
    color: #008c79 !important; }

.dark-pink .text-secondary {
  color: #00BFA5 !important; }

.dark-pink .bg-secondary,
.dark-pink .badge-secondary {
  background-color: #00BFA5 !important;
  color: #fff !important; }

.dark-pink .btn-secondary {
  background-color: #00BFA5;
  border-color: #00BFA5;
  color: #fff; }
  .dark-pink .btn-secondary:hover, .dark-pink .btn-secondary:focus, .dark-pink .btn-secondary:active {
    background-color: #00a68f !important;
    border-color: #00a68f !important;
    color: #fff !important; }

.dark-pink .app-main-header {
  background-color: #e91e63 !important; }
  .dark-pink .app-main-header .search-bar input {
    background-color: #eb3573; }
    .dark-pink .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-pink .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-pink .color-theme-header {
  background-color: #e91e63; }

.dark-pink .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-pink .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-pink .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-pink .side-nav .user-detail .user-name {
    color: #fff; }

.dark-pink ul.nav-menu li a {
  color: #a1a1a1; }

.dark-pink ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-pink ul.nav-menu > li.open > a,
.dark-pink ul.nav-menu > li > a:hover,
.dark-pink ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-pink ul.nav-menu li.menu.open > a {
  border-color: #e91e63; }

.dark-pink ul.nav-menu li.menu > a:focus:before,
.dark-pink ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-pink ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-pink ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-pink ul.nav-menu li.menu.open > a:before,
.dark-pink ul.nav-menu li.menu > a:focus:before,
.dark-pink ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-pink ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-pink ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff; }

.dark-pink ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-pink ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-pink ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.dark-pink ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-pink ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.dark-pink .module-nav li a.active {
  border-color: #e91e63; }

.dark-pink .rbc-event {
  background-color: #e91e63; }

.dark-pink .rbc-event.rbc-selected {
  background-color: #c1134e; }

.dark-pink .rbc-slot-selection {
  background-color: rgba(233, 30, 99, 0.7); }

.dark-pink .rbc-toolbar button:active,
.dark-pink .rbc-toolbar button.rbc-active {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.dark-pink .rbc-toolbar button:active:hover,
.dark-pink .rbc-toolbar button.rbc-active:hover,
.dark-pink .rbc-toolbar button:active:focus,
.dark-pink .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.dark-pink .rbc-toolbar button:focus {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.dark-pink .rbc-toolbar button:hover {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.dark-pink .chat-sidenav-title {
  color: #e91e63; }

.dark-pink .chat-user-item.active, .dark-pink .chat-user-item:hover {
  background-color: #fdeff4; }

.dark-pink .contact-list i {
  color: #e91e63; }

.dark-pink .Collapsible__trigger {
  background: #e91e63; }

.dark-pink .categories-list li a:focus,
.dark-pink .categories-list li a:hover,
.dark-pink .categories-list li.active a {
  color: #e91e63; }

.dark-pink .login-content .form-control:focus {
  border-color: #e91e63; }

.dark-pink .filter-with-bg-color ul li a {
  border-color: #e91e63; }
  .dark-pink .filter-with-bg-color ul li a:hover, .dark-pink .filter-with-bg-color ul li a:focus, .dark-pink .filter-with-bg-color ul li a.active {
    background-color: #e91e63; }

.dark-pink .profile-intro .icon {
  color: #e91e63; }

.dark-pink .social-link li.active a,
.dark-pink .social-link li a:hover,
.dark-pink .social-link li a:focus {
  color: #00BFA5; }

.dark-pink .actions {
  color: #00BFA5; }

.dark-pink .table-hover tbody tr:hover {
  background-color: rgba(233, 30, 99, 0.075); }

.dark-pink .border-primary {
  border-color: #e91e63 !important; }

.dark-pink .app-logo-content {
  background-color: #e91e63; }

.dark-pink .app-social-block .social-link button {
  border: solid 1px #e91e63;
  color: #e91e63; }
  .dark-pink .app-social-block .social-link button:hover, .dark-pink .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #e91e63; }

/*Theme Dark Blue Styles*/
.dark-blue .right-arrow {
  color: #2196F3; }
  .dark-blue .right-arrow:after {
    color: #2196F3; }

.dark-blue a,
.dark-blue a.text-primary {
  color: #2196F3; }
  .dark-blue a:focus, .dark-blue a:hover,
  .dark-blue a.text-primary:focus,
  .dark-blue a.text-primary:hover {
    color: #0c7cd5; }

.dark-blue .text-primary {
  color: #2196F3 !important; }

.dark-blue .page-link {
  color: #2196F3; }
  .dark-blue .page-link:focus, .dark-blue .page-link:hover {
    color: #2196F3; }

.dark-blue .bg-primary,
.dark-blue .badge-primary {
  background-color: #2196F3 !important;
  color: #fff !important; }

.dark-blue .btn-primary {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #fff; }
  .dark-blue .btn-primary:hover, .dark-blue .btn-primary:focus, .dark-blue .btn-primary:active {
    background-color: #0d8aee !important;
    border-color: #0d8aee !important;
    color: #fff !important; }

.dark-blue .bg-primary.lighten-4 {
  background-color: #BBDEFB !important; }

.dark-blue .bg-primary.lighten-3 {
  background-color: #90CAF9 !important; }

.dark-blue .bg-primary.lighten-2 {
  background-color: #64B5F6 !important; }

.dark-blue .bg-primary.lighten-1 {
  background-color: #42A5F5 !important; }

.dark-blue .bg-primary.darken-1 {
  background-color: #1E88E5 !important; }

.dark-blue .bg-primary.darken-2 {
  background-color: #1976D2 !important; }

.dark-blue .bg-primary.darken-3 {
  background-color: #1565C0 !important; }

.dark-blue .bg-primary.darken-4 {
  background-color: #0D47A1 !important; }

.dark-blue .bg-primary.accent-1 {
  background-color: #82B1FF !important; }

.dark-blue .bg-primary.accent-2 {
  background-color: #448AFF !important; }

.dark-blue .bg-primary.accent-3 {
  background-color: #2979FF !important; }

.dark-blue .bg-primary.accent-4 {
  background-color: #2962FF !important; }

.dark-blue a.text-secondary {
  color: #FF6E40; }
  .dark-blue a.text-secondary:focus, .dark-blue a.text-secondary:hover {
    color: #ff470d !important; }

.dark-blue .text-secondary {
  color: #FF6E40 !important; }

.dark-blue .bg-secondary,
.dark-blue .badge-secondary {
  background-color: #FF6E40 !important;
  color: #fff !important; }

.dark-blue .btn-secondary {
  background-color: #FF6E40;
  border-color: #FF6E40;
  color: #fff; }
  .dark-blue .btn-secondary:hover, .dark-blue .btn-secondary:focus, .dark-blue .btn-secondary:active {
    background-color: #ff5b27 !important;
    border-color: #ff5b27 !important;
    color: #fff !important; }

.dark-blue .app-main-header {
  background-color: #2196F3 !important; }
  .dark-blue .app-main-header .search-bar input {
    background-color: #39a1f4; }
    .dark-blue .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-blue .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-blue .color-theme-header {
  background-color: #2196F3; }

.dark-blue .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-blue .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-blue .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-blue .side-nav .user-detail .user-name {
    color: #fff; }

.dark-blue ul.nav-menu li a {
  color: #a1a1a1; }

.dark-blue ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-blue ul.nav-menu > li.open > a,
.dark-blue ul.nav-menu > li > a:hover,
.dark-blue ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-blue ul.nav-menu li.menu.open > a {
  border-color: #2196F3; }

.dark-blue ul.nav-menu li.menu > a:focus:before,
.dark-blue ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-blue ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-blue ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-blue ul.nav-menu li.menu.open > a:before,
.dark-blue ul.nav-menu li.menu > a:focus:before,
.dark-blue ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-blue ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-blue ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff; }

.dark-blue ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-blue ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-blue ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.dark-blue ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-blue ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.dark-blue .module-nav li a.active {
  border-color: #2196F3; }

.dark-blue .rbc-event {
  background-color: #2196F3; }

.dark-blue .rbc-event.rbc-selected {
  background-color: #0c7cd5; }

.dark-blue .rbc-slot-selection {
  background-color: rgba(33, 150, 243, 0.7); }

.dark-blue .rbc-toolbar button:active,
.dark-blue .rbc-toolbar button.rbc-active {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-toolbar button:active:hover,
.dark-blue .rbc-toolbar button.rbc-active:hover,
.dark-blue .rbc-toolbar button:active:focus,
.dark-blue .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-toolbar button:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-toolbar button:hover {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .chat-sidenav-title {
  color: #2196F3; }

.dark-blue .chat-user-item.active, .dark-blue .chat-user-item:hover {
  background-color: #fbfdff; }

.dark-blue .contact-list i {
  color: #2196F3; }

.dark-blue .Collapsible__trigger {
  background: #2196F3; }

.dark-blue .categories-list li a:focus,
.dark-blue .categories-list li a:hover,
.dark-blue .categories-list li.active a {
  color: #2196F3; }

.dark-blue .login-content .form-control:focus {
  border-color: #2196F3; }

.dark-blue .filter-with-bg-color ul li a {
  border-color: #2196F3; }
  .dark-blue .filter-with-bg-color ul li a:hover, .dark-blue .filter-with-bg-color ul li a:focus, .dark-blue .filter-with-bg-color ul li a.active {
    background-color: #2196F3; }

.dark-blue .profile-intro .icon {
  color: #2196F3; }

.dark-blue .social-link li.active a,
.dark-blue .social-link li a:hover,
.dark-blue .social-link li a:focus {
  color: #FF6E40; }

.dark-blue .actions {
  color: #FF6E40; }

.dark-blue .table-hover tbody tr:hover {
  background-color: rgba(33, 150, 243, 0.075); }

.dark-blue .border-primary {
  border-color: #2196F3 !important; }

.dark-blue .app-logo-content {
  background-color: #2196F3; }

.dark-blue .app-social-block .social-link button {
  border: solid 1px #2196F3;
  color: #2196F3; }
  .dark-blue .app-social-block .social-link button:hover, .dark-blue .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #2196F3; }

/*Theme Dark Deep Purple Styles*/
.dark-deep-purple .right-arrow {
  color: #673ab7; }
  .dark-deep-purple .right-arrow:after {
    color: #673ab7; }

.dark-deep-purple a,
.dark-deep-purple a.text-primary {
  color: #673ab7; }
  .dark-deep-purple a:focus, .dark-deep-purple a:hover,
  .dark-deep-purple a.text-primary:focus,
  .dark-deep-purple a.text-primary:hover {
    color: #512e90; }

.dark-deep-purple .text-primary {
  color: #673ab7 !important; }

.dark-deep-purple .page-link {
  color: #673ab7; }
  .dark-deep-purple .page-link:focus, .dark-deep-purple .page-link:hover {
    color: #673ab7; }

.dark-deep-purple .bg-primary,
.dark-deep-purple .badge-primary {
  background-color: #673ab7 !important;
  color: #fff !important; }

.dark-deep-purple .btn-primary {
  background-color: #673ab7;
  border-color: #673ab7;
  color: #fff; }
  .dark-deep-purple .btn-primary:hover, .dark-deep-purple .btn-primary:focus, .dark-deep-purple .btn-primary:active {
    background-color: #5c34a4 !important;
    border-color: #5c34a4 !important;
    color: #fff !important; }

.dark-deep-purple .bg-primary.lighten-4 {
  background-color: #d1c4e9 !important; }

.dark-deep-purple .bg-primary.lighten-3 {
  background-color: #b39ddb !important; }

.dark-deep-purple .bg-primary.lighten-2 {
  background-color: #9575cd !important; }

.dark-deep-purple .bg-primary.lighten-1 {
  background-color: #7e57c2 !important; }

.dark-deep-purple .bg-primary.darken-1 {
  background-color: #5e35b1 !important; }

.dark-deep-purple .bg-primary.darken-2 {
  background-color: #512da8 !important; }

.dark-deep-purple .bg-primary.darken-3 {
  background-color: #4527a0 !important; }

.dark-deep-purple .bg-primary.darken-4 {
  background-color: #311b92 !important; }

.dark-deep-purple .bg-primary.accent-1 {
  background-color: #b388ff !important; }

.dark-deep-purple .bg-primary.accent-2 {
  background-color: #7c4dff !important; }

.dark-deep-purple .bg-primary.accent-3 {
  background-color: #651fff !important; }

.dark-deep-purple .bg-primary.accent-4 {
  background-color: #6200ea !important; }

.dark-deep-purple a.text-secondary {
  color: #FFAB00; }
  .dark-deep-purple a.text-secondary:focus, .dark-deep-purple a.text-secondary:hover {
    color: #cc8900 !important; }

.dark-deep-purple .text-secondary {
  color: #FFAB00 !important; }

.dark-deep-purple .bg-secondary,
.dark-deep-purple .badge-secondary {
  background-color: #FFAB00 !important;
  color: #fff !important; }

.dark-deep-purple .btn-secondary {
  background-color: #FFAB00;
  border-color: #FFAB00;
  color: #fff; }
  .dark-deep-purple .btn-secondary:hover, .dark-deep-purple .btn-secondary:focus, .dark-deep-purple .btn-secondary:active {
    background-color: #e69a00 !important;
    border-color: #e69a00 !important;
    color: #fff !important; }

.dark-deep-purple .app-main-header {
  background-color: #673ab7 !important; }
  .dark-deep-purple .app-main-header .search-bar input {
    background-color: #7446c4; }
    .dark-deep-purple .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-deep-purple .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-deep-purple .color-theme-header {
  background-color: #673ab7; }

.dark-deep-purple .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-deep-purple .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-deep-purple .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-deep-purple .side-nav .user-detail .user-name {
    color: #fff; }

.dark-deep-purple ul.nav-menu li a {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu > li.open > a,
.dark-deep-purple ul.nav-menu > li > a:hover,
.dark-deep-purple ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-purple ul.nav-menu li.menu.open > a {
  border-color: #FFAB00; }

.dark-deep-purple ul.nav-menu li.menu > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-deep-purple ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-deep-purple ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu li.menu.open > a:before,
.dark-deep-purple ul.nav-menu li.menu > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-deep-purple ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-purple .module-nav li a.active {
  border-color: #673ab7; }

.dark-deep-purple .rbc-event {
  background-color: #673ab7; }

.dark-deep-purple .rbc-event.rbc-selected {
  background-color: #512e90; }

.dark-deep-purple .rbc-slot-selection {
  background-color: rgba(103, 58, 183, 0.7); }

.dark-deep-purple .rbc-toolbar button:active,
.dark-deep-purple .rbc-toolbar button.rbc-active {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-toolbar button:active:hover,
.dark-deep-purple .rbc-toolbar button.rbc-active:hover,
.dark-deep-purple .rbc-toolbar button:active:focus,
.dark-deep-purple .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-toolbar button:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-toolbar button:hover {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .chat-sidenav-title {
  color: #673ab7; }

.dark-deep-purple .chat-user-item.active, .dark-deep-purple .chat-user-item:hover {
  background-color: #e8e1f5; }

.dark-deep-purple .contact-list i {
  color: #673ab7; }

.dark-deep-purple .Collapsible__trigger {
  background: #673ab7; }

.dark-deep-purple .categories-list li a:focus,
.dark-deep-purple .categories-list li a:hover,
.dark-deep-purple .categories-list li.active a {
  color: #673ab7; }

.dark-deep-purple .login-content .form-control:focus {
  border-color: #673ab7; }

.dark-deep-purple .filter-with-bg-color ul li a {
  border-color: #673ab7; }
  .dark-deep-purple .filter-with-bg-color ul li a:hover, .dark-deep-purple .filter-with-bg-color ul li a:focus, .dark-deep-purple .filter-with-bg-color ul li a.active {
    background-color: #673ab7; }

.dark-deep-purple .profile-intro .icon {
  color: #673ab7; }

.dark-deep-purple .social-link li.active a,
.dark-deep-purple .social-link li a:hover,
.dark-deep-purple .social-link li a:focus {
  color: #FFAB00; }

.dark-deep-purple .actions {
  color: #FFAB00; }

.dark-deep-purple .table-hover tbody tr:hover {
  background-color: rgba(103, 58, 183, 0.075); }

.dark-deep-purple .border-primary {
  border-color: #673ab7 !important; }

.dark-deep-purple .app-logo-content {
  background-color: #673ab7; }

.dark-deep-purple .app-social-block .social-link button {
  border: solid 1px #673ab7;
  color: #673ab7; }
  .dark-deep-purple .app-social-block .social-link button:hover, .dark-deep-purple .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #673ab7; }

/*Theme Dark Green Styles*/
.dark-green .right-arrow {
  color: #4CAF50; }
  .dark-green .right-arrow:after {
    color: #4CAF50; }

.dark-green a,
.dark-green a.text-primary {
  color: #4CAF50; }
  .dark-green a:focus, .dark-green a:hover,
  .dark-green a.text-primary:focus,
  .dark-green a.text-primary:hover {
    color: #3d8b40; }

.dark-green .text-primary {
  color: #4CAF50 !important; }

.dark-green .page-link {
  color: #4CAF50; }
  .dark-green .page-link:focus, .dark-green .page-link:hover {
    color: #4CAF50; }

.dark-green .bg-primary,
.dark-green .badge-primary {
  background-color: #4CAF50 !important;
  color: #fff !important; }

.dark-green .btn-primary {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }
  .dark-green .btn-primary:hover, .dark-green .btn-primary:focus, .dark-green .btn-primary:active {
    background-color: #449d48 !important;
    border-color: #449d48 !important;
    color: #fff !important; }

.dark-green .bg-primary.lighten-4 {
  background-color: #C8E6C9 !important; }

.dark-green .bg-primary.lighten-3 {
  background-color: #A5D6A7 !important; }

.dark-green .bg-primary.lighten-2 {
  background-color: #81C784 !important; }

.dark-green .bg-primary.lighten-1 {
  background-color: #66BB6A !important; }

.dark-green .bg-primary.darken-1 {
  background-color: #43A047 !important; }

.dark-green .bg-primary.darken-2 {
  background-color: #388E3C !important; }

.dark-green .bg-primary.darken-3 {
  background-color: #2E7D32 !important; }

.dark-green .bg-primary.darken-4 {
  background-color: #1B5E20 !important; }

.dark-green .bg-primary.accent-1 {
  background-color: #B9F6CA !important; }

.dark-green .bg-primary.accent-2 {
  background-color: #69F0AE !important; }

.dark-green .bg-primary.accent-3 {
  background-color: #00E676 !important; }

.dark-green .bg-primary.accent-4 {
  background-color: #00C853 !important; }

.dark-green a.text-secondary {
  color: #FF6D00; }
  .dark-green a.text-secondary:focus, .dark-green a.text-secondary:hover {
    color: #cc5700 !important; }

.dark-green .text-secondary {
  color: #FF6D00 !important; }

.dark-green .bg-secondary,
.dark-green .badge-secondary {
  background-color: #FF6D00 !important;
  color: #fff !important; }

.dark-green .btn-secondary {
  background-color: #FF6D00;
  border-color: #FF6D00;
  color: #fff; }
  .dark-green .btn-secondary:hover, .dark-green .btn-secondary:focus, .dark-green .btn-secondary:active {
    background-color: #e66200 !important;
    border-color: #e66200 !important;
    color: #fff !important; }

.dark-green .app-main-header {
  background-color: #4CAF50 !important; }
  .dark-green .app-main-header .search-bar input {
    background-color: #5cb860; }
    .dark-green .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-green .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-green .color-theme-header {
  background-color: #4CAF50; }

.dark-green .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-green .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-green .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-green .side-nav .user-detail .user-name {
    color: #fff; }

.dark-green ul.nav-menu li a {
  color: #a1a1a1; }

.dark-green ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-green ul.nav-menu > li.open > a,
.dark-green ul.nav-menu > li > a:hover,
.dark-green ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-green ul.nav-menu li.menu.open > a {
  border-color: #4CAF50; }

.dark-green ul.nav-menu li.menu > a:focus:before,
.dark-green ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-green ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-green ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-green ul.nav-menu li.menu.open > a:before,
.dark-green ul.nav-menu li.menu > a:focus:before,
.dark-green ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-green ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-green ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff; }

.dark-green ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-green ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-green ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.dark-green ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-green ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.dark-green .module-nav li a.active {
  border-color: #4CAF50; }

.dark-green .rbc-event {
  background-color: #4CAF50; }

.dark-green .rbc-event.rbc-selected {
  background-color: #3d8b40; }

.dark-green .rbc-slot-selection {
  background-color: rgba(76, 175, 80, 0.7); }

.dark-green .rbc-toolbar button:active,
.dark-green .rbc-toolbar button.rbc-active {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-toolbar button:active:hover,
.dark-green .rbc-toolbar button.rbc-active:hover,
.dark-green .rbc-toolbar button:active:focus,
.dark-green .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-toolbar button:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-toolbar button:hover {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .chat-sidenav-title {
  color: #4CAF50; }

.dark-green .chat-user-item.active, .dark-green .chat-user-item:hover {
  background-color: #eaf6eb; }

.dark-green .contact-list i {
  color: #4CAF50; }

.dark-green .Collapsible__trigger {
  background: #4CAF50; }

.dark-green .categories-list li a:focus,
.dark-green .categories-list li a:hover,
.dark-green .categories-list li.active a {
  color: #4CAF50; }

.dark-green .login-content .form-control:focus {
  border-color: #4CAF50; }

.dark-green .filter-with-bg-color ul li a {
  border-color: #4CAF50; }
  .dark-green .filter-with-bg-color ul li a:hover, .dark-green .filter-with-bg-color ul li a:focus, .dark-green .filter-with-bg-color ul li a.active {
    background-color: #4CAF50; }

.dark-green .profile-intro .icon {
  color: #4CAF50; }

.dark-green .social-link li.active a,
.dark-green .social-link li a:hover,
.dark-green .social-link li a:focus {
  color: #FF6D00; }

.dark-green .actions {
  color: #FF6D00; }

.dark-green .table-hover tbody tr:hover {
  background-color: rgba(76, 175, 80, 0.075); }

.dark-green .border-primary {
  border-color: #4CAF50 !important; }

.dark-green .app-logo-content {
  background-color: #4CAF50; }

.dark-green .app-social-block .social-link button {
  border: solid 1px #4CAF50;
  color: #4CAF50; }
  .dark-green .app-social-block .social-link button:hover, .dark-green .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #4CAF50; }

/*Theme Dark Amber Styles*/
body.dark-theme {
  color: #E0E0E0;
  background-color: #38424B; }

.dark-theme .card,
.dark-theme .jr-card,
.dark-theme .page-heading {
  background-color: #434f5a; }

.dark-theme .sub-heading,
.dark-theme .meta-date,
.dark-theme .text-muted,
.dark-theme .meta-comment,
.dark-theme .user-detail .user-description {
  color: #adadad; }

.dark-theme .right-arrow {
  color: lightgray; }
  .dark-theme .right-arrow:after {
    color: lightgray; }

.dark-theme a,
.dark-theme a.text-primary {
  color: lightgray; }
  .dark-theme a:focus, .dark-theme a:hover,
  .dark-theme a.text-primary:focus,
  .dark-theme a.text-primary:hover {
    color: #c7c7c7; }

.dark-theme a.badge-light {
  color: #616161; }
  .dark-theme a.badge-light:focus, .dark-theme a.badge-light:hover {
    color: #474747 !important; }

.dark-theme .text-primary {
  color: lightgray !important; }

.dark-theme .page-link {
  color: #596977; }
  .dark-theme .page-link:focus, .dark-theme .page-link:hover {
    color: #4e5c68; }

.dark-theme .bg-primary,
.dark-theme .badge-primary {
  background-color: #2d353c !important;
  color: #E0E0E0 !important; }

.dark-theme .btn-primary {
  border-color: #38424B;
  background-color: #2d353c !important;
  color: #E0E0E0 !important; }
  .dark-theme .btn-primary:hover, .dark-theme .btn-primary:focus, .dark-theme .btn-primary:active {
    background-color: #2d353c !important;
    border-color: #2d353c !important;
    color: lightgray !important; }

.dark-theme a.text-secondary {
  color: #4e5c68; }
  .dark-theme a.text-secondary:focus, .dark-theme a.text-secondary:hover {
    color: #38424b !important; }

.dark-theme .text-secondary {
  color: #4e5c68 !important; }

.dark-theme .bg-secondary {
  background-color: #4e5c68 !important;
  color: lightgray !important; }

.dark-theme .badge-secondary {
  background-color: #38424b !important;
  color: lightgray !important; }

.dark-theme .btn-secondary {
  background-color: #4e5c68;
  border-color: #4e5c68;
  color: lightgray; }
  .dark-theme .btn-secondary:hover, .dark-theme .btn-secondary:focus, .dark-theme .btn-secondary:active {
    background-color: #434f5a !important;
    border-color: #434f5a !important;
    color: lightgray !important; }

.dark-theme .app-main-header {
  background-color: #38424B !important; }
  .dark-theme .app-main-header .search-bar input {
    background-color: #434f5a; }
    .dark-theme .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-theme .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-theme .app-footer {
  padding: 0 24px;
  background-color: #38424B;
  color: #E0E0E0; }
  .dark-theme .app-footer a.text-secondary {
    color: #E0E0E0 !important; }
    .dark-theme .app-footer a.text-secondary:hover {
      color: #c7c7c7 !important; }

.dark-theme .color-theme-header {
  background-color: #38424B; }

.dark-theme .side-nav {
  background-color: #2d353c !important;
  color: #E0E0E0 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-theme .side-nav .user-profile {
    background-color: #343d45;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-theme .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-theme .side-nav .user-detail .user-name {
    color: lightgray; }

.dark-theme ul.nav-menu li a {
  color: #E0E0E0; }

.dark-theme ul.nav-menu .nav-header {
  color: #E0E0E0; }

.dark-theme ul.nav-menu > li.open > a,
.dark-theme ul.nav-menu > li > a:hover,
.dark-theme ul.nav-menu > li > a:focus {
  background-color: #343d45;
  color: lightgray; }

.dark-theme ul.nav-menu li.menu.open > a {
  border-color: lightgray; }

.dark-theme ul.nav-menu li.menu > a:focus:before,
.dark-theme ul.nav-menu li.menu > a:hover:before {
  color: lightgray; }

.dark-theme ul.nav-menu li ul {
  background-color: #343d45; }

.dark-theme ul.nav-menu li.menu > a:before {
  color: #E0E0E0; }

.dark-theme ul.nav-menu li.menu.open > a:before,
.dark-theme ul.nav-menu li.menu > a:focus:before,
.dark-theme ul.nav-menu li.menu li > a:hover:before {
  color: lightgray; }

.dark-theme ul.nav-menu li.menu .sub-menu li > a:before {
  color: #E0E0E0; }

.dark-theme ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #2d353c;
  color: #fff; }

.dark-theme ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-theme ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #2d353c;
  color: #fff; }

.dark-theme .chat-sidenav-header,
.dark-theme .chat-tabs {
  background-color: #22282e; }

.dark-theme .module-nav li a.active {
  border-color: #22282e; }

.dark-theme .rbc-event {
  background-color: #7e8f9e; }

.dark-theme .rbc-event.rbc-selected {
  background-color: #22282e; }

.dark-theme .rbc-slot-selection {
  background-color: rgba(56, 66, 75, 0.7); }

.dark-theme .rbc-toolbar button:active,
.dark-theme .rbc-toolbar button.rbc-active {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: white; }

.dark-theme .rbc-toolbar button:active:hover,
.dark-theme .rbc-toolbar button.rbc-active:hover,
.dark-theme .rbc-toolbar button:active:focus,
.dark-theme .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: white; }

.dark-theme .rbc-toolbar button:hover,
.dark-theme .rbc-toolbar button:focus {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: white; }

.dark-theme .rbc-toolbar button {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: #9ba8b4; }

.dark-theme .rbc-show-more {
  background-color: #7e8f9e; }

.dark-theme .rbc-off-range-bg {
  background-color: #4e5c68; }

.dark-theme .chat-sidenav-title {
  color: #38424B; }

.dark-theme .chat-user-item.active, .dark-theme .chat-user-item:hover {
  background-color: #a9b5bf; }

.dark-theme .contact-list i {
  color: #38424B; }

.dark-theme .Collapsible__trigger {
  background: #38424B; }

.dark-theme .categories-list li a:focus,
.dark-theme .categories-list li a:hover,
.dark-theme .categories-list li.active a {
  color: #a0a0a0; }

.dark-theme .login-content .form-control:focus {
  border-color: #38424B; }

.dark-theme .filter-with-bg-color ul li a {
  border-color: #38424B; }
  .dark-theme .filter-with-bg-color ul li a:hover, .dark-theme .filter-with-bg-color ul li a:focus, .dark-theme .filter-with-bg-color ul li a.active {
    background-color: #38424B; }

.dark-theme .profile-intro .icon {
  color: #38424B; }

.dark-theme .social-link li.active a,
.dark-theme .social-link li a:hover,
.dark-theme .social-link li a:focus {
  color: lightgray; }

.dark-theme .actions {
  color: lightgray; }

.dark-theme .table-hover tbody tr:hover {
  background-color: rgba(56, 66, 75, 0.075); }

.dark-theme .border-primary {
  border-color: lightgray !important; }

.dark-theme .breadcrumb {
  background-color: #38424B; }

.dark-theme .desc-list.even-odd li:nth-child(2n) {
  background-color: #38424B; }

.dark-theme .timeline-panel {
  background-color: #4e5c68; }
  .dark-theme .timeline-panel:after {
    border-color: transparent #4e5c68; }
  .dark-theme .timeline-panel:before {
    border-color: transparent #38424B; }

.dark-theme .login-content {
  background-color: #434f5a; }

.dark-theme .chat-module-box,
.dark-theme .chat-sidenav-content,
.dark-theme .chat-main-header,
.dark-theme .chat-main-footer,
.dark-theme .chat-textarea,
.dark-theme .module-detail-header,
.dark-theme .module-box-content,
.dark-theme .modal-box-footer {
  background-color: #38424B; }

.dark-theme .modal-box-content {
  background-color: #434f5a; }

.dark-theme .chat-user-item.active, .dark-theme .chat-user-item:hover {
  background-color: #22282e; }

.dark-theme .chat-textarea {
  color: #fff; }

.dark-theme .chat-item {
  padding: 16px; }
  .dark-theme .chat-item .bubble {
    background-color: #4e5c68; }
    .dark-theme .chat-item .bubble:before {
      border-right: 9px solid #4e5c68; }
    .dark-theme .chat-item .bubble:after {
      border-right: 8px solid #4e5c68; }
  .dark-theme .chat-item.flex-row-reverse .bubble {
    margin-right: 16px;
    margin-left: 0;
    background-color: #22282e; }
    .dark-theme .chat-item.flex-row-reverse .bubble:before {
      left: auto;
      right: -9px;
      border-left: 9px solid #22282e;
      border-right: 0 none; }
    .dark-theme .chat-item.flex-row-reverse .bubble:after {
      left: auto;
      right: -8px;
      border-left: 8px solid #22282e;
      border-right: 0 none; }

.dark-theme .module-nav li a {
  color: #adb5bd; }
  .dark-theme .module-nav li a:hover, .dark-theme .module-nav li a:focus {
    color: #343a40; }
  .dark-theme .module-nav li a.active {
    color: #343a40; }

.dark-theme .app-logo-content {
  background-color: #38424B; }

.dark-theme .app-social-block .social-link button {
  border: solid 1px #38424B;
  color: #38424B; }
  .dark-theme .app-social-block .social-link button:hover, .dark-theme .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #38424B; }

/*Bootstrap Styles*/
.rtl .list-unstyled,
.rtl .list-inline,
.rtl .contact-info,
.rtl .color-option,
.rtl .module-nav,
.rtl .contact-info,
.rtl .social-link {
  padding-right: 0; }

.rtl .zmdi-hc-ul {
  margin-left: 0;
  margin-right: 2.14285714em; }

.rtl .zmdi-hc-li {
  left: auto;
  right: -2.14285714em; }

.rtl .mr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.rtl .ml-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.rtl .mr-1 {
  margin-left: 0.25rem !important;
  margin-right: 0 !important; }

.rtl .ml-1 {
  margin-right: 0.25rem !important;
  margin-left: 0 !important; }

.rtl .mr-2 {
  margin-left: 0.5rem !important;
  margin-right: 0 !important; }

.rtl .ml-2 {
  margin-right: 0.5rem !important;
  margin-left: 0 !important; }

.rtl .mr-3 {
  margin-left: 1rem !important;
  margin-right: 0 !important; }

.rtl .ml-3 {
  margin-right: 1rem !important;
  margin-left: 0 !important; }

.rtl .mr-4 {
  margin-left: 1.5rem !important;
  margin-right: 0 !important; }

.rtl .ml-4 {
  margin-right: 1.5rem !important;
  margin-left: 0 !important; }

.rtl .mr-5 {
  margin-left: 3rem !important;
  margin-right: 0 !important; }

.rtl .ml-5 {
  margin-right: 3rem !important;
  margin-left: 0 !important; }

.rtl .pr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.rtl .pl-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.rtl .pr-1 {
  padding-left: 0.25rem !important;
  padding-right: 0 !important; }

.rtl .pl-1 {
  padding-right: 0.25rem !important;
  padding-left: 0 !important; }

.rtl .pr-2 {
  padding-left: 0.5rem !important;
  padding-right: 0 !important; }

.rtl .pl-2 {
  padding-right: 0.5rem !important;
  padding-left: 0 !important; }

.rtl .pr-3 {
  padding-left: 1rem !important;
  padding-right: 0 !important; }

.rtl .pl-3 {
  padding-right: 1rem !important;
  padding-left: 0 !important; }

.rtl .pr-4 {
  padding-left: 1.5rem !important;
  padding-right: 0 !important; }

.rtl .pl-4 {
  padding-right: 1.5rem !important;
  padding-left: 0 !important; }

.rtl .pr-5 {
  padding-left: 3rem !important;
  padding-right: 0 !important; }

.rtl .pl-5 {
  padding-right: 3rem !important;
  padding-left: 0 !important; }

.rtl .mr-auto {
  margin-left: auto !important;
  margin-right: 0 !important; }

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important; }

@media (min-width: 576px) {
  .rtl .mr-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important; }
  .rtl .pr-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important; }
  .rtl .mr-sm-auto {
    margin-left: auto !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .rtl .mr-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .ml-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .mr-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important; }
  .rtl .mr-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-md-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important; }
  .rtl .mr-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-md-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important; }
  .rtl .pr-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pl-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pr-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important; }
  .rtl .pr-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-md-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important; }
  .rtl .pr-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-md-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important; }
  .rtl .mr-md-auto {
    margin-left: auto !important;
    margin-right: 0 !important; }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .rtl .mr-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important; }
  .rtl .pr-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important; }
  .rtl .mr-lg-auto {
    margin-left: auto !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

@media (min-width: 1200px) {
  .rtl .mr-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important; }
  .rtl .pr-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important; }
  .rtl .mr-xl-auto {
    margin-left: auto !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

.rtl .text-left {
  text-align: right !important; }

.rtl .text-right {
  text-align: left !important; }

@media (min-width: 576px) {
  .rtl .text-sm-left {
    text-align: right !important; }
  .rtl .text-sm-right {
    text-align: left !important; } }

@media (min-width: 768px) {
  .rtl .text-md-left {
    text-align: right !important; }
  .rtl .text-md-right {
    text-align: left !important; } }

@media (min-width: 992px) {
  .rtl .text-lg-left {
    text-align: right !important; }
  .rtl .text-lg-right {
    text-align: left !important; } }

@media (min-width: 1200px) {
  .rtl .text-xl-left {
    text-align: right !important; }
  .rtl .text-xl-right {
    text-align: left !important; } }

.rtl .jr-menu-icon .menu-icon:after, .rtl .jr-menu-icon .menu-icon:before {
  left: auto;
  right: 0; }

/*Sidebar Styles*/
.rtl .side-nav {
  border-left: 0 none !important; }
  .rtl .side-nav .user-avatar {
    margin: 0 0 0 15px !important; }

.rtl ul.nav-menu li.menu > a {
  border-left: 0 none;
  border-right: 2px solid transparent; }

.rtl ul.nav-menu li.menu > a:before {
  float: left;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: 10px; }

.rtl ul.nav-menu li.menu.open > a:before,
.rtl ul.nav-menu li.menu.active > a:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.rtl ul.nav-menu li i {
  margin-left: 16px;
  margin-right: 0; }

.rtl ul.nav-menu li ul > li > a {
  padding-left: 20px;
  padding-right: 56px; }

.rtl ul.nav-menu li ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 76px; }

.rtl ul.nav-menu li ul > li > ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 96px; }

.rtl ul.nav-menu li ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 116px; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu li {
  text-align: right; }
  .rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu li i {
    margin-left: 16px;
    margin-right: 0; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu li a {
  padding-left: 20px;
  padding-right: 56px; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > a {
  padding-left: 20px;
  padding-right: 76px; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 96px; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 116px; }

/*Right Sidebar Styles*/
.rtl .theme-option {
  right: auto;
  left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.rtl .cr-op-dark-sidebar li a:after {
  left: auto;
  right: 0; }

/*App Layout Styles*/
.rtl .app-container.fixed-drawer .app-main-header {
  left: 0;
  right: 280px; }
  @media screen and (max-width: 1199px) {
    .rtl .app-container.fixed-drawer .app-main-header {
      left: 0;
      right: 0; } }

.rtl .app-container.mini-drawer .app-main-header {
  left: 0;
  right: 80px; }

/*Header Styles*/
.rtl .jr-menu-icon {
  margin-right: 0;
  margin-left: 15px; }

.rtl .app-toolbar .search-bar {
  margin-left: auto;
  margin-right: 100px; }
  @media screen and (max-width: 767px) {
    .rtl .app-toolbar .search-bar {
      margin-left: auto;
      margin-right: 40px; } }

.rtl .app-toolbar .nav-searchbox .search-bar {
  margin-left: auto;
  margin-right: 0; }

.rtl .quick-menu .dropdown-menu .jr-card-header {
  padding: 10px 20px 10px 10px; }

.rtl .quick-menu .dropdown-menu:after {
  right: auto;
  left: 8px; }

.rtl .quick-menu .dropdown-menu:before {
  right: auto;
  left: 8px; }

.rtl .quick-menu .messages-list .badge {
  left: auto;
  right: -4px; }

.rtl .messages-list ul li .jr-btn {
  margin-right: auto !important;
  margin-left: 0 !important; }

.rtl .quick-menu .icon-alert:after {
  right: auto;
  left: 0; }

.rtl .quick-menu .dropdown-menu {
  right: auto !important;
  left: 0 !important; }

/*Dashboard Styles*/
.rtl .net-chart {
  padding-left: 8px;
  padding-right: 0; }
  .rtl .net-chart .jr-card-thumb {
    margin: 0 0 0 20px; }
  .rtl .net-chart:hover .jr-card-thumb {
    margin-right: 0;
    margin-left: 5px; }
  .rtl .net-chart .jr-card-thumb img {
    margin-left: 0;
    margin-right: 15px; }
  .rtl .net-chart:hover .jr-card-thumb img {
    margin: -18px 0 -18px 15px; }

.rtl .net-chart-hr {
  padding-right: 24px;
  padding-left: 8px; }
  .rtl .net-chart-hr .jr-card-thumb {
    margin: -30px -24px -30px 12px; }

.rtl .jr-card-header-top {
  margin-right: 0;
  margin-left: -16px; }

.rtl .social-list-line:not(:last-child):before {
  border-left: 0 none;
  border-right: solid 1px #adb5bd;
  left: 0;
  right: 20px; }

.rtl .list-line-item {
  padding-left: 0;
  padding-right: 40px; }
  .rtl .list-line-item:not(:last-child):before {
    border-left: 0 none;
    border-right: solid 1px #adb5bd;
    left: 0;
    right: 20px; }

.rtl .list-line-badge {
  left: 0;
  right: 16px; }

.rtl .box .box-top .size-h5,
.rtl .box .box-bottom .size-h5 {
  margin-left: 0;
  margin-right: 2px; }

.rtl .contact-list i {
  margin-right: 0;
  margin-left: 14px; }

.rtl .status-cell {
  padding-right: 0 !important;
  padding-left: 24px !important; }

.rtl .zmdi-chevron-right::before,
.rtl .breadcrumb-item + .breadcrumb-item::before {
  content: '\f2fa'; }

/*Chart Styles*/
.rtl .chartWrapper,
.rtl .chartAreaWrapper,
.rtl .recharts-responsive-container {
  direction: rtl; }

/*Base Styles*/
.rtl .manage-margin > *:not(:last-child) {
  margin-right: 0;
  margin-left: 16px; }

.rtl .col-with-divider div[class*="col-"] {
  border-left: 0 none;
  border-right: solid 1px #dee2e6; }

.rtl .right-arrow:after {
  margin-left: 0;
  margin-right: 8px; }

/*Button Group Styles*/
.rtl .btn-group > .jr-btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rtl .btn-group > .jr-btn:last-child:not(:first-child),
.rtl .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

/* App Module Style */
.rtl .app-module {
  margin-left: 0;
  margin-right: -24px; }
  .rtl .app-module:before {
    right: 0;
    left: -24px; }

.rtl .module-side-header {
  padding: 24px 29px 24px 10px; }

.rtl .module-nav li i {
  margin-right: 0;
  margin-left: 16px; }

.rtl .module-box-header .search-bar.right-side-icon .form-control {
  padding: 18px 70px 18px 18px; }

.rtl .module-box-header .search-bar.right-side-icon .search-icon {
  left: auto;
  right: 9px; }

.rtl .toolbar-separator {
  border-left: 0 none;
  border-right: solid 1px #ced4da; }

.rtl .module-list-item.mail-cell {
  padding-left: 0;
  padding-right: 12px; }

.rtl .module-list-actions {
  text-align: left; }

.rtl .chat-todo-avatar {
  margin-right: 0;
  margin-left: 15px; }

/*Chat Module Styles*/
.rtl .chat-module {
  margin-left: 0;
  margin-right: 0; }
  .rtl .chat-module:before {
    left: auto;
    right: -29px; }

.rtl .chat-sidenav {
  border-right: 0 none;
  border-left: solid 1px #ced4da; }

.rtl .search-wrapper .search-bar.right-side-icon .form-control {
  padding-left: 0;
  padding-right: 32px; }

.rtl .search-wrapper .search-bar.right-side-icon .search-icon {
  left: auto;
  right: 0; }

.rtl .chat-avatar-mode .chat-mode {
  right: auto;
  left: 0; }

.rtl .chat-date {
  text-align: left; }

.rtl .chat-item .bubble {
  padding: 6px 9px 8px 7px;
  margin-left: 0;
  margin-right: 16px; }
  .rtl .chat-item .bubble:before {
    left: auto;
    right: -9px;
    border-right: 0 none;
    border-left: 9px solid #dee2e6; }
  .rtl .chat-item .bubble:after {
    left: auto;
    right: -8px;
    border-right: 0 none;
    border-left: 8px solid #fff; }

.rtl .chat-item.flex-row-reverse .bubble {
  margin-right: 0;
  margin-left: 16px; }
  .rtl .chat-item.flex-row-reverse .bubble:before {
    left: -9px;
    right: auto;
    border-left: 0 none;
    border-right: 9px solid #dee2e6; }
  .rtl .chat-item.flex-row-reverse .bubble:after {
    left: -8px;
    right: auto;
    border-right: 8px solid #eaf6eb;
    border-left: 0 none; }

.rtl .chat-btn {
  margin: 0 -8px 0 10px; }

/*Contact Module Styles*/
.rtl .modal-box-header h2 button {
  right: auto;
  left: 0; }

.rtl .btn-fixed {
  right: auto;
  left: 40px; }

/*Mails Module Styles*/
.rtl .mail-user-info .avatar {
  margin-right: 0;
  margin-left: 10px; }

.rtl .sender-name {
  margin-right: 0;
  margin-left: 8px; }

.rtl .mail-header-actions {
  text-align: left; }
  .rtl .mail-header-actions .btn:not(:last-child) {
    margin-right: 0;
    margin-left: 3px; }

/*Contact Page Styles*/
.rtl .contact-block .contact-info li {
  border-right: 0 none;
  border-left: 2px solid #212529; }
  .rtl .contact-block .contact-info li > i {
    float: right;
    margin-right: 0;
    margin-left: 5px; }
  .rtl .contact-block .contact-info li .contact-body .icon {
    margin-right: 0;
    margin-left: 6px; }
    .rtl .contact-block .contact-info li .contact-body .icon:last-child {
      margin-left: 0; }

.rtl .contact-block .contact-info.vertical li {
  border-left: 0 none; }

/*Faqs Styles*/
.rtl .Collapsible__trigger:after {
  right: auto;
  left: 24px; }

.rtl .meta-date + .meta-comment {
  margin-left: 0;
  margin-right: 10px; }

.rtl .search-bar input[type="search"] {
  padding-right: 15px;
  padding-left: 50px; }

.rtl .search-bar .form-control.form-control-lg + .search-icon {
  right: auto;
  left: 14px; }

.rtl .search-bar .search-icon {
  right: auto;
  left: 14px; }

.rtl .search-bar.right-side-icon input[type="search"] {
  padding-right: 50px;
  padding-left: 16px; }

.rtl .search-bar.right-side-icon .search-icon {
  left: auto;
  right: 14px; }

.rtl .search-bar.right-side-icon .form-control.form-control-lg + .search-icon {
  left: auto;
  right: 14px; }

.rtl .meta-date i,
.rtl .meta-comment i {
  margin-right: 0;
  margin-left: 6px; }

/*Alert Styles*/
.rtl .alert .alert-addon {
  left: auto;
  right: 0; }

.rtl .alert-dismissible {
  padding-left: 60px; }

.rtl .alert-addon-card {
  padding-right: 80px; }

/*Avatar Styles*/
.rtl .user-avatar {
  margin-right: 0;
  margin-left: 8px; }

/*Badges Styles*/
.rtl .badge:not(:last-child) {
  margin-right: 0;
  margin-left: 15px; }

/*Blockquotes Styles*/
.rtl blockquote {
  padding: 10px 20px 10px 0; }

.rtl .blockquote {
  border-left: 0 none;
  border-right: 5px solid #e9ecef; }

.rtl .blockquote-reverse {
  border-right: 0 none;
  border-left: 5px solid #e9ecef;
  text-align: left;
  padding-left: 20px;
  padding-right: 0; }

/*Button Styles*/
.rtl .jr-btn:not(:last-child),
.rtl .jr-fab-btn:not(:last-child),
.rtl .btn:not(:last-child) {
  margin-right: 0;
  margin-left: 15px; }

.rtl .jr-btn i + span,
.rtl .jr-btn span + i {
  margin-left: 0;
  margin-right: 6px; }

.rtl .complex-btn .img-marked {
  left: auto;
  right: calc(50% - 9px); }

.rtl .jr-btn.jr-btn-label.left i {
  left: auto;
  right: 0; }

.rtl .jr-btn.jr-btn-label.right i {
  right: auto;
  left: 0; }

.rtl .jr-btn.jr-btn-label.left i + span {
  margin-left: 0;
  margin-right: 30px; }

.rtl .jr-btn.jr-btn-label.right i + span {
  margin-right: 0;
  margin-left: 30px; }

/*Callout Styles*/
.rtl .product-row {
  text-align: left; }
  .rtl .product-row.even {
    text-align: right; }

/*Cards Styles*/
.rtl .profile-intro .icon:not(:last-child) {
  margin-right: 0;
  margin-left: 10px; }

.rtl .jr-card-header .badge:last-child {
  margin-left: 0;
  margin-right: 10px; }

/*Icons Styles*/
.rtl .icons-wrapper + .icons-wrapper {
  margin-left: 0;
  margin-right: 25px; }

/*List Styles*/
.rtl .user-list .user-avatar {
  margin-right: 0;
  margin-left: 20px; }

.rtl .jr-mbtn-list {
  margin-left: 0;
  margin-right: -20px; }

/*Pricing Styles*/
.rtl .pt-classic .package-header > .price > i,
.rtl .pt-default .package-header > .price > i,
.rtl .pt-dark .package-header > .price > i,
.rtl .pt-circle .package-header > .price > i {
  margin-right: 0;
  margin-left: 6px; }

.rtl .pt-classic .package-items li i,
.rtl .pt-default .package-items li i,
.rtl .pt-dark .package-items li i,
.rtl .pt-circle .package-items li i {
  float: right;
  margin-right: 0;
  margin-left: 16px; }

/*Progressbar Styles*/
.rtl .fab-progress {
  left: auto;
  right: -6px; }

.rtl .btn-progress {
  margin-left: 0;
  margin-right: -12px; }

/*Tables Styles*/
.rtl .table-header {
  padding-right: 24px !important;
  padding-left: 2px !important; }

/*Testimonial Styles*/
.rtl .testimonial-in-bg {
  padding: 30px 150px 30px 30px; }
  @media screen and (max-width: 450px) {
    .rtl .testimonial-in-bg {
      padding-left: 30px;
      padding-right: 100px; } }
  .rtl .testimonial-in-bg .pic {
    left: auto;
    right: 20px; }
  .rtl .testimonial-in-bg .description:before {
    left: auto;
    right: -24px; }

/*Time Lines Style*/
.rtl .timeline-left {
  float: right;
  margin-right: 0;
  margin-left: 20px; }

.rtl .timeline-right {
  float: left;
  margin-left: 0;
  margin-right: 20px; }

.rtl .timeline-item {
  padding-left: 0;
  padding-right: 80px; }
  .rtl .timeline-item:before {
    border-left: 0 none;
    border-right: solid 2px #dee2e6;
    left: auto;
    right: 25px; }
  .rtl .timeline-item:first-child:before {
    border-right-style: dashed; }
  .rtl .timeline-item:last-child:before {
    border-right-style: dashed; }

.rtl .timeline-badge {
  left: auto;
  right: 0; }

.rtl .timeline-panel:before {
  border-width: 15px 0 15px 15px;
  left: auto;
  right: -15px; }

.rtl .timeline-panel:after {
  border-width: 14px 0 14px 14px;
  left: auto;
  right: -14px; }

.rtl .timeline-inverted .timeline-left {
  float: left;
  margin-right: 20px;
  margin-left: 0; }

.rtl .timeline-center .timeline-item {
  float: left;
  padding-left: 0;
  padding-right: 55px; }
  .rtl .timeline-center .timeline-item:before {
    left: auto;
    right: 0; }

.rtl .timeline-center .timeline-badge {
  left: auto;
  right: -25px; }

.rtl .timeline-center .timeline-time {
  left: auto;
  right: -150px; }

.rtl .timeline-center .timeline-inverted {
  float: right;
  text-align: left;
  padding-left: 55px;
  padding-right: 0; }
  .rtl .timeline-center .timeline-inverted:before {
    border-left: solid 2px #dee2e6;
    border-right: 0 none;
    left: -2px;
    right: auto; }
  .rtl .timeline-center .timeline-inverted .timeline-badge {
    left: -25px;
    right: auto; }
  .rtl .timeline-center .timeline-inverted .timeline-panel:before {
    border-width: 15px 15px 15px 0;
    left: -15px;
    right: auto; }
  .rtl .timeline-center .timeline-inverted .timeline-panel:after {
    border-width: 14px 14px 14px 0;
    left: -14px;
    right: auto; }
  .rtl .timeline-center .timeline-inverted .timeline-time {
    right: auto;
    left: -150px; }

.rtl .timeline-zigzag .timeline-item {
  padding-left: 0;
  padding-right: 150px; }

.rtl .timeline-zigzag .timeline-img {
  left: auto;
  right: 5px; }

.rtl .timeline-zigzag .timeline-inverted .timeline-img {
  right: auto !important;
  left: 5px !important; }

.rtl .timeline-zigzag .timeline-inverted {
  padding-left: 150px;
  padding-right: 0; }

.rtl .timeline-zigzag .timeline-item:first-child:before {
  border-right-style: solid; }

/* Responsive Work */
@media screen and (max-width: 1199px) {
  .rtl .app-module {
    margin-right: 0; }
    .rtl .app-module:before {
      right: -24px; }
  .rtl .module-box-header .search-bar.right-side-icon .form-control {
    padding-left: 0;
    padding-right: 100px; }
  .rtl .module-box-header .search-bar.right-side-icon .search-icon {
    left: auto;
    right: 48px; }
  .rtl .module-box-header .drawer-btn {
    left: auto;
    right: 0; } }

@media screen and (max-width: 991px) {
  .rtl .module-list-item.mail-cell {
    padding-right: 0;
    padding-left: 20px; }
  .rtl .timeline-zigzag .timeline-item {
    padding-left: 0;
    padding-right: 120px; }
  .rtl .timeline-zigzag .timeline-inverted {
    padding-right: 0;
    padding-left: 120px; } }

@media screen and (max-width: 767px) {
  .rtl .contact-block .contact-info li {
    border-left: 0 none; }
  .rtl .timeline-center .timeline-item {
    padding-left: 0;
    padding-right: 80px; }
    .rtl .timeline-center .timeline-item:before {
      left: auto;
      right: 25px; }
  .rtl .timeline-center .timeline-badge {
    left: auto;
    right: 0; }
  .rtl .timeline-center .timeline-inverted {
    text-align: right;
    padding-left: 0; }
    .rtl .timeline-center .timeline-inverted:before {
      border-left: 0 none;
      border-right: solid 2px #dee2e6;
      left: auto;
      right: 24px; }
    .rtl .timeline-center .timeline-inverted .timeline-badge {
      right: 0;
      left: auto; }
    .rtl .timeline-center .timeline-inverted .timeline-panel:before {
      border-width: 15px 0 15px 15px;
      left: auto;
      right: -15px; }
    .rtl .timeline-center .timeline-inverted .timeline-panel:after {
      border-width: 14px 0 14px 14px;
      left: auto;
      right: -14px; }
  .rtl .timeline-inverted .timeline-left {
    float: right;
    margin-left: 20px;
    margin-right: 0; }
  .rtl .timeline-zigzag .timeline-item {
    padding-left: 0;
    padding-right: 100px; }
    .rtl .timeline-zigzag .timeline-item:before {
      left: auto;
      right: 45px; }
  .rtl .product-row {
    text-align: center; }
    .rtl .product-row.even {
      text-align: center; }
  .rtl .timeline-center .timeline-time {
    right: 0; } }

@media screen and (max-width: 575px) {
  .rtl .jr-menu-icon {
    margin-left: 0; }
  .rtl .app-main-header .list-inline-item:not(:last-child) {
    margin-right: 0; } }

@media screen and (max-width: 499px) {
  .rtl .mail-header-actions {
    text-align: right; } }

@media screen and (max-width: 399px) {
  .rtl .timeline-left {
    margin-left: 10px; }
  .rtl .timeline-right {
    margin-right: 0; }
  .rtl .timeline-inverted .timeline-left {
    margin-left: 0; } }

@font-face {
  font-family: Material-Design-Iconic-Font;
  src: url(/static/media/Material-Design-Iconic-Font.a4d31128.woff2) format("woff2"), url(/static/media/Material-Design-Iconic-Font.d2a55d33.woff) format("woff"), url(/static/media/Material-Design-Iconic-Font.b351bd62.ttf) format("truetype"); }

.zmdi {
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.zmdi-hc-lg {
  font-size: 1.33333333em;
  line-height: .75em;
  vertical-align: -15%; }

.zmdi-hc-2x {
  font-size: 2em; }

.zmdi-hc-3x {
  font-size: 3em; }

.zmdi-hc-4x {
  font-size: 4em; }

.zmdi-hc-5x {
  font-size: 5em; }

.zmdi-hc-fw {
  width: 1.28571429em;
  text-align: center; }

.zmdi-hc-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.zmdi-hc-ul > li {
  position: relative; }

.zmdi-hc-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: .14285714em;
  text-align: center; }

.zmdi-hc-li.zmdi-hc-lg {
  left: -1.85714286em; }

.zmdi-hc-border {
  padding: .1em .25em;
  border: solid .1em #9e9e9e;
  border-radius: 2px; }

.zmdi-hc-border-circle {
  padding: .1em .25em;
  border: solid .1em #9e9e9e;
  border-radius: 50%; }

.zmdi.pull-left {
  float: left;
  margin-right: .15em; }

.zmdi.pull-right {
  float: right;
  margin-left: .15em; }

.zmdi-hc-spin {
  -webkit-animation: zmdi-spin 1.5s infinite linear;
  animation: zmdi-spin 1.5s infinite linear; }

.zmdi-hc-spin-reverse {
  -webkit-animation: zmdi-spin-reverse 1.5s infinite linear;
  animation: zmdi-spin-reverse 1.5s infinite linear; }

@-webkit-keyframes zmdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes zmdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-webkit-keyframes zmdi-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg); } }

@keyframes zmdi-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg); } }

.zmdi-hc-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.zmdi-hc-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.zmdi-hc-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.zmdi-hc-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.zmdi-hc-flip-vertical {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1); }

.zmdi-hc-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

.zmdi-hc-stack-1x, .zmdi-hc-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

.zmdi-hc-stack-1x {
  line-height: inherit; }

.zmdi-hc-stack-2x {
  font-size: 2em; }

.zmdi-hc-inverse {
  color: #fff; }

.zmdi-3d-rotation:before {
  content: '\f101'; }

.zmdi-airplane-off:before {
  content: '\f102'; }

.zmdi-airplane:before {
  content: '\f103'; }

.zmdi-album:before {
  content: '\f104'; }

.zmdi-archive:before {
  content: '\f105'; }

.zmdi-assignment-account:before {
  content: '\f106'; }

.zmdi-assignment-alert:before {
  content: '\f107'; }

.zmdi-assignment-check:before {
  content: '\f108'; }

.zmdi-assignment-o:before {
  content: '\f109'; }

.zmdi-assignment-return:before {
  content: '\f10a'; }

.zmdi-assignment-returned:before {
  content: '\f10b'; }

.zmdi-assignment:before {
  content: '\f10c'; }

.zmdi-attachment-alt:before {
  content: '\f10d'; }

.zmdi-attachment:before {
  content: '\f10e'; }

.zmdi-audio:before {
  content: '\f10f'; }

.zmdi-badge-check:before {
  content: '\f110'; }

.zmdi-balance-wallet:before {
  content: '\f111'; }

.zmdi-balance:before {
  content: '\f112'; }

.zmdi-battery-alert:before {
  content: '\f113'; }

.zmdi-battery-flash:before {
  content: '\f114'; }

.zmdi-battery-unknown:before {
  content: '\f115'; }

.zmdi-battery:before {
  content: '\f116'; }

.zmdi-bike:before {
  content: '\f117'; }

.zmdi-block-alt:before {
  content: '\f118'; }

.zmdi-block:before {
  content: '\f119'; }

.zmdi-boat:before {
  content: '\f11a'; }

.zmdi-book-image:before {
  content: '\f11b'; }

.zmdi-book:before {
  content: '\f11c'; }

.zmdi-bookmark-outline:before {
  content: '\f11d'; }

.zmdi-bookmark:before {
  content: '\f11e'; }

.zmdi-brush:before {
  content: '\f11f'; }

.zmdi-bug:before {
  content: '\f120'; }

.zmdi-bus:before {
  content: '\f121'; }

.zmdi-cake:before {
  content: '\f122'; }

.zmdi-car-taxi:before {
  content: '\f123'; }

.zmdi-car-wash:before {
  content: '\f124'; }

.zmdi-car:before {
  content: '\f125'; }

.zmdi-card-giftcard:before {
  content: '\f126'; }

.zmdi-card-membership:before {
  content: '\f127'; }

.zmdi-card-travel:before {
  content: '\f128'; }

.zmdi-card:before {
  content: '\f129'; }

.zmdi-case-check:before {
  content: '\f12a'; }

.zmdi-case-download:before {
  content: '\f12b'; }

.zmdi-case-play:before {
  content: '\f12c'; }

.zmdi-case:before {
  content: '\f12d'; }

.zmdi-cast-connected:before {
  content: '\f12e'; }

.zmdi-cast:before {
  content: '\f12f'; }

.zmdi-chart-donut:before {
  content: '\f130'; }

.zmdi-chart:before {
  content: '\f131'; }

.zmdi-city-alt:before {
  content: '\f132'; }

.zmdi-city:before {
  content: '\f133'; }

.zmdi-close-circle-o:before {
  content: '\f134'; }

.zmdi-close-circle:before {
  content: '\f135'; }

.zmdi-close:before {
  content: '\f136'; }

.zmdi-cocktail:before {
  content: '\f137'; }

.zmdi-code-setting:before {
  content: '\f138'; }

.zmdi-code-smartphone:before {
  content: '\f139'; }

.zmdi-code:before {
  content: '\f13a'; }

.zmdi-coffee:before {
  content: '\f13b'; }

.zmdi-collection-bookmark:before {
  content: '\f13c'; }

.zmdi-collection-case-play:before {
  content: '\f13d'; }

.zmdi-collection-folder-image:before {
  content: '\f13e'; }

.zmdi-collection-image-o:before {
  content: '\f13f'; }

.zmdi-collection-image:before {
  content: '\f140'; }

.zmdi-collection-item-1:before {
  content: '\f141'; }

.zmdi-collection-item-2:before {
  content: '\f142'; }

.zmdi-collection-item-3:before {
  content: '\f143'; }

.zmdi-collection-item-4:before {
  content: '\f144'; }

.zmdi-collection-item-5:before {
  content: '\f145'; }

.zmdi-collection-item-6:before {
  content: '\f146'; }

.zmdi-collection-item-7:before {
  content: '\f147'; }

.zmdi-collection-item-8:before {
  content: '\f148'; }

.zmdi-collection-item-9-plus:before {
  content: '\f149'; }

.zmdi-collection-item-9:before {
  content: '\f14a'; }

.zmdi-collection-item:before {
  content: '\f14b'; }

.zmdi-collection-music:before {
  content: '\f14c'; }

.zmdi-collection-pdf:before {
  content: '\f14d'; }

.zmdi-collection-plus:before {
  content: '\f14e'; }

.zmdi-collection-speaker:before {
  content: '\f14f'; }

.zmdi-collection-text:before {
  content: '\f150'; }

.zmdi-collection-video:before {
  content: '\f151'; }

.zmdi-compass:before {
  content: '\f152'; }

.zmdi-cutlery:before {
  content: '\f153'; }

.zmdi-delete:before {
  content: '\f154'; }

.zmdi-dialpad:before {
  content: '\f155'; }

.zmdi-dns:before {
  content: '\f156'; }

.zmdi-drink:before {
  content: '\f157'; }

.zmdi-edit:before {
  content: '\f158'; }

.zmdi-email-open:before {
  content: '\f159'; }

.zmdi-email:before {
  content: '\f15a'; }

.zmdi-eye-off:before {
  content: '\f15b'; }

.zmdi-eye:before {
  content: '\f15c'; }

.zmdi-eyedropper:before {
  content: '\f15d'; }

.zmdi-favorite-outline:before {
  content: '\f15e'; }

.zmdi-favorite:before {
  content: '\f15f'; }

.zmdi-filter-list:before {
  content: '\f160'; }

.zmdi-fire:before {
  content: '\f161'; }

.zmdi-flag:before {
  content: '\f162'; }

.zmdi-flare:before {
  content: '\f163'; }

.zmdi-flash-auto:before {
  content: '\f164'; }

.zmdi-flash-off:before {
  content: '\f165'; }

.zmdi-flash:before {
  content: '\f166'; }

.zmdi-flip:before {
  content: '\f167'; }

.zmdi-flower-alt:before {
  content: '\f168'; }

.zmdi-flower:before {
  content: '\f169'; }

.zmdi-font:before {
  content: '\f16a'; }

.zmdi-fullscreen-alt:before {
  content: '\f16b'; }

.zmdi-fullscreen-exit:before {
  content: '\f16c'; }

.zmdi-fullscreen:before {
  content: '\f16d'; }

.zmdi-functions:before {
  content: '\f16e'; }

.zmdi-gas-station:before {
  content: '\f16f'; }

.zmdi-gesture:before {
  content: '\f170'; }

.zmdi-globe-alt:before {
  content: '\f171'; }

.zmdi-globe-lock:before {
  content: '\f172'; }

.zmdi-globe:before {
  content: '\f173'; }

.zmdi-graduation-cap:before {
  content: '\f174'; }

.zmdi-home:before {
  content: '\f175'; }

.zmdi-hospital-alt:before {
  content: '\f176'; }

.zmdi-hospital:before {
  content: '\f177'; }

.zmdi-hotel:before {
  content: '\f178'; }

.zmdi-hourglass-alt:before {
  content: '\f179'; }

.zmdi-hourglass-outline:before {
  content: '\f17a'; }

.zmdi-hourglass:before {
  content: '\f17b'; }

.zmdi-http:before {
  content: '\f17c'; }

.zmdi-image-alt:before {
  content: '\f17d'; }

.zmdi-image-o:before {
  content: '\f17e'; }

.zmdi-image:before {
  content: '\f17f'; }

.zmdi-inbox:before {
  content: '\f180'; }

.zmdi-invert-colors-off:before {
  content: '\f181'; }

.zmdi-invert-colors:before {
  content: '\f182'; }

.zmdi-key:before {
  content: '\f183'; }

.zmdi-label-alt-outline:before {
  content: '\f184'; }

.zmdi-label-alt:before {
  content: '\f185'; }

.zmdi-label-heart:before {
  content: '\f186'; }

.zmdi-label:before {
  content: '\f187'; }

.zmdi-labels:before {
  content: '\f188'; }

.zmdi-lamp:before {
  content: '\f189'; }

.zmdi-landscape:before {
  content: '\f18a'; }

.zmdi-layers-off:before {
  content: '\f18b'; }

.zmdi-layers:before {
  content: '\f18c'; }

.zmdi-library:before {
  content: '\f18d'; }

.zmdi-link:before {
  content: '\f18e'; }

.zmdi-lock-open:before {
  content: '\f18f'; }

.zmdi-lock-outline:before {
  content: '\f190'; }

.zmdi-lock:before {
  content: '\f191'; }

.zmdi-mail-reply-all:before {
  content: '\f192'; }

.zmdi-mail-reply:before {
  content: '\f193'; }

.zmdi-mail-send:before {
  content: '\f194'; }

.zmdi-mall:before {
  content: '\f195'; }

.zmdi-map:before {
  content: '\f196'; }

.zmdi-menu:before {
  content: '\f197'; }

.zmdi-money-box:before {
  content: '\f198'; }

.zmdi-money-off:before {
  content: '\f199'; }

.zmdi-money:before {
  content: '\f19a'; }

.zmdi-more-vert:before {
  content: '\f19b'; }

.zmdi-more:before {
  content: '\f19c'; }

.zmdi-movie-alt:before {
  content: '\f19d'; }

.zmdi-movie:before {
  content: '\f19e'; }

.zmdi-nature-people:before {
  content: '\f19f'; }

.zmdi-nature:before {
  content: '\f1a0'; }

.zmdi-navigation:before {
  content: '\f1a1'; }

.zmdi-open-in-browser:before {
  content: '\f1a2'; }

.zmdi-open-in-new:before {
  content: '\f1a3'; }

.zmdi-palette:before {
  content: '\f1a4'; }

.zmdi-parking:before {
  content: '\f1a5'; }

.zmdi-pin-account:before {
  content: '\f1a6'; }

.zmdi-pin-assistant:before {
  content: '\f1a7'; }

.zmdi-pin-drop:before {
  content: '\f1a8'; }

.zmdi-pin-help:before {
  content: '\f1a9'; }

.zmdi-pin-off:before {
  content: '\f1aa'; }

.zmdi-pin:before {
  content: '\f1ab'; }

.zmdi-pizza:before {
  content: '\f1ac'; }

.zmdi-plaster:before {
  content: '\f1ad'; }

.zmdi-power-setting:before {
  content: '\f1ae'; }

.zmdi-power:before {
  content: '\f1af'; }

.zmdi-print:before {
  content: '\f1b0'; }

.zmdi-puzzle-piece:before {
  content: '\f1b1'; }

.zmdi-quote:before {
  content: '\f1b2'; }

.zmdi-railway:before {
  content: '\f1b3'; }

.zmdi-receipt:before {
  content: '\f1b4'; }

.zmdi-refresh-alt:before {
  content: '\f1b5'; }

.zmdi-refresh-sync-alert:before {
  content: '\f1b6'; }

.zmdi-refresh-sync-off:before {
  content: '\f1b7'; }

.zmdi-refresh-sync:before {
  content: '\f1b8'; }

.zmdi-refresh:before {
  content: '\f1b9'; }

.zmdi-roller:before {
  content: '\f1ba'; }

.zmdi-ruler:before {
  content: '\f1bb'; }

.zmdi-scissors:before {
  content: '\f1bc'; }

.zmdi-screen-rotation-lock:before {
  content: '\f1bd'; }

.zmdi-screen-rotation:before {
  content: '\f1be'; }

.zmdi-search-for:before {
  content: '\f1bf'; }

.zmdi-search-in-file:before {
  content: '\f1c0'; }

.zmdi-search-in-page:before {
  content: '\f1c1'; }

.zmdi-search-replace:before {
  content: '\f1c2'; }

.zmdi-search:before {
  content: '\f1c3'; }

.zmdi-seat:before {
  content: '\f1c4'; }

.zmdi-settings-square:before {
  content: '\f1c5'; }

.zmdi-settings:before {
  content: '\f1c6'; }

.zmdi-shield-check:before {
  content: '\f1c7'; }

.zmdi-shield-security:before {
  content: '\f1c8'; }

.zmdi-shopping-basket:before {
  content: '\f1c9'; }

.zmdi-shopping-cart-plus:before {
  content: '\f1ca'; }

.zmdi-shopping-cart:before {
  content: '\f1cb'; }

.zmdi-sign-in:before {
  content: '\f1cc'; }

.zmdi-sort-amount-asc:before {
  content: '\f1cd'; }

.zmdi-sort-amount-desc:before {
  content: '\f1ce'; }

.zmdi-sort-asc:before {
  content: '\f1cf'; }

.zmdi-sort-desc:before {
  content: '\f1d0'; }

.zmdi-spellcheck:before {
  content: '\f1d1'; }

.zmdi-storage:before {
  content: '\f1d2'; }

.zmdi-store-24:before {
  content: '\f1d3'; }

.zmdi-store:before {
  content: '\f1d4'; }

.zmdi-subway:before {
  content: '\f1d5'; }

.zmdi-sun:before {
  content: '\f1d6'; }

.zmdi-tab-unselected:before {
  content: '\f1d7'; }

.zmdi-tab:before {
  content: '\f1d8'; }

.zmdi-tag-close:before {
  content: '\f1d9'; }

.zmdi-tag-more:before {
  content: '\f1da'; }

.zmdi-tag:before {
  content: '\f1db'; }

.zmdi-thumb-down:before {
  content: '\f1dc'; }

.zmdi-thumb-up-down:before {
  content: '\f1dd'; }

.zmdi-thumb-up:before {
  content: '\f1de'; }

.zmdi-ticket-star:before {
  content: '\f1df'; }

.zmdi-toll:before {
  content: '\f1e0'; }

.zmdi-toys:before {
  content: '\f1e1'; }

.zmdi-traffic:before {
  content: '\f1e2'; }

.zmdi-translate:before {
  content: '\f1e3'; }

.zmdi-triangle-down:before {
  content: '\f1e4'; }

.zmdi-triangle-up:before {
  content: '\f1e5'; }

.zmdi-truck:before {
  content: '\f1e6'; }

.zmdi-turning-sign:before {
  content: '\f1e7'; }

.zmdi-wallpaper:before {
  content: '\f1e8'; }

.zmdi-washing-machine:before {
  content: '\f1e9'; }

.zmdi-window-maximize:before {
  content: '\f1ea'; }

.zmdi-window-minimize:before {
  content: '\f1eb'; }

.zmdi-window-restore:before {
  content: '\f1ec'; }

.zmdi-wrench:before {
  content: '\f1ed'; }

.zmdi-zoom-in:before {
  content: '\f1ee'; }

.zmdi-zoom-out:before {
  content: '\f1ef'; }

.zmdi-alert-circle-o:before {
  content: '\f1f0'; }

.zmdi-alert-circle:before {
  content: '\f1f1'; }

.zmdi-alert-octagon:before {
  content: '\f1f2'; }

.zmdi-alert-polygon:before {
  content: '\f1f3'; }

.zmdi-alert-triangle:before {
  content: '\f1f4'; }

.zmdi-help-outline:before {
  content: '\f1f5'; }

.zmdi-help:before {
  content: '\f1f6'; }

.zmdi-info-outline:before {
  content: '\f1f7'; }

.zmdi-info:before {
  content: '\f1f8'; }

.zmdi-notifications-active:before {
  content: '\f1f9'; }

.zmdi-notifications-add:before {
  content: '\f1fa'; }

.zmdi-notifications-none:before {
  content: '\f1fb'; }

.zmdi-notifications-off:before {
  content: '\f1fc'; }

.zmdi-notifications-paused:before {
  content: '\f1fd'; }

.zmdi-notifications:before {
  content: '\f1fe'; }

.zmdi-account-add:before {
  content: '\f1ff'; }

.zmdi-account-box-mail:before {
  content: '\f200'; }

.zmdi-account-box-o:before {
  content: '\f201'; }

.zmdi-account-box-phone:before {
  content: '\f202'; }

.zmdi-account-box:before {
  content: '\f203'; }

.zmdi-account-calendar:before {
  content: '\f204'; }

.zmdi-account-circle:before {
  content: '\f205'; }

.zmdi-account-o:before {
  content: '\f206'; }

.zmdi-account:before {
  content: '\f207'; }

.zmdi-accounts-add:before {
  content: '\f208'; }

.zmdi-accounts-alt:before {
  content: '\f209'; }

.zmdi-accounts-list-alt:before {
  content: '\f20a'; }

.zmdi-accounts-list:before {
  content: '\f20b'; }

.zmdi-accounts-outline:before {
  content: '\f20c'; }

.zmdi-accounts:before {
  content: '\f20d'; }

.zmdi-face:before {
  content: '\f20e'; }

.zmdi-female:before {
  content: '\f20f'; }

.zmdi-male-alt:before {
  content: '\f210'; }

.zmdi-male-female:before {
  content: '\f211'; }

.zmdi-male:before {
  content: '\f212'; }

.zmdi-mood-bad:before {
  content: '\f213'; }

.zmdi-mood:before {
  content: '\f214'; }

.zmdi-run:before {
  content: '\f215'; }

.zmdi-walk:before {
  content: '\f216'; }

.zmdi-cloud-box:before {
  content: '\f217'; }

.zmdi-cloud-circle:before {
  content: '\f218'; }

.zmdi-cloud-done:before {
  content: '\f219'; }

.zmdi-cloud-download:before {
  content: '\f21a'; }

.zmdi-cloud-off:before {
  content: '\f21b'; }

.zmdi-cloud-outline-alt:before {
  content: '\f21c'; }

.zmdi-cloud-outline:before {
  content: '\f21d'; }

.zmdi-cloud-upload:before {
  content: '\f21e'; }

.zmdi-cloud:before {
  content: '\f21f'; }

.zmdi-download:before {
  content: '\f220'; }

.zmdi-file-plus:before {
  content: '\f221'; }

.zmdi-file-text:before {
  content: '\f222'; }

.zmdi-file:before {
  content: '\f223'; }

.zmdi-folder-outline:before {
  content: '\f224'; }

.zmdi-folder-person:before {
  content: '\f225'; }

.zmdi-folder-star-alt:before {
  content: '\f226'; }

.zmdi-folder-star:before {
  content: '\f227'; }

.zmdi-folder:before {
  content: '\f228'; }

.zmdi-gif:before {
  content: '\f229'; }

.zmdi-upload:before {
  content: '\f22a'; }

.zmdi-border-all:before {
  content: '\f22b'; }

.zmdi-border-bottom:before {
  content: '\f22c'; }

.zmdi-border-clear:before {
  content: '\f22d'; }

.zmdi-border-color:before {
  content: '\f22e'; }

.zmdi-border-horizontal:before {
  content: '\f22f'; }

.zmdi-border-inner:before {
  content: '\f230'; }

.zmdi-border-left:before {
  content: '\f231'; }

.zmdi-border-outer:before {
  content: '\f232'; }

.zmdi-border-right:before {
  content: '\f233'; }

.zmdi-border-style:before {
  content: '\f234'; }

.zmdi-border-top:before {
  content: '\f235'; }

.zmdi-border-vertical:before {
  content: '\f236'; }

.zmdi-copy:before {
  content: '\f237'; }

.zmdi-crop:before {
  content: '\f238'; }

.zmdi-format-align-center:before {
  content: '\f239'; }

.zmdi-format-align-justify:before {
  content: '\f23a'; }

.zmdi-format-align-left:before {
  content: '\f23b'; }

.zmdi-format-align-right:before {
  content: '\f23c'; }

.zmdi-format-bold:before {
  content: '\f23d'; }

.zmdi-format-clear-all:before {
  content: '\f23e'; }

.zmdi-format-clear:before {
  content: '\f23f'; }

.zmdi-format-color-fill:before {
  content: '\f240'; }

.zmdi-format-color-reset:before {
  content: '\f241'; }

.zmdi-format-color-text:before {
  content: '\f242'; }

.zmdi-format-indent-decrease:before {
  content: '\f243'; }

.zmdi-format-indent-increase:before {
  content: '\f244'; }

.zmdi-format-italic:before {
  content: '\f245'; }

.zmdi-format-line-spacing:before {
  content: '\f246'; }

.zmdi-format-list-bulleted:before {
  content: '\f247'; }

.zmdi-format-list-numbered:before {
  content: '\f248'; }

.zmdi-format-ltr:before {
  content: '\f249'; }

.zmdi-format-rtl:before {
  content: '\f24a'; }

.zmdi-format-size:before {
  content: '\f24b'; }

.zmdi-format-strikethrough-s:before {
  content: '\f24c'; }

.zmdi-format-strikethrough:before {
  content: '\f24d'; }

.zmdi-format-subject:before {
  content: '\f24e'; }

.zmdi-format-underlined:before {
  content: '\f24f'; }

.zmdi-format-valign-bottom:before {
  content: '\f250'; }

.zmdi-format-valign-center:before {
  content: '\f251'; }

.zmdi-format-valign-top:before {
  content: '\f252'; }

.zmdi-redo:before {
  content: '\f253'; }

.zmdi-select-all:before {
  content: '\f254'; }

.zmdi-space-bar:before {
  content: '\f255'; }

.zmdi-text-format:before {
  content: '\f256'; }

.zmdi-transform:before {
  content: '\f257'; }

.zmdi-undo:before {
  content: '\f258'; }

.zmdi-wrap-text:before {
  content: '\f259'; }

.zmdi-comment-alert:before {
  content: '\f25a'; }

.zmdi-comment-alt-text:before {
  content: '\f25b'; }

.zmdi-comment-alt:before {
  content: '\f25c'; }

.zmdi-comment-edit:before {
  content: '\f25d'; }

.zmdi-comment-image:before {
  content: '\f25e'; }

.zmdi-comment-list:before {
  content: '\f25f'; }

.zmdi-comment-more:before {
  content: '\f260'; }

.zmdi-comment-outline:before {
  content: '\f261'; }

.zmdi-comment-text-alt:before {
  content: '\f262'; }

.zmdi-comment-text:before {
  content: '\f263'; }

.zmdi-comment-video:before {
  content: '\f264'; }

.zmdi-comment:before {
  content: '\f265'; }

.zmdi-comments:before {
  content: '\f266'; }

.zmdi-check-all:before {
  content: '\f267'; }

.zmdi-check-circle-u:before {
  content: '\f268'; }

.zmdi-check-circle:before {
  content: '\f269'; }

.zmdi-check-square:before {
  content: '\f26a'; }

.zmdi-check:before {
  content: '\f26b'; }

.zmdi-circle-o:before {
  content: '\f26c'; }

.zmdi-circle:before {
  content: '\f26d'; }

.zmdi-dot-circle-alt:before {
  content: '\f26e'; }

.zmdi-dot-circle:before {
  content: '\f26f'; }

.zmdi-minus-circle-outline:before {
  content: '\f270'; }

.zmdi-minus-circle:before {
  content: '\f271'; }

.zmdi-minus-square:before {
  content: '\f272'; }

.zmdi-minus:before {
  content: '\f273'; }

.zmdi-plus-circle-o-duplicate:before {
  content: '\f274'; }

.zmdi-plus-circle-o:before {
  content: '\f275'; }

.zmdi-plus-circle:before {
  content: '\f276'; }

.zmdi-plus-square:before {
  content: '\f277'; }

.zmdi-plus:before {
  content: '\f278'; }

.zmdi-square-o:before {
  content: '\f279'; }

.zmdi-star-circle:before {
  content: '\f27a'; }

.zmdi-star-half:before {
  content: '\f27b'; }

.zmdi-star-outline:before {
  content: '\f27c'; }

.zmdi-star:before {
  content: '\f27d'; }

.zmdi-bluetooth-connected:before {
  content: '\f27e'; }

.zmdi-bluetooth-off:before {
  content: '\f27f'; }

.zmdi-bluetooth-search:before {
  content: '\f280'; }

.zmdi-bluetooth-setting:before {
  content: '\f281'; }

.zmdi-bluetooth:before {
  content: '\f282'; }

.zmdi-camera-add:before {
  content: '\f283'; }

.zmdi-camera-alt:before {
  content: '\f284'; }

.zmdi-camera-bw:before {
  content: '\f285'; }

.zmdi-camera-front:before {
  content: '\f286'; }

.zmdi-camera-mic:before {
  content: '\f287'; }

.zmdi-camera-party-mode:before {
  content: '\f288'; }

.zmdi-camera-rear:before {
  content: '\f289'; }

.zmdi-camera-roll:before {
  content: '\f28a'; }

.zmdi-camera-switch:before {
  content: '\f28b'; }

.zmdi-camera:before {
  content: '\f28c'; }

.zmdi-card-alert:before {
  content: '\f28d'; }

.zmdi-card-off:before {
  content: '\f28e'; }

.zmdi-card-sd:before {
  content: '\f28f'; }

.zmdi-card-sim:before {
  content: '\f290'; }

.zmdi-desktop-mac:before {
  content: '\f291'; }

.zmdi-desktop-windows:before {
  content: '\f292'; }

.zmdi-device-hub:before {
  content: '\f293'; }

.zmdi-devices-off:before {
  content: '\f294'; }

.zmdi-devices:before {
  content: '\f295'; }

.zmdi-dock:before {
  content: '\f296'; }

.zmdi-floppy:before {
  content: '\f297'; }

.zmdi-gamepad:before {
  content: '\f298'; }

.zmdi-gps-dot:before {
  content: '\f299'; }

.zmdi-gps-off:before {
  content: '\f29a'; }

.zmdi-gps:before {
  content: '\f29b'; }

.zmdi-headset-mic:before {
  content: '\f29c'; }

.zmdi-headset:before {
  content: '\f29d'; }

.zmdi-input-antenna:before {
  content: '\f29e'; }

.zmdi-input-composite:before {
  content: '\f29f'; }

.zmdi-input-hdmi:before {
  content: '\f2a0'; }

.zmdi-input-power:before {
  content: '\f2a1'; }

.zmdi-input-svideo:before {
  content: '\f2a2'; }

.zmdi-keyboard-hide:before {
  content: '\f2a3'; }

.zmdi-keyboard:before {
  content: '\f2a4'; }

.zmdi-laptop-chromebook:before {
  content: '\f2a5'; }

.zmdi-laptop-mac:before {
  content: '\f2a6'; }

.zmdi-laptop:before {
  content: '\f2a7'; }

.zmdi-mic-off:before {
  content: '\f2a8'; }

.zmdi-mic-outline:before {
  content: '\f2a9'; }

.zmdi-mic-setting:before {
  content: '\f2aa'; }

.zmdi-mic:before {
  content: '\f2ab'; }

.zmdi-mouse:before {
  content: '\f2ac'; }

.zmdi-network-alert:before {
  content: '\f2ad'; }

.zmdi-network-locked:before {
  content: '\f2ae'; }

.zmdi-network-off:before {
  content: '\f2af'; }

.zmdi-network-outline:before {
  content: '\f2b0'; }

.zmdi-network-setting:before {
  content: '\f2b1'; }

.zmdi-network:before {
  content: '\f2b2'; }

.zmdi-phone-bluetooth:before {
  content: '\f2b3'; }

.zmdi-phone-end:before {
  content: '\f2b4'; }

.zmdi-phone-forwarded:before {
  content: '\f2b5'; }

.zmdi-phone-in-talk:before {
  content: '\f2b6'; }

.zmdi-phone-locked:before {
  content: '\f2b7'; }

.zmdi-phone-missed:before {
  content: '\f2b8'; }

.zmdi-phone-msg:before {
  content: '\f2b9'; }

.zmdi-phone-paused:before {
  content: '\f2ba'; }

.zmdi-phone-ring:before {
  content: '\f2bb'; }

.zmdi-phone-setting:before {
  content: '\f2bc'; }

.zmdi-phone-sip:before {
  content: '\f2bd'; }

.zmdi-phone:before {
  content: '\f2be'; }

.zmdi-portable-wifi-changes:before {
  content: '\f2bf'; }

.zmdi-portable-wifi-off:before {
  content: '\f2c0'; }

.zmdi-portable-wifi:before {
  content: '\f2c1'; }

.zmdi-radio:before {
  content: '\f2c2'; }

.zmdi-reader:before {
  content: '\f2c3'; }

.zmdi-remote-control-alt:before {
  content: '\f2c4'; }

.zmdi-remote-control:before {
  content: '\f2c5'; }

.zmdi-router:before {
  content: '\f2c6'; }

.zmdi-scanner:before {
  content: '\f2c7'; }

.zmdi-smartphone-android:before {
  content: '\f2c8'; }

.zmdi-smartphone-download:before {
  content: '\f2c9'; }

.zmdi-smartphone-erase:before {
  content: '\f2ca'; }

.zmdi-smartphone-info:before {
  content: '\f2cb'; }

.zmdi-smartphone-iphone:before {
  content: '\f2cc'; }

.zmdi-smartphone-landscape-lock:before {
  content: '\f2cd'; }

.zmdi-smartphone-landscape:before {
  content: '\f2ce'; }

.zmdi-smartphone-lock:before {
  content: '\f2cf'; }

.zmdi-smartphone-portrait-lock:before {
  content: '\f2d0'; }

.zmdi-smartphone-ring:before {
  content: '\f2d1'; }

.zmdi-smartphone-setting:before {
  content: '\f2d2'; }

.zmdi-smartphone-setup:before {
  content: '\f2d3'; }

.zmdi-smartphone:before {
  content: '\f2d4'; }

.zmdi-speaker:before {
  content: '\f2d5'; }

.zmdi-tablet-android:before {
  content: '\f2d6'; }

.zmdi-tablet-mac:before {
  content: '\f2d7'; }

.zmdi-tablet:before {
  content: '\f2d8'; }

.zmdi-tv-alt-play:before {
  content: '\f2d9'; }

.zmdi-tv-list:before {
  content: '\f2da'; }

.zmdi-tv-play:before {
  content: '\f2db'; }

.zmdi-tv:before {
  content: '\f2dc'; }

.zmdi-usb:before {
  content: '\f2dd'; }

.zmdi-videocam-off:before {
  content: '\f2de'; }

.zmdi-videocam-switch:before {
  content: '\f2df'; }

.zmdi-videocam:before {
  content: '\f2e0'; }

.zmdi-watch:before {
  content: '\f2e1'; }

.zmdi-wifi-alt-2:before {
  content: '\f2e2'; }

.zmdi-wifi-alt:before {
  content: '\f2e3'; }

.zmdi-wifi-info:before {
  content: '\f2e4'; }

.zmdi-wifi-lock:before {
  content: '\f2e5'; }

.zmdi-wifi-off:before {
  content: '\f2e6'; }

.zmdi-wifi-outline:before {
  content: '\f2e7'; }

.zmdi-wifi:before {
  content: '\f2e8'; }

.zmdi-arrow-left-bottom:before {
  content: '\f2e9'; }

.zmdi-arrow-left:before {
  content: '\f2ea'; }

.zmdi-arrow-merge:before {
  content: '\f2eb'; }

.zmdi-arrow-missed:before {
  content: '\f2ec'; }

.zmdi-arrow-right-top:before {
  content: '\f2ed'; }

.zmdi-arrow-right:before {
  content: '\f2ee'; }

.zmdi-arrow-split:before {
  content: '\f2ef'; }

.zmdi-arrows:before {
  content: '\f2f0'; }

.zmdi-caret-down-circle:before {
  content: '\f2f1'; }

.zmdi-caret-down:before {
  content: '\f2f2'; }

.zmdi-caret-left-circle:before {
  content: '\f2f3'; }

.zmdi-caret-left:before {
  content: '\f2f4'; }

.zmdi-caret-right-circle:before {
  content: '\f2f5'; }

.zmdi-caret-right:before {
  content: '\f2f6'; }

.zmdi-caret-up-circle:before {
  content: '\f2f7'; }

.zmdi-caret-up:before {
  content: '\f2f8'; }

.zmdi-chevron-down:before {
  content: '\f2f9'; }

.zmdi-chevron-left:before {
  content: '\f2fa'; }

.zmdi-chevron-right:before {
  content: '\f2fb'; }

.zmdi-chevron-up:before {
  content: '\f2fc'; }

.zmdi-forward:before {
  content: '\f2fd'; }

.zmdi-long-arrow-down:before {
  content: '\f2fe'; }

.zmdi-long-arrow-left:before {
  content: '\f2ff'; }

.zmdi-long-arrow-return:before {
  content: '\f300'; }

.zmdi-long-arrow-right:before {
  content: '\f301'; }

.zmdi-long-arrow-tab:before {
  content: '\f302'; }

.zmdi-long-arrow-up:before {
  content: '\f303'; }

.zmdi-rotate-ccw:before {
  content: '\f304'; }

.zmdi-rotate-cw:before {
  content: '\f305'; }

.zmdi-rotate-left:before {
  content: '\f306'; }

.zmdi-rotate-right:before {
  content: '\f307'; }

.zmdi-square-down:before {
  content: '\f308'; }

.zmdi-square-right:before {
  content: '\f309'; }

.zmdi-swap-alt:before {
  content: '\f30a'; }

.zmdi-swap-vertical-circle:before {
  content: '\f30b'; }

.zmdi-swap-vertical:before {
  content: '\f30c'; }

.zmdi-swap:before {
  content: '\f30d'; }

.zmdi-trending-down:before {
  content: '\f30e'; }

.zmdi-trending-flat:before {
  content: '\f30f'; }

.zmdi-trending-up:before {
  content: '\f310'; }

.zmdi-unfold-less:before {
  content: '\f311'; }

.zmdi-unfold-more:before {
  content: '\f312'; }

.zmdi-apps:before {
  content: '\f313'; }

.zmdi-grid-off:before {
  content: '\f314'; }

.zmdi-grid:before {
  content: '\f315'; }

.zmdi-view-agenda:before {
  content: '\f316'; }

.zmdi-view-array:before {
  content: '\f317'; }

.zmdi-view-carousel:before {
  content: '\f318'; }

.zmdi-view-column:before {
  content: '\f319'; }

.zmdi-view-comfy:before {
  content: '\f31a'; }

.zmdi-view-compact:before {
  content: '\f31b'; }

.zmdi-view-dashboard:before {
  content: '\f31c'; }

.zmdi-view-day:before {
  content: '\f31d'; }

.zmdi-view-headline:before {
  content: '\f31e'; }

.zmdi-view-list-alt:before {
  content: '\f31f'; }

.zmdi-view-list:before {
  content: '\f320'; }

.zmdi-view-module:before {
  content: '\f321'; }

.zmdi-view-quilt:before {
  content: '\f322'; }

.zmdi-view-stream:before {
  content: '\f323'; }

.zmdi-view-subtitles:before {
  content: '\f324'; }

.zmdi-view-toc:before {
  content: '\f325'; }

.zmdi-view-web:before {
  content: '\f326'; }

.zmdi-view-week:before {
  content: '\f327'; }

.zmdi-widgets:before {
  content: '\f328'; }

.zmdi-alarm-check:before {
  content: '\f329'; }

.zmdi-alarm-off:before {
  content: '\f32a'; }

.zmdi-alarm-plus:before {
  content: '\f32b'; }

.zmdi-alarm-snooze:before {
  content: '\f32c'; }

.zmdi-alarm:before {
  content: '\f32d'; }

.zmdi-calendar-alt:before {
  content: '\f32e'; }

.zmdi-calendar-check:before {
  content: '\f32f'; }

.zmdi-calendar-close:before {
  content: '\f330'; }

.zmdi-calendar-note:before {
  content: '\f331'; }

.zmdi-calendar:before {
  content: '\f332'; }

.zmdi-time-countdown:before {
  content: '\f333'; }

.zmdi-time-interval:before {
  content: '\f334'; }

.zmdi-time-restore-setting:before {
  content: '\f335'; }

.zmdi-time-restore:before {
  content: '\f336'; }

.zmdi-time:before {
  content: '\f337'; }

.zmdi-timer-off:before {
  content: '\f338'; }

.zmdi-timer:before {
  content: '\f339'; }

.zmdi-android-alt:before {
  content: '\f33a'; }

.zmdi-android:before {
  content: '\f33b'; }

.zmdi-apple:before {
  content: '\f33c'; }

.zmdi-behance:before {
  content: '\f33d'; }

.zmdi-codepen:before {
  content: '\f33e'; }

.zmdi-dribbble:before {
  content: '\f33f'; }

.zmdi-dropbox:before {
  content: '\f340'; }

.zmdi-evernote:before {
  content: '\f341'; }

.zmdi-facebook-box:before {
  content: '\f342'; }

.zmdi-facebook:before {
  content: '\f343'; }

.zmdi-github-box:before {
  content: '\f344'; }

.zmdi-github:before {
  content: '\f345'; }

.zmdi-google-drive:before {
  content: '\f346'; }

.zmdi-google-earth:before {
  content: '\f347'; }

.zmdi-google-glass:before {
  content: '\f348'; }

.zmdi-google-maps:before {
  content: '\f349'; }

.zmdi-google-pages:before {
  content: '\f34a'; }

.zmdi-google-play:before {
  content: '\f34b'; }

.zmdi-google-plus-box:before {
  content: '\f34c'; }

.zmdi-google-plus:before {
  content: '\f34d'; }

.zmdi-google:before {
  content: '\f34e'; }

.zmdi-instagram:before {
  content: '\f34f'; }

.zmdi-language-css3:before {
  content: '\f350'; }

.zmdi-language-html5:before {
  content: '\f351'; }

.zmdi-language-javascript:before {
  content: '\f352'; }

.zmdi-language-python-alt:before {
  content: '\f353'; }

.zmdi-language-python:before {
  content: '\f354'; }

.zmdi-lastfm:before {
  content: '\f355'; }

.zmdi-linkedin-box:before {
  content: '\f356'; }

.zmdi-paypal:before {
  content: '\f357'; }

.zmdi-pinterest-box:before {
  content: '\f358'; }

.zmdi-pocket:before {
  content: '\f359'; }

.zmdi-polymer:before {
  content: '\f35a'; }

.zmdi-share:before {
  content: '\f35b'; }

.zmdi-stackoverflow:before {
  content: '\f35c'; }

.zmdi-steam-square:before {
  content: '\f35d'; }

.zmdi-steam:before {
  content: '\f35e'; }

.zmdi-twitter-box:before {
  content: '\f35f'; }

.zmdi-twitter:before {
  content: '\f360'; }

.zmdi-vk:before {
  content: '\f361'; }

.zmdi-wikipedia:before {
  content: '\f362'; }

.zmdi-windows:before {
  content: '\f363'; }

.zmdi-aspect-ratio-alt:before {
  content: '\f364'; }

.zmdi-aspect-ratio:before {
  content: '\f365'; }

.zmdi-blur-circular:before {
  content: '\f366'; }

.zmdi-blur-linear:before {
  content: '\f367'; }

.zmdi-blur-off:before {
  content: '\f368'; }

.zmdi-blur:before {
  content: '\f369'; }

.zmdi-brightness-2:before {
  content: '\f36a'; }

.zmdi-brightness-3:before {
  content: '\f36b'; }

.zmdi-brightness-4:before {
  content: '\f36c'; }

.zmdi-brightness-5:before {
  content: '\f36d'; }

.zmdi-brightness-6:before {
  content: '\f36e'; }

.zmdi-brightness-7:before {
  content: '\f36f'; }

.zmdi-brightness-auto:before {
  content: '\f370'; }

.zmdi-brightness-setting:before {
  content: '\f371'; }

.zmdi-broken-image:before {
  content: '\f372'; }

.zmdi-center-focus-strong:before {
  content: '\f373'; }

.zmdi-center-focus-weak:before {
  content: '\f374'; }

.zmdi-compare:before {
  content: '\f375'; }

.zmdi-crop-16-9:before {
  content: '\f376'; }

.zmdi-crop-3-2:before {
  content: '\f377'; }

.zmdi-crop-5-4:before {
  content: '\f378'; }

.zmdi-crop-7-5:before {
  content: '\f379'; }

.zmdi-crop-din:before {
  content: '\f37a'; }

.zmdi-crop-free:before {
  content: '\f37b'; }

.zmdi-crop-landscape:before {
  content: '\f37c'; }

.zmdi-crop-portrait:before {
  content: '\f37d'; }

.zmdi-crop-square:before {
  content: '\f37e'; }

.zmdi-exposure-alt:before {
  content: '\f37f'; }

.zmdi-exposure:before {
  content: '\f380'; }

.zmdi-filter-b-and-w:before {
  content: '\f381'; }

.zmdi-filter-center-focus:before {
  content: '\f382'; }

.zmdi-filter-frames:before {
  content: '\f383'; }

.zmdi-filter-tilt-shift:before {
  content: '\f384'; }

.zmdi-gradient:before {
  content: '\f385'; }

.zmdi-grain:before {
  content: '\f386'; }

.zmdi-graphic-eq:before {
  content: '\f387'; }

.zmdi-hdr-off:before {
  content: '\f388'; }

.zmdi-hdr-strong:before {
  content: '\f389'; }

.zmdi-hdr-weak:before {
  content: '\f38a'; }

.zmdi-hdr:before {
  content: '\f38b'; }

.zmdi-iridescent:before {
  content: '\f38c'; }

.zmdi-leak-off:before {
  content: '\f38d'; }

.zmdi-leak:before {
  content: '\f38e'; }

.zmdi-looks:before {
  content: '\f38f'; }

.zmdi-loupe:before {
  content: '\f390'; }

.zmdi-panorama-horizontal:before {
  content: '\f391'; }

.zmdi-panorama-vertical:before {
  content: '\f392'; }

.zmdi-panorama-wide-angle:before {
  content: '\f393'; }

.zmdi-photo-size-select-large:before {
  content: '\f394'; }

.zmdi-photo-size-select-small:before {
  content: '\f395'; }

.zmdi-picture-in-picture:before {
  content: '\f396'; }

.zmdi-slideshow:before {
  content: '\f397'; }

.zmdi-texture:before {
  content: '\f398'; }

.zmdi-tonality:before {
  content: '\f399'; }

.zmdi-vignette:before {
  content: '\f39a'; }

.zmdi-wb-auto:before {
  content: '\f39b'; }

.zmdi-eject-alt:before {
  content: '\f39c'; }

.zmdi-eject:before {
  content: '\f39d'; }

.zmdi-equalizer:before {
  content: '\f39e'; }

.zmdi-fast-forward:before {
  content: '\f39f'; }

.zmdi-fast-rewind:before {
  content: '\f3a0'; }

.zmdi-forward-10:before {
  content: '\f3a1'; }

.zmdi-forward-30:before {
  content: '\f3a2'; }

.zmdi-forward-5:before {
  content: '\f3a3'; }

.zmdi-hearing:before {
  content: '\f3a4'; }

.zmdi-pause-circle-outline:before {
  content: '\f3a5'; }

.zmdi-pause-circle:before {
  content: '\f3a6'; }

.zmdi-pause:before {
  content: '\f3a7'; }

.zmdi-play-circle-outline:before {
  content: '\f3a8'; }

.zmdi-play-circle:before {
  content: '\f3a9'; }

.zmdi-play:before {
  content: '\f3aa'; }

.zmdi-playlist-audio:before {
  content: '\f3ab'; }

.zmdi-playlist-plus:before {
  content: '\f3ac'; }

.zmdi-repeat-one:before {
  content: '\f3ad'; }

.zmdi-repeat:before {
  content: '\f3ae'; }

.zmdi-replay-10:before {
  content: '\f3af'; }

.zmdi-replay-30:before {
  content: '\f3b0'; }

.zmdi-replay-5:before {
  content: '\f3b1'; }

.zmdi-replay:before {
  content: '\f3b2'; }

.zmdi-shuffle:before {
  content: '\f3b3'; }

.zmdi-skip-next:before {
  content: '\f3b4'; }

.zmdi-skip-previous:before {
  content: '\f3b5'; }

.zmdi-stop:before {
  content: '\f3b6'; }

.zmdi-surround-sound:before {
  content: '\f3b7'; }

.zmdi-tune:before {
  content: '\f3b8'; }

.zmdi-volume-down:before {
  content: '\f3b9'; }

.zmdi-volume-mute:before {
  content: '\f3ba'; }

.zmdi-volume-off:before {
  content: '\f3bb'; }

.zmdi-volume-up:before {
  content: '\f3bc'; }

.zmdi-n-1-square:before {
  content: '\f3bd'; }

.zmdi-n-2-square:before {
  content: '\f3be'; }

.zmdi-n-3-square:before {
  content: '\f3bf'; }

.zmdi-n-4-square:before {
  content: '\f3c0'; }

.zmdi-n-5-square:before {
  content: '\f3c1'; }

.zmdi-n-6-square:before {
  content: '\f3c2'; }

.zmdi-neg-1:before {
  content: '\f3c3'; }

.zmdi-neg-2:before {
  content: '\f3c4'; }

.zmdi-plus-1:before {
  content: '\f3c5'; }

.zmdi-plus-2:before {
  content: '\f3c6'; }

.zmdi-sec-10:before {
  content: '\f3c7'; }

.zmdi-sec-3:before {
  content: '\f3c8'; }

.zmdi-zero:before {
  content: '\f3c9'; }

.zmdi-airline-seat-flat-angled:before {
  content: '\f3ca'; }

.zmdi-airline-seat-flat:before {
  content: '\f3cb'; }

.zmdi-airline-seat-individual-suite:before {
  content: '\f3cc'; }

.zmdi-airline-seat-legroom-extra:before {
  content: '\f3cd'; }

.zmdi-airline-seat-legroom-normal:before {
  content: '\f3ce'; }

.zmdi-airline-seat-legroom-reduced:before {
  content: '\f3cf'; }

.zmdi-airline-seat-recline-extra:before {
  content: '\f3d0'; }

.zmdi-airline-seat-recline-normal:before {
  content: '\f3d1'; }

.zmdi-airplay:before {
  content: '\f3d2'; }

.zmdi-closed-caption:before {
  content: '\f3d3'; }

.zmdi-confirmation-number:before {
  content: '\f3d4'; }

.zmdi-developer-board:before {
  content: '\f3d5'; }

.zmdi-disc-full:before {
  content: '\f3d6'; }

.zmdi-explicit:before {
  content: '\f3d7'; }

.zmdi-flight-land:before {
  content: '\f3d8'; }

.zmdi-flight-takeoff:before {
  content: '\f3d9'; }

.zmdi-flip-to-back:before {
  content: '\f3da'; }

.zmdi-flip-to-front:before {
  content: '\f3db'; }

.zmdi-group-work:before {
  content: '\f3dc'; }

.zmdi-hd:before {
  content: '\f3dd'; }

.zmdi-hq:before {
  content: '\f3de'; }

.zmdi-markunread-mailbox:before {
  content: '\f3df'; }

.zmdi-memory:before {
  content: '\f3e0'; }

.zmdi-nfc:before {
  content: '\f3e1'; }

.zmdi-play-for-work:before {
  content: '\f3e2'; }

.zmdi-power-input:before {
  content: '\f3e3'; }

.zmdi-present-to-all:before {
  content: '\f3e4'; }

.zmdi-satellite:before {
  content: '\f3e5'; }

.zmdi-tap-and-play:before {
  content: '\f3e6'; }

.zmdi-vibration:before {
  content: '\f3e7'; }

.zmdi-voicemail:before {
  content: '\f3e8'; }

.zmdi-group:before {
  content: '\f3e9'; }

.zmdi-rss:before {
  content: '\f3ea'; }

.zmdi-shape:before {
  content: '\f3eb'; }

.zmdi-spinner:before {
  content: '\f3ec'; }

.zmdi-ungroup:before {
  content: '\f3ed'; }

.zmdi-500px:before {
  content: '\f3ee'; }

.zmdi-8tracks:before {
  content: '\f3ef'; }

.zmdi-amazon:before {
  content: '\f3f0'; }

.zmdi-blogger:before {
  content: '\f3f1'; }

.zmdi-delicious:before {
  content: '\f3f2'; }

.zmdi-disqus:before {
  content: '\f3f3'; }

.zmdi-flattr:before {
  content: '\f3f4'; }

.zmdi-flickr:before {
  content: '\f3f5'; }

.zmdi-github-alt:before {
  content: '\f3f6'; }

.zmdi-google-old:before {
  content: '\f3f7'; }

.zmdi-linkedin:before {
  content: '\f3f8'; }

.zmdi-odnoklassniki:before {
  content: '\f3f9'; }

.zmdi-outlook:before {
  content: '\f3fa'; }

.zmdi-paypal-alt:before {
  content: '\f3fb'; }

.zmdi-pinterest:before {
  content: '\f3fc'; }

.zmdi-playstation:before {
  content: '\f3fd'; }

.zmdi-reddit:before {
  content: '\f3fe'; }

.zmdi-skype:before {
  content: '\f3ff'; }

.zmdi-slideshare:before {
  content: '\f400'; }

.zmdi-soundcloud:before {
  content: '\f401'; }

.zmdi-tumblr:before {
  content: '\f402'; }

.zmdi-twitch:before {
  content: '\f403'; }

.zmdi-vimeo:before {
  content: '\f404'; }

.zmdi-whatsapp:before {
  content: '\f405'; }

.zmdi-xbox:before {
  content: '\f406'; }

.zmdi-yahoo:before {
  content: '\f407'; }

.zmdi-youtube-play:before {
  content: '\f408'; }

.zmdi-youtube:before {
  content: '\f409'; }

.zmdi-3d-rotation:before {
  content: '\f101'; }

.zmdi-airplane-off:before {
  content: '\f102'; }

.zmdi-airplane:before {
  content: '\f103'; }

.zmdi-album:before {
  content: '\f104'; }

.zmdi-archive:before {
  content: '\f105'; }

.zmdi-assignment-account:before {
  content: '\f106'; }

.zmdi-assignment-alert:before {
  content: '\f107'; }

.zmdi-assignment-check:before {
  content: '\f108'; }

.zmdi-assignment-o:before {
  content: '\f109'; }

.zmdi-assignment-return:before {
  content: '\f10a'; }

.zmdi-assignment-returned:before {
  content: '\f10b'; }

.zmdi-assignment:before {
  content: '\f10c'; }

.zmdi-attachment-alt:before {
  content: '\f10d'; }

.zmdi-attachment:before {
  content: '\f10e'; }

.zmdi-audio:before {
  content: '\f10f'; }

.zmdi-badge-check:before {
  content: '\f110'; }

.zmdi-balance-wallet:before {
  content: '\f111'; }

.zmdi-balance:before {
  content: '\f112'; }

.zmdi-battery-alert:before {
  content: '\f113'; }

.zmdi-battery-flash:before {
  content: '\f114'; }

.zmdi-battery-unknown:before {
  content: '\f115'; }

.zmdi-battery:before {
  content: '\f116'; }

.zmdi-bike:before {
  content: '\f117'; }

.zmdi-block-alt:before {
  content: '\f118'; }

.zmdi-block:before {
  content: '\f119'; }

.zmdi-boat:before {
  content: '\f11a'; }

.zmdi-book-image:before {
  content: '\f11b'; }

.zmdi-book:before {
  content: '\f11c'; }

.zmdi-bookmark-outline:before {
  content: '\f11d'; }

.zmdi-bookmark:before {
  content: '\f11e'; }

.zmdi-brush:before {
  content: '\f11f'; }

.zmdi-bug:before {
  content: '\f120'; }

.zmdi-bus:before {
  content: '\f121'; }

.zmdi-cake:before {
  content: '\f122'; }

.zmdi-car-taxi:before {
  content: '\f123'; }

.zmdi-car-wash:before {
  content: '\f124'; }

.zmdi-car:before {
  content: '\f125'; }

.zmdi-card-giftcard:before {
  content: '\f126'; }

.zmdi-card-membership:before {
  content: '\f127'; }

.zmdi-card-travel:before {
  content: '\f128'; }

.zmdi-card:before {
  content: '\f129'; }

.zmdi-case-check:before {
  content: '\f12a'; }

.zmdi-case-download:before {
  content: '\f12b'; }

.zmdi-case-play:before {
  content: '\f12c'; }

.zmdi-case:before {
  content: '\f12d'; }

.zmdi-cast-connected:before {
  content: '\f12e'; }

.zmdi-cast:before {
  content: '\f12f'; }

.zmdi-chart-donut:before {
  content: '\f130'; }

.zmdi-chart:before {
  content: '\f131'; }

.zmdi-city-alt:before {
  content: '\f132'; }

.zmdi-city:before {
  content: '\f133'; }

.zmdi-close-circle-o:before {
  content: '\f134'; }

.zmdi-close-circle:before {
  content: '\f135'; }

.zmdi-close:before {
  content: '\f136'; }

.zmdi-cocktail:before {
  content: '\f137'; }

.zmdi-code-setting:before {
  content: '\f138'; }

.zmdi-code-smartphone:before {
  content: '\f139'; }

.zmdi-code:before {
  content: '\f13a'; }

.zmdi-coffee:before {
  content: '\f13b'; }

.zmdi-collection-bookmark:before {
  content: '\f13c'; }

.zmdi-collection-case-play:before {
  content: '\f13d'; }

.zmdi-collection-folder-image:before {
  content: '\f13e'; }

.zmdi-collection-image-o:before {
  content: '\f13f'; }

.zmdi-collection-image:before {
  content: '\f140'; }

.zmdi-collection-item-1:before {
  content: '\f141'; }

.zmdi-collection-item-2:before {
  content: '\f142'; }

.zmdi-collection-item-3:before {
  content: '\f143'; }

.zmdi-collection-item-4:before {
  content: '\f144'; }

.zmdi-collection-item-5:before {
  content: '\f145'; }

.zmdi-collection-item-6:before {
  content: '\f146'; }

.zmdi-collection-item-7:before {
  content: '\f147'; }

.zmdi-collection-item-8:before {
  content: '\f148'; }

.zmdi-collection-item-9-plus:before {
  content: '\f149'; }

.zmdi-collection-item-9:before {
  content: '\f14a'; }

.zmdi-collection-item:before {
  content: '\f14b'; }

.zmdi-collection-music:before {
  content: '\f14c'; }

.zmdi-collection-pdf:before {
  content: '\f14d'; }

.zmdi-collection-plus:before {
  content: '\f14e'; }

.zmdi-collection-speaker:before {
  content: '\f14f'; }

.zmdi-collection-text:before {
  content: '\f150'; }

.zmdi-collection-video:before {
  content: '\f151'; }

.zmdi-compass:before {
  content: '\f152'; }

.zmdi-cutlery:before {
  content: '\f153'; }

.zmdi-delete:before {
  content: '\f154'; }

.zmdi-dialpad:before {
  content: '\f155'; }

.zmdi-dns:before {
  content: '\f156'; }

.zmdi-drink:before {
  content: '\f157'; }

.zmdi-edit:before {
  content: '\f158'; }

.zmdi-email-open:before {
  content: '\f159'; }

.zmdi-email:before {
  content: '\f15a'; }

.zmdi-eye-off:before {
  content: '\f15b'; }

.zmdi-eye:before {
  content: '\f15c'; }

.zmdi-eyedropper:before {
  content: '\f15d'; }

.zmdi-favorite-outline:before {
  content: '\f15e'; }

.zmdi-favorite:before {
  content: '\f15f'; }

.zmdi-filter-list:before {
  content: '\f160'; }

.zmdi-fire:before {
  content: '\f161'; }

.zmdi-flag:before {
  content: '\f162'; }

.zmdi-flare:before {
  content: '\f163'; }

.zmdi-flash-auto:before {
  content: '\f164'; }

.zmdi-flash-off:before {
  content: '\f165'; }

.zmdi-flash:before {
  content: '\f166'; }

.zmdi-flip:before {
  content: '\f167'; }

.zmdi-flower-alt:before {
  content: '\f168'; }

.zmdi-flower:before {
  content: '\f169'; }

.zmdi-font:before {
  content: '\f16a'; }

.zmdi-fullscreen-alt:before {
  content: '\f16b'; }

.zmdi-fullscreen-exit:before {
  content: '\f16c'; }

.zmdi-fullscreen:before {
  content: '\f16d'; }

.zmdi-functions:before {
  content: '\f16e'; }

.zmdi-gas-station:before {
  content: '\f16f'; }

.zmdi-gesture:before {
  content: '\f170'; }

.zmdi-globe-alt:before {
  content: '\f171'; }

.zmdi-globe-lock:before {
  content: '\f172'; }

.zmdi-globe:before {
  content: '\f173'; }

.zmdi-graduation-cap:before {
  content: '\f174'; }

.zmdi-home:before {
  content: '\f175'; }

.zmdi-hospital-alt:before {
  content: '\f176'; }

.zmdi-hospital:before {
  content: '\f177'; }

.zmdi-hotel:before {
  content: '\f178'; }

.zmdi-hourglass-alt:before {
  content: '\f179'; }

.zmdi-hourglass-outline:before {
  content: '\f17a'; }

.zmdi-hourglass:before {
  content: '\f17b'; }

.zmdi-http:before {
  content: '\f17c'; }

.zmdi-image-alt:before {
  content: '\f17d'; }

.zmdi-image-o:before {
  content: '\f17e'; }

.zmdi-image:before {
  content: '\f17f'; }

.zmdi-inbox:before {
  content: '\f180'; }

.zmdi-invert-colors-off:before {
  content: '\f181'; }

.zmdi-invert-colors:before {
  content: '\f182'; }

.zmdi-key:before {
  content: '\f183'; }

.zmdi-label-alt-outline:before {
  content: '\f184'; }

.zmdi-label-alt:before {
  content: '\f185'; }

.zmdi-label-heart:before {
  content: '\f186'; }

.zmdi-label:before {
  content: '\f187'; }

.zmdi-labels:before {
  content: '\f188'; }

.zmdi-lamp:before {
  content: '\f189'; }

.zmdi-landscape:before {
  content: '\f18a'; }

.zmdi-layers-off:before {
  content: '\f18b'; }

.zmdi-layers:before {
  content: '\f18c'; }

.zmdi-library:before {
  content: '\f18d'; }

.zmdi-link:before {
  content: '\f18e'; }

.zmdi-lock-open:before {
  content: '\f18f'; }

.zmdi-lock-outline:before {
  content: '\f190'; }

.zmdi-lock:before {
  content: '\f191'; }

.zmdi-mail-reply-all:before {
  content: '\f192'; }

.zmdi-mail-reply:before {
  content: '\f193'; }

.zmdi-mail-send:before {
  content: '\f194'; }

.zmdi-mall:before {
  content: '\f195'; }

.zmdi-map:before {
  content: '\f196'; }

.zmdi-menu:before {
  content: '\f197'; }

.zmdi-money-box:before {
  content: '\f198'; }

.zmdi-money-off:before {
  content: '\f199'; }

.zmdi-money:before {
  content: '\f19a'; }

.zmdi-more-vert:before {
  content: '\f19b'; }

.zmdi-more:before {
  content: '\f19c'; }

.zmdi-movie-alt:before {
  content: '\f19d'; }

.zmdi-movie:before {
  content: '\f19e'; }

.zmdi-nature-people:before {
  content: '\f19f'; }

.zmdi-nature:before {
  content: '\f1a0'; }

.zmdi-navigation:before {
  content: '\f1a1'; }

.zmdi-open-in-browser:before {
  content: '\f1a2'; }

.zmdi-open-in-new:before {
  content: '\f1a3'; }

.zmdi-palette:before {
  content: '\f1a4'; }

.zmdi-parking:before {
  content: '\f1a5'; }

.zmdi-pin-account:before {
  content: '\f1a6'; }

.zmdi-pin-assistant:before {
  content: '\f1a7'; }

.zmdi-pin-drop:before {
  content: '\f1a8'; }

.zmdi-pin-help:before {
  content: '\f1a9'; }

.zmdi-pin-off:before {
  content: '\f1aa'; }

.zmdi-pin:before {
  content: '\f1ab'; }

.zmdi-pizza:before {
  content: '\f1ac'; }

.zmdi-plaster:before {
  content: '\f1ad'; }

.zmdi-power-setting:before {
  content: '\f1ae'; }

.zmdi-power:before {
  content: '\f1af'; }

.zmdi-print:before {
  content: '\f1b0'; }

.zmdi-puzzle-piece:before {
  content: '\f1b1'; }

.zmdi-quote:before {
  content: '\f1b2'; }

.zmdi-railway:before {
  content: '\f1b3'; }

.zmdi-receipt:before {
  content: '\f1b4'; }

.zmdi-refresh-alt:before {
  content: '\f1b5'; }

.zmdi-refresh-sync-alert:before {
  content: '\f1b6'; }

.zmdi-refresh-sync-off:before {
  content: '\f1b7'; }

.zmdi-refresh-sync:before {
  content: '\f1b8'; }

.zmdi-refresh:before {
  content: '\f1b9'; }

.zmdi-roller:before {
  content: '\f1ba'; }

.zmdi-ruler:before {
  content: '\f1bb'; }

.zmdi-scissors:before {
  content: '\f1bc'; }

.zmdi-screen-rotation-lock:before {
  content: '\f1bd'; }

.zmdi-screen-rotation:before {
  content: '\f1be'; }

.zmdi-search-for:before {
  content: '\f1bf'; }

.zmdi-search-in-file:before {
  content: '\f1c0'; }

.zmdi-search-in-page:before {
  content: '\f1c1'; }

.zmdi-search-replace:before {
  content: '\f1c2'; }

.zmdi-search:before {
  content: '\f1c3'; }

.zmdi-seat:before {
  content: '\f1c4'; }

.zmdi-settings-square:before {
  content: '\f1c5'; }

.zmdi-settings:before {
  content: '\f1c6'; }

.zmdi-shield-check:before {
  content: '\f1c7'; }

.zmdi-shield-security:before {
  content: '\f1c8'; }

.zmdi-shopping-basket:before {
  content: '\f1c9'; }

.zmdi-shopping-cart-plus:before {
  content: '\f1ca'; }

.zmdi-shopping-cart:before {
  content: '\f1cb'; }

.zmdi-sign-in:before {
  content: '\f1cc'; }

.zmdi-sort-amount-asc:before {
  content: '\f1cd'; }

.zmdi-sort-amount-desc:before {
  content: '\f1ce'; }

.zmdi-sort-asc:before {
  content: '\f1cf'; }

.zmdi-sort-desc:before {
  content: '\f1d0'; }

.zmdi-spellcheck:before {
  content: '\f1d1'; }

.zmdi-storage:before {
  content: '\f1d2'; }

.zmdi-store-24:before {
  content: '\f1d3'; }

.zmdi-store:before {
  content: '\f1d4'; }

.zmdi-subway:before {
  content: '\f1d5'; }

.zmdi-sun:before {
  content: '\f1d6'; }

.zmdi-tab-unselected:before {
  content: '\f1d7'; }

.zmdi-tab:before {
  content: '\f1d8'; }

.zmdi-tag-close:before {
  content: '\f1d9'; }

.zmdi-tag-more:before {
  content: '\f1da'; }

.zmdi-tag:before {
  content: '\f1db'; }

.zmdi-thumb-down:before {
  content: '\f1dc'; }

.zmdi-thumb-up-down:before {
  content: '\f1dd'; }

.zmdi-thumb-up:before {
  content: '\f1de'; }

.zmdi-ticket-star:before {
  content: '\f1df'; }

.zmdi-toll:before {
  content: '\f1e0'; }

.zmdi-toys:before {
  content: '\f1e1'; }

.zmdi-traffic:before {
  content: '\f1e2'; }

.zmdi-translate:before {
  content: '\f1e3'; }

.zmdi-triangle-down:before {
  content: '\f1e4'; }

.zmdi-triangle-up:before {
  content: '\f1e5'; }

.zmdi-truck:before {
  content: '\f1e6'; }

.zmdi-turning-sign:before {
  content: '\f1e7'; }

.zmdi-wallpaper:before {
  content: '\f1e8'; }

.zmdi-washing-machine:before {
  content: '\f1e9'; }

.zmdi-window-maximize:before {
  content: '\f1ea'; }

.zmdi-window-minimize:before {
  content: '\f1eb'; }

.zmdi-window-restore:before {
  content: '\f1ec'; }

.zmdi-wrench:before {
  content: '\f1ed'; }

.zmdi-zoom-in:before {
  content: '\f1ee'; }

.zmdi-zoom-out:before {
  content: '\f1ef'; }

.zmdi-alert-circle-o:before {
  content: '\f1f0'; }

.zmdi-alert-circle:before {
  content: '\f1f1'; }

.zmdi-alert-octagon:before {
  content: '\f1f2'; }

.zmdi-alert-polygon:before {
  content: '\f1f3'; }

.zmdi-alert-triangle:before {
  content: '\f1f4'; }

.zmdi-help-outline:before {
  content: '\f1f5'; }

.zmdi-help:before {
  content: '\f1f6'; }

.zmdi-info-outline:before {
  content: '\f1f7'; }

.zmdi-info:before {
  content: '\f1f8'; }

.zmdi-notifications-active:before {
  content: '\f1f9'; }

.zmdi-notifications-add:before {
  content: '\f1fa'; }

.zmdi-notifications-none:before {
  content: '\f1fb'; }

.zmdi-notifications-off:before {
  content: '\f1fc'; }

.zmdi-notifications-paused:before {
  content: '\f1fd'; }

.zmdi-notifications:before {
  content: '\f1fe'; }

.zmdi-account-add:before {
  content: '\f1ff'; }

.zmdi-account-box-mail:before {
  content: '\f200'; }

.zmdi-account-box-o:before {
  content: '\f201'; }

.zmdi-account-box-phone:before {
  content: '\f202'; }

.zmdi-account-box:before {
  content: '\f203'; }

.zmdi-account-calendar:before {
  content: '\f204'; }

.zmdi-account-circle:before {
  content: '\f205'; }

.zmdi-account-o:before {
  content: '\f206'; }

.zmdi-account:before {
  content: '\f207'; }

.zmdi-accounts-add:before {
  content: '\f208'; }

.zmdi-accounts-alt:before {
  content: '\f209'; }

.zmdi-accounts-list-alt:before {
  content: '\f20a'; }

.zmdi-accounts-list:before {
  content: '\f20b'; }

.zmdi-accounts-outline:before {
  content: '\f20c'; }

.zmdi-accounts:before {
  content: '\f20d'; }

.zmdi-face:before {
  content: '\f20e'; }

.zmdi-female:before {
  content: '\f20f'; }

.zmdi-male-alt:before {
  content: '\f210'; }

.zmdi-male-female:before {
  content: '\f211'; }

.zmdi-male:before {
  content: '\f212'; }

.zmdi-mood-bad:before {
  content: '\f213'; }

.zmdi-mood:before {
  content: '\f214'; }

.zmdi-run:before {
  content: '\f215'; }

.zmdi-walk:before {
  content: '\f216'; }

.zmdi-cloud-box:before {
  content: '\f217'; }

.zmdi-cloud-circle:before {
  content: '\f218'; }

.zmdi-cloud-done:before {
  content: '\f219'; }

.zmdi-cloud-download:before {
  content: '\f21a'; }

.zmdi-cloud-off:before {
  content: '\f21b'; }

.zmdi-cloud-outline-alt:before {
  content: '\f21c'; }

.zmdi-cloud-outline:before {
  content: '\f21d'; }

.zmdi-cloud-upload:before {
  content: '\f21e'; }

.zmdi-cloud:before {
  content: '\f21f'; }

.zmdi-download:before {
  content: '\f220'; }

.zmdi-file-plus:before {
  content: '\f221'; }

.zmdi-file-text:before {
  content: '\f222'; }

.zmdi-file:before {
  content: '\f223'; }

.zmdi-folder-outline:before {
  content: '\f224'; }

.zmdi-folder-person:before {
  content: '\f225'; }

.zmdi-folder-star-alt:before {
  content: '\f226'; }

.zmdi-folder-star:before {
  content: '\f227'; }

.zmdi-folder:before {
  content: '\f228'; }

.zmdi-gif:before {
  content: '\f229'; }

.zmdi-upload:before {
  content: '\f22a'; }

.zmdi-border-all:before {
  content: '\f22b'; }

.zmdi-border-bottom:before {
  content: '\f22c'; }

.zmdi-border-clear:before {
  content: '\f22d'; }

.zmdi-border-color:before {
  content: '\f22e'; }

.zmdi-border-horizontal:before {
  content: '\f22f'; }

.zmdi-border-inner:before {
  content: '\f230'; }

.zmdi-border-left:before {
  content: '\f231'; }

.zmdi-border-outer:before {
  content: '\f232'; }

.zmdi-border-right:before {
  content: '\f233'; }

.zmdi-border-style:before {
  content: '\f234'; }

.zmdi-border-top:before {
  content: '\f235'; }

.zmdi-border-vertical:before {
  content: '\f236'; }

.zmdi-copy:before {
  content: '\f237'; }

.zmdi-crop:before {
  content: '\f238'; }

.zmdi-format-align-center:before {
  content: '\f239'; }

.zmdi-format-align-justify:before {
  content: '\f23a'; }

.zmdi-format-align-left:before {
  content: '\f23b'; }

.zmdi-format-align-right:before {
  content: '\f23c'; }

.zmdi-format-bold:before {
  content: '\f23d'; }

.zmdi-format-clear-all:before {
  content: '\f23e'; }

.zmdi-format-clear:before {
  content: '\f23f'; }

.zmdi-format-color-fill:before {
  content: '\f240'; }

.zmdi-format-color-reset:before {
  content: '\f241'; }

.zmdi-format-color-text:before {
  content: '\f242'; }

.zmdi-format-indent-decrease:before {
  content: '\f243'; }

.zmdi-format-indent-increase:before {
  content: '\f244'; }

.zmdi-format-italic:before {
  content: '\f245'; }

.zmdi-format-line-spacing:before {
  content: '\f246'; }

.zmdi-format-list-bulleted:before {
  content: '\f247'; }

.zmdi-format-list-numbered:before {
  content: '\f248'; }

.zmdi-format-ltr:before {
  content: '\f249'; }

.zmdi-format-rtl:before {
  content: '\f24a'; }

.zmdi-format-size:before {
  content: '\f24b'; }

.zmdi-format-strikethrough-s:before {
  content: '\f24c'; }

.zmdi-format-strikethrough:before {
  content: '\f24d'; }

.zmdi-format-subject:before {
  content: '\f24e'; }

.zmdi-format-underlined:before {
  content: '\f24f'; }

.zmdi-format-valign-bottom:before {
  content: '\f250'; }

.zmdi-format-valign-center:before {
  content: '\f251'; }

.zmdi-format-valign-top:before {
  content: '\f252'; }

.zmdi-redo:before {
  content: '\f253'; }

.zmdi-select-all:before {
  content: '\f254'; }

.zmdi-space-bar:before {
  content: '\f255'; }

.zmdi-text-format:before {
  content: '\f256'; }

.zmdi-transform:before {
  content: '\f257'; }

.zmdi-undo:before {
  content: '\f258'; }

.zmdi-wrap-text:before {
  content: '\f259'; }

.zmdi-comment-alert:before {
  content: '\f25a'; }

.zmdi-comment-alt-text:before {
  content: '\f25b'; }

.zmdi-comment-alt:before {
  content: '\f25c'; }

.zmdi-comment-edit:before {
  content: '\f25d'; }

.zmdi-comment-image:before {
  content: '\f25e'; }

.zmdi-comment-list:before {
  content: '\f25f'; }

.zmdi-comment-more:before {
  content: '\f260'; }

.zmdi-comment-outline:before {
  content: '\f261'; }

.zmdi-comment-text-alt:before {
  content: '\f262'; }

.zmdi-comment-text:before {
  content: '\f263'; }

.zmdi-comment-video:before {
  content: '\f264'; }

.zmdi-comment:before {
  content: '\f265'; }

.zmdi-comments:before {
  content: '\f266'; }

.zmdi-check-all:before {
  content: '\f267'; }

.zmdi-check-circle-u:before {
  content: '\f268'; }

.zmdi-check-circle:before {
  content: '\f269'; }

.zmdi-check-square:before {
  content: '\f26a'; }

.zmdi-check:before {
  content: '\f26b'; }

.zmdi-circle-o:before {
  content: '\f26c'; }

.zmdi-circle:before {
  content: '\f26d'; }

.zmdi-dot-circle-alt:before {
  content: '\f26e'; }

.zmdi-dot-circle:before {
  content: '\f26f'; }

.zmdi-minus-circle-outline:before {
  content: '\f270'; }

.zmdi-minus-circle:before {
  content: '\f271'; }

.zmdi-minus-square:before {
  content: '\f272'; }

.zmdi-minus:before {
  content: '\f273'; }

.zmdi-plus-circle-o-duplicate:before {
  content: '\f274'; }

.zmdi-plus-circle-o:before {
  content: '\f275'; }

.zmdi-plus-circle:before {
  content: '\f276'; }

.zmdi-plus-square:before {
  content: '\f277'; }

.zmdi-plus:before {
  content: '\f278'; }

.zmdi-square-o:before {
  content: '\f279'; }

.zmdi-star-circle:before {
  content: '\f27a'; }

.zmdi-star-half:before {
  content: '\f27b'; }

.zmdi-star-outline:before {
  content: '\f27c'; }

.zmdi-star:before {
  content: '\f27d'; }

.zmdi-bluetooth-connected:before {
  content: '\f27e'; }

.zmdi-bluetooth-off:before {
  content: '\f27f'; }

.zmdi-bluetooth-search:before {
  content: '\f280'; }

.zmdi-bluetooth-setting:before {
  content: '\f281'; }

.zmdi-bluetooth:before {
  content: '\f282'; }

.zmdi-camera-add:before {
  content: '\f283'; }

.zmdi-camera-alt:before {
  content: '\f284'; }

.zmdi-camera-bw:before {
  content: '\f285'; }

.zmdi-camera-front:before {
  content: '\f286'; }

.zmdi-camera-mic:before {
  content: '\f287'; }

.zmdi-camera-party-mode:before {
  content: '\f288'; }

.zmdi-camera-rear:before {
  content: '\f289'; }

.zmdi-camera-roll:before {
  content: '\f28a'; }

.zmdi-camera-switch:before {
  content: '\f28b'; }

.zmdi-camera:before {
  content: '\f28c'; }

.zmdi-card-alert:before {
  content: '\f28d'; }

.zmdi-card-off:before {
  content: '\f28e'; }

.zmdi-card-sd:before {
  content: '\f28f'; }

.zmdi-card-sim:before {
  content: '\f290'; }

.zmdi-desktop-mac:before {
  content: '\f291'; }

.zmdi-desktop-windows:before {
  content: '\f292'; }

.zmdi-device-hub:before {
  content: '\f293'; }

.zmdi-devices-off:before {
  content: '\f294'; }

.zmdi-devices:before {
  content: '\f295'; }

.zmdi-dock:before {
  content: '\f296'; }

.zmdi-floppy:before {
  content: '\f297'; }

.zmdi-gamepad:before {
  content: '\f298'; }

.zmdi-gps-dot:before {
  content: '\f299'; }

.zmdi-gps-off:before {
  content: '\f29a'; }

.zmdi-gps:before {
  content: '\f29b'; }

.zmdi-headset-mic:before {
  content: '\f29c'; }

.zmdi-headset:before {
  content: '\f29d'; }

.zmdi-input-antenna:before {
  content: '\f29e'; }

.zmdi-input-composite:before {
  content: '\f29f'; }

.zmdi-input-hdmi:before {
  content: '\f2a0'; }

.zmdi-input-power:before {
  content: '\f2a1'; }

.zmdi-input-svideo:before {
  content: '\f2a2'; }

.zmdi-keyboard-hide:before {
  content: '\f2a3'; }

.zmdi-keyboard:before {
  content: '\f2a4'; }

.zmdi-laptop-chromebook:before {
  content: '\f2a5'; }

.zmdi-laptop-mac:before {
  content: '\f2a6'; }

.zmdi-laptop:before {
  content: '\f2a7'; }

.zmdi-mic-off:before {
  content: '\f2a8'; }

.zmdi-mic-outline:before {
  content: '\f2a9'; }

.zmdi-mic-setting:before {
  content: '\f2aa'; }

.zmdi-mic:before {
  content: '\f2ab'; }

.zmdi-mouse:before {
  content: '\f2ac'; }

.zmdi-network-alert:before {
  content: '\f2ad'; }

.zmdi-network-locked:before {
  content: '\f2ae'; }

.zmdi-network-off:before {
  content: '\f2af'; }

.zmdi-network-outline:before {
  content: '\f2b0'; }

.zmdi-network-setting:before {
  content: '\f2b1'; }

.zmdi-network:before {
  content: '\f2b2'; }

.zmdi-phone-bluetooth:before {
  content: '\f2b3'; }

.zmdi-phone-end:before {
  content: '\f2b4'; }

.zmdi-phone-forwarded:before {
  content: '\f2b5'; }

.zmdi-phone-in-talk:before {
  content: '\f2b6'; }

.zmdi-phone-locked:before {
  content: '\f2b7'; }

.zmdi-phone-missed:before {
  content: '\f2b8'; }

.zmdi-phone-msg:before {
  content: '\f2b9'; }

.zmdi-phone-paused:before {
  content: '\f2ba'; }

.zmdi-phone-ring:before {
  content: '\f2bb'; }

.zmdi-phone-setting:before {
  content: '\f2bc'; }

.zmdi-phone-sip:before {
  content: '\f2bd'; }

.zmdi-phone:before {
  content: '\f2be'; }

.zmdi-portable-wifi-changes:before {
  content: '\f2bf'; }

.zmdi-portable-wifi-off:before {
  content: '\f2c0'; }

.zmdi-portable-wifi:before {
  content: '\f2c1'; }

.zmdi-radio:before {
  content: '\f2c2'; }

.zmdi-reader:before {
  content: '\f2c3'; }

.zmdi-remote-control-alt:before {
  content: '\f2c4'; }

.zmdi-remote-control:before {
  content: '\f2c5'; }

.zmdi-router:before {
  content: '\f2c6'; }

.zmdi-scanner:before {
  content: '\f2c7'; }

.zmdi-smartphone-android:before {
  content: '\f2c8'; }

.zmdi-smartphone-download:before {
  content: '\f2c9'; }

.zmdi-smartphone-erase:before {
  content: '\f2ca'; }

.zmdi-smartphone-info:before {
  content: '\f2cb'; }

.zmdi-smartphone-iphone:before {
  content: '\f2cc'; }

.zmdi-smartphone-landscape-lock:before {
  content: '\f2cd'; }

.zmdi-smartphone-landscape:before {
  content: '\f2ce'; }

.zmdi-smartphone-lock:before {
  content: '\f2cf'; }

.zmdi-smartphone-portrait-lock:before {
  content: '\f2d0'; }

.zmdi-smartphone-ring:before {
  content: '\f2d1'; }

.zmdi-smartphone-setting:before {
  content: '\f2d2'; }

.zmdi-smartphone-setup:before {
  content: '\f2d3'; }

.zmdi-smartphone:before {
  content: '\f2d4'; }

.zmdi-speaker:before {
  content: '\f2d5'; }

.zmdi-tablet-android:before {
  content: '\f2d6'; }

.zmdi-tablet-mac:before {
  content: '\f2d7'; }

.zmdi-tablet:before {
  content: '\f2d8'; }

.zmdi-tv-alt-play:before {
  content: '\f2d9'; }

.zmdi-tv-list:before {
  content: '\f2da'; }

.zmdi-tv-play:before {
  content: '\f2db'; }

.zmdi-tv:before {
  content: '\f2dc'; }

.zmdi-usb:before {
  content: '\f2dd'; }

.zmdi-videocam-off:before {
  content: '\f2de'; }

.zmdi-videocam-switch:before {
  content: '\f2df'; }

.zmdi-videocam:before {
  content: '\f2e0'; }

.zmdi-watch:before {
  content: '\f2e1'; }

.zmdi-wifi-alt-2:before {
  content: '\f2e2'; }

.zmdi-wifi-alt:before {
  content: '\f2e3'; }

.zmdi-wifi-info:before {
  content: '\f2e4'; }

.zmdi-wifi-lock:before {
  content: '\f2e5'; }

.zmdi-wifi-off:before {
  content: '\f2e6'; }

.zmdi-wifi-outline:before {
  content: '\f2e7'; }

.zmdi-wifi:before {
  content: '\f2e8'; }

.zmdi-arrow-left-bottom:before {
  content: '\f2e9'; }

.zmdi-arrow-left:before {
  content: '\f2ea'; }

.zmdi-arrow-merge:before {
  content: '\f2eb'; }

.zmdi-arrow-missed:before {
  content: '\f2ec'; }

.zmdi-arrow-right-top:before {
  content: '\f2ed'; }

.zmdi-arrow-right:before {
  content: '\f2ee'; }

.zmdi-arrow-split:before {
  content: '\f2ef'; }

.zmdi-arrows:before {
  content: '\f2f0'; }

.zmdi-caret-down-circle:before {
  content: '\f2f1'; }

.zmdi-caret-down:before {
  content: '\f2f2'; }

.zmdi-caret-left-circle:before {
  content: '\f2f3'; }

.zmdi-caret-left:before {
  content: '\f2f4'; }

.zmdi-caret-right-circle:before {
  content: '\f2f5'; }

.zmdi-caret-right:before {
  content: '\f2f6'; }

.zmdi-caret-up-circle:before {
  content: '\f2f7'; }

.zmdi-caret-up:before {
  content: '\f2f8'; }

.zmdi-chevron-down:before {
  content: '\f2f9'; }

.zmdi-chevron-left:before {
  content: '\f2fa'; }

.zmdi-chevron-right:before {
  content: '\f2fb'; }

.zmdi-chevron-up:before {
  content: '\f2fc'; }

.zmdi-forward:before {
  content: '\f2fd'; }

.zmdi-long-arrow-down:before {
  content: '\f2fe'; }

.zmdi-long-arrow-left:before {
  content: '\f2ff'; }

.zmdi-long-arrow-return:before {
  content: '\f300'; }

.zmdi-long-arrow-right:before {
  content: '\f301'; }

.zmdi-long-arrow-tab:before {
  content: '\f302'; }

.zmdi-long-arrow-up:before {
  content: '\f303'; }

.zmdi-rotate-ccw:before {
  content: '\f304'; }

.zmdi-rotate-cw:before {
  content: '\f305'; }

.zmdi-rotate-left:before {
  content: '\f306'; }

.zmdi-rotate-right:before {
  content: '\f307'; }

.zmdi-square-down:before {
  content: '\f308'; }

.zmdi-square-right:before {
  content: '\f309'; }

.zmdi-swap-alt:before {
  content: '\f30a'; }

.zmdi-swap-vertical-circle:before {
  content: '\f30b'; }

.zmdi-swap-vertical:before {
  content: '\f30c'; }

.zmdi-swap:before {
  content: '\f30d'; }

.zmdi-trending-down:before {
  content: '\f30e'; }

.zmdi-trending-flat:before {
  content: '\f30f'; }

.zmdi-trending-up:before {
  content: '\f310'; }

.zmdi-unfold-less:before {
  content: '\f311'; }

.zmdi-unfold-more:before {
  content: '\f312'; }

.zmdi-apps:before {
  content: '\f313'; }

.zmdi-grid-off:before {
  content: '\f314'; }

.zmdi-grid:before {
  content: '\f315'; }

.zmdi-view-agenda:before {
  content: '\f316'; }

.zmdi-view-array:before {
  content: '\f317'; }

.zmdi-view-carousel:before {
  content: '\f318'; }

.zmdi-view-column:before {
  content: '\f319'; }

.zmdi-view-comfy:before {
  content: '\f31a'; }

.zmdi-view-compact:before {
  content: '\f31b'; }

.zmdi-view-dashboard:before {
  content: '\f31c'; }

.zmdi-view-day:before {
  content: '\f31d'; }

.zmdi-view-headline:before {
  content: '\f31e'; }

.zmdi-view-list-alt:before {
  content: '\f31f'; }

.zmdi-view-list:before {
  content: '\f320'; }

.zmdi-view-module:before {
  content: '\f321'; }

.zmdi-view-quilt:before {
  content: '\f322'; }

.zmdi-view-stream:before {
  content: '\f323'; }

.zmdi-view-subtitles:before {
  content: '\f324'; }

.zmdi-view-toc:before {
  content: '\f325'; }

.zmdi-view-web:before {
  content: '\f326'; }

.zmdi-view-week:before {
  content: '\f327'; }

.zmdi-widgets:before {
  content: '\f328'; }

.zmdi-alarm-check:before {
  content: '\f329'; }

.zmdi-alarm-off:before {
  content: '\f32a'; }

.zmdi-alarm-plus:before {
  content: '\f32b'; }

.zmdi-alarm-snooze:before {
  content: '\f32c'; }

.zmdi-alarm:before {
  content: '\f32d'; }

.zmdi-calendar-alt:before {
  content: '\f32e'; }

.zmdi-calendar-check:before {
  content: '\f32f'; }

.zmdi-calendar-close:before {
  content: '\f330'; }

.zmdi-calendar-note:before {
  content: '\f331'; }

.zmdi-calendar:before {
  content: '\f332'; }

.zmdi-time-countdown:before {
  content: '\f333'; }

.zmdi-time-interval:before {
  content: '\f334'; }

.zmdi-time-restore-setting:before {
  content: '\f335'; }

.zmdi-time-restore:before {
  content: '\f336'; }

.zmdi-time:before {
  content: '\f337'; }

.zmdi-timer-off:before {
  content: '\f338'; }

.zmdi-timer:before {
  content: '\f339'; }

.zmdi-android-alt:before {
  content: '\f33a'; }

.zmdi-android:before {
  content: '\f33b'; }

.zmdi-apple:before {
  content: '\f33c'; }

.zmdi-behance:before {
  content: '\f33d'; }

.zmdi-codepen:before {
  content: '\f33e'; }

.zmdi-dribbble:before {
  content: '\f33f'; }

.zmdi-dropbox:before {
  content: '\f340'; }

.zmdi-evernote:before {
  content: '\f341'; }

.zmdi-facebook-box:before {
  content: '\f342'; }

.zmdi-facebook:before {
  content: '\f343'; }

.zmdi-github-box:before {
  content: '\f344'; }

.zmdi-github:before {
  content: '\f345'; }

.zmdi-google-drive:before {
  content: '\f346'; }

.zmdi-google-earth:before {
  content: '\f347'; }

.zmdi-google-glass:before {
  content: '\f348'; }

.zmdi-google-maps:before {
  content: '\f349'; }

.zmdi-google-pages:before {
  content: '\f34a'; }

.zmdi-google-play:before {
  content: '\f34b'; }

.zmdi-google-plus-box:before {
  content: '\f34c'; }

.zmdi-google-plus:before {
  content: '\f34d'; }

.zmdi-google:before {
  content: '\f34e'; }

.zmdi-instagram:before {
  content: '\f34f'; }

.zmdi-language-css3:before {
  content: '\f350'; }

.zmdi-language-html5:before {
  content: '\f351'; }

.zmdi-language-javascript:before {
  content: '\f352'; }

.zmdi-language-python-alt:before {
  content: '\f353'; }

.zmdi-language-python:before {
  content: '\f354'; }

.zmdi-lastfm:before {
  content: '\f355'; }

.zmdi-linkedin-box:before {
  content: '\f356'; }

.zmdi-paypal:before {
  content: '\f357'; }

.zmdi-pinterest-box:before {
  content: '\f358'; }

.zmdi-pocket:before {
  content: '\f359'; }

.zmdi-polymer:before {
  content: '\f35a'; }

.zmdi-share:before {
  content: '\f35b'; }

.zmdi-stackoverflow:before {
  content: '\f35c'; }

.zmdi-steam-square:before {
  content: '\f35d'; }

.zmdi-steam:before {
  content: '\f35e'; }

.zmdi-twitter-box:before {
  content: '\f35f'; }

.zmdi-twitter:before {
  content: '\f360'; }

.zmdi-vk:before {
  content: '\f361'; }

.zmdi-wikipedia:before {
  content: '\f362'; }

.zmdi-windows:before {
  content: '\f363'; }

.zmdi-aspect-ratio-alt:before {
  content: '\f364'; }

.zmdi-aspect-ratio:before {
  content: '\f365'; }

.zmdi-blur-circular:before {
  content: '\f366'; }

.zmdi-blur-linear:before {
  content: '\f367'; }

.zmdi-blur-off:before {
  content: '\f368'; }

.zmdi-blur:before {
  content: '\f369'; }

.zmdi-brightness-2:before {
  content: '\f36a'; }

.zmdi-brightness-3:before {
  content: '\f36b'; }

.zmdi-brightness-4:before {
  content: '\f36c'; }

.zmdi-brightness-5:before {
  content: '\f36d'; }

.zmdi-brightness-6:before {
  content: '\f36e'; }

.zmdi-brightness-7:before {
  content: '\f36f'; }

.zmdi-brightness-auto:before {
  content: '\f370'; }

.zmdi-brightness-setting:before {
  content: '\f371'; }

.zmdi-broken-image:before {
  content: '\f372'; }

.zmdi-center-focus-strong:before {
  content: '\f373'; }

.zmdi-center-focus-weak:before {
  content: '\f374'; }

.zmdi-compare:before {
  content: '\f375'; }

.zmdi-crop-16-9:before {
  content: '\f376'; }

.zmdi-crop-3-2:before {
  content: '\f377'; }

.zmdi-crop-5-4:before {
  content: '\f378'; }

.zmdi-crop-7-5:before {
  content: '\f379'; }

.zmdi-crop-din:before {
  content: '\f37a'; }

.zmdi-crop-free:before {
  content: '\f37b'; }

.zmdi-crop-landscape:before {
  content: '\f37c'; }

.zmdi-crop-portrait:before {
  content: '\f37d'; }

.zmdi-crop-square:before {
  content: '\f37e'; }

.zmdi-exposure-alt:before {
  content: '\f37f'; }

.zmdi-exposure:before {
  content: '\f380'; }

.zmdi-filter-b-and-w:before {
  content: '\f381'; }

.zmdi-filter-center-focus:before {
  content: '\f382'; }

.zmdi-filter-frames:before {
  content: '\f383'; }

.zmdi-filter-tilt-shift:before {
  content: '\f384'; }

.zmdi-gradient:before {
  content: '\f385'; }

.zmdi-grain:before {
  content: '\f386'; }

.zmdi-graphic-eq:before {
  content: '\f387'; }

.zmdi-hdr-off:before {
  content: '\f388'; }

.zmdi-hdr-strong:before {
  content: '\f389'; }

.zmdi-hdr-weak:before {
  content: '\f38a'; }

.zmdi-hdr:before {
  content: '\f38b'; }

.zmdi-iridescent:before {
  content: '\f38c'; }

.zmdi-leak-off:before {
  content: '\f38d'; }

.zmdi-leak:before {
  content: '\f38e'; }

.zmdi-looks:before {
  content: '\f38f'; }

.zmdi-loupe:before {
  content: '\f390'; }

.zmdi-panorama-horizontal:before {
  content: '\f391'; }

.zmdi-panorama-vertical:before {
  content: '\f392'; }

.zmdi-panorama-wide-angle:before {
  content: '\f393'; }

.zmdi-photo-size-select-large:before {
  content: '\f394'; }

.zmdi-photo-size-select-small:before {
  content: '\f395'; }

.zmdi-picture-in-picture:before {
  content: '\f396'; }

.zmdi-slideshow:before {
  content: '\f397'; }

.zmdi-texture:before {
  content: '\f398'; }

.zmdi-tonality:before {
  content: '\f399'; }

.zmdi-vignette:before {
  content: '\f39a'; }

.zmdi-wb-auto:before {
  content: '\f39b'; }

.zmdi-eject-alt:before {
  content: '\f39c'; }

.zmdi-eject:before {
  content: '\f39d'; }

.zmdi-equalizer:before {
  content: '\f39e'; }

.zmdi-fast-forward:before {
  content: '\f39f'; }

.zmdi-fast-rewind:before {
  content: '\f3a0'; }

.zmdi-forward-10:before {
  content: '\f3a1'; }

.zmdi-forward-30:before {
  content: '\f3a2'; }

.zmdi-forward-5:before {
  content: '\f3a3'; }

.zmdi-hearing:before {
  content: '\f3a4'; }

.zmdi-pause-circle-outline:before {
  content: '\f3a5'; }

.zmdi-pause-circle:before {
  content: '\f3a6'; }

.zmdi-pause:before {
  content: '\f3a7'; }

.zmdi-play-circle-outline:before {
  content: '\f3a8'; }

.zmdi-play-circle:before {
  content: '\f3a9'; }

.zmdi-play:before {
  content: '\f3aa'; }

.zmdi-playlist-audio:before {
  content: '\f3ab'; }

.zmdi-playlist-plus:before {
  content: '\f3ac'; }

.zmdi-repeat-one:before {
  content: '\f3ad'; }

.zmdi-repeat:before {
  content: '\f3ae'; }

.zmdi-replay-10:before {
  content: '\f3af'; }

.zmdi-replay-30:before {
  content: '\f3b0'; }

.zmdi-replay-5:before {
  content: '\f3b1'; }

.zmdi-replay:before {
  content: '\f3b2'; }

.zmdi-shuffle:before {
  content: '\f3b3'; }

.zmdi-skip-next:before {
  content: '\f3b4'; }

.zmdi-skip-previous:before {
  content: '\f3b5'; }

.zmdi-stop:before {
  content: '\f3b6'; }

.zmdi-surround-sound:before {
  content: '\f3b7'; }

.zmdi-tune:before {
  content: '\f3b8'; }

.zmdi-volume-down:before {
  content: '\f3b9'; }

.zmdi-volume-mute:before {
  content: '\f3ba'; }

.zmdi-volume-off:before {
  content: '\f3bb'; }

.zmdi-volume-up:before {
  content: '\f3bc'; }

.zmdi-n-1-square:before {
  content: '\f3bd'; }

.zmdi-n-2-square:before {
  content: '\f3be'; }

.zmdi-n-3-square:before {
  content: '\f3bf'; }

.zmdi-n-4-square:before {
  content: '\f3c0'; }

.zmdi-n-5-square:before {
  content: '\f3c1'; }

.zmdi-n-6-square:before {
  content: '\f3c2'; }

.zmdi-neg-1:before {
  content: '\f3c3'; }

.zmdi-neg-2:before {
  content: '\f3c4'; }

.zmdi-plus-1:before {
  content: '\f3c5'; }

.zmdi-plus-2:before {
  content: '\f3c6'; }

.zmdi-sec-10:before {
  content: '\f3c7'; }

.zmdi-sec-3:before {
  content: '\f3c8'; }

.zmdi-zero:before {
  content: '\f3c9'; }

.zmdi-airline-seat-flat-angled:before {
  content: '\f3ca'; }

.zmdi-airline-seat-flat:before {
  content: '\f3cb'; }

.zmdi-airline-seat-individual-suite:before {
  content: '\f3cc'; }

.zmdi-airline-seat-legroom-extra:before {
  content: '\f3cd'; }

.zmdi-airline-seat-legroom-normal:before {
  content: '\f3ce'; }

.zmdi-airline-seat-legroom-reduced:before {
  content: '\f3cf'; }

.zmdi-airline-seat-recline-extra:before {
  content: '\f3d0'; }

.zmdi-airline-seat-recline-normal:before {
  content: '\f3d1'; }

.zmdi-airplay:before {
  content: '\f3d2'; }

.zmdi-closed-caption:before {
  content: '\f3d3'; }

.zmdi-confirmation-number:before {
  content: '\f3d4'; }

.zmdi-developer-board:before {
  content: '\f3d5'; }

.zmdi-disc-full:before {
  content: '\f3d6'; }

.zmdi-explicit:before {
  content: '\f3d7'; }

.zmdi-flight-land:before {
  content: '\f3d8'; }

.zmdi-flight-takeoff:before {
  content: '\f3d9'; }

.zmdi-flip-to-back:before {
  content: '\f3da'; }

.zmdi-flip-to-front:before {
  content: '\f3db'; }

.zmdi-group-work:before {
  content: '\f3dc'; }

.zmdi-hd:before {
  content: '\f3dd'; }

.zmdi-hq:before {
  content: '\f3de'; }

.zmdi-markunread-mailbox:before {
  content: '\f3df'; }

.zmdi-memory:before {
  content: '\f3e0'; }

.zmdi-nfc:before {
  content: '\f3e1'; }

.zmdi-play-for-work:before {
  content: '\f3e2'; }

.zmdi-power-input:before {
  content: '\f3e3'; }

.zmdi-present-to-all:before {
  content: '\f3e4'; }

.zmdi-satellite:before {
  content: '\f3e5'; }

.zmdi-tap-and-play:before {
  content: '\f3e6'; }

.zmdi-vibration:before {
  content: '\f3e7'; }

.zmdi-voicemail:before {
  content: '\f3e8'; }

.zmdi-group:before {
  content: '\f3e9'; }

.zmdi-rss:before {
  content: '\f3ea'; }

.zmdi-shape:before {
  content: '\f3eb'; }

.zmdi-spinner:before {
  content: '\f3ec'; }

.zmdi-ungroup:before {
  content: '\f3ed'; }

.zmdi-500px:before {
  content: '\f3ee'; }

.zmdi-8tracks:before {
  content: '\f3ef'; }

.zmdi-amazon:before {
  content: '\f3f0'; }

.zmdi-blogger:before {
  content: '\f3f1'; }

.zmdi-delicious:before {
  content: '\f3f2'; }

.zmdi-disqus:before {
  content: '\f3f3'; }

.zmdi-flattr:before {
  content: '\f3f4'; }

.zmdi-flickr:before {
  content: '\f3f5'; }

.zmdi-github-alt:before {
  content: '\f3f6'; }

.zmdi-google-old:before {
  content: '\f3f7'; }

.zmdi-linkedin:before {
  content: '\f3f8'; }

.zmdi-odnoklassniki:before {
  content: '\f3f9'; }

.zmdi-outlook:before {
  content: '\f3fa'; }

.zmdi-paypal-alt:before {
  content: '\f3fb'; }

.zmdi-pinterest:before {
  content: '\f3fc'; }

.zmdi-playstation:before {
  content: '\f3fd'; }

.zmdi-reddit:before {
  content: '\f3fe'; }

.zmdi-skype:before {
  content: '\f3ff'; }

.zmdi-slideshare:before {
  content: '\f400'; }

.zmdi-soundcloud:before {
  content: '\f401'; }

.zmdi-tumblr:before {
  content: '\f402'; }

.zmdi-twitch:before {
  content: '\f403'; }

.zmdi-vimeo:before {
  content: '\f404'; }

.zmdi-whatsapp:before {
  content: '\f405'; }

.zmdi-xbox:before {
  content: '\f406'; }

.zmdi-yahoo:before {
  content: '\f407'; }

.zmdi-youtube-play:before {
  content: '\f408'; }

.zmdi-youtube:before {
  content: '\f409'; }

.zmdi-import-export:before {
  content: '\f30c'; }

.zmdi-swap-vertical-:before {
  content: '\f30c'; }

.zmdi-airplanemode-inactive:before {
  content: '\f102'; }

.zmdi-airplanemode-active:before {
  content: '\f103'; }

.zmdi-rate-review:before {
  content: '\f103'; }

.zmdi-comment-sign:before {
  content: '\f25a'; }

.zmdi-network-warning:before {
  content: '\f2ad'; }

.zmdi-shopping-cart-add:before {
  content: '\f1ca'; }

.zmdi-file-add:before {
  content: '\f221'; }

.zmdi-network-wifi-scan:before {
  content: '\f2e4'; }

.zmdi-collection-add:before {
  content: '\f14e'; }

.zmdi-format-playlist-add:before {
  content: '\f3ac'; }

.zmdi-format-queue-music:before {
  content: '\f3ab'; }

.zmdi-plus-box:before {
  content: '\f277'; }

.zmdi-tag-backspace:before {
  content: '\f1d9'; }

.zmdi-alarm-add:before {
  content: '\f32b'; }

.zmdi-battery-charging:before {
  content: '\f114'; }

.zmdi-daydream-setting:before {
  content: '\f217'; }

.zmdi-more-horiz:before {
  content: '\f19c'; }

.zmdi-book-photo:before {
  content: '\f11b'; }

.zmdi-incandescent:before {
  content: '\f189'; }

.zmdi-wb-iridescent:before {
  content: '\f38c'; }

.zmdi-calendar-remove:before {
  content: '\f330'; }

.zmdi-refresh-sync-disabled:before {
  content: '\f1b7'; }

.zmdi-refresh-sync-problem:before {
  content: '\f1b6'; }

.zmdi-crop-original:before {
  content: '\f17e'; }

.zmdi-power-off:before {
  content: '\f1af'; }

.zmdi-power-off-setting:before {
  content: '\f1ae'; }

.zmdi-leak-remove:before {
  content: '\f38d'; }

.zmdi-star-border:before {
  content: '\f27c'; }

.zmdi-brightness-low:before {
  content: '\f36d'; }

.zmdi-brightness-medium:before {
  content: '\f36e'; }

.zmdi-brightness-high:before {
  content: '\f36f'; }

.zmdi-smartphone-portrait:before {
  content: '\f2d4'; }

.zmdi-live-tv:before {
  content: '\f2d9'; }

.zmdi-format-textdirection-l-to-r:before {
  content: '\f249'; }

.zmdi-format-textdirection-r-to-l:before {
  content: '\f24a'; }

.zmdi-arrow-back:before {
  content: '\f2ea'; }

.zmdi-arrow-forward:before {
  content: '\f2ee'; }

.zmdi-arrow-in:before {
  content: '\f2e9'; }

.zmdi-arrow-out:before {
  content: '\f2ed'; }

.zmdi-rotate-90-degrees-ccw:before {
  content: '\f304'; }

.zmdi-adb:before {
  content: '\f33a'; }

.zmdi-network-wifi:before {
  content: '\f2e8'; }

.zmdi-network-wifi-alt:before {
  content: '\f2e3'; }

.zmdi-network-wifi-lock:before {
  content: '\f2e5'; }

.zmdi-network-wifi-off:before {
  content: '\f2e6'; }

.zmdi-network-wifi-outline:before {
  content: '\f2e7'; }

.zmdi-network-wifi-info:before {
  content: '\f2e4'; }

.zmdi-layers-clear:before {
  content: '\f18b'; }

.zmdi-colorize:before {
  content: '\f15d'; }

.zmdi-format-paint:before {
  content: '\f1ba'; }

.zmdi-format-quote:before {
  content: '\f1b2'; }

.zmdi-camera-monochrome-photos:before {
  content: '\f285'; }

.zmdi-sort-by-alpha:before {
  content: '\f1cf'; }

.zmdi-folder-shared:before {
  content: '\f225'; }

.zmdi-folder-special:before {
  content: '\f226'; }

.zmdi-comment-dots:before {
  content: '\f260'; }

.zmdi-reorder:before {
  content: '\f31e'; }

.zmdi-dehaze:before {
  content: '\f197'; }

.zmdi-sort:before {
  content: '\f1ce'; }

.zmdi-pages:before {
  content: '\f34a'; }

.zmdi-stack-overflow:before {
  content: '\f35c'; }

.zmdi-calendar-account:before {
  content: '\f204'; }

.zmdi-paste:before {
  content: '\f109'; }

.zmdi-cut:before {
  content: '\f1bc'; }

.zmdi-save:before {
  content: '\f297'; }

.zmdi-smartphone-code:before {
  content: '\f139'; }

.zmdi-directions-bike:before {
  content: '\f117'; }

.zmdi-directions-boat:before {
  content: '\f11a'; }

.zmdi-directions-bus:before {
  content: '\f121'; }

.zmdi-directions-car:before {
  content: '\f125'; }

.zmdi-directions-railway:before {
  content: '\f1b3'; }

.zmdi-directions-run:before {
  content: '\f215'; }

.zmdi-directions-subway:before {
  content: '\f1d5'; }

.zmdi-directions-walk:before {
  content: '\f216'; }

.zmdi-local-hotel:before {
  content: '\f178'; }

.zmdi-local-activity:before {
  content: '\f1df'; }

.zmdi-local-play:before {
  content: '\f1df'; }

.zmdi-local-airport:before {
  content: '\f103'; }

.zmdi-local-atm:before {
  content: '\f198'; }

.zmdi-local-bar:before {
  content: '\f137'; }

.zmdi-local-cafe:before {
  content: '\f13b'; }

.zmdi-local-car-wash:before {
  content: '\f124'; }

.zmdi-local-convenience-store:before {
  content: '\f1d3'; }

.zmdi-local-dining:before {
  content: '\f153'; }

.zmdi-local-drink:before {
  content: '\f157'; }

.zmdi-local-florist:before {
  content: '\f168'; }

.zmdi-local-gas-station:before {
  content: '\f16f'; }

.zmdi-local-grocery-store:before {
  content: '\f1cb'; }

.zmdi-local-hospital:before {
  content: '\f177'; }

.zmdi-local-laundry-service:before {
  content: '\f1e9'; }

.zmdi-local-library:before {
  content: '\f18d'; }

.zmdi-local-mall:before {
  content: '\f195'; }

.zmdi-local-movies:before {
  content: '\f19d'; }

.zmdi-local-offer:before {
  content: '\f187'; }

.zmdi-local-parking:before {
  content: '\f1a5'; }

.zmdi-local-parking:before {
  content: '\f1a5'; }

.zmdi-local-pharmacy:before {
  content: '\f176'; }

.zmdi-local-phone:before {
  content: '\f2be'; }

.zmdi-local-pizza:before {
  content: '\f1ac'; }

.zmdi-local-post-office:before {
  content: '\f15a'; }

.zmdi-local-printshop:before {
  content: '\f1b0'; }

.zmdi-local-see:before {
  content: '\f28c'; }

.zmdi-local-shipping:before {
  content: '\f1e6'; }

.zmdi-local-store:before {
  content: '\f1d4'; }

.zmdi-local-taxi:before {
  content: '\f123'; }

.zmdi-local-wc:before {
  content: '\f211'; }

.zmdi-my-location:before {
  content: '\f299'; }

.zmdi-directions:before {
  content: '\f1e7'; }

.sidebar {
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
  border-right: 1px solid #eee; }

.sidebar .nav {
  margin-bottom: 20px; }

.sidebar .nav-item {
  width: 100%; }

.sidebar .nav-item + .nav-item {
  margin-left: 0; }

.sidebar .nav-link {
  border-radius: 0; }

