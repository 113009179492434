.sidebar {
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
  border-right: 1px solid #eee; }

.sidebar .nav {
  margin-bottom: 20px; }

.sidebar .nav-item {
  width: 100%; }

.sidebar .nav-item + .nav-item {
  margin-left: 0; }

.sidebar .nav-link {
  border-radius: 0; }
